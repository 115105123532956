// allow for using MathJax's "clearspeak" system to generate alt-text for LaTeX formulas, without the overhead of loading/initializing MathJax when we don't need it
// modeled after code from https://mathjax.github.io/MathJax-demos-web/speech-generator/convert-with-speech.html
// for notes on options, see https://github.com/Speech-Rule-Engine/speech-rule-engine/tree/master#options-to-control-speech-output

// Note that we are not currently using this; we use MathLive's clearspeak engine instead
// file left in repo for archival purposes
U.mathjax = {
	started_initializing: false,
	SRE: null,
	sre_ready: false,

	initialize: () => {
		return
		// if we've already started initializing, just return
		if (U.mathjax.started_initializing) return
		U.mathjax.started_initializing = true
		// also store in the store
		vapp.$store.state.mathjax_initialized = 'started'

		// set up initialization parameters
		window.MathJax = {
			loader: {load: ['input/tex', 'output/svg', 'a11y/sre' ]},
			tex: {inlineMath: [['$', '$'], ['\\(', '\\)']]},
			// loader: {load: ['input/tex', 'output/svg', 'a11y/sre', '[tex]/autoload', '[tex]/color', '[tex]/textmacros', '[tex]/gensymb', '[tex]/physics', '[tex]/upgreek', ]},
			// tex: {inlineMath: [['$', '$'], ['\\(', '\\)']], packages: {'[+]': ['autoload', 'textmacros', 'gensymb', 'physics', 'upgreek']}},
			startup: {
				ready: function() {
					MathJax.startup.defaultReady()
					// Initialize convert when MathJax/SRE is fully loaded.
					MathJax.startup.promise.then(function () {
						U.mathjax.SRE = MathJax._.a11y.sre.Sre
						U.mathjax.SRE.setupEngine({
							locale: 'en',
							domain: 'clearspeak',
							modality: 'speech',
							style: 'AbsoluteValue_Auto:Bar_Auto:Caps_Auto:CombinationPermutation_Auto:Currency_Auto:Ellipses_Auto:Enclosed_Auto:Exponent_Auto:Fraction_Auto:Functions_Auto:ImpliedTimes_Auto:Log_Auto:Matrix_Auto:MultsymbolDot_Auto:MultsymbolX_Auto:Paren_Auto:Prime_Auto:Roots_Auto:SetMemberSymbol_Auto:Sets_Auto:TriangleSymbol_Auto:Trig_Auto:VerticalLine_Auto:MultiLineLabel_Auto:MultiLineOverview_Auto:MultiLinePausesBetweenColumns_Auto',
							// markup: 'none',
							markup: 'punctuation',	// this inserts commas as pauses into the equation, which seems helpful
							pprint: true,
						}).then(() => {
							console.log('MathJax ready to translate LaTeX...')
							U.mathjax.sre_ready = true
							// also store in the store
							vapp.$store.state.mathjax_initialized = 'done'
						})
					})
				}
			}
		}

		// then load this script from cdn to load the right mathjax components
		let mathJaxSrc = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/startup.js';
		let mathJaxScript = document.createElement('script');
		mathJaxScript.src = mathJaxSrc;
		mathJaxScript.async = true;
		document.head.appendChild(mathJaxScript);
		// once this is called, generate_clearspeak can be called
	},

	// to use this version, first call U.mathjax.initialize() and make sure to wait at least a second for it to be initialized (or wait for U.mathjax.sre_ready to be true); then call like this:
	// U.mathjax.generate_clearspeak('\\sqrt{47}')
	generate_clearspeak: (l) => {
		// console.log(l)
		if (!U.mathjax.sre_ready) {
			console.log('MathJax processor not ready')
			return 'MathJax processor not ready'
		}
		return U.mathjax.SRE.toSpeech(MathJax.tex2mml(l))
	},

	// use this version if it's OK to use a promise
	// U.mathjax.generate_clearspeak_await('\\sqrt{47}').then((cs)=>{ … })
	// U.mathjax.generate_clearspeak_await('\\sqrt{47}')
	// 	.then((cs)=>{ console.log('here is the clearspeak: ', cs) })
	// 	.catch(()=>{ console.log('MathJax processor could not load.') })
	generate_clearspeak_await: (l) => {
		return new Promise((resolve, reject)=>{
			U.mathjax.initialize()
			U.mathjax.generate_clearspeak_await_helper(l, resolve, reject, 200)
		})
	},
	
	generate_clearspeak_await_helper: (l, resolve, reject, n) => {
		// console.log(`generate_clearspeak_await_helper: ${n}`, l)
		if (n == 0) {
			reject()

		} else if (U.mathjax.sre_ready) {
			// console.log(MathJax.tex2mml(l))
			resolve(U.mathjax.SRE.toSpeech(MathJax.tex2mml(l)))

		} else {
			setTimeout(()=>{U.mathjax.generate_clearspeak_await_helper(l, resolve, reject, n-1)}, 50)
		}
	}
}