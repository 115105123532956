<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div v-if="expanded&&!minimized" class="k-help-viewer-overlay-scrim"></div>
	<div class="k-help-viewer-outer">		
		<div class="k-help-viewer-title">
			<div class="k-help-viewer-control-btn-holder" style="right:8px" v-show="!minimized">
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!expanded&&!minimized" color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" style="margin-right:2px" @click="show_toc_on_side=!show_toc_on_side"><v-icon large color="#fff" style="font-size:15px!important">fas fa-list-ul</v-icon></v-btn></template>Toggle user guide contents</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" style="margin-right:2px" @click="back" :disabled="current_doc_index==0"><v-icon large color="#fff" style="font-size:17px!important">fas fa-arrow-left</v-icon></v-btn></template><div class="text-center">Back to the previously-viewed<br>user guide page</div></v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" @click="forward" :disabled="current_doc_index==queue.length-1"><v-icon large color="#fff" style="font-size:17px!important">fas fa-arrow-right</v-icon></v-btn></template><div class="text-center">Forward to the next-viewed<br>user guide page</div></v-tooltip>
			</div>
			<div class="k-help-viewer-control-btn-holder" style="left:8px">
				<v-btn color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" style="margin-right:2px" @click="$emit('hide_help')"><v-icon large color="#fff" style="font-size:20px!important">fas fa-xmark</v-icon></v-btn>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!minimized" color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" style="margin-right:2px" @click="toggle_size"><v-icon small color="#fff" style="font-size:16px!important">fas {{expanded?'fa-compress':'fa-expand'}}</v-icon></v-btn></template>{{!expanded?'Expand user guide':'Move user guide to side'}}</v-tooltip>
				<v-tooltip :bottom="!minimized" :top="minimized"><template v-slot:activator="{on}"><v-btn v-on="on" color="#333" fab x-small class="k-help-viewer-control-btn elevation-0" @click="toggle_minimize"><v-icon small color="#fff" style="font-size:16px!important">fas {{minimized?'fa-angle-up':'fa-angle-down'}}</v-icon></v-btn></template>{{minimized?'Show user guide':'Minimize user guide'}}</v-tooltip>
			</div>
			<div><v-icon color="#fff" style="font-size:18px; margin-top:-2px; margin-right:6px">fas fa-circle-info</v-icon>User Guide</div>
		</div>
		<div class="k-help-viewer-body">
			<HelpTOC v-show="toc_showing" ref="help_toc" :current_doc="current_doc" />
			<div class="k-help-page">
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="$vuetify.breakpoint.smAndUp" v-on="on" icon small color="light-blue darken-3" class="k-help-link-btn" @click.stop="copy_link"><v-icon small>fas fa-link</v-icon></v-btn></template>Copy a link to this help doc</v-tooltip>
				<component
					ref="help_doc"
					:is="current_doc_component"
				></component>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import HelpTOC from './HelpTOC'

/*
How this works:
- This file, help/HelpViewer.vue, is included in App.vue with ref="help"
- Individual help docs are located in help/, named `xxx.vue`
- When a doc is loaded:
	- the doc filename is pushed onto `queue`;
	- current_doc points to the final doc filename in queue;
	- current_doc_component dynamically loads the doc component;
	- the <component> tag shows the component

- The initial help doc for the app is overview.vue; it is loaded to start
- When a component that has a dedicated help doc is shown, call `vapp.$refs.help.set_current_app_doc('xxx')`
	- This sets current_app_doc to the provided value, and flashes the help icon on the screen
	- If the user clicks to show help at that point, the current_app_doc will be added to the queue (if it isn't already the last-viewed item) and shown

- To show a help doc right away, call `vapp.show_help('xxx')`
*/

export default {
	components: { HelpTOC, },
	// 	'home': () => import('./home'),
	// 	'treeview': () => import('./treeview'),
	// },
	props: {
		help_showing: { type: Boolean, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		queue: ['overview'],
		current_doc_index: 0,
		current_app_doc: '',
		expanded: false,
		compressed_side: 'right',
		show_toc_on_side: false,
		minimized: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// NO LONGER USED
		top_class() {
			let s = 'k-help-viewer'
			if (this.$vuetify.breakpoint.smAndUp) return s + ' elevation-6'
			else return s + ' k-help-viewer-small-screen'
		},
		current_doc() { return this.queue[this.current_doc_index] },
		current_doc_component() {
			// we need this reference to current_doc to ensure this gets refreshed whenever current_doc gets refreshed
			if (this.current_doc == '') return
			return () => import(`./${this.current_doc}`)
		},
		toc_showing() {
			this.$nextTick(()=>this.maintenance_fn())
			if (this.expanded) return true
			return this.show_toc_on_side
		},
		// return this.$store.state.site_config.app_name + ' User Guide'
	},
	watch: {
		current_doc: {immediate: true, handler(val) {
			let target = $(sr('[data-help-doc=$1]', this.current_doc))[0]
			if (!empty(target)) {
				let container = $('.k-help-toc-outer')[0]
				this.$vuetify.goTo(target, {container:container, offset:80})
			}
		}},
		help_showing() {
			if (this.help_showing) {
				// when the help window appears, start calling maintenance_fn
				this.$nextTick(x=>this.maintenance_fn())
				this.maintenance_interval = setInterval(()=>this.maintenance_fn(), 100)
			} else {
				// and when it disappears, stop calling maintenance_fn
				clearInterval(this.maintenance_interval)
				this.$nextTick(x=>this.maintenance_fn())
				// also clear minimized when help is closed
				this.minimized = false
			}
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		set_current_app_doc(doc) {
			// send '' to clear
			this.current_app_doc = doc
		},

		help_icon_clicked() {
			// push current_app_doc onto queue if we have one
			if (this.current_app_doc) {
				if (this.queue[this.queue.length-1] != this.current_app_doc) {
					this.show(this.current_app_doc)
				}
			}
			// clear current_app_doc so that if the user changes to a different page, then opens help again, we don't come back to current_app_doc again
			this.current_app_doc = ''
			this.$nextTick(x=>this.maintenance_fn())
		},

		back() {
			if (this.current_doc_index > 0) this.current_doc_index -= 1
			this.$nextTick(x=>this.maintenance_fn())
		},

		forward() {
			if (this.current_doc_index < (this.queue.length - 1)) this.current_doc_index += 1
			this.$nextTick(x=>this.maintenance_fn())
		},

		show(doc, flag) {
			if (empty(doc)) return	// just in case

			// push doc onto the queue, unless it was already the last thing shown
			if (doc != this.queue[this.queue.length-1]) {
				// use this splice so that anything later than the previous-current item in the queue is removed (this is how history works!)
				this.queue.splice(this.current_doc_index + 1, 100000, doc)
			}

			// and make the last thing on the queue the thing that's currently showing
			this.current_doc_index = this.queue.length - 1
			this.$nextTick(x=>this.maintenance_fn())
		},

		flash() {
			// not currently used, but could be in the future
			clearTimeout(this.flash_timeout)

			let ff = ()=>{
				if (this.count % 2 == 0) $(vapp.$refs.help_icon.$el).addClass('amber--text k-app-help-icon-highlighted').removeClass('light-blue--text')
				else $(vapp.$refs.help_icon.$el).removeClass('amber--text k-app-help-icon-highlighted').addClass('light-blue--text')
				++this.count
				if (this.count <= 3) this.flash_timeout = setTimeout(ff, 300)
			}

			this.count = 0
			this.flash_timeout = setTimeout(ff, 50)
		},

		copy_link() {
			let link = document.location.toString() + '?help=' + this.current_doc
			U.copy_to_clipboard(link)
			this.$inform('Link copied to clipboard')
		},

		toggle_size(evt) {
			if (typeof(evt) == 'boolean') this.expanded == evt
			this.expanded = !this.expanded
			this.maintenance_fn()
			if (typeof(evt) != 'boolean' && !empty(evt) && !empty(evt.target)) $(evt.target).closest('button').blur()
		},

		toggle_minimize(evt) {
			if (typeof(evt) == 'boolean') this.minimized == evt
			else this.minimized = !this.minimized
			this.maintenance_fn()
			if (typeof(evt) != 'boolean' && !empty(evt) && !empty(evt.target)) $(evt.target).closest('button').blur()
		},

		// this fn constantly runs while the help component is showing, and does "maintenance" things like setting the wrapper boundaries
		maintenance_fn() {
			// set width and height of component, depending on settings for expanded
			let ww = $(window).width()
			let wh = $(window).height()

			if (!this.help_showing) return

			if (this.minimized) {
				let sw = 300

				$('.k-help-viewer-outer').css({
					'width': sw + 'px',
					'left': 'auto',
					'right': '0px',
					'height': '42px',
					'top': (wh - 42)+'px',
				})
				$('.k-help-viewer-title').css({
					'border-radius': '12px 0 0 0',
					'border-right-width': '0',
					'border-left-width': '1px',
				})

				return
			}

			// determine compressed_side
			let current_toc_item = this.$refs.help_toc.find_toc_item(this.current_doc)
			if (current_toc_item && current_toc_item.position) {
				if (current_toc_item.position == 'left') {
					this.compressed_side = 'left'
				} else if (current_toc_item.position == 'center') {
					this.compressed_side = 'right'
					this.expanded = true
				} else {
					// default is right
					this.compressed_side = 'right'
				}
			}

			// positioning for when the help window is on the right side
			if (!this.expanded) {
				let toc_width = (this.show_toc_on_side) ? 240 : 0
				let sw = Math.round(ww / 3) - toc_width
				if (sw > 550) sw = 550
				if (sw < 450) sw = 450
				sw += toc_width

				let top = 60
				if (vapp.case_tree_component && $(vapp.case_tree_component.$el).is(':visible')) top += 47	// height of viewer header bar
				let sh = wh - top

				$('.k-help-viewer-outer').css({
					'width': sw + 'px',
					'left': (this.compressed_side == 'left') ? '0px' : 'auto',
					'right': (this.compressed_side == 'right') ? '0px' : 'auto',
					'height': sh + 'px',
					'top': top + 'px',
				})
				$('.k-help-viewer-title').css({
					'border-radius': (this.compressed_side == 'left') ? '0 12px 0 0' : '12px 0 0 0',
					'border-right-width': (this.compressed_side == 'left') ? '1px' : '0',
					'border-left-width': (this.compressed_side == 'left') ? '0' : '1px',
				})
				$('.k-help-page').css({
					'border-right-width': (this.compressed_side == 'left') ? '1px' : '0',
					'border-left-width': (this.compressed_side == 'left') ? '0' : '1px',
					'border-bottom-width': '0',
					'border-bottom-right-radius': '0',
				})
				$('.k-help-toc-outer').css({
					'border-bottom-width': '0',
					'border-bottom-left-radius': '0'
				})

			// positioning for when the help window is overlaying the main window
			} else {
				let sw = ww - 100
				let sh = wh - 40
				if (sw > 1200) sw = 1200

				$('.k-help-viewer-outer').css({
					'width': sw + 'px',
					'left': sr('calc(50vw - $1)', Math.round(sw/2) + 'px'),
					'right': 'auto',
					'height': sh + 'px',
					'top': '20px',
				})
				$('.k-help-viewer-title').css({
					'border-radius': '12px 12px 0 0',
					'border-right-width': '1px',
					'border-left-width': '1px',
				})
				$('.k-help-page').css({
					'border-right-width': '1px',
					'border-left-width': '1px',
					'border-bottom-width': '1px',
					'border-bottom-right-radius': '12px'
				})
				$('.k-help-toc-outer').css({
					'border-bottom-width': '1px',
					'border-bottom-left-radius': '12px'
				})
			}
		},
	}
}
</script>

<style lang="scss">
.k-help-viewer-outer {
	position:fixed;
	z-index:10000;
	transition: top .2s, height .2s;
}

.k-help-viewer-control-btn-holder {
	position:absolute;
	top:4px;
}

.k-help-viewer-control-btn {
	width:27px!important;
	height:27px!important;
	margin:3px 0;
}

.k-help-viewer-overlay-scrim {
	position:fixed;
	z-index:9999;
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	opacity:0.8; 
	background-color:#000;
}

.k-app-help-icon {
	transition: all 0.05s;
}

.k-app-help-icon-highlighted {
	box-shadow: 0px 0px 20px $v-amber;
}

.k-help-viewer-title {
	background-color:$v-light-blue-accent-4;
	color:#fff;
	font-size:16px;
	font-weight:bold;
	padding:8px 8px 6px 8px;
	text-align:center;
	position:relative;
	height:42px;	// match to the height of the viewer toolbar
	border-color:$v-light-blue-darken-4;
	border-style:solid;
	border-width:0;
	border-top-width:1px;
}

.k-help-viewer-body {
	display:flex;
	align-items: flex-start;
	height:calc(100% - 42px);
	max-height:calc(100vh - 28px);
	overflow:auto;
	font-size:14px;
	line-height:18px;
	h3 {
		margin-bottom:8px;
	}
}

.k-help-page {
	flex: 0 1 auto;
	padding:8px 20px 8px 12px;
	height:100%;
	width:100%;
	background-color:#fff;
	overflow:auto;
	border-color:$v-light-blue-darken-4;
	border-style:solid;
	border-width:0;

	li {
		margin-bottom:6px;
		margin-top:6px;
		clear:both;
	}

	p {
		margin:12px 0;
		clear:both;
	}

	.k-no-clear {
		clear: none;
		li { clear: none; }	// this is so the class works both on a ul/ol and a li
	}

	a {
		font-weight:bold;
		color:#555!important;
	}

	a.k-help-internal-link {
		color:$v-light-blue-darken-3!important;
	}
}

.k-help-img {
	// transform: scale(0.5) translate(-1,-1);
	max-width:100%;
	border:1px solid #888;
	border-radius:4px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	// image-resolution: 72dpi
}

.k-help-img.float-right {
	margin-left:8px;
	margin-bottom:6px;
}

.k-help-img.float-left {
	margin-right:8px;
	margin-bottom:6px;
}

.k-help-img.block {
	display:block;
	margin:4px auto;
}

.k-help-toc-outer {
	flex: 0 0 30%;
	min-width:240px;
	max-width:300px;
	padding:8px 8px 8px 8px;
	background-color:$v-light-blue-lighten-5;
	border-color:$v-light-blue-darken-4;
	border-style:solid;
	border-width:0;
	border-left-width:1px;
	height:100%;
	overflow:auto;

	.v-text-field .v-input__control .v-input__slot {
		min-height: 0 !important;
		padding: 0 8px !important;
		margin-bottom: 2px !important;
		display: flex !important;
		align-items: center !important;
		font-size:14px;
	}

	.v-text-field .v-input__control .v-input__slot {
		.v-input__prepend-inner, .v-input__append-inner {
			margin-top: 4px !important;
			margin-left:-6px;
			margin-right:6px;
			width:18px !important;
			i {font-size:14px!important;}
		}
		.fa-times-circle { font-size:14px!important }
	}

	.v-text-field .v-input__control .v-input__slot label {
		margin-top: -4px !important;
		font-size:14px;
	}

	.v-text-field .v-input__control .v-input__slot label.v-label--active {
		margin: 0 0 0 5px !important;
	}

	.v-text-field__details {
		margin: 2px !important;
	}

}

.k-help-toc-item-wrapper {
	margin-bottom:0!important;
	position:relative;
}

.k-help-toc-item-wrapper::before {
	// content:'\f0da';	// caret-right
	// content:'\f061';	// arrow-right
	// content:'\f068';	// minus
	content:'\f111';	// circle
	position:absolute;
	left:-10px;
	top:-2px;
	font-size:5px;
	font-family: 'Font Awesome 6 Pro';
	font-weight: 900;
	color:$v-light-blue-accent-4;
}

.k-help-toc-item-wrapper.k-top-level::before {
	left:-10px;
	top:-1px;
	font-size:10px;
	content:'\f105';	// angle-right
	transition: all 0.2s;
}

.k-help-toc-item-wrapper.k-top-level-expanded::before {
	transform: rotate(90deg);
}

.k-help-toc-item {
	font-size:12px;
	line-height:14px;
	margin: 2px 0;
	cursor:pointer;
}
.k-help-toc-item:hover {
	text-decoration:underline;
}

.k-help-toc-item-current {
	font-weight:bold;
	color:$v-light-blue-accent-4;
}

.k-help-toc-item-search-match {
	color:$v-deep-orange-darken-4;
}

.k-help-toc-item-children {
	padding-left:14px!important;
	list-style-type: none;
}

.k-help-expand-btn {
	float:left;
	margin-left:-10px;
	margin-top:-5px;
	margin-right:2px;
}

.k-help-link-btn {
	float:right;
	margin-left:4px;
	margin-top:-6px;
	margin-right:-16px;
}
</style>
