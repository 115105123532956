<template><div class="k-frameworks-category-card k-custom-border-color">
	<div class="k-fwcc-category" @click="toggle_revealed">
		<v-icon class="mr-2" color="primary">fas fa-circle-chevron-{{contents_revealed?'down':'right'}}</v-icon>
		<div class="k-fwcc-category-text k-custom-sub-heading-color" v-html="cr.title"></div>
	</div>

	<v-expand-transition><div class="k-fwcc-docs" v-show="contents_revealed">
		<FrameworkListFramework v-for="(fr, j) in cr.framework_records" :key="fr.lsdoc_identifier" v-show="cr.doc_showing[fr.lsdoc_identifier]" :framework_record="fr" :case_tree_lsdoc_identifier="case_tree_lsdoc_identifier" @view_framework="$emit('view_framework',$event)" />
		<!-- <v-spacer/> -->
	</div></v-expand-transition>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import goTo from 'vuetify/lib/services/goto'
import FrameworkListFramework from './FrameworkListFramework'

export default {
	components: { FrameworkListFramework, },
	props: {
		cr: { type: Object, required: true },
		case_tree_lsdoc_identifier: { type: String, required: true },
		search: { required: true },
	},
	data() { return {
		revealed_local: null,
	}},
	computed: {
		...mapState(['user_info']),
		contents_revealed() {
			if (this.search) return true
			if (this.revealed_local !== null) return this.revealed_local
			// if the category hasn't been explicitly clicked, use default_revealed
			return this.default_revealed
		},
		default_revealed() {
			// if we have a "home category", default is showing for the home framework and hidden otherwise
			let home_category = this.$store.state.site_config.home_category
			if (home_category) {
				return this.cr.map_label == home_category
			}

			// otherwise default is hidden for a small screen, and showing otherwise
			return !this.$vuetify.breakpoint.smAndDown
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_revealed(val) {
			if (typeof(val) != 'boolean') {
				// if the category has never been explicitly clicked, set to the opposite of the default value
				if (this.revealed_local === null) val = !this.default_revealed
				else val = !this.revealed_local
			}
			this.revealed_local = val
		},
	}
}
</script>

<style lang="scss">
.k-frameworks-category-card {
	border-top:1px solid #999;	// this color will be overwritten by site_config.dividing_line_color
	margin:12px;
	padding:12px 0px 0 0;
	color:#222;
}

.k-frameworks-category-card:first-of-type {
	border:0!important;
	margin-top:0;
}

.k-fwcc-category {
	font-weight: bold;
	font-size:16px;
	line-height:18px;
	display:flex;
	align-items: center;
	cursor:pointer;
}

.k-fwcc-docs {
	margin-top:8px;
	display:flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap:wrap;
}

</style>
