<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="900" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<div><b>Crosswalks:</b> {{this.framework_record.json.CFDocument.title}}</div>
				<v-spacer/>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<v-data-table dense hide-default-footer item-key="crosswalk_identifier"
					:headers="table_headers"
					:items="crosswalk_frameworks"
					:items-per-page="-1"
					:must-sort="true"
				>
				<template v-slot:item.crosswalk_identifier="{ item }">
					<v-btn small color="primary" class="my-1" @click="$emit('open_crosswalk',[framework_identifier,item.to_framework_identifier])">Show Crosswalk <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
				</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		framework_identifier: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		// add to includer component
		show_dialog: false
	}},
	computed: {
		...mapState(['framework_records']),
		...mapGetters(['crosswalk_framework_hash']),
		framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.framework_identifier) },
		table_headers() {
			let arr = [
				{ text: 'Category', value: 'category', sortable: true, align: 'left' },
				{ text: 'Framework Title', value: 'to_framework_title', sortable: true, align: 'left' },
				{ text: '', value: 'crosswalk_identifier', sortable: false, align: 'left' },
			]
			return arr
		},
		crosswalk_frameworks() { 
			console.warn(this.crosswalk_framework_hash[this.framework_identifier])
			let arr = []
			for (let o of this.crosswalk_framework_hash[this.framework_identifier]) {
				// the other framework could be identifier1 or identifier2
				let ofi = (o.identifier1 == this.framework_identifier) ? o.identifier2 : o.identifier1
				let to_framework = this.framework_records.find(x=>x.lsdoc_identifier == ofi)
				if (!to_framework) {
					console.warn(`CrosswalkListForIdentifier: couldn’t find framework ${ofi}`, o)
					continue
				}
				let category_data = U.parse_framework_category(to_framework.ss_framework_data.category)
				arr.push({
					category: category_data.title,
					crosswalk_identifier: o.crosswalk_identifier,
					to_framework: to_framework,
					to_framework_identifier: ofi,
					to_framework_title: to_framework.json.CFDocument.title,
				})
			}
			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.crosswalk_list_component = this
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>