<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" :max-width="notification_settings.list_type=='all'?860:1040" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Framework Notification Preferences</b></v-card-title>
			<v-card-text class="mt-0" style="font-size:16px">
				<div class="d-flex mt-6">
					<v-spacer/>
					<v-checkbox class="mt-0 pt-0" :label="'Send email notifications when frameworks change'" v-model="notifications_on" hide-details></v-checkbox>
					<v-spacer/>
				</div>
				<div v-if="notifications_on" class="d-flex align-start">
					<div style="width:160px;margin-top:18px;" class="mr-2"><b>Notify me about:</b></div>
					<div style="flex:1 1 auto">
						<v-radio-group v-model="notification_settings.list_type" hide-details row>
							<v-radio label="All agencies/frameworks" value="all"></v-radio>
							<v-radio label="Selected agencies" value="agencies"></v-radio>
							<v-radio label="Selected frameworks" value="frameworks"></v-radio>
						</v-radio-group>

						<div v-if="notification_settings.list_type=='agencies'" class="mt-4 d-flex align-center">
							<v-autocomplete :menu-props="'dense'" small-chips deletable-chips multiple hide-details outlined label="Select Agencies…" :items="agency_options" v-model="notification_settings.agency_list"></v-autocomplete>
						</div>
						<div v-if="notification_settings.list_type=='frameworks'" class="mt-4 d-flex align-center">
							<v-autocomplete :menu-props="'dense'" small-chips deletable-chips multiple hide-details outlined label="Select Frameworks…" :items="framework_options" v-model="notification_settings.framework_list"></v-autocomplete>
						</div>
					</div>
				</div>
				<div v-if="notifications_on" class="mt-6 d-flex align-center">
					<div style="width:160px" class="mr-2"><b>Send notifications to:</b></div>
					<v-text-field outlined hide-details label="Email" v-model="notification_settings.email"></v-text-field>
				</div>
				<div class="mt-3 text-center"><i>Notification emails are sent once per week, on Friday after 8:00 PM Eastern Time.</i></div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="finish"><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
// components: { UserPrefs },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		notification_settings: {},
		agency_options: [],
		framework_options: [],
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters([]),
		notifications_on: {
			get() { return this.notification_settings.on == 'yes' },
			set(val) { this.notification_settings.on = (val) ? 'yes' : 'no' }
		}
	},
	watch: {
	},
	created() {
		this.notification_settings = $.extend(true, {}, this.user_info.notification_settings)

		// this fn is in case_utilities, because we also need it for FrameworkSwitcher and UserPrefs
		let category_records = U.create_category_records(false, false)
		for (let cr of category_records) {
			this.agency_options.push({value:cr.category, text:cr.title})
			for (let fr of cr.framework_records) {
				this.framework_options.push({value:fr.lsdoc_identifier, text:`${cr.title}: ${fr.json.CFDocument.title}`})
			}
		}
	},
	mounted() {
	},
	methods: {
		finish() {
			// if any prefs have changed, save
			if (JSON.stringify(this.notification_settings) != JSON.stringify(this.user_info.notification_settings)) {
				this.$store.commit('set', [this.user_info, 'notification_settings', this.notification_settings])
				this.$store.dispatch('save_user_account_data')
			}
			this.$emit('dialog_cancel')
		},
	}
}
</script>

<style lang="scss">
</style>