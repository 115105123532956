import '@fortawesome/fontawesome-pro/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	theme: {
		light: true,
		themes: {
			light: {
				// primary: '#3f51b5',
				// primary: '#0C7C8E',
				primary: process.env.VUE_APP_PRIMARY_COLOR,
				// secondary: '#b0bec5',
			}
		}
	}
});
