import Vue from 'vue'
// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// require packages
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

// Require Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/themes/dark.min.css'

// require package css files
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'

// Import and use Vue Froala lib
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

import FroalaEditor from 'froala-editor';
window.FroalaEditor = FroalaEditor

FroalaEditor.DefineIconTemplate('mark_highlight_btn', '<div class="k-fr-mark_highlight-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">  </div>');
FroalaEditor.DefineIcon('mark_highlight', {NAME: 'mark_highlight', template:'mark_highlight_btn'});
FroalaEditor.RegisterCommand('mark_highlight', {
	title: 'Highlight',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_highlight-text">$1</span>', html)
		this.html.insert(html)
	}
});

FroalaEditor.DefineIconTemplate('mark_add_btn', '<div class="k-fr-mark_add-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">add</div>');
FroalaEditor.DefineIcon('mark_add', {NAME: 'mark_add', template:'mark_add_btn'});
FroalaEditor.RegisterCommand('mark_add', {
	title: 'Mark as addition',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_add-text">$1</span>', html)
		this.html.insert(html)
	}
});

FroalaEditor.DefineIconTemplate('mark_delete_btn', '<div class="k-fr-mark_delete-text" style="margin:0 3px; min-width:24px; font-size:9px; line-height:14px; text-align:center;">del</div>');
FroalaEditor.DefineIcon('mark_delete', {NAME: 'mark_delete', template:'mark_delete_btn'});
FroalaEditor.RegisterCommand('mark_delete', {
	title: 'Mark as deletion',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let html = this.selection.text()
		html = sr('<span class="k-fr-mark_delete-text">$1</span>', html)
		this.html.insert(html)
	}
});

// mathtype equations!!
FroalaEditor.DefineIconTemplate('insertmathtypeicon', '<span style="text-align:center;font-size:21px;padding:0 12px;">Σ</span>');
FroalaEditor.DefineIcon('insertmathtype', {NAME: 'mathtype', template:'insertmathtypeicon'});
FroalaEditor.RegisterCommand('insertmathtype', {
	title: 'Insert Equation',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let text = this.selection.text()

		// if user selects text, just convert without opening the editor
		if (!empty(text)) {
			// remove enclosing $'s if there
			text = text.replace(/^\$(.*)\$$/, '$1')
			console.log(text)
			this.html.insert(U.render_latex(`$${text}$`) + '&nbsp;')
			U.update_froala_model(this)

		} else {
			// for some reason, sometimes froala throws an error after the insert here, though it doesn't seem to cause any problems. by catching it we allow the math editor to show and everything seems to work
			try {
				this.html.insert('<span class="k-mathtype-placeholder">_____</span>')
			} catch(e) {
				console.log('caught', e)
			}

			vapp.show_math_live_editor(text, new_latex => {
				let s = this.html.get()
				// if new_latex is null (which happens if the user clicks 'cancel' from the MathLiveEditor) or empty, just remove the placeholder
				if (empty(new_latex)) {
					s = s.replace(/<span class="k-mathtype-placeholder">_____<\/span>/, '')
					// note that if the user is editing a pre-existing formula, we will open the editor via another mechanism

				} else {
					// always add a space after the equation; you almost always want one anyway, and doing so helps the editing flow
					s = s.replace(/<span class="k-mathtype-placeholder">_____<\/span>/, U.render_latex(`$${new_latex}$`) + '&nbsp;')
				}

				this.html.set(s)
				U.update_froala_model(this)
			})
		}
	}
});
FroalaEditor.RegisterShortcut(187, 'insertmathtype', '', '=', false);

///////////////////////////////////////////////////////
// The following pattern allows us to better manipulate the froala editor functionality. to implement, we use the <froala-wrapper> tag like so:

// <froala-wrapper v-model="description" />
// <froala-wrapper :config="{zIndex: 2501}" parameter="description" />
// <froala-wrapper :config="editor_config()" :parameter_object="course" parameter="description" />

// then we can reference the froala component and its surrounding component; one important thing this allows us to do is the following,
// which ensures that the modeled value will be updated after we've manipulated things in the editor
// (note that updateModel is a fn supplied by the official froala vue component)
// let fco = U.get_froala_component(editor)
// if (fco.froala_component) fco.froala_component.updateModel()

// this <froala-wrapper> method MUST be used for the image-insertion and paste "helpers" below to work properly

U.get_froala_component = function(source) {
	// source can either be a) the froala editor dom object (usually retrieved, directly or indirectly, from a froala event callback fn; see below)
	// or b) a selector referencing a dom object, or the dom object itself, that is a part of the froala-edited html (e.g. a link in the edited html)

	// the returned object will include:
	// froala_editor:  the froala editor dom object
	// froala_component: the <froala> vue component that wraps the froala_editor
	// froala_wrapper_component: the FroalaWrapper component (which in turn contains the <froala> component)
	// parent_component: the component that contains the FroalaWrapper component

	let o = {}
	if (typeof(source) == 'object' && !empty(source.$el)) {
		o.froala_wrapper_id = $(source.$el).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	} else {
		o.froala_wrapper_id = $(source).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	}

	// the FroalaWrapper component registers the froala_wrapper_id in the store (but if we haven't initialized a froala editor at all, froala_wrapper_components may not even exist)
	if (vapp.$store.state.froala_wrapper_components) {
		o.froala_wrapper_component = vapp.$store.state.froala_wrapper_components[o.froala_wrapper_id]
		if (empty(o.froala_wrapper_component)) {
			console.log('couldn’t find editor', o.froala_wrapper_id)
		} else {
			o.froala_component = o.froala_wrapper_component.$refs.froala_component
			o.parent_component = o.froala_wrapper_component.get_parent()
			o.froala_editor = o.froala_component.getEditor()
		}
	}
	// console.log(o)
	return o
}

// pass the froala editor into this fn to ensure that the <froala> vue component immediately updates the modeled parameter to the current value specified by the froala editor
// (it may seem like this shouldn't be necessary, but sometimes it is.)
U.update_froala_model = function(editor) {
	let fco = U.get_froala_component(editor)
	if (fco.froala_component) fco.froala_component.updateModel()
	else console.log('couldn’t update model...')
}
///////////////////////////////////////////////////////


U.get_froala_config = function(params) {
	// params could include, e.g.:
	// placeholderText
	// heightMin
	// heightMax
	// toolbarInline: true,
	if (empty(params)) params = {}

	let config = {
		key: vapp.$store.state.froala_key,
		placeholderText: '',
		charCounterCount: true,
		attribution: false,
		quickInsertEnabled: true,
		linkAlwaysBlank: true,
		theme: 'light',
		paragraphFormat: {
			N: 'Normal',
		    H1: 'Heading 1',
		    H2: 'Heading 2',
		    H3: 'Heading 3',
			BLOCKQUOTE: 'Block Quote',
		    PRE: 'Code',
		},
		paragraphFormatSelection: true,
		htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
			'onclick',
		],
		// we need 'span' to be listed here because otherwise, mathlive 'strut' spans are removed
		htmlAllowedEmptyTags: ['table', 'strong', 'span'],
		toolbarButtons: {
			moreRich: {buttons: ['bold', 'italic', 'insertLink', 'insertImage', 'insertmathtype',
				'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align',]
				, buttonsVisible: 9},	// , 'quote', 'outdent', 'indent'
			moreMisc: {buttons: ['fullscreen', 'paragraphFormat', 'insertVideo', 'specialCharacters', 'formatUL', 'formatOL', 'insertHR', 'insertTable', 'clearFormatting', 'html'], buttonsVisible: 1, align:'right'}
		},
		imageEditButtons: ['imageDisplay', 'imageAlign', 'imageStyle', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAlt', 'imageSize', 'imageReplace', 'imageRemove'],	// , 'imageCaption' doesn't seem to work consistently
		imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
		linkInsertButtons: ['linkBack'],
		videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
		videoEditButtons: ['videoDisplay', 'videoAlign', 'videoSize', 'videoReplace', 'videoRemove'],

		imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
		imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
		imageRoundPercent: true,		// round to integer when resizing
		imageDefaultDisplay: 'block',
		imageDefaultAlign: 'center',

		enter: FroalaEditor.ENTER_P,	// options: ENTER_P, ENTER_BR, ENTER_DIV

		// don't convert things like ” or ’ to entities
		entities: '',

		zIndex: 1000,	// may be needed to make image popup (and other popups) show up properly

		// image uploading -- not used; we convert images to inline dataURLs (see below)
		// imageUploadURL: '/src/ajax.php',
	    // imageUploadMethod: 'POST',
	    // imageMaxSize: 1 * 1024 * 1024,	// 1 MB
	    // imageUploadParams: {
		// 	service_name: 'froala_file_upload',
	    //     user_id: vapp.user_info.user_id,
	    // },
		// imageUploadMethod: 'POST',
		imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'svg+xml', 'svg'],

		events: {
			// store images as data-urls
			'image.beforeUpload': function (images) {
				// note that images is analogous to the file object returned by the onChange handler of a file input
				let image_file = images[0]
				let editor = this

				if (!image_file.type.startsWith('image/')) {
					vapp.$alert('The file you specified does not appear to be an image.')
					return
				}

				// for some reason this seems to get called for already-existing images as well as newly-pasted images
				let ei = editor.image.get()
				if (ei && (ei.attr('data-fr-image-pasted') != 'true' || ei.attr('data-cglt') == 'true')) {
					console.log('skipping already-processed image')
					return
				}
				console.log('processing image')

				vapp.$prompt({
					title: 'Choose Image Size',
					text: 'Choose a size for your pasted image. (Smaller-sized images take up less storage and load faster, so please choose a smaller size if possible.)',
					promptType: 'select',
					// selectOptions: [{value:'360', text: 'Small'}, {value:'500', text: 'Medium'}, {value:'640', text: 'Large'}, {value:'800', text: 'X-Large'}, {value:'full', text: 'Full-Size'}],
					// allowing for full-size images is too dangerous -- files could be *huge*
					selectOptions: [{value:'360', text: 'Small'}, {value:'500', text: 'Medium'}, {value:'640', text: 'Large'}, {value:'800', text: 'X-Large'}, {value:'1000', text: 'Max'}],
					initialValue: vapp.$store.state.lst.froala_image_size,
					acceptText: 'Select',
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(size => {
					vapp.$store.commit('lst_set', ['froala_image_size', size])

					// use the create_image_data_url utility fn to convert the image file to a dataURL
					// typical file sizes for given max_width's: 500 (56815) - 600 (77939) - 668 (93107)
					U.create_image_data_url(image_file, {image_format: 'webp', max_width: size, compression_level:0.9, callback_fn: o=>{
						// use the returned img_url (dataURL) as the src for the img tag
						vapp.$inform('Pasted image size: ' + U.format_bytes(o.img_url.length))

						// console.log('callback', ei, o.img_url)
						// if ei is empty, insert a new img tag
						if (empty(ei) || ei.length == 0) {
							let img_tag = sr('<img src="$1" class="fr-fic fr-dib" data-cglt="true">', o.img_url)
							editor.html.insert(img_tag)

						} else {
							// else insert the dataURL as the src tag
							ei.attr('src', o.img_url)
							ei.attr('data-cglt', 'true')
							ei.removeAttr('data-fr-image-pasted', '')
						}

						// hide the img popup/uploading popup if open
						editor.popups.hideAll()
						setTimeout(x=>editor.popups.hideAll(), 10)
						setTimeout(x=>editor.popups.hideAll(), 100)

						U.update_froala_model(editor)
					}})
				}).catch(n=>{
					// if user cancels, remove the image
					ei.remove()
				}).finally(f=>{})

				// hide the img popup/uploading popup if open
				editor.popups.hideAll()
				setTimeout(x=>editor.popups.hideAll(), 10)
				setTimeout(x=>editor.popups.hideAll(), 100)

				return false
			},

			'paste.afterCleanup': function (clipboard_html) {
				let plain_html = U.html_to_text(clipboard_html)
				let equivalent = (clipboard_html == plain_html)
				let editor = this
				// console.log('froala_before_cleanup_html', window.froala_before_cleanup_html)
				// console.log('after', clipboard_html)
				// console.log('equivalent: ' + equivalent)

				let fn = function(clipboard_html) {
					if (vapp.$store.state.lst.froala_paste_mode == 'normal') {
						clipboard_html = window.clean_froala_pasted_text(clipboard_html)
					} else if (vapp.$store.state.lst.froala_paste_mode == 'plain') {
						// console.log(1, clipboard_html)
						// make sure there is a \n after all block tags and <br> tags
						clipboard_html = clipboard_html.replace(/(<\/(p|h1|h2|h3|h4|h5|h6|li|pre|blockquote|td|button)>)(\n)?/g, '$1\n')
						clipboard_html = clipboard_html.replace(/(<br>)(\n)?/g, '\n')

						// console.log(2, clipboard_html)
						// replace \n's with a string we can find again below
						clipboard_html = clipboard_html.replace(/\n/g, 'XXXRETURNXXX')

						// console.log(3, clipboard_html)
						// convert to plain text, then replace that string with <br>'s
						clipboard_html = U.html_to_text(clipboard_html).replace(/XXXRETURNXXX/g, '<br>')

						// for spaces immediately following br's, use nbsp's
						clipboard_html = clipboard_html.replace(/(<br>)(\s+)/g, ($0, $1, $2) => {
							let s = '<br>'
							for (let i = 0; i < $2.length; ++i) s += '&nbsp;'
							return s
						})
						// console.log(4, clipboard_html)
					}

					let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/, clipboard_html)
					editor.html.set(html)
				}

				// if text has no formatting, just paste
				if (clipboard_html == U.html_to_text(clipboard_html) || clipboard_html.search(/(\bstyle=)|(\bclass=)/) == -1) {
					return clipboard_html

				// look for tell-tale signature of pasting from froala to froala; if this is found, do our standard cleanup
				} else if (clipboard_html.search(/<span style="color: rgb\(\d+.*?\); [^>]*font-variant-ligatures/) > -1) {
					return window.clean_froala_pasted_text(clipboard_html)

				} else {
					vapp.$prompt({
						title: 'Paste Text',
						text: 'Choose an option for formatting the pasted text:',
						promptType: 'select',
						selectOptions: [{value:'asis', text: 'Preserve all html formatting (not recommended)'}, {value:'normal', text: 'Preserve basic formatting (e.g. bold and italics)'}, {value:'plain', text: 'Discard all formatting (paste as plain text)'}],
						initialValue: vapp.$store.state.lst.froala_paste_mode,
						acceptText: 'Paste',
						// hideCancel: true,
						focusBtn: true,
					}).then(mode => {
						// clear the dummy `k-pasted-text` stage out of the undo stack, so that if the user undo-s, they won't go back to having the dummy div in the editor
						editor.undo_stack.pop()

						vapp.$store.commit('lst_set', ['froala_paste_mode', mode])
						fn(clipboard_html)
						U.update_froala_model(editor)

					}).catch(n=>{
						editor.undo_stack.pop()

						let html = editor.html.get().replace(/<span[^>]*?class="k-pasted-text"[^>]*?>xxx<\/span>/g, '')
						editor.html.set(html)
						U.update_froala_model(editor)
					}).finally(f=>{})

					return '<span class="k-pasted-text" style="display:none">xxx</span>'
				}
			},

			'keyup': function ($evt) {
				// console.log('keyup', $evt.keyCode)
				// console.log('keyup', this.selection)
				// window.fks = this.selection

				// if user navigates using arrow keys into a mathlive equation span, position before or after the span, depending on the key they tapped
				let ml_el = U.froala_mathlive_node(this.selection)
				if (ml_el) {
					// console.log('in ml', $evt.keyCode)
					// backspace or forward delete: delete the equation altogether
					if ($evt.keyCode == 8 || $evt.keyCode == 46) {
						$(ml_el).remove()
						U.update_froala_model(this)

					// left, up arrow
					} else if ($evt.keyCode == 37 || $evt.keyCode == 38) {
						U.froala_move_cursor_out_of_el(this.selection, ml_el, 'before')

					// right, down arrow
					} else if ($evt.keyCode == 39 || $evt.keyCode == 40) {
						U.froala_move_cursor_out_of_el(this.selection, ml_el, 'after')
					}

					$evt.preventDefault()
					$evt.stopPropagation()
				}
			},

			'keypress': function ($evt) {
				// console.log('keypress', $evt.keyCode)
				// console.log('keypress', this.selection)

				// if user taps a key while inside the mathlive span...
				let ml_el = U.froala_mathlive_node(this.selection)
				if (ml_el) {
					// console.log('keypress in ml', $evt.keyCode)

					let char_node = document.createTextNode(String.fromCharCode($evt.keyCode))

					// if we're at the very start of the equation, move and place before the equation; otherwise move and place after
					if (U.froala_at_start_of_mathlive(this.selection)) {
						U.froala_move_cursor_out_of_el(this.selection, ml_el, 'before')
						$(ml_el).before(char_node)
					} else {
						U.froala_move_cursor_out_of_el(this.selection, ml_el, 'after')
						$(ml_el).after(char_node)
					}
					this.selection.setAfter(char_node)
					this.selection.restore()
					U.update_froala_model(this)

					$evt.preventDefault()
					$evt.stopPropagation()
				}
			},

			'mouseup': function ($evt) {
				// console.log('keyup', this.selection)
				// window.fks = this.selection

				// if click is entirely within the same equation, open the editor
				let ml_el = U.froala_starts_in_node(this.selection, '.k-mathlive-span')
				if (ml_el && ml_el == U.froala_ends_in_node(this.selection, '.k-mathlive-span')) {
					let latex = $(ml_el).attr('data-latex') ?? ''
					// if this is an empty selection...
					if (this.selection.isCollapsed()) {
						if ($(ml_el).text().length == 1) {
							// if there is only one char, either at_end or at_start will always return true, so always open the editor in this case

						// if we're at the very start or end of the equation, return (allowing the user to edit there)
						} else if (U.froala_at_end_of_mathlive(this.selection)) {
							// console.log('at end...')
							return

						} else if (U.froala_at_start_of_mathlive(this.selection)) {
							// console.log('at start...')
							return
						}
					}

					vapp.show_math_live_editor(latex, new_latex => {
						// if new_latex is null, user clicked cancel
						if (new_latex === null) return

						// console.log('finished editing:', new_latex)
						$(ml_el).replaceWith(U.render_latex(`$${new_latex}$`))
						U.update_froala_model(this)
					})
				}

				// if click ends or starts in an equation (or if we opened the editor), move to after the equation
				ml_el = U.froala_mathlive_node(this.selection)
				if (ml_el) U.froala_move_cursor_out_of_el(this.selection, ml_el, 'after')
			},
		}
	}

	return $.extend(config, params)
}

window.clean_froala_pasted_text = function(clipboard_html) {
	// try to preserve bold and italics
	let x = $(sr('<div>$1</div>', clipboard_html))
	x.find('*').each(function() {
		let el = $(this)

		// add strong tags for font-weight bold and > 400
		let fw = el.css('font-weight')
		if (fw == 'bold' || fw*1 > 400) {
			if (this.tagName != 'STRONG') {
				// console.log('found bold: ' + el.html())
				el.wrapInner('<strong></strong>')
			}
		}

		// add em tags for font-style italic
		let fs = el.css('font-style')
		if (fs == 'italic') {
			if (this.tagName != 'EM') {
				// console.log('found italic: ' + el.html())
				el.wrapInner('<em></em>')
			}
		}

		if (this.tagName == 'B') {
			el.replaceWith('<strong>' + el.html() + '</strong>')
		}
		if (this.tagName == 'I') {
			el.replaceWith('<em>' + el.html() + '</em>')
		}

		// remove style and class attributes
		el.removeAttr('style')
		el.removeAttr('class')
	})

	// remove these tags altogether
	x.find('base,head,link,meta,style,title,area,map,script,canvas,noscript,del,ins').remove()

	// these too
	x.find('option,datalist,fieldset,meter,optgroup,option,output,progress,select,textarea').remove()

	// these too
	x.find('iframe,video,audio,track,embed,object,param,picture,source').remove()

	// remove all inputs (remember that if this is an "internal paste" with queries, we won't be here)
	x.find('input').remove()

	// for these tags, extract everything in them and put them directly in the dom
	x.find('body,address,article,aside,footer,header,main,nav,section').replaceWith(function() {
		return $(this).html()
	})

	// these too
	x.find('button,label,legend').replaceWith(function() {
		return $(this).html()
	})

	let s = x.html()

	// preserve just the tags we want to save, and remove any attributes from non-images
	s = s.replace(/<((\/)?(em|strong|sub|sup))\b.*?>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
	s = s.replace(/<((\/)?img\b.*?)>/gi, 'ZZZLTZZZ$1ZZZGTZZZ')
	// console.log(s)

	// insert p tags at the ends of block-level things; this results in line breaks where we want them
	s = s.replace(/(\s*<\/(p|div|li|tr|figcaption|figure|pre|blockquote).*?>\s*)+/ig, 'ZZZLTZZZpZZZGTZZZ')

	// strip all other tags
	s = s.replace(/<(\/?)[a-z].*?>/ig, '')

	// put back the <'s we preserved
	s = s.replace(/ZZZLTZZZ/g, '<')
	s = s.replace(/ZZZGTZZZ/g, '>')

	// consolidate multiple ps
	s = s.replace(/(<p>)+/g, '<p>')

	return s
}

///////////////////////////////////////////////////////////
// fn for clearing extra empty paragraphs or breaks at the ends of froala-entered text
U.trim_froala_text = function(html) {
	// console.log('trim_froala_text (start)', html)

	// clear ` id="isPasted"`
	html = html.replace(/\s+id="isPasted"/g, '')

	// clear 'undefined' tags (this shouldn't be necessary once the froala 3.2.7 ENTER_BR bug is fixed)
	html = html.replace(/<\s*(\/?)undefined\s*>/g, '')

	html = html.replace(/^((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*([\s\S]*?)((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*$/, '$4')

	// replace singleton p's both before and after replacing empty p's with closing tags
	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	html = html.replace(/^((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*([\s\S]*?)((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*$/, '$5')

	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	// console.log('trim_froala_text (end)', html)

	return $.trim(html)
}

U.froala_starts_in_node = function(selection, selector) {
	let p = $(selection.element()).parents(selector)
	if (p.length > 0) return p[0]
	return null
}

U.froala_ends_in_node = function(selection, selector) {
	let p = $(selection.endElement()).parents(selector)
	if (p.length > 0) return p[0]
	return null
}

// if the froala selection object starts or end within a mathlive span, return the mathlive span node
U.froala_mathlive_node = function(selection) {
	let ml_el = U.froala_ends_in_node(selection, '.k-mathlive-span')
	if (ml_el) return ml_el

	ml_el = U.froala_starts_in_node(selection, '.k-mathlive-span')
	if (ml_el) return ml_el

	return null
}

// move the text cursor before or after a given node
U.froala_move_cursor_out_of_el = function(selection, node, where) {
	if (where == 'before') selection.setBefore(node)
	else selection.setAfter(node)
	selection.restore()
}

U.froala_at_end_of_mathlive = function(selection) {
	// start with the node we're in
	let at_end = true
	let jq = $(selection.element())
	// while we haven't reached the outer mathlive node
	while (jq && !jq.hasClass('k-mathlive-span')) {
		// if this node has a following sibling, we're not at the end of the equation
		let next = jq.next()
		if (next.length > 0) {
			at_end = false
			break
		}
		// otherwise go up a level
		jq = jq.parent()
	}
	return at_end
}

U.froala_at_start_of_mathlive = function(selection) {
	// if selection.get().focusOffset is not 0, we can't be at the start
	if (selection.get().focusOffset > 0) return false

	// start with the node we're in
	let at_start = true
	let jq = $(selection.element())
	// while we haven't reached the outer mathlive node
	while (jq && !jq.hasClass('k-mathlive-span')) {
		// if this node has a previous non-empty sibling, we're not at the start of the equation
		let prev = jq.prev()
		if (prev.length > 0) {
			// but skip struts
			let class_name = prev.attr('class')
			if (class_name && class_name.includes('strut')) {
				// console.log('skipping strut', prev, 'text: ' + prev.text())
				jq = prev
				continue
			}
			at_start = false
			break
		}
		// otherwise go up a level
		jq = jq.parent()
	}
	return at_start && !U.froala_at_end_of_mathlive(selection)
}