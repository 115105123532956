<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div :class="comment_wrapper_css"><div v-if="!hide_comment||!comment_and_replies_are_read" class="k-comment-wrapper">
	<div class="k-comment">
		<div v-if="show_comment_editor" class="k-tile-comment-editor-wrapper"><CommentEditor :original_comment="comment" :viewer="viewer" :item="item" :render_latex="render_latex" @editor_cancel="show_comment_editor=false"></CommentEditor></div>
		<v-hover v-slot:default="{hover}"><div>
			<div v-if="!show_comment_editor" class="k-comment-author-line">
				<div class="mr-2"><nobr><span class="k-comment-author"><v-icon x-small color="#555" class="mr-1" style="margin-top:-2px">fas {{comment.resolved?'fa-check-double':'fa-comment'}}</v-icon>{{ comment.first_name}} {{ comment.last_name}}</span> &nbsp; {{ comment_date }}</nobr></div>
				<v-btn v-visible="hover" v-if="user_can_edit" x-small text color="#666" class="k-tight-btn" @click="edit_comment"><v-icon x-small class="mr-1">fas fa-edit</v-icon>Edit</v-btn>
				<v-btn v-visible="hover" v-if="!show_comment_editor && !show_comment_reply_editor && comment.parent_comment_id==0" x-small text color="#666" class="k-tight-btn" @click="add_comment_reply"><v-icon x-small class="mr-1">fas fa-plus</v-icon>Reply</v-btn>
				<v-btn v-visible="hover" v-if="show_resolved_btn" x-small text color="#666" class="k-tight-btn" @click="toggle_resolved"><v-icon x-small class="mr-1">fas {{comment.resolved?'fa-circle-minus':'fa-check-double'}}</v-icon>Mark {{comment.resolved?'Unresolved':'Resolved'}}</v-btn>
				<v-spacer/>
				<div class="d-flex">
					<div class="k-comment-group-name">{{comment_group_name}}<b class="k-comment-attn-to" v-if="comment_attn_initials">{{comment_attn_initials}}</b></div>
					<div v-if="!comment_is_read" class="k-comment-unread" @click.stop="mark_comment_as_read"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on"><v-icon>fas fa-exclamation-circle</v-icon></div></template><span>Unread; click to mark as read</span></v-tooltip></div>
				</div>
			</div>
			<div v-if="!show_comment_editor" class="k-comment-body">
				<div class="k-comment-body-text">
					<div class="k-comment-author-initials" v-html="comment_author_initials"></div>
					<div class="fr-view" v-html="comment_body_formatted"></div>
					<div v-if="suggested_edits_formatted" class="mt-1 mb-2">
						<div class="mb-1">
							<b style="color:#555; font-size:12px"><v-icon x-small class="mr-1" color="#555">fas fa-edit</v-icon>Suggested edits:</b>
							<v-btn v-if="show_apply_suggested" x-small color="primary" text class="ml-3 k-tight-btn" @click="apply_suggested_edits">Apply Suggested Edits<v-icon small class="ml-1">fas fa-arrow-alt-circle-right</v-icon></v-btn>
							<span v-if="comment.suggested_edits.applied" class="ml-3"><v-icon small class="mr-1" color="light-green darken-4">fas fa-check-circle</v-icon><b class="light-green--text text--darken-4">APPLIED</b></span>
						</div>
						<div class="k-comment-suggested-edits-wrapper" v-html="suggested_edits_formatted"></div>
					</div>
				</div>
				<!-- <v-icon v-if="comment.pinned" small class="mt-1 ml-2" color="primary">fas fa-thumbtack</v-icon> -->
			</div>
		</div></v-hover>
		<div v-if="replies.length>0 || show_comment_reply_editor" class="k-comment-replies">
			<TileComment v-for="(reply) in replies" v-show="reply.body!=''" :key="reply.comment_id" :comment="reply" :viewer="viewer" :item="item" :render_latex="render_latex"></TileComment>
			<div v-if="show_comment_reply_editor" class="k-tile-comment-new-reply-editor-wrapper"><CommentEditor :original_comment="new_comment_reply" :render_latex="render_latex" @editor_cancel="show_comment_reply_editor=false"></CommentEditor></div>
		</div>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CommentEditor from './CommentEditor'

export default {
	components: { CommentEditor },
	name: 'TileComment',
	props: {
		comment: { type: Object, required: true },
		hide_comment: { type: Boolean, required: false, default() { return false } },
		item: { required: false, default() { return null } },
		viewer: { required: false, default() { return null } },
		render_latex: { required: false, default() { return true } },
	},
	data() { return {
		show_comment_reply_editor: false,
		show_comment_editor: false,
		new_comment_reply: {},
	}},
	computed: {
		...mapState(['user_info', 'comments', 'comment_groups']),
		...mapGetters(['comments_hash', 'signed_in']),
		public_review() { return this.comment.comment_group_id == -1 },
		comment_group() {
			if (this.comment.comment_group_id == 0) return null
			return this.comment_groups.find(x=>x.comment_group_id == this.comment.comment_group_id)
		},
		is_group_admin() {
			// you're always the admin of your personal group
			if (this.comment.comment_group_id == 0) return true
			if (!this.comment_group) return false
			// check user's user_id against the group's admin_user_ids
			return (this.comment_group.admin_user_ids.findIndex(x=>x==this.user_info.user_id) > -1)
		},
		comment_group_name() {
			// if the user isn't in any comment groups, no need to put a name -- everything is personal
			if (this.comment_groups.length == 0) return ''
			if (this.comment.comment_group_id == 0) return 'Personal'
			if (this.comment_group) return this.comment_group.name
			return ''	// shouldn't happen
		},
		comment_author_initials() {
			return this.comment.first_name[0] + this.comment.last_name[0] + ':'
		},
		comment_attn_initials() {
			if (this.comment.comment_group_id == 0 || this.comment.attn_user_id == 0) return ''
			// get the group, then the user_name, then generate initials
			let cg = this.comment_groups.find(x=>x.comment_group_id == this.comment.comment_group_id)
			if (cg) {
				let i = cg.user_ids.findIndex(x=>x==this.comment.attn_user_id)
				if (i > -1) {
					return cg.user_names[i].replace(/^(.).* (.).*/, ' [$1$2]')
				}
			}
			// if we get to here return empty string
			return '??'

		},
		comment_date() {
			let d = new Date(this.comment.created_at * 1000)
			return date.format(U.convert_to_local_date(d), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},
		user_can_edit() {
			return this.is_group_admin || this.comment.author_user_id == this.user_info.user_id
		},
		user_can_edit_framework() {
			if (this.viewer) return this.viewer.user_can_edit
			if (!this.signed_in) return false
			return vapp.is_granted('edit_framework', this.comment.framework_identifier)
		},
		show_resolved_btn() {
			// don't show the resolved btn here if we're editing the comment
			if (this.show_comment_editor || this.show_comment_reply_editor) return false

			// can't mark replies as resolved
			if (this.comment.parent_comment_id != 0) return false

			// only an editor of the framework can mark public_review comments as resolved
			// for public_review comments...
			if (this.public_review) {
				// if you can edit the framework, you can mark it as resolved; otherwise you can's
				return this.user_can_edit_framework
			}

			// otherwise if you can edit the comment you can mark it as resolved
			if (!this.user_can_edit) return false
			return true
		},
		replies() {
			// note that we have to look in comments_hash and not comments so that we include public_review comments
			let comments = this.comments_hash[this.comment.framework_identifier]
			if (comments) comments = comments[this.comment.item_identifier]
			if (!comments) return []

			let arr = comments.filter((comment) => {
				return (this.comment.comment_id!= 0 && comment.parent_comment_id == this.comment.comment_id && !empty(comment.body))
			})

			arr.sort((a,b) => {
				// sort replies so that older ones come first
				return a.created_at - b.created_at
			})

			return arr
		},
		comment_is_read() {
			return this.comment.comment_is_read()
		},
		comment_and_replies_are_read() {
			if (!this.comment_is_read) return false
			for (let pr of this.replies) {
				if (!pr.comment_is_read()) return false
			}
			return true
		},
		comment_or_reply_to_this_users_attn() {
			if (this.comment.comment_group_id != 0) {
				if (this.comment.attn_user_id == this.user_info.user_id) {
					return true
				}
			}
			return false
		},
		comment_wrapper_css() {
			let s = ''
			if (this.public_review) {
				s += ' k-comment-wrapper-public-review'
			}
			if (this.comment_or_reply_to_this_users_attn) {
				s += ' k-comment-wrapper-attn'
			}
			if (!this.comment_and_replies_are_read) {
				s += ' k-comment-wrapper-unread'
			}
			if (this.comment.pinned) {
				s += ' k-comment-wrapper-pinned'
			}
			if (this.comment.resolved) {
				s += ' k-comment-wrapper-resolved'
			}
			// if (this.comment.parent_comment_id == 0) s += ' elevation-1'
			return s
		},
		comment_body_formatted() {
			let s = this.comment.body
			// add latex formatting if told to do so
			if (this.render_latex) s = U.render_latex(s)
			return s
		},
		suggested_edits_formatted() {
			let fr = (vapp.case_tree_component) ? vapp.case_tree_component.framework_record : null
			return this.comment.suggested_edits_formatted(this.render_latex, fr)
		},
		show_apply_suggested() {
			if (!this.viewer) return false

			// if edits have been applied, don't show the button to apply
			if (this.comment.suggested_edits.applied) return false

			// return viewer's user_can_edit computed
			return this.viewer.user_can_edit
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_resolved(val) {
			// note that only users who can edit -- meaning the author of the comment, or a group admin, can toggle the resolved status
			if (typeof(val) != 'boolean') val = !this.comment.resolved
			this.$store.commit('set', [this.comment, 'resolved', val])
			this.$store.dispatch('save_comment_resolved', this.comment)
		},

		add_comment_reply() {
			this.new_comment_reply = new Comment({
				item_identifier: this.comment.item_identifier,
				framework_identifier: this.comment.framework_identifier,
				parent_comment_id: this.comment.comment_id,
				comment_group_id: this.comment.comment_group_id,
				author_user_id: this.user_info.user_id,
				first_name: (this.public_review && !this.signed_in) ? this.$store.state.lst.public_review_name : this.user_info.first_name,
				last_name: this.user_info.last_name,
			})
			this.show_comment_reply_editor = true

			// if the user replies to an unread comment, assume the comment has been read
			if (!this.comment_is_read) {
				this.mark_comment_as_read()
			}
		},

		edit_comment() {
			this.show_comment_editor = true
		},

		mark_comment_as_read() {
			this.$store.commit('set', [this.user_info.comment_reads, this.comment.comment_id+'', 1])
			this.$store.dispatch('save_user_account_data')
		},

		apply_suggested_edits() {
			// if we're not in edit mode, tell the user they have to go into edit mode first...
			if (!this.viewer.editing_enabled) {
				this.$alert('You must “Enable Framework Editing” (from the&nbsp; <i class="fas fa-ellipsis-v"></i>&nbsp; menu) prior to applying suggested edits.')
				return
			}

			// set viewer.comment_editor to this component, so that it'll callback here
			this.viewer.comment_editor = this

			// to handle suggested item edits, we call viewer.edit_item in apply_suggestions mode, with the suggested edits included
			this.viewer.edit_item(this.item, 'apply_suggestions', this.comment.suggested_edits)
		},

		suggested_edits_applied() {
			// save a flag specifying that the edits were applied
			let edited_comment = new Comment(this.comment)
			edited_comment.suggested_edits.applied = true

			this.$store.dispatch('save_comment', edited_comment).then(()=>{
				console.log('applied...')
			})
		},
	}
}
</script>

<style lang="scss">
.k-comment-wrapper {
	// background-color:#fffff8;
	background-color:#666;
	border:1px solid black;
	margin:4px 4px 0px 4px;
	// note that when we mess with this margin, we have to mess with comment-editor margins below
	border-radius:6px;
}

.k-comment {
	// margin-top:10px;
	padding:4px 8px 4px 8px;

	.k-comment-body {
		display:flex;
		align-items:flex-start;
		p { margin-bottom:6px; }
		// border-bottom:1px solid #ddd;

		.k-comment-body-text {
			flex:1 1 auto;

			.fr-view {
				font-size:inherit;
				line-height:inherit;
			}
		}

		.fa-thumbtack {
			transform:rotate(30deg);
		}
	}

	.k-comment-replies {
		border-left:8px solid black;
		margin:4px -4px 0px 8px;
		border-bottom:1px solid black;
		border-right:1px solid black;
		border-top:1px solid black;
		// border-radius:6px 0 6px 0px;
		border-radius:6px;
		background-color:#666;

		.k-comment-wrapper {
			background-color:transparent!important;
			border:0;
			margin-bottom:0;
		}

		.k-comment-wrapper, .k-comment-editor {
			// border-top:1px solid black!important;
		}
		.k-comment-wrapper:first-of-type, .k-comment-editor:first-of-type {
			// border-top:0!important;
		}
		.k-comment {
			padding-right:4px;
			padding-left:4px;
		}

		.k-comment-group-name {
			margin-right:0!important;
		}

		.k-tile-comment-new-reply-editor-wrapper {
			// border-radius:0 6px 6px 0;
			margin:4px;
		}
	}

	.k-comment-unread {
		cursor:pointer;
		margin-top:-2px;
		margin-left:6px;
		margin-right:-2px;
		.v-icon {font-size:24px!important;}
	}
}

.k-comment-author-initials {
	// shown in SideBySideComments
	display:none;
}

.k-comment-author-line {
	display:flex;
	align-items: center;
	flex-wrap:wrap;

	font-size:12px;
	line-height:22px;
	// padding-bottom:4px;
	color:#555;

	.k-comment-author {
		font-weight:bold;
	}

	.k-comment-group-name {
		font-size:0.85em;
		margin-right:5px;
		margin-left:4px;
		white-space:nowrap;
		font-style: italic;
	}

	a {
		font-weight:bold;
		opacity:0.85;
	}
}

.k-comment-wrapper:first-of-type {
	.k-comment, .k-comment-editor {
		// border-top:0;
	}
}

.k-tile-comment-editor-wrapper {
	background-color:#666;
	// margin:-4px -8px -4px -8px;
	margin:0px -4px 0px -4px;
	border-radius:6px;
}

.k-comment-replies .k-tile-comment-editor-wrapper {
	// margin:-8px -8px -4px -8px;
	margin:-4px -4px 0px -4px;
	// border-radius:0px 0 6px 0;
	border-radius:6px;
}

.k-tile-comment-new-reply-editor-wrapper {
	background-color:#666;
	// border-radius:0px 0 6px 0;
	border-radius:6px;
}

// this div is in TileComments
.k-tile-comments-new-tile-editor-wrapper {
	border-radius:6px;
	margin:4px 4px 0 4px;
}

//////////////////////////////////////
// colors
$comment-read-border-color:$v-light-green;
$comment-read-bgd-color-top:$v-light-green-lighten-5;
$comment-read-bgd-color-reply:$v-light-green-lighten-4;

$comment-unread-border-color:$v-light-green-lighten-2;
$comment-unread-bgd-color-top:$v-light-green-lighten-5;
$comment-unread-bgd-color-reply:$v-light-green-lighten-4;
$comment-unread-alert-icon-color:$v-light-green-darken-3;

$comment-attn-border-color:$v-orange-lighten-2;
$comment-attn-bgd-color-top:$v-orange-lighten-5;
$comment-attn-bgd-color-reply:$v-orange-lighten-4;
$comment-attn-alert-icon-color:$v-orange-darken-3;

$comment-resolved-border-color:$v-purple-lighten-2;
$comment-resolved-bgd-color-top:$v-purple-lighten-5;
$comment-resolved-bgd-color-reply:$v-purple-lighten-4;
$comment-resolved-alert-icon-color:$v-purple-darken-3;

$comment-editor-bgd-color:$v-yellow-lighten-3;

$comment-attn-to-text-color:#888;
$comment-attn-to-subject-text-color:$v-orange-darken-3;

$comment-public-review-border-color:$v-pink-lighten-2;
$comment-public-review-bgd-color:$v-pink-lighten-5;
$comment-public-review-bgd-color-reply:$v-pink-lighten-4;

// editor colors
.k-tile-comment-editor-wrapper {
	background-color:$comment-editor-bgd-color;
}

.k-tile-comment-new-reply-editor-wrapper {
	background-color:$comment-editor-bgd-color;
}

.k-tile-comments-new-tile-editor-wrapper {
	background-color:$comment-editor-bgd-color;
}

// already-read comments (default)
.k-comment-wrapper {
	// background-color:#fffff8;
	background-color:$comment-read-bgd-color-top;
	border-color:$comment-read-border-color;
}

.k-comment {
	.k-comment-replies {
		border-color:$comment-read-border-color;
		background-color:$comment-read-bgd-color-reply;

		.k-comment-wrapper, .k-comment-editor {
			border-color:$comment-read-border-color;
		}
	}
	.k-comment-attn-to { color: $comment-attn-to-text-color; }
}

.k-tile-comments-new-tile-editor-wrapper {
	border-color:$comment-read-border-color;
}

// unread comments
.k-comment-wrapper-unread {
	.k-comment-wrapper {
		background-color:$comment-unread-bgd-color-top;
		border-color:$comment-unread-border-color;
	}

	.k-comment {
		.k-comment-replies {
			border-color:$comment-unread-border-color;
			background-color:$comment-unread-bgd-color-reply;

			.k-comment-wrapper, .k-comment-editor {
				border-color:$comment-unread-border-color;
			}
		}
		.k-comment-unread {
			.v-icon {color:$comment-unread-alert-icon-color;}
		}
	}
}

.k-comment-wrapper-public-review {
	.k-comment-wrapper {
		border-color:$comment-public-review-border-color;
		background-color:$comment-public-review-bgd-color;
	}
	.k-comment {
		.k-comment-replies {
			border-color:$comment-public-review-border-color;
			background-color:$comment-public-review-bgd-color-reply;

			.k-comment-wrapper, .k-comment-editor {
				border-color:$comment-public-review-border-color;
			}
		}
	}
}


// comments tagged to this user's attention
.k-comment-wrapper-attn {
	.k-comment-attn-to { color: $comment-attn-to-subject-text-color; }
}

.k-comment-wrapper-attn.k-comment-wrapper-unread {
	.k-comment-wrapper {
		background-color:$comment-attn-bgd-color-top!important;
		border-color:$comment-attn-border-color;
	}

	.k-comment {
		.k-comment-replies {
			border-color:$comment-attn-border-color;
			background-color:$comment-attn-bgd-color-reply;

			.k-comment-wrapper, .k-comment-editor {
				border-color:$comment-attn-border-color;
			}
		}
		.k-comment-unread {
			.v-icon {color:$comment-attn-alert-icon-color;}
		}
	}
}

// resolved comments
.k-comment-wrapper-resolved {
	.k-comment-wrapper {
		background-color:$comment-resolved-bgd-color-top;
		border-color:$comment-resolved-border-color;
	}

	.k-comment {
		.k-comment-replies {
			border-color:$comment-resolved-border-color;
			background-color:$comment-resolved-bgd-color-reply;

			.k-comment-wrapper, .k-comment-editor {
				border-color:$comment-resolved-border-color;
			}
		}
		.k-comment-resolved {
			.v-icon {color:$comment-resolved-alert-icon-color;}
		}
	}
}

// pinned colors (not currently used)
.k-comment-wrapper-pinned {
	background-color:#FCF1CA;
}

.k-comment-suggested-edits-wrapper {
	background-color:#f9f9f9;
	border-radius:4px;
	padding:4px;
}


////////////////////////////////
// updated css when we appear in a table
.k-framework-comments-table {
	.k-comment-wrapper {
		margin:0;
		// .fr-view {
		// 	font-size:12px;
		// 	line-height:16px;
		// }
	}
}
</style>
