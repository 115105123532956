<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="900px"><v-card>
	<v-card-title>
		<div>Edit Framework Rights for <b class="mx-2">{{(user.last_name) ? (user.last_name + ', ' + user.first_name) : ''}}</b> ({{user.email}})</div>
		<v-spacer></v-spacer>
		<v-btn icon @click="show_help" color="light-blue"><v-icon large>fas fa-info-circle</v-icon></v-btn>
	</v-card-title>
	<v-card-text style="color:#000;">
		<div class="d-flex align-center mt-3">
			<v-spacer/>
			<v-select class="ml-1" style="flex:0 0 340px" outlined hide-details v-model="framework_role" :items="framework_role_options" label="Framework Role" solo hide-details dense></v-select>
			<v-spacer/>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		user: { type: Object, required: true }, // the user being edited
		framework_record: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		framework_role: null,
		role_level: {
			admin: 3,
			editor: 2,
			reviewer: 1,
			public: 0,
		},
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		framework_rights() {
			return this.framework_record.ss_framework_data.user_rights
		},
		framework_role_options() {
			let arr = [
				{value:'admin', text: 'Framework Admin'},
				{value:'editor', text: 'Framework Editor'},
			]
			if (U.framework_is_private(this.framework_record)) {
				arr.push({value:'reviewer', text: 'Framework Reviewer (view/comment)'})
				arr.push({value:'public', text: 'None (user cannot view framework)'})
			} else {
				arr.push({value:'public', text: 'Default (view/comment)'})
			}
			return arr
		},
	},
	created() { },
	mounted() {
		// set current role; by default you get framework_rights, but check system rights for 'upgrade'
		this.framework_role = this.framework_rights[this.user.user_id] || 'public'
		if (this.user.system_role == 'admin') {
			this.framework_role = 'admin'
		} else if (this.user.system_role == 'editor') {
			if (this.framework_role != 'admin') {
				this.framework_role = this.user.system_role
			}
		} else if (this.user.system_role == 'reviewer') {
			if (this.framework_role != 'admin' && this.framework_role != 'editor') {
				this.framework_role = this.user.system_role
			}
		}	
		// else we stick with framework_role
	},
	methods: {
		show_help() { vapp.show_help('roles_framework') },

		save_edits() {
			let payload = {
				user_id_being_edited: this.user.user_id,
				framework_role: this.framework_role
			}

			if (empty(this.framework_role)) {
				this.$alert('Please select a valid role.')
				return
			}

			// you shouldn't be able to set a user's role for a particular framework below their system role.
			// so, e.g., if the user's system_role is 'editor', you shouldn't be able to change their framework role to 'reviewer' or ''
			let fr_role = this.framework_role
			let sys_role = this.user.system_role || 'public'

			// framework rights may be downgraded to public, provided they have no greater system role
			if (this.role_level[fr_role] < this.role_level[sys_role]) {
				this.$alert(sr("Framework rights can only be set higher than a user's system role. User <b>$1</b> has <b>$2</b> rights and <b>$3</b> role was selected.",
					this.user.email, sys_role, fr_role))
				return
			}

			this.$emit('user_edited', payload)
		},
	}
}
</script>

<style lang="scss">
</style>
