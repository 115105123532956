var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"margin":"8px -8px 0 -8px"}},[(_vm.framework_identifier)?_c('v-data-table',{staticClass:"k-framework-updates-table",attrs:{"light":"","dense":"","headers":_vm.headers,"hide-default-header":"","items":_vm.table_rows,"footer-props":_vm.footer_options},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',{staticClass:"k-framework-updates-table-header"},[_c('th',{staticClass:"k-framework-updates-table-th-left",staticStyle:{"cursor":"default"},attrs:{"colspan":"2"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin":"0 0 -20px 0px","font-size":"16px"}},[_c('b',{staticClass:"mr-3"},[_vm._v("Update Counts by Type/Date")]),_c('v-btn',{attrs:{"small":"","color":"orange darken-2","dark":""},on:{"click":_vm.view_framework_archives}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-archive")]),_vm._v("Show Framework Archives")],1)],1)]),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"cursor":"default","border-left":"1px solid #ccc","border-right":"1px solid #ccc","font-size":"14px!important"},attrs:{"colspan":"5"}},[_c('b',[_vm._v("Updates Since...")])]),_vm._v(" "),_c('th',{staticClass:"k-framework-updates-table-th-right",staticStyle:{"cursor":"default"},attrs:{"colspan":"1"}})]),_vm._v(" "),_c('tr',{staticClass:"k-framework-updates-table-header"},[_c('th',{staticStyle:{"white-space":"nowrap","padding-left":"24px","cursor":"default"}},[_vm._v("Update Type")]),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap"}},[_vm._v("Last Change Date")]),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","border-left":"1px solid #ccc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('day'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.compare_to_archive(_vm.update_record['day'],$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-table-list")])],1):_vm._e()]}}],null,false,1588993107)},[_vm._v("Show changes in a table")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('day'))?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.track_changes_to_archive(_vm.update_record['day'].archive_fn,$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,false,2918782025)},[_vm._v("Track changes")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"3px"}},[_vm._v("This morning"),_c('br'),_vm._v(_vm._s(_vm.ref_dates.day))])],1),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","border-left":"1px solid #ccc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('week'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.compare_to_archive(_vm.update_record['week'],$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-table-list")])],1):_vm._e()]}}],null,false,3398083187)},[_vm._v("Show changes in a table")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('week'))?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.track_changes_to_archive(_vm.update_record['week'].archive_fn,$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,false,1701272905)},[_vm._v("Track changes")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"3px"}},[_vm._v("1 Week Ago"),_c('br'),_vm._v(_vm._s(_vm.ref_dates.week))])],1),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","border-left":"1px solid #ccc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('month'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.compare_to_archive(_vm.update_record['month'],$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-table-list")])],1):_vm._e()]}}],null,false,2917465555)},[_vm._v("Show changes in a table")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('month'))?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.track_changes_to_archive(_vm.update_record['month'].archive_fn,$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,false,3601269065)},[_vm._v("Track changes")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"3px"}},[_vm._v("1 Month Ago"),_c('br'),_vm._v(_vm._s(_vm.ref_dates.month))])],1),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","border-left":"1px solid #ccc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('3_months'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.compare_to_archive(_vm.update_record['3_months'],$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-table-list")])],1):_vm._e()]}}],null,false,3070419123)},[_vm._v("Show changes in a table")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('3_months'))?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.track_changes_to_archive(_vm.update_record['3_months'].archive_fn,$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,false,3234405673)},[_vm._v("Track changes")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"3px"}},[_vm._v("3 Months Ago"),_c('br'),_vm._v(_vm._s(_vm.ref_dates['3_months']))])],1),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","border-left":"1px solid #ccc","border-right":"1px solid #ccc"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('year'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.compare_to_archive(_vm.update_record['year'],$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-table-list")])],1):_vm._e()]}}],null,false,2859566515)},[_vm._v("Show changes in a table")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.archive_available('year'))?_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"x-small":"","text":"","color":"orange darken-3","icon":""},on:{"click":function($event){return _vm.track_changes_to_archive(_vm.update_record['year'].archive_fn,$event)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"}},[_vm._v("fas fa-exchange-alt")])],1):_vm._e()]}}],null,false,1129537641)},[_vm._v("Track changes")]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"3px"}},[_vm._v("1 Year Ago"),_c('br'),_vm._v(_vm._s(_vm.ref_dates.year))])],1),_vm._v(" "),_c('th',{staticClass:"text-center",staticStyle:{"white-space":"nowrap","cursor":"default"}},[_vm._v("Date Added")])])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-left d-flex align-center pl-2",staticStyle:{"vertical-align":"middle"}},[_c('nobr',{domProps:{"innerHTML":_vm._s(item.label)}})],1),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle"}},[_c('nobr',[_vm._v(_vm._s(item.lastChangeDateTime))])],1),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_vm._v(_vm._s(item['day']))]),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_vm._v(_vm._s(item['week']))]),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_vm._v(_vm._s(item['month']))]),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_vm._v(_vm._s(item['3_months']))]),_vm._v(" "),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_vm._v(_vm._s(item['year']))]),_vm._v(" "),_c('td',{staticClass:"text-center px-2",staticStyle:{"vertical-align":"middle","border-left":"1px solid #ccc"}},[_c('nobr',[_vm._v(_vm._s(item.date_added))])],1)])]}}],null,false,3511728696)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }