class Resource_Set {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'resource_set_id', 0)	// this should always be filled in
		sdp(this, data, 'resource_set_title', '')
		sdp(this, data, 'creator_user_id', 0)
		sdp(this, data, 'creator_email', '')	// not currently used
		sdp(this, data, 'editor_emails', [])
		sdp(this, data, 'created_at', '')

		sdp(this, data, 'resources_loaded', false)

		// note that the resource_count and resources, as well as some other vals (see copy_for_save), are not actually stored in the resource DB table
		sdp(this, data, 'resource_count', 0)
		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		// remove things we don't want to save in the db for this object record
		delete o.creator_email
		delete o.resources_loaded
		delete o.resource_count
		delete o.resources	// note that resources must be saved separately
		delete o.created_at

		return o
	}
}
window.Resource_Set = Resource_Set

class Resource {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'resource_id', 0)	// this should always be filled in
		sdp(this, data, 'resource_set_id', 0)	// this should always be filled in
		sdp(this, data, 'external_resource_id', '')	// this should always be filled in
		sdp(this, data, 'creator_user_id', 0)
		sdp(this, data, 'created_at', '')

		sdp(this, data, 'resource_title', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'text', '')

		sdp(this, data, 'url', '')
		sdp(this, data, 'educationLevel', [])

		this.alignments = []
		if (!empty(data.alignments)) {
			for (let a of data.alignments) {
				this.alignments.push(new Resource_Alignment(a))
			}
		}

		sdp(this, data, 'alignment_suggestions', [])
		sdp(this, data, 'comp_strings', '')
		sdp(this, data, 'comp_string_vectors', [])
		sdp(this, data, 'candidates', [])
		sdp(this, data, 'candidates_showing', 0)
		sdp(this, data, 'candidate_params', '')
	}

	random_icon() {
		let icons = [
			'alicorn', 'ant', 'bat', 'bee', 'bird', 'cat', 'cow', 'crab', 'crow', 'deer', 'dinosaur', 'dog', 'dolphin', 'dove', 'dragon', 'duck', 'elephant', 'fish-fins', 'fish', 'frog', 'hippo', 'horse', 'hydra', 'kiwi-bird', 'lobster', 'locust', 'monkey', 'mosquito', 'mouse-field', 'narwhal', 'octopus', 'otter', 'pegasus', 'pig', 'rabbit', 'raccoon', 'ram', 'sheep', 'shrimp', 'snake', 'spider', 'squid', 'squirrel', 't-rex', 'turtle', 'unicorn', 'whale', 'worm',
		]

		if (!this.external_resource_id) return 'cube'
		let rng = new Math.seedrandom(this.external_resource_id)
		return icons[Math.floor(rng()*icons.length)]
	}

	random_icon_rotation() {
		if (!this.external_resource_id) return 0
		let rng = new Math.seedrandom(this.external_resource_id)
		return Math.round(rng()*360)
	}

	random_color() {
		if (!this.external_resource_id) return '#000'
		let rng = new Math.seedrandom(this.external_resource_id + this.resource_id)
		return `hsl(${Math.round(rng()*360)}, 100%, 30%)`
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		// remove things we don't want to save in the db for this object record
		delete o.created_at
		delete o.alignments	// note that alignments must be saved separately
		delete o.alignment_suggestions
		delete o.comp_strings
		delete o.comp_string_vectors
		delete o.candidates
		delete o.candidate_params

		return o
	}

}
window.Resource = Resource

class Resource_Alignment {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'resource_alignment_id', 0)
		sdp(this, data, 'resource_id', 0)
		sdp(this, data, 'framework_identifier', '')
		sdp(this, data, 'item_identifier', '')
		sdp(this, data, 'creator_user_id', 0)
		sdp(this, data, 'created_at', '')
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		// remove things we don't want to save in the db for this object record
		delete o.created_at

		return o
	}
}
window.Resource_Alignment = Resource_Alignment

class Resource_Alignment_Candidate {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'framework_identifier', '')
		sdp(this, data, 'cfitem', null)
		sdp(this, data, 'comps', {
			education_level: -1,
			item: 0,
			parent: 0,
			sme_assocs: 0,
			ai_assocs: 0,
		})
		sdp(this, data, 'currently_aligned', false)
		sdp(this, data, 'simscore', -1)
		sdp(this, data, 'simscore_pct', -1)
	}
}
window.Resource_Alignment_Candidate = Resource_Alignment_Candidate
