<template>
<svg class="k-svg-line" :class="css_class" :style="svg_style" @click="line_clicked"><line :style="line_style" :x1="x1" :x2="x2" :y1="y1" :y2="y2" /></svg>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		uuid: { type: String, required: false, default() { return U.new_uuid() } },
		update_counter: { type: Number, required: false, default() { return 0 }},

		// these can be either a string selector, a jquery object, or an [x,y] array
		line_start: { required: true },
		line_end: { required: true },

		stroke_width: { type: Number, required: false, default() { return 1 } },
		stroke_color: { type: String, required: false, default() { return '#000'} },
		css_class: { type: String, required: false, default() { return ''} },

		z_index: { type: Number, required: false, default() { return 0 } },

		// first letter represents offset X ('c', 'l', or 'r'); second is offset Y ('c', 't', or 'b')
		offset_start: { type: String, required: false, default() { return 'cc'} },
		offset_end: { type: String, required: false, default() { return 'cc'} },

		// jq fn to use: 'position' or 'offset'
		position_scheme: { type: String, required: false, default() { return 'offset'} },

		// click_fn: { required: false, default() { return null} },
	},
	data() { return {
		update_counter_start: -1,
		error_shown: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		line_start_coords() {
			// [x,y], where x and y are pixels
			if ($.isArray(this.line_start)) return this.line_start
			return this.jq_position(this.line_start, this.offset_start)
		},
		line_end_coords() {
			// [x,y], where x and y are pixels
			if ($.isArray(this.line_end)) return this.line_end
			return this.jq_position(this.line_end, this.offset_end)
		},

		line_width() { return Math.abs(this.line_end_coords[0] - this.line_start_coords[0]) },
		line_height() { return Math.abs(this.line_end_coords[1] - this.line_start_coords[1]) },

		x1() { return (this.line_start_coords[0] < this.line_end_coords[0]) ? 5 : this.line_width + 5 },
		x2() { return (this.line_start_coords[0] > this.line_end_coords[0]) ? 5 : this.line_width + 5 },
		y1() { return (this.line_start_coords[1] < this.line_end_coords[1]) ? 5 : this.line_height + 5 },
		y2() { return (this.line_start_coords[1] > this.line_end_coords[1]) ? 5 : this.line_height + 5 },

		svg_style() {
			let s = ''

			let left = (this.line_start_coords[0] < this.line_end_coords[0]) ? this.line_start_coords[0] : this.line_end_coords[0]
			let top = (this.line_start_coords[1] < this.line_end_coords[1]) ? this.line_start_coords[1] : this.line_end_coords[1]

			// pad by 5 pixels in each direction so that the line ends look good
			s += 'position: absolute;'
			s += sr('left: $1;', (left - 5) + 'px')
			s += sr('top: $1;', (top - 5) + 'px')
			s += sr('width: $1;', (this.line_width + 10) + 'px')
			s += sr('height: $1;', (this.line_height + 10) + 'px')
			s += sr('z-index:$1;', this.z_index)

			// console.log(s)

			return s
		},
		line_style() {
			let s = ''
			s += sr('stroke-width:$1;', this.stroke_width + 'px')
			// note: line `stroke` value is controlled by css
			return s
		},
	},
	watch: {
	},
	created() {
		this.update_counter_start = this.update_counter
	},
	mounted() {
	},
	methods: {
		jq_position(jq, offset) {
			// including a conditional with the update_counter here allows us to constantly update the endpoints of the line,
			// as long as line is actually present on the screen
			if (this.update_counter == this.update_counter_start) return [0,0]

			// string -- a selector, for which we get the jquery object
			if (typeof(jq) == 'string') {
				jq = $(jq)
			}
			// else line_start must itself be a jquery object

			// if the jq is no longer there, kill the line
			if (jq.length == 0 || jq.outerWidth() == 0 || jq.outerHeight() == 0) {
				this.$emit('remove_line', this.uuid)
				return [0,0]
			}

			let x, y
			let coords = jq[this.position_scheme]()

			if (offset[0] == 'l') x = coords.left
			else if (offset[0] == 'r') x = coords.left + jq.outerWidth()
			else x = coords.left + (jq.outerWidth()/2)	// center

			if (offset[1] == 't') y = coords.top
			else if (offset[1] == 'b') y = coords.top + jq.outerHeight()
			else y = coords.top + (jq.outerHeight()/2)	// center

			// let global_offset = $(this.$el).parent().offset()
			// x -= global_offset.left
			// y -= global_offset.top

			return [x,y]
		},

		line_clicked(evt) {
			// this used to be use to let events bubble down to things underneath the line, 
			// but now (11/2022) we know to just set pointer-events: none; on k-svg-lines, so it's not needed anymore
			// console.log('line_clicked1', evt)
			// if click_fn is 'bubble'
			// if (this.click_fn == 'bubble') {
			// 	// get x/y coords of click event, and set up a MouseEvent at this location
			// 	let x = evt.clientX
			// 	let y = evt.clientY
			// 	let new_evt = new MouseEvent('click', {
			// 		'view': window,
			// 		'bubbles': true,
			// 		'cancelable': true,
			// 		'screenX': x,
			// 		'screenY': y
			// 	})

			// 	// very briefly hide the k-svg-lines div so that we can...
			// 	$('.k-svg-lines').hide()
			// 	setTimeout(o=>{
			// 		// detect the element underneath
			// 		let el = document.elementFromPoint(x, y)
			// 		// console.log(el)

			// 		// call the click evt on this element
			// 		el.dispatchEvent(new_evt)
			// 		// then immediately re-show the lines
			// 		$('.k-svg-lines').show()
			// 	}, 0)

			// } else if (typeof(this.click_fn) == 'function') {
			// 	// else click_fn should be an actual function; just call it with the event info
			// 	this.click_fn(evt)
			// }
		},
	}
}
</script>

<style lang="scss">
.k-svg-line {
	line {
		stroke:#000;
	}
}
</style>
