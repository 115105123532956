<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div class="k-frameworks-map-outer" @mousemove="set_category_menu_pos">
		<div class="k-frameworks-map-instructions"><i>Hover over a <span class="indigo darken-4 white--text" style="border-radius:4px; padding:2px 6px;">state</span> or <span class="teal darken-4 white--text" style="border-radius:4px; padding:2px 6px;">issuing agency</span> to view frameworks</i></div>
		<div v-for="(row, index) in squares" :key="index" class="k-frameworks-map-row" :class="row_css(index)">
			<div v-for="(square, index) in row" :key="index" class="k-frameworks-map-sq" :style="square_style"><v-hover v-slot:default="{hover}" open-delay="150"><div class="k-frameworks-map-sq-mid">
				<div :class="square_css(square,hover)" @click.stop="square_clicked($event,square)">
					<div>{{square_label(square)}}</div>
					<v-spacer/>
					<div v-if="square&&square.category">
						<div v-if="true" class="k-frameworks-map-sq-subs"><span v-for="(subject_record, index) in square.subject_records" :key="index" v-show="subject_record.doc_showing" class="k-frameworks-map-sq-sub" :class="subject_record.subject.color_class+'-text-light'">•</span></div>
					</div>
				</div>
				<div v-show="hovered_square_category_showing(square)" class="k-frameworks-map-square-menu elevation-3" :style="category_menu_style" :class="square.category?'':'k-frameworks-map-square-menu-no-category'" :data-map-label="square.map_label">
					<div v-if="!square.category">{{square.map_label}}: No frameworks hosted on {{$store.state.site_config.app_name}};<br>click to view frameworks on <a href="https://casenetwork.commongoodlt.com">CASE Network 2</a>.</div>
					<div v-else @click.stop="expanded_square=square">
						<div class="k-frameworks-map-square-menu-title">
							<div>{{square.category.title}}</div>
							<v-spacer/>
							<v-btn x-small color="primary" text class="ml-1" style="margin-top:2px" @click.stop="expanded_square=square">More Info</v-btn>
						</div>
						<ul v-if="square.subject_records.length==1">
							<li v-for="(fr, k) in square.subject_records[0].framework_records" :key="fr.lsdoc_identifier"><a class="black--text" @click.stop="framework_clicked(fr,$event)">{{fr.json.CFDocument.title}}</a><v-icon style="margin-top:-2px; margin-left:4px" small :class="square.subject_records[0].subject.color_class+'-text'" @click.stop="framework_clicked(fr,$event)">fas fa-circle-arrow-right</v-icon></li>
						</ul>
						<ul v-if="square.subject_records.length>1">
							<li v-for="(subject_record, j) in square.subject_records" :key="subject_record.subject.subject_guess_string.lsdoc_identifier" :class="subject_record.doc_showing?'':'k-frameworks-map-fw-link-not-showing'"><a @click.stop="subject_clicked(square, subject_record, $event)" :class="subject_record.subject.color_class+'-text'"><b>{{subject_record.subject.label}}</b></a></li>
						</ul>
					</div>
				</div>
			</div></v-hover></div>
		</div>
		<div v-if="expanded_square" class="k-frameworks-map-outer-scrim" @click="expanded_square=null"></div>
		<div v-if="expanded_square" class="k-frameworks-map-expanded-square-outer"><div class="k-frameworks-map-expanded-square elevation-3">
			<v-btn fab x-small color="amber accent-4" light class="elevation-1" style="position:absolute; right:2px; top:2px" @click="expanded_square=null"><v-icon small>fas fa-times</v-icon></v-btn>
			<div class="k-frameworks-map-square-menu-title">
				<div>{{expanded_square.category.title}}</div>
			</div>
			<div class="k-frameworks-map-expanded-square-list">
				<div v-for="(subject_record, j) in expanded_square.subject_records" :key="subject_record.subject.subject_guess_string.lsdoc_identifier" :class="subject_record.doc_showing?'':'k-frameworks-map-fw-link-not-showing'">
					<div :class="subject_record.subject.color_class+'-text'" class="d-flex align-center"><div class="k-frameworks-map-menu-subject-bullet">•</div><div class="k-frameworks-map-menu-subject-title">{{subject_record.subject.label}}</div></div>
					<ul class="mt-1 mb-3">
						<li v-for="(fr, k) in subject_record.framework_records" :key="fr.lsdoc_identifier" :class="expanded_square.category.doc_showing[fr.lsdoc_identifier]?'':'k-frameworks-map-fw-link-not-showing'"><a class="black--text" @click.stop="framework_clicked(fr,$event)">{{fr.json.CFDocument.title}}</a><v-icon style="margin-top:-2px; margin-left:4px" small :class="subject_record.subject.color_class+'-text'" @click.stop="framework_clicked(fr,$event)">fas fa-circle-arrow-right</v-icon></li>
					</ul>
				</div>

			</div>
		</div></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		category_records: { type: Array, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		// Move SC under NC
		// Move VA down to where SC was
		// Move DC slides between MD and WV
		map_template: [{map_label:'AL',x:7,y:6},{map_label:'AK',x:0,y:1},{map_label:'AZ',x:2,y:5},{map_label:'AR',x:5,y:5},{map_label:'CA',x:1,y:4},{map_label:'CO',x:3,y:4},{map_label:'CT',x:10,y:3},{map_label:'DE',x:10,y:4},{map_label:'FL',x:8,y:7},{map_label:'GA',x:8,y:6},{map_label:'HI',x:0,y:6},{map_label:'ID',x:2,y:2},{map_label:'IL',x:6,y:3},{map_label:'IN',x:6,y:4},{map_label:'IA',x:5,y:3},{map_label:'KS',x:4,y:5},{map_label:'KY',x:6,y:5},{map_label:'LA',x:5,y:6},{map_label:'ME',x:11,y:0},{map_label:'MD',x:9,y:4},{map_label:'MA',x:10,y:2},{map_label:'MI',x:7,y:2},{map_label:'MN',x:5,y:2},{map_label:'MS',x:6,y:6},{map_label:'MO',x:5,y:4},{map_label:'MT',x:3,y:2},{map_label:'NE',x:4,y:4},{map_label:'NV',x:2,y:4},{map_label:'NH',x:11,y:1},{map_label:'NJ',x:9,y:3},{map_label:'NM',x:3,y:5},{map_label:'NY',x:9,y:2},{map_label:'NC',x:9,y:5},{map_label:'ND',x:4,y:2},{map_label:'OH',x:7,y:3},{map_label:'OK',x:4,y:6},{map_label:'OR',x:1,y:3},{map_label:'PA',x:8,y:3},{map_label:'PR',x:11,y:7},{map_label:'RI',x:11,y:2},{map_label:'SC',x:9,y:6},{map_label:'SD',x:4,y:3},{map_label:'TN',x:7,y:5},{map_label:'TX',x:4,y:7},{map_label:'UT',x:2,y:3},{map_label:'VT',x:10,y:1},{map_label:'VA',x:8,y:5},{map_label:'WA',x:1,y:2},{map_label:'WV',x:7,y:4},{map_label:'WI',x:6,y:2},{map_label:'WY',x:3,y:3}, {map_label:'DC',x:8,y:4},],
		max_map_row: 0,
		max_map_col: 0,
		square_dim: 0,
		hovered_square: null,
		hover_changed: false,
		category_menu_x: 0,
		category_menu_y: 0,
		expanded_square: null,
	}},
	computed: {
		...mapState(['map_frozen_hovered_square']),
		...mapGetters([]),
		squares() {
			function get_subject_records(category) {
				let arr = []
				for (let fr of category.framework_records) {
					let subject = U.framework_subject_guess(fr.json.CFDocument)
					let sr = arr.find(x=>x.subject==subject)
					if (!sr) {
						 arr.push({
							subject: subject,
							doc_showing: true,
							framework_records: [fr],
						}) 
					} else {
						sr.framework_records.push(fr)
						sr.framework_records.sort((a,b)=>{
							if (a.json.CFDocument.title < b.json.CFDocument.title) return -1
							if (b.json.CFDocument.title < a.json.CFDocument.title) return -1
							return 0
						})
					}
				}
				arr.sort((a,b)=>a.subject.sort_index - b.subject.sort_index)
				return arr
			}

			let rows = []
			this.max_map_row = 0
			this.max_map_col = 0
			for (let item of this.map_template) {
				if (!rows[item.y]) rows[item.y] = []

				let square = Object.assign({}, item)
				square.category = this.category_records.find(x=>x.map_label == square.map_label)
				if (square.category) square.subject_records = get_subject_records(square.category)

				rows[item.y][item.x] = square
				if (item.y > this.max_map_row) this.max_map_row = item.y
				if (item.x > this.max_map_col) this.max_map_col = item.x
			}

			// add blank cols
			for (let row of rows) {
				for (let i = 0; i <= this.max_map_col; ++i) {
					if (!row[i]) row[i] = {map_label: ''}
				}
			}

			// add extra categories in additional row(s) -- but these don't get filled in with the same number of columns
			let extra_row = []
			for (let cr of this.category_records) {
				if (cr.map_label && !this.map_template.find(x=>x.map_label == cr.map_label)) {
					if (extra_row.length == this.max_map_col) {
						rows.push(extra_row)
						extra_row = []
					}
					extra_row.push({
						y: rows.length,
						x: extra_row.length,
						map_label: cr.map_label,
						category: cr,
						subject_records: get_subject_records(cr)
					})
				}
			}
			if (extra_row.length > 0) rows.push(extra_row)

			return rows
		},
		square_style() {
			return sr('flex: 0 0 $1; width:$1; height:$1; ', (this.square_dim + 'px'))
		},
		category_menu_style() {
			return sr('left:$1; top:$2', this.category_menu_x + 'px', this.category_menu_y + 'px')
		},
	},
	watch: {
		// on window size, resize squares
		'$vuetify.breakpoint.width': {immediate: true, handler(val) { this.set_square_dim() }},
		'$vuetify.breakpoint.height': {immediate: true, handler(val) { this.set_square_dim() }},
	},
	created() {
		vapp.frameworks_map_component = this
	},
	mounted() {
	},
	methods: {
		set_square_dim() {
			this.$nextTick(()=>{
				// let w = Math.floor($('.k-frameworks-map-outer').width() / (this.max_map_col + 1) - 8)
				// this.square_dim = w - 4
				// if (this.square_dim < 40) this.square_dim = 40

				let w = Math.floor($('.k-frameworks-map-outer').width() / (this.max_map_col + 1) - 8)
				let h = Math.floor(($(window).height() - 200) / 8 - 8)	// the US map has 8 rows; so use (8) instead of (this.squares.length)
				this.square_dim = ((w < h ? w : h) - 4)
				if (this.square_dim < 40) this.square_dim = 40
				if (this.square_dim > 64) this.square_dim = 64
				// console.log(w, h, this.square_dim)
			})
		},

		row_css(row_index) {
			let s = ''
			// "extra" frameworks outside the map get an extra class, and the first row gets an extra-extra class
			if (row_index > this.max_map_row) s += ' k-frameworks-map-row-extra'
			if (row_index == (this.max_map_row + 1)) s += ' k-frameworks-map-row-first-extra'
			return s
		},

		square_css(square, hover) {
			// console.log('square_css')
			// this fn gets called whenever the hover value changes for a square
			// so in addition to setting the square's css, we use this fn to set hovered_square based on hover
			if (!hover && square == this.hovered_square) this.hovered_square = null
			if (hover && this.hovered_square != square) {
				this.hovered_square = square
				// set hover_changed to true to make sure we re-position the category menu
				this.hover_changed = true
			}

			// if the square is blank, show it as a blank square
			if (!square || square.map_label == '') return 'k-frameworks-map-sq-blank'

			let s = 'k-frameworks-map-sq-inner'
			
			if (this.square_dim < 50) s += ' k-frameworks-map-sq-small'
			else if (this.square_dim < 60) s += ' k-frameworks-map-sq-med'
			else s += ' k-frameworks-map-sq-large'

			if (square.category) {
				// 'filled' class means the square is associated with a category that includes frameworks
				s += ' k-frameworks-map-sq-filled'

				// if search terms are entered and the square doesn't have any frameworks that match the terms, mark as "not showing"
				if (!square.category.showing) s += ' k-frameworks-map-sq-filled-not-showing'
			}

			// if (hover || square.map_label == this.map_frozen_hovered_square) s += ' k-frameworks-map-sq-hovered elevation-5'
			if (hover && !this.expanded_square) s += ' k-frameworks-map-sq-hovered elevation-5'

			setTimeout(x=>this.set_category_menu_pos(event))
			
			return s
		},

		square_label(square) {
			if (empty(square?.map_label)) return ''
			return square.map_label
		},

		framework_dot_color(framework_record) {
			return U.framework_color(framework_record.lsdoc_identifier) + '-text-light'
		},

		set_category_menu_pos(event) {
			// if a category's menu is "frozen", because the user clicked on the category, 
			if (this.map_frozen_hovered_square) {
				// then if the user just hovered over the frozen category itself, unfreeze it
				if (this.hovered_square && this.hovered_square.map_label == this.map_frozen_hovered_square) {
					this.$store.commit('set', ['map_frozen_hovered_square', ''])
				}
				// and return, so we don't change the menu position
				return
			}
			// if we're already hovering over a square that has a category, don't change the menu position
			if (this.hovered_square && this.hovered_square.category) {
				// ... unless the menu is off the window, in which case correct
				let menu = $(this.$el).find(sr('[data-map-label=$1]', this.hovered_square.map_label))
				let pos = menu.offset()
				let left = pos.left
				let top = pos.top
				let right = left + menu.outerWidth()
				let bottom = top + menu.outerHeight()
				// console.log(left, top, right, bottom, this.$vuetify.breakpoint.width, this.$vuetify.breakpoint.height)
				if (left < 0) this.category_menu_x += (-1 * left) + 4
				else if (right > this.$vuetify.breakpoint.width) this.category_menu_x -= (right - this.$vuetify.breakpoint.width + 4)

				// don't set bottom if we had to set top...
				if (top < 0) {
					this.category_menu_y += (-1 * top) + 4
					// and if we set top, also set height of menu if necessary to keep it on the screen
				} else if (bottom > this.$vuetify.breakpoint.height) {
					this.category_menu_y -= (bottom - this.$vuetify.breakpoint.height + 4)
				}

				// if the hovered square just changed, we *do* want to change the menu position
				if (!this.hover_changed) return
			}
			// if we get to here, change the menu position
			if (event) {
				this.category_menu_x = event.clientX
				this.category_menu_y = event.clientY
			}
			this.hover_changed = false
		},

		// this determines whether or not each square's hovered category menu is showing
		hovered_square_category_showing(square) {
			// if we're showing an expanded square, don't show
			if (this.expanded_square) return false
			// if a framework is showing, don't show
			if (vapp.framework_list_component.case_tree_lsdoc_identifier) return false
			// if the square is blank, nothing to show
			if (empty(square?.map_label)) return false
			// if one square's menu is "frozen", only show if it's that square
			if (this.map_frozen_hovered_square) return (square.map_label == this.map_frozen_hovered_square)
			// else if we're hovering over this square, show it
			if (this.hovered_square == square) return true
			// if we get to here, don't show it
			return false
		},

		square_clicked(event, square) {
			if (!square.category) {
				window.open('https://casenetwork.commongoodlt.com')
				return
			}

			// when a square is clicked, cancel hovered_square, so that if a different square was hovered, this square will be shown
			this.$store.commit('set', ['map_frozen_hovered_square', ''])
			this.category_menu_x = event.clientX
			this.category_menu_y = event.clientY
			this.expanded_square = square
		},

		framework_clicked(fr, $event) {
			// when a user clicks to view a framework, "freeze" the framework's category, 
			// so that if the user comes back to the map, they can quickly see and click on a different framework from this category
			// this.$store.commit('set', ['map_frozen_hovered_square', this.hovered_square.map_label])
			this.$emit('view_framework', [fr.lsdoc_identifier, null, $event])
		},

		subject_clicked(square, subject_record, $event) {
			this.expanded_square = square;
			if (subject_record.framework_records.length == 1) {
				this.framework_clicked(subject_record.framework_records[0], $event)
			}
		},
	}
}
</script>

<style lang="scss">
.k-frameworks-map-outer {
	width:100%;
	max-width:1000px;
	margin:20px auto 60px auto;
	position:relative;
}

.k-frameworks-map-outer-scrim {
	background-color:rgba(0,0,0,0.3);
	cursor:pointer;
	position:absolute;
	left:0;
	top:0;
	width:calc(100% + 40px);
	height:calc(100% + 8px);
	margin:-4px -20px -18px -20px;
	z-index:9;
	border-radius:12px;
}

.k-frameworks-map-instructions {
	position:absolute;
	left:-12px;
	top:12px;
}

.k-frameworks-map-row {
	display:flex;
	align-items: center;
	justify-content: center;
}

.k-frameworks-map-row-first-extra {
	margin-top:16px;
	padding-top:16px;
	border-top:1px solid #999;
}

.k-frameworks-map-sq {
	flex:0 0 auto;
	margin:4px;
}

.k-frameworks-map-sq-mid {
	width:100%;
	height:100%;
}

.k-frameworks-map-sq-inner {
	width:100%;
	height:100%;
	padding:4px;
	background-color:#ddd;
	color:#000;
	font-size:16px;
	border-radius: 5px;
	display:flex;
	flex-direction: column;
	border:2px solid transparent;
	transition: transform 0.1s;
}

.k-frameworks-map-sq-subs {
	text-align:left;
	line-height: 2px;
	margin-left:-2px;
}
.k-frameworks-map-sq-sub { 
	// font-size:48px; 
	// letter-spacing: -4px;
}

.k-frameworks-map-sq-small { font-size:10px; line-height:10px; padding: 2px; .k-frameworks-map-sq-subs { margin-bottom:-4px; } .k-frameworks-map-sq-sub { font-size:24px; letter-spacing:-1px; } }
.k-frameworks-map-sq-med { font-size:13px; line-height:13px; padding: 3px; .k-frameworks-map-sq-subs { margin-bottom:-6px; } .k-frameworks-map-sq-sub { font-size:36px; letter-spacing:-2px; } }
.k-frameworks-map-sq-large { font-size:16px; line-height:16px; padding: 4px; .k-frameworks-map-sq-subs { margin-bottom:-6px; } .k-frameworks-map-sq-sub { font-size:44px; letter-spacing:-4px; } }

.k-frameworks-map-sq-hovered {
	border-color: $v-amber-accent-4;
	transform:scale(1.2);
}

.k-frameworks-map-sq-blank {
	visibility:hidden;
}

.k-frameworks-map-sq-filled {
	background-color:$v-indigo-darken-4;
	color:#fff;
	cursor:pointer;
}

.k-frameworks-map-sq-filled-not-showing {
	background-color:$v-indigo-lighten-3;
	color:#000;
}

.k-frameworks-map-square-menu {
	position:fixed;
	cursor: auto;
	border:2px solid $v-amber-accent-4;
	border-radius:12px;
	padding:12px;
	background-color:#eee;
	color:#000;
	font-size:14px;
	line-height:17px;
	min-width:300px;
	max-width:500px;
	max-height:calc(100vh - 8px);
	overflow:auto;
	// margin-left:-6px;
	// margin-top:-6px;
	z-index:10;
	li { margin-top:3px; margin-bottom:3px; }
	a:hover { text-decoration: underline; }
}

.k-frameworks-map-square-menu-no-category {
	background-color:#fff;
	border-color:transparent;
	color:#000;
	min-width: auto;
	margin:0;
}

.k-frameworks-map-square-menu-title {
	font-size:18px;
	line-height:22px;
	font-weight:bold;
	margin-bottom:6px;
	display:flex;
	flex-align:center;
}

.k-frameworks-map-menu-subject-bullet {
	display:inline-block;
	font-size:48px;
	line-height:0;
	// margin-bottom:-12px;
}
.k-frameworks-map-menu-subject-title {
	font-size:16px;
	line-height:22px;
	font-weight:bold;
	margin-top:-2px;
	margin-left:4px;
}

.k-frameworks-map-expanded-square-outer {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
}

.k-frameworks-map-expanded-square {
	position:relative;
	border:4px solid $v-amber-accent-4;
	border-radius:12px;
	padding:12px;
	background-color:#eee;
	color:#000;
	font-size:14px;
	line-height:19px;
	width:600px;
	margin:32px auto 8px auto;
	max-width:100vw;
	max-height:calc(100% - 40px);
	overflow:auto;
	z-index:10;
}

.k-frameworks-map-expanded-square-list {
	margin-top:8px;
	// max-height:calc(100vh - 360px);
	// max-height:calc(100% - 28px);
	// overflow:auto;
	a:hover { text-decoration:underline; }
}


.k-frameworks-map-fw-link-not-showing {
	opacity:0.5;
}

.k-frameworks-map-row-extra {
	.k-frameworks-map-sq-filled {
		background-color:$v-teal-darken-4;
	}
	.k-frameworks-map-sq-filled-not-showing {
		background-color:$v-teal-lighten-3;
	}
	.k-frameworks-map-square-menu {
		background-color:$v-teal-lighten-5;
	}
}

</style>
