<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="640" persistent scrollable :fullscreen="$vuetify.breakpoint.xsOnly" light :transition="false">
		<v-card>
			<v-card-title class="ma-0 pt-2 pb-0 px-3 d-flex align-center">
				<b>Equation Editor</b>
				<v-spacer/>
				<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="mathlive_advanced_options" mandatory>
					<v-btn x-small light @click="focus_mathfield" :value="false">Basic</v-btn>
					<v-btn x-small light @click="focus_mathfield" :value="true">  Expert  </v-btn>
				</v-btn-toggle>
			</v-card-title>
			<v-card-text style="font-size:14px" class="pa-2">
				<div v-show="mathlive_advanced_options"><div class="mb-2 d-flex align-center k-mathlive-latex-text-field-wrapper">
					<div class="mr-2 text-right" style="width:80px;"><b>LaTeX:</b></div>
					<v-text-field background-color="#fff" outlined dense hide-details v-model="new_latex" placeholder="" @input="raw_latex_changed"></v-text-field>
				</div></div>
				<div class="d-flex align-center">
					<div v-show="mathlive_advanced_options" class="mr-2 text-right" style="width:80px;"><b>Rendered:</b></div>
					<div style="flex:1 0 auto"><math-field class="k-mathlive-math-field" ref="math_field"
						@input="mathlive_changed" @mount="initialize" @unmount="mathlive_unmounted" @keydown="mathlive_keydown" @focusin="mathlive_focused" @focusout="mathlive_blurred"
					></math-field></div>
				</div>
				<div class="k-mathlive-keyboard-holder-outer" theme="dark">
					<div class="k-mathlive-keyboard-holder"></div>
					<div class="k-mathlive-keyboard-screen" v-show="!mathfield_focused" @click="focus_mathfield"></div>
				</div>
			</v-card-text>
			<v-card-actions class="px-3 pb-3">
				<v-btn color="secondary" @click="close"><v-icon small class="mr-1">fas fa-xmark</v-icon>Cancel Edits</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="save"><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		original_latex: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		new_latex: '',
		mathfield_focused: false,
		uuid: '',
		// https://github.com/arnog/mathlive/blob/49f11e27e44cb71f02fbcd99336199e41335a1c7/src/virtual-keyboard/data.ts
		/*
		arrows -- at least single and double
		one line - two lines - three lines
		delta, mol, deg
		parentheses
		common element letters; other letters always in mathrm
		periodic table?
		1+, 2+, 3+, 4+, 1-, 2-, 3-, 4- superscript?
		dot notation
		forms for superscripts/subscripts
		*/
		cglt_chemistry_layout:{
			label: 'CHEM',
			// labelClass: 'MLK__tex-math',
			// tooltip: 'keyboard.tooltip.numeric',
			rows: [
			['[7]', '[8]', '[9]', {label: '\\rightarrow', latex: '\\rightarrow', shift: {label: '\\Rightarrow', latex: '\\Rightarrow'}, },
				'#@^{#?}', 
				{ label: '[backspace]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[4]', '[5]', '[6]', {label: '\\leftarrow', latex: '\\leftarrow', shift: {label: '\\Leftarrow', latex: '\\Leftarrow'}, },
				'\\sqrt{#0}', 
				{ label: '[left]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[1]', '[2]', '[3]', , {label: '\\rightleftharpoons', latex: '\\rightleftharpoons', shift: {label: '\\Leftrightarrow', latex: '\\Leftrightarrow'}, },
				{ class: 'hide-shift', latex: '\\frac{#@}{#?}', shift: '\\frac{#?}{#@}', }, 
				{ label: '[right]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[0]', '[.]', '[=]', '', 
				{ label: '>', latex: '>', class: 'hide-shift', shift: { latex: '<', label: '<' }, },
				{ label: '[shift]', width: 1.0 } ],		// , '[separator]'
		]},
		cglt_numeric_layout_basic:{
			label: '123',
			// labelClass: 'MLK__tex-math',
			// tooltip: 'keyboard.tooltip.numeric',
			rows: [
			['[7]', '[8]', '[9]', '[+]', 
				'#@^{#?}', 
				{ label: '[backspace]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[4]', '[5]', '[6]', '[-]', 
				'\\sqrt{#0}', 
				{ label: '[left]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[1]', '[2]', '[3]', { class: 'hide-shift', latex: '\\times', shift: '\\cdot' }, 
				{ class: 'hide-shift', latex: '\\frac{#@}{#?}', shift: '\\frac{#?}{#@}', }, 
				{ label: '[right]', class: 'action hide-shift', width:1.0 }, ],		// , '[separator]'
			['[0]', '[.]', '[=]', '[/]', 
				{ label: '>', latex: '>', class: 'hide-shift', shift: { latex: '<', label: '<' }, },
				{ label: '[shift]', width: 1.0 } ],		// , '[separator]'
		]},
		// this is the standard '123' with a few changes -- most importantly, fraction instead of "forall" and \times as the default instead of \cdot
		cglt_numeric_layout_advanced:{
			label: '123',
			// labelClass: 'MLK__tex-math',
			tooltip: 'keyboard.tooltip.numeric',
			rows: [
			[
				{
				latex: 'x',
				shift: 'y',
				variants: [
					'y',
					'z',
					't',
					'r',
					'x^2',
					'x^n',
					'x^{#?}',
					'x_n',
					'x_i',
					'x_{#?}',
					{ latex: 'f(#?)', class: 'small' },
					{ latex: 'g(#?)', class: 'small' },
				],
				},
				{ latex: 'n', shift: 'a', variants: ['i', 'j', 'p', 'k', 'a', 'u'] },
				'[separator-5]',
				'[7]',
				'[8]',
				'[9]',
				'[/]',
				'[separator-5]',
				{
				latex: '\\exponentialE',
				shift: '\\ln',
				variants: ['\\exp', '\\times 10^{#?}', '\\ln', '\\log_{10}', '\\log'],
				},
				{
				latex: '\\imaginaryI',
				variants: ['\\Re', '\\Im', '\\imaginaryJ', '\\Vert #0 \\Vert'],
				},
				{
				latex: '\\pi',
				shift: '\\sin',
				variants: [
					'\\prod',
					{ latex: '\\theta', aside: 'theta' },
					{ latex: '\\rho', aside: 'rho' },
					{ latex: '\\tau', aside: 'tau' },
					'\\sin',
					'\\cos',
					'\\tan',
				],
				},
			],
			[
				{
				label: '<',
				latex: '<',
				class: 'hide-shift',
				shift: { latex: '\\le', label: '≤' },
				},
				{
				label: '>',
				latex: '>',
				class: 'hide-shift',
				shift: { latex: '\\ge', label: '≥' },
				},
				'[separator-5]',
				'[4]',
				'[5]',
				'[6]',
				// '[*]',
				{
				class: 'hide-shift',
				latex: '\\times',
				shift: '\\cdot',
				},
				'[separator-5]',
				{
				class: 'hide-shift',
				latex: '#@^2}',
				shift: '#@^{\\prime}}',
				},
				{
				latex: '#@^{#0}}',
				class: 'hide-shift',
				shift: '#@_{#?}',
				},
				{
				class: 'hide-shift',
				latex: '\\sqrt{#0}',
				shift: { latex: '\\sqrt[#0]{#?}}' },
				},
			],
			[
				'[(]',
				'[)]',
				'[separator-5]',
				'[1]',
				'[2]',
				'[3]',
				'[-]',
				'[separator-5]',
				{
				class: 'hide-shift',
				latex: '\\frac{#@}{#?}',
				shift: '\\frac{#?}{#@}',
				},
				{
				latex: '\\int^{\\infty}_{0}\\!#?\\,\\mathrm{d}x',
				class: 'small hide-shift',
				shift: '\\int',
				variants: [
					{ latex: '\\int_{#?}^{#?}', class: 'small' },
					{ latex: '\\int', class: 'small' },
					{ latex: '\\iint', class: 'small' },
					{ latex: '\\iiint', class: 'small' },
					{ latex: '\\oint', class: 'small' },
					'\\mathrm{d}x',
					{ latex: '\\dfrac{\\mathrm{d}}{\\mathrm{d} x}', class: 'small' },
					{ latex: '\\frac{\\partial}{\\partial x}', class: 'small' },

					'\\partial',
				],
				},
				{ label: '[backspace]', width: 1.0 },
			],
			[
				{ label: '[shift]', width: 2.0 },
				'[separator-5]',
				'[0]',
				'[.]',
				'[=]',
				'[+]',
				'[separator-5]',
				'[left]',
				'[right]',
				{ label: '[action]', width: 1.0 },
			],
			],
		},
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		mathlive_advanced_options: {
			get() { return this.$store.state.lst.mathlive_advanced_options },
			set(val) { 
				this.$store.commit('lst_set', ['mathlive_advanced_options', val])
				this.mathlive_set_keyboard_layouts()
			}
		},
		mathlive_chosen_keyboard_index: {
			get() { return this.$store.state.lst.mathlive_chosen_keyboard_index },
			set(val) { this.$store.commit('lst_set', ['mathlive_chosen_keyboard_index', val]) }
		},
		mf() { return this.$refs.math_field },
	},
	watch: {
	},
	created() {
		// if we get the special 'XXDOLLARXX' value in original_latex, it means the user just typed a dollar sign; start with empty value
		this.new_latex = (this.original_latex == 'XXDOLLARXX') ? '' : this.original_latex
	},
	mounted() {
	},
	methods: {
		initialize() {
			if (empty(this.uuid)) {
				this.uuid = U.new_uuid()
			}
			// console.log('MATH-FIELD MOUNTED: ' + this.uuid)

			this.mathlive_set_value(this.new_latex)
			this.mf.mathVirtualKeyboardPolicy = 'manual'
			this.mf.removeExtraneousParentheses = true

			// for some reason, $(this.$el).find('.k-mathlive-keyboard-holder')[0] doesn't seem to work
			mathVirtualKeyboard.container = $('.k-mathlive-keyboard-holder')[0] 
			this.mathlive_set_keyboard_layouts()

			// we can't seem to remove the geometrychange listener, so just set it up once. if state.mathfield_uuid is empty, this is the first time we're setting up a mathfield
			if (empty(this.$store.state.mathfield_uuid)) {
				mathVirtualKeyboard.addEventListener('geometrychange', () => {
					let holder = $('.k-mathlive-keyboard-holder')
					let height = mathVirtualKeyboard.boundingRect.height
					// console.log(`geometrychange:`, holder.length, height)
					if (holder.length > 0) {
						if (height == 0) {
							mathVirtualKeyboard.show()
						} else {
							holder.height(height)
						}
					}
				})
			}

			mathVirtualKeyboard.show()
			setTimeout(x=>{
				this.focus_mathfield()
				this.restore_mathlive_keyboard()
			}, 0)

			this.$store.commit('set', ['mathfield_uuid', this.uuid])
		},

		mathlive_set_keyboard_layouts() {
			// https://cortexjs.io/mathlive/guides/virtual-keyboards/
			if (this.mathlive_advanced_options) {
				mathVirtualKeyboard.layouts = [this.cglt_numeric_layout_advanced, 'symbols', 'alphabetic', 'greek', this.cglt_chemistry_layout]
			} else {
				mathVirtualKeyboard.layouts = [this.cglt_numeric_layout_basic, 'symbols', 'alphabetic', 'greek', this.cglt_chemistry_layout]
			}
		},

		mathlive_set_value(val) {
			// the silenceNotifications prevents an infinite loop
			this.mf.setValue(val, {silenceNotifications: true})
		},

		focus_mathfield() {
			this.mf.focus()
		},

		save() {
			this.$emit('mathlive_save', this.new_latex)
			this.$emit('dialog_cancel')
		},

		close() {
			this.$emit('mathlive_cancel')
			this.$emit('dialog_cancel')
		},

		//////////////////////////////////
		// event handlers
		mathlive_unmounted($evt) {
			// console.log('math-field unmounted: ' + this.uuid)
		},

		mathlive_focused($evt) {
			// console.log('mathlive_focused')
			this.mathfield_focused = true
		},

		mathlive_blurred($evt) {
			// console.log('mathlive_blurred')
			this.mathfield_focused = false
		},

		mathlive_keydown($evt) {
			// console.log('mathlive_keydown', $evt)
			// \ key allows for typing a symbol name; this would prevent it
			// if ($evt.key === '\\') {
			// 	$evt.preventDefault()
			// 	this.mf.executeCommand(['insert', '\\backslash'])

			// not sure what it's supposed to be doing on escape, but cancel it
			if ($evt.key === 'Escape') $evt.preventDefault()

			// if user holds shift key down while hitting enter, save; otherwise prevent default
			if ($evt.key === 'Enter') {
				if ($evt.shiftKey) {
					this.save()
				}
				$evt.preventDefault()
			}
		},

		mathlive_changed($evt) {
			let new_val = this.mf.getValue()

			// console.log('mathlive_changed', new_val)
			if (!empty(new_val)) {
				// don't use '\left'/'\right' with parentheses -- they are unnecessarily and make it harder to read the "plain LaTeX"
				// it seems to work well to replace these in the new_latex value without forcing that change to the math-field itself
				new_val = new_val.replace(/\\left\(/g, '(')
				new_val = new_val.replace(/\\right\)/g, ')')

				this.new_latex = new_val
			}

			// hackish way to get the index of the currently-selected keyboard, so that we can restore to the user's preferred keyboard the next time we launch
			let index = $('#' + mathVirtualKeyboard.currentLayer).parent().children().toArray().findIndex(x=>x.getAttribute('id')==mathVirtualKeyboard.currentLayer)
			this.mathlive_chosen_keyboard_index = index
		},

		restore_mathlive_keyboard() {
			let layer_id = $('#' + mathVirtualKeyboard.currentLayer).parent().children()[this.mathlive_chosen_keyboard_index].getAttribute('id')
			mathVirtualKeyboard.currentLayer = layer_id
			// this.mathlive_resize_keyboard_holder()
		},

		raw_latex_changed() {
			// console.log('raw_latex_changed', this.new_latex)
			this.mathlive_set_value(this.new_latex)
		},
	}
}
</script>

<style lang="scss">
.k-mathlive-latex-text-field-wrapper {
	input {
		width:100%;
		font-size:14px;
	}
	.v-input__slot {
		padding-left:6px!important;
	}
}

.k-mathlive-math-field {
	display:block;
	font-size:18px;
	--smart-fence-color: $v-red-darken-1;
	padding:6px 0 6px 6px;
	border-radius:5px;
}

// hide the keyboard toggle button, because we always show the keyboard
.k-mathlive-math-field::part(virtual-keyboard-toggle) {
	display: none;
}

.k-mathlive-keyboard-holder-outer {
	// margin-left:88px;
	position:relative;
}

.k-mathlive-keyboard-holder {
	// display:none;
	width:100%;
	// height:500px;
	margin-left:auto;
	margin-right:auto;
	margin-top:8px;
	background-color:#151515;
	border-radius:6px;
	padding:0 4px 4px 4px;
	--keycap-font-size: 18px;
}

.k-mathlive-keyboard-screen {
	position:absolute;
	z-index:999;
	left:0;
	top:0;
	width:100%;
	height:100%;
	border-radius:6px;
	background-color:#666;
	opacity:0.7;
}

.MLK__plate {
	// if we don't do this the keyboard gets cut off at the bttom
	top:0px!important;
}
</style>