// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		ap_metadata_compute() {
			let tree = []
			let branch
			let import_lines = ''

			let process_node = (level, node) => {
				branch.item_type = node.cfitem.CFItemType ?? '-'
				branch.example = node.cfitem.fullStatement

				for (let i = 0; i < level; ++i) import_lines += '\t'
				import_lines += `Metadata Category\t${branch.item_type}\n`
				if (node.children.length > 0) {
					branch.child = {}
					branch = branch.child
					process_node(level+1, node.children[0])
				}
			}

			// process top-level nodes
			for (let node of this.cfo.cftree.children) {
				// when we get to 'Additional Metadata', stop
				if (node.cfitem.fullStatement.search(/metadata/i) > -1) break

				if (node.children.length > 0) {
					tree.push({})
					branch = tree[tree.length-1]
					process_node(0, node.children[0])
				}
			}
			console.log(tree)
			if (vapp.item_import_component) vapp.item_import_component.raw_text_for_import = import_lines
			else console.log(import_lines)
		},
	}
}