<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div style="width:100vw; height:calc(100vh - 60px); padding:0 12px 12px 12px; background-color:#eee;">
	<div class="k-framework-list-agency-title k-banner-color k-agency-color k-agency-title-extra" v-html="site_config.agency_name" @click="open_agency_url"></div>
	<div class="k-toolbar-app-title k-toolbar-app-title--framework-list">{{site_config.app_name}}</div> <!-- Note that this becomes visible when the window size is very narrow -->
	<div class="d-flex">
		<h3 class="k-framework-list-header-line k-custom-heading-color d-flex align-center">
			<div style="margin:-2px 0 0 4px;">Resource Sets</div>
			<v-btn icon small style="margin:-3px 0 0 10px" @click="U.show_help('framework_index')" color="light-blue"><v-icon>fas fa-info-circle</v-icon></v-btn>
		</h3>
		<v-spacer></v-spacer>

		<v-btn color="primary" small class="ml-3 mt-4 mr-1" v-show="can_create_new" @click="create_resource_set"><v-icon small class="mr-2">fas fa-plus</v-icon>New Resource Set</v-btn>
	</div>
	<div class="k-framework-list-search-wrapper mr-1" style="margin-top:-25px; max-width:320px;"><v-text-field v-model="search" dense outlined rounded clearable append-icon="fa fa-search" label="Search resource set titles" background-color="#fff" single-line hide-details @input="handle_search"></v-text-field></div>

	<div class="k-framework-list-wrapper elevation-3 mx-auto" style="width:1100px"><div class="k-framework-list-wrapper-inner">
		<v-data-table dense class="k-framework-list-table my-2" hide-default-footer 
			:headers="table_headers"
			:items="table_filtered_resource_sets"
			item-key="resource_set_id"
			:items-per-page="-1"
			:must-sort="true"
			:sort-by.sync="table_sort_by"
			:sort-desc.sync="table_sort_desc"
		>
			<template v-slot:item.resource_set_title="{ item }">
				<div class="d-flex align-center">
					<v-btn fab x-small color="primary" dark class="mr-1 my-1 elevation-1" @click="view_resource_set(item.resource_set_id,$event)"><v-icon small>fas fa-cubes-stacked</v-icon></v-btn>
					<div class="ml-2">
						<a v-html="item.resource_set_title" @click="view_resource_set(item.resource_set_id,$event)"></a>
					</div>
				</div>
			</template>
			<template v-slot:item.created_at="{ item }"><nobr>{{formatted_date(item.created_at)}}</nobr></template>
		</v-data-table>
	</div></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		query: { type: Object, required: false, default() { return {} }},
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		search: null,
	}},
	computed: {
		...mapState(['framework_records', 'user_info', 'site_config', 'resource_sets']),
		...mapGetters([]),
		can_create_new() { 
			return vapp.is_granted('resources', 'create_sets')
		},
		table_sort_by: {
			get() { return this.$store.state.lst.resource_set_list_table_sort_by },
			set(val) { this.$store.commit('lst_set', ['resource_set_list_table_sort_by', val]) }
		},
		table_sort_desc: {
			get() { return this.$store.state.lst.resource_set_list_table_sort_desc },
			set(val) { this.$store.commit('lst_set', ['resource_set_list_table_sort_desc', val]) }
		},
		table_headers() {
			let arr = [
				// { text: 'ID', value: 'resource_set_id', sortable: true, align: 'left' },
				{ text: 'Resource Set Title', value: 'resource_set_title', sortable: true, align: 'left' },
				// { text: 'Creator', value: 'creator_email', sortable: true, align: 'left' },
				{ text: '# Resources', value: 'n_resources', sortable: true, align: 'center' },
				{ text: 'Created At', value: 'created_at', sortable: true, align: 'center' },
			]
			return arr
		},
		table_filtered_resource_sets() {
			let arr = []
			for (let rs of this.resource_sets) {
				arr.push({
					resource_set_id: rs.resource_set_id,
					resource_set_title: rs.resource_set_title,
					// resource_count holds the original count when we get_resource_sets; once we've loaded resources, go on the basis of resources.length
					n_resources: rs.resources_loaded ? rs.resources.length : rs.resource_count,
					created_at: rs.created_at,
				})
			}
			return arr
		},
	},
	watch: {
	},
	created() {
		// if you're not allowed to be in the resources viewer, redirect to the framework list
		if (!vapp.show_resources_toggle) {
			vapp.go_to_route('frameworks')
			return
		}
		// else make sure frameworks_or_resources_toggle is set to 'resources'
		this.$store.commit('lst_set', ['frameworks_or_resources_toggle', 'resources'])

		this.$store.dispatch('get_resource_sets')

		vapp.resource_set_list_component = this
	},
	mounted() {
	},
	methods: {
		open_agency_url() { window.open(this.site_config.agency_url) },
		handle_search() {
		},
		formatted_date(s) {
			s += '+00:00'	// add GMT timezone indicator, then use U.local_last_change_date_time
			return U.local_last_change_date_time(s)
		},

		create_resource_set() {
			this.$prompt({
				title: 'New Resource Set',
				text: 'Enter a title for your new Resource Set:',
				disableForEmptyValue: true,
				acceptText: 'Create Resource Set',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(resource_set_title => {
				resource_set_title = $.trim(resource_set_title)
				if (empty(resource_set_title)) return

				let payload = {
					service_url: 'save_resource_set',
					user_id: this.user_info.user_id,
					resource_set_data: {resource_set_title: resource_set_title},
				}
				this.$store.dispatch('service', payload).then((result)=>{
					if (!result.resource_set) {
						console.log(result)
						this.$alert('save_resource_set service failed')
					}
					this.$store.commit('set', [this.resource_sets, 'PUSH', new Resource_Set(result.resource_set)])
					this.$alert({title: 'Success', text: `Resource Set “${resource_set_title}” created!`})
				}).catch(result=>{
					if (result?.status == 'resource_set_already_exists') {
						this.$alert(`You have already created a Resource Set with title “${resource_set_title}”.`)
					} else {
						this.$alert('Error: ' + result?.status)
					}
				})
				
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		view_resource_set(resource_set_id, $event) {
			// start url with the document identifier
			let url = '/resources/' + resource_set_id

			// add the query string ??
			// url += this.get_query_string()

			// if user holds meta/ctrl key down, open in a new tab/window
			if (U.meta_or_alt_key($event)) {
				window.open(url)
			} else {
				this.$router.push({ path: url })
			}
		},
	}
}
</script>

<style lang="scss">
</style>