<template>
	<div>Bad Route!!!</div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
	},
	data() { return {
	}},
	computed: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
