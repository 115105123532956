<template><div class="k-case-item-importer mt-2">
	<div class="text-center" style="color:black; font-size:18px;"><b>Batch Import Item Metadata</b></div>

	<div class="k-case-ie-line mt-2">
		<div class="k-case-ie-line-label mr-2 text-right" style="width:104px"><nobr>Field to update:</nobr></div>
		<div style="width:400px"><v-autocomplete v-model="field" :items="field_options" label="" outlined background-color="#fff" dense hide-details></v-autocomplete></div>
	</div>

	<div class="k-case-ie-line mt-0" v-show="selected_field_option.appendable"><v-spacer/>
		<v-radio-group v-model="replace_or_append" hide-details row class="mt-1">
			<v-radio label="Replace values" value="replace"></v-radio>
			<v-radio label="Append to existing values" value="append"></v-radio>
		</v-radio-group>
	<v-spacer/></div>

	<div class="mt-2">
		<ul>
			<li>Each line should include a human-readable code or an item identifier (GUID), followed by a space or tab, then the value to be imported for the specified item.</li>
			<li>To clear the value for an item, enter “*CLEAR*” as the value.</li>
			<li v-if="selected_field_option.appendable&&replace_or_append=='append'&&field!='notes'">Add an underscore (“_”) to the start of the value to insert a space before the appended text, or add two underscores (“__”) to the start of the value to insert a line break.</li>
		</ul><div class="mt-2 mb-1">Sample import lines (for field “Item Type”):</div>
<pre class="elevation-2 pa-1 mt-1 mb-2" style="border-radius:6px; border:1px solid #ccc;">
RL.K.9&nbsp;&nbsp;Content Standard
e9b86358-9a29-49c6-a046-11ee23782339&nbsp;&nbsp;Element
cf3434bf-2ade-4593-b39c-2d55decfbe29&nbsp;&nbsp;*CLEAR*
</pre>
	</div>

	<div class="mt-1 pt-2 text-center" style="border-top:1px solid #999">Paste lines in below, then click “Process Metadata” to continue.<br>You will then be asked to review and confirm changes.</div>
	<div class="d-flex align-center mt-2 mb-3">
		<v-btn small color="secondary" class="mr-2" @click="$emit('cancel_import')">Cancel Import</v-btn>
		<v-btn small color="primary" @click="import_metadata">Process Metadata…</v-btn>
		<v-spacer/>
		<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=true"><v-icon>fas fa-expand</v-icon></v-btn>
	</div>
	<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area" outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="10"></v-textarea>

	<v-dialog v-model="ta_dialog_open" max-width="95vw" persistent scrollable>
		<v-card style="background-color:#eee">
			<v-card-text>
				<div class="mt-4">
<pre class="elevation-2 pa-1 mt-1 mb-2 white" style="border-radius:6px; border:1px solid #ccc;">
RL.K.9&nbsp;Content Standard
e9b86358-9a29-49c6-a046-11ee23782339&nbsp;Element
cf3434bf-2ade-4593-b39c-2d55decfbe29&nbsp;&nbsp;*CLEAR*
</pre>
				</div>
				<div class="d-flex align-center mt-2 mb-3">
					<v-btn small color="secondary" class="mr-2" @click="ta_dialog_open=false;$emit('cancel_import')">Cancel Import</v-btn>
					<v-btn small color="primary" @click="import_metadata">Process Metadata…</v-btn>
					<v-spacer/>
					<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=false"><v-icon>fas fa-compress</v-icon></v-btn>
				</div>
				<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area-max" autofocus outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="40"></v-textarea>
			</v-card-text>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		editor_component: { required: true },
	},
	data() { return {
		raw_text_for_import: '',
		ta_dialog_open: false,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
		framework_record() { return this.editor_component.framework_record },
		field: {
			get() { return this.$store.state.lst.import_metadata_field },
			set(val) { this.$store.commit('lst_set', ['import_metadata_field', val]) }
		},
		replace_or_append: {
			get() { return this.$store.state.lst.import_metadata_replace_or_append },
			set(val) { this.$store.commit('lst_set', ['import_metadata_replace_or_append', val]) }
		},
		field_options() {
			let arr = [
				// {value:'uri', text: 'URI' },
				// {value:'sourceItemIdentifier', text: 'Source Item Identifier*' },
				// {value:'sourceItemURI', text: 'Source Item URI*' },
				{value:'humanCodingScheme', text: 'Human-Readable Code', appendable:true },
				{value:'fullStatement', text: 'Full Statement', appendable:true },
				{value:'abbreviatedStatement', text: 'Abbreviated Statement', appendable:true },
				{value:'notes', text: 'Notes', appendable:true },
				{value:'supplementalNotes', text: 'Supplemental Information', appendable:true },
				{value:'CFItemType', text: 'Item Type', appendable:false },
				{value:'educationLevel', text: 'Education Level', appendable:false },
				{value:'statusStartDate', text: 'Implementation Start Date', appendable:false },
				{value:'statusEndDate', text: 'Retirement Date', appendable:false },
				{value:'language', text: 'Language', appendable:false },
				// {value:'identifier', text: 'Identifier (GUID)', appendable:false },
				{value:'alternativeLabel', text: 'Alternate Label', appendable:false },
				{value:'subject', text: 'Subject', appendable:false },
				{value:'listEnumeration', text: 'listEnumeration', appendable:false },
				// {value:'conceptKeywords', text: 'conceptKeywords' },
				// {value:'licenseURI', text: 'licenseURI' },
				// {value:'isSupplementalItem', text: 'Supplemental Branch*' },
			]

			// add custom metadata fields
			if (window.CASE_Custom_Extension_Fields.CFItem) {
				for (let key in window.CASE_Custom_Extension_Fields.CFItem) {
					let def = window.CASE_Custom_Extension_Fields.CFItem[key]
					arr.push({value: 'ext:' + key, text: 'Extended Metadata: ' + (def.display_key || key), appendable:false})
				}
			}
			return arr
		},
		selected_field_option() {
			if (empty(this.field)) return {}
			return this.field_options.find(x=>x.value==this.field) ?? {}
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		import_metadata(confirmed) {
			// internal fn for processing errors
			let process_error = (line_number, error) => {
				log.push(sr('<div class="my-3">$1: <b class="red" style="padding:3px 3px; border-radius:3px; color:#fff;">Error:</b> $2</div>', line_number, error))
				++error_count
			}

			// internal fn for processing non-error info
			let process_info = (line_number, statement) => {
				log.push(sr('$1: $2', line_number, statement))
			}

			////////////////////////////////
			if (empty(this.field)) {
				this.$alert('You must specify the field you wish to update')
				return
			}

			if (confirmed !== true && this.field == 'identifier') {
				this.$confirm({
					title: 'WARNING!',
					text: 'Do not use this tool to update identifiers unless you’re sure you know what you’re doing!',
					acceptText: 'I float',
					acceptColor: 'red',
					cancelText: 'I sink',
					dialogMaxWidth: 800,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let log = []
			let error_count = 0

			let lines = $.trim(this.raw_text_for_import)
			if (empty(lines)) {
				this.$alert('You must enter text to import.')
				return
			}

			let updates = []

			lines = lines.split('\n')
			let line_number = 0
			///////////////////////////////////////
			// go through each line
			for (let line of lines) {
				++line_number

				// trim line
				line = $.trim(line)

				// skip blank lines
				if (empty(line)) continue

				// normal lines: identifier or hcs, then space(s), then data
				if (line.search(/^\s*(\S+)(\s+(.*))?/) == 0) {
					let id = RegExp.$1
					let value = RegExp.$3
					value = $.trim(value)
					let original_value = value

					// try to identify the item
					let item
					if (U.is_uuid(id)) item = this.framework_record.json.CFItems.find(x=>x.identifier==id)
					else item = this.framework_record.json.CFItems.find(x=>x.humanCodingScheme==id)
					if (!item) {
						process_error(line_number, 'Couldn’t find item <b>' + id + '</b>')
						continue
					}

					if (empty(value)) {
						process_info(line_number, 'No value for item <b>' + id + '</b>')
						continue
					}

					// we only need to store the item identifier, the property we're updating, and the lastChangeDateTime (the default value for replace_objects in save_framework_data is 'no')
					let o = {identifier: item.identifier, lastChangeDateTime: '*NOW*'}
					let verb = 'Replacing', report_value = value
					// deal with the updated field. note that value could be '*CLEAR*', which save_framework_data will handle
					if (this.field.search(/ext:(.+)/) > -1) {
						// we have to send through the whole extensions field; save_framework_data can't handle subfields
						let subfield = RegExp.$1
						let extensions = object_copy(item.extensions ?? {})

						if (value == '*CLEAR*') {
							delete extensions[subfield]
							verb = 'Clearing'
						} else {
							// validation for field types
							let def = window.CASE_Custom_Extension_Fields.CFItem[subfield]
							if (def.type == 'boolean') {
								value = value.toLowerCase()
								if (value == '0') value = false
								else if (value == '1') value = true
								else if (['true','false'].includes(value)) value = (value == 'true')
								else { process_error(line_number, `Bad boolean value for id “${id}”: ${original_value}`); continue; }

							} else if (def.type == 'number') {
								value = value * 1
								if (isNaN(value)) { process_error(line_number, `Bad numeric value for id “${id}”: ${original_value}`); continue; }
								report_value = value + ''	// this makes it so that if, e.g., '4.30' is specified, what will be reported is '4.3'

							} else if (def.type == 'array') {
								value = value.split(/\s*,\s*/)
								if (def.legal_vals && def.legal_vals.length > 0) {
									let illegal_vals = []
									for (let v of value) {
										if (!def.legal_vals.includes(v)) illegal_vals.push(v)
									}
									if (illegal_vals.length > 0) {
										process_error(line_number, `Illegal value(s) for id “${id}”: ${illegal_vals.join(', ')}`)
										continue
									}
								}
								report_value = JSON.stringify(value)

							} else if (def.type == 'string') {
								// if we have legal values, check against them
								if (def.legal_vals && def.legal_vals.length > 0 && !def.legal_vals.includes(value)) {
									process_error(line_number, `Illegal value for id “${id}”: ${original_value}`)
									continue
								}

							} else {
								// object -- not sure if we need to support this...
								try { value = JSON.parse(value) }
								catch(e) { process_error(line_number, `Bad ${def.type} value for id “${id}”: ${original_value}`); continue; }
								report_value = JSON.stringify(value)
							}
							extensions[subfield] = value
						}
						o.extensions = extensions

					} else if (this.field == 'identifier') {
						// while going through the lines, add an 'X' to the identifier (but not uri's) so that we can support 'swapping' identifiers; we'll remove the Xs after the user confirms the save
						// update the item's identifier and uri
						// find all associations that use the item; update identifier and uri in each one

					// TODO: validation for other types: educationLevel, statusStartDate, statusEndDate, language
					} else {
						// replace '\n' with newlines
						value = value.replace(/\\n/g, '\n')

						let o2 = o
						if (this.field == 'supplementalNotes') {
							if (!o.extensions) o.extensions = {}
							o2 = o.extensions
						}

						// append if appropriate...
						if (value != '*CLEAR*' && this.replace_or_append == 'append' && this.selected_field_option.appendable) {
							let initial_value = (this.field == 'supplementalNotes') ? item.extensions?.supplementalNotes : item[this.field]
							if (empty(initial_value)) initial_value = ''

							o2[this.field] = initial_value
							// add space or line breaks if specified
							if (value.search(/^__/) == 0) o2[this.field] += '\n\n'
							else if (value.search(/^_/) == 0) o2[this.field] += ' '
							// then add the value
							o2[this.field] += value.replace(/^_*/, '')

							if (value == '*CLEAR*') verb = 'Cleared'
							else verb = 'Appending'

						// or replace
						} else {
							o2[this.field] = value
						}
						report_value = o2[this.field].replace(/\n/g, '\\n')
					}

					if (verb != 'Cleared') report_value = `new value: ${report_value}`

					o.verb = verb
					o.id = id
					o.report_value = report_value

					updates.push(o)

					process_info(line_number, `${verb} “${id}”: ${original_value}`)

				} else {
					process_error(line_number, 'Couldn’t process line <b>' + line + '</b>')
				}
			}
			// finished going through each line
			///////////////////////////////////////

			// show log and give user options for what to do
			let error_text = ''
			if (error_count > 0) error_text = sr('; <span class="red white--text" style="padding:3px 6px; border-radius:4px;">$1 possible errors</span>', error_count)
			let text = sr('<div style="margin-top:-10px; margin-bottom:8px;">Processed $1 lines; found $2 items to change$3.</div>', line_number, updates.length, error_text)
			let acceptText = ''

			if (updates.length > 0) {
				text += `<div class="mb-1">Updates to process for field <b>${this.field}</b>:</div>`
				text += '<div style="margin-left:16px; font-size:14px; line-height:18px;">'
				for (let update of updates) {
					let verb_color = (update.verb == 'Cleared') ? 'red--text text--darken-3' : 'green--text text--darken-3'
					text += `<div style="margin-left:25px; text-indent:-35px; margin-top:4px;">• <b class="${verb_color}">${update.verb}</b> <b>${update.id}:</b> ${update.report_value}</div>`
					delete update.verb
					delete update.id
					delete update.report_value
				}
				text += '</div>'
				acceptText += `Update ${updates.length} ${U.ps('Item', updates.length)}`
			}

			text += '<div class="mt-4 mb-2"><b>Processing log:</b></div>'
			text += '<div class="k-case-item-importer-report"><div class="k-case-item-importer-report-inner">'
			for (let log_line of log) text += sr('<div style="margin-left:50px; text-indent:-50px;">$1</div>', log_line)
			text += '</div></div>'

			this.$confirm({
			    title: 'Processing Results',
			    text: text,
			    acceptText: acceptText,
				hideAccept: empty(acceptText),
				dialogMaxWidth: 950,
			}).then(y => {
				this.save_imported_updates(updates)
			}).catch(n=>{console.log(n)}).finally(f=>{})

			console.log(updates)
		},

		save_imported_updates(updates) {
			let data = {
				lsdoc_identifier: this.framework_record.lsdoc_identifier,
				CFItems: updates,
				update_item_types: true,
			}

			// make sure max-editor is closed
			this.ta_dialog_open = false

			///////////////////////////////////
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// apply updates to framework_record -- copied from BatchEditor
				for (let cfitem of data.CFItems) {
					// create or update the CFItem in the framework json and to the cfo cfitem
					let json = this.framework_record.json.CFItems.find(x=>x.identifier == cfitem.identifier)
					let cfo_cfitem = this.framework_record.cfo.cfitems[cfitem.identifier]
					for (let key in cfitem) {
						if (key == 'identifier') continue
						if (key == 'lastChangeDateTime') {
							this.$store.commit('set', [json, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
							this.$store.commit('set', [cfo_cfitem, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
						} else {
							this.$store.commit('set', [json, key, cfitem[key]])
							this.$store.commit('set', [cfo_cfitem, key, cfitem[key]])
						}
					}
				}

				// for identifier changes, update CFAssociations too
				if (data.CFAssociations) for (let cfa of data.CFAssociations) {
					// // update lastChangeDateTime json before pushing CFAssociation
					// if (cfa.lastChangeDateTime == '*NOW*') {
					// 	cfa.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					// }

					// this.$store.commit('set', [this.host_framework_record.json.CFAssociations, 'PUSH', cfa])

					// // also add to associations_hash for the cfo
					// U.update_associations_hash(this.host_framework_record.cfo, cfa)
				}

			}).catch((e)=>{
				console.log(e)
				// in case of failure...
				this.$alert('Error saving updated metadata')
			})
		}
	}
}
</script>

<style lang="scss">
</style>
