<template><div class="k-case-tree-blob-item-outer" :class="item_outer_css()" :data-tree-key="item.tree_key">

	<v-hover v-slot:default="{hover}" v-if="!item_is_document" :disabled="$vuetify.breakpoint.xs"><div class="k-case-tree-blob-item" :data-case-tree-item-tree-key="item.tree_key" :class="item_css(hover)">
		<div class="k-case-tree-blob-item-inner" @click.stop="item_clicked">
			<v-btn v-if="item_is_document" class="k-case-tree-blob-item-node-btn" x-small icon color="grey darken-2"><v-icon small>fas fa-map</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length>0" class="k-case-tree-blob-item-node-btn" :style="node_btn_style" x-small icon color="grey darken-2" @click.stop="node_btn_clicked"><v-icon small>fas fa-chevron-circle-right</v-icon></v-btn>
			<v-btn v-if="!item_is_document && item.children.length==0" class="k-case-tree-blob-item-node-btn" x-small icon color="grey darken-2"><v-icon small>fas fa-dot-circle</v-icon></v-btn>

			<div class="k-case-tree-blob-item-code-plus-statement">
				<div>
					<span v-if="tracking_changes" class="k-case-tree-item-node-btn k-case-tree-item-track-changes-btn"><v-icon small>fas {{tracked_change_icon}}</v-icon></span>
					<b v-if="item.cfitem.humanCodingScheme" class="mr-1 grey--text text--darken-2" v-html="human_coding_scheme" :class="hover?'k-case-tree-item-statement-hovered':''"></b>
					<span class="k-case-tree-item-statement k-case-tree-markdown" :class="(hover&&!item.cfitem.humanCodingScheme)?'k-case-tree-item-statement-hovered':''" v-html="marked_full_statement"></span>
					<nobr>
						<v-icon v-if="item_is_pinned" @click.stop="show_expanded_tile" color="green" x-small>fas fa-thumbtack</v-icon>
						<span v-if="show_associations_count" class="k-case-tree-item-associations-count" :class="show_associations_count_css(hover)"><v-icon x-small>fas fa-arrows-alt-h</v-icon><span v-if="self_association_count>0" style="margin-left:3px" v-html="self_association_count"></span></span>
						<span v-if="show_comments_count" class="k-case-tree-item-comments-count" :class="show_comments_count_css(hover)"><v-icon x-small>{{hover||node_is_active?'fas':'far'}} fa-comment</v-icon><span class="k-case-tree-item-comments-number" v-if="self_comment_count>0" style="margin-left:3px">{{self_comment_count}}</span></span>
						<span v-visible="node_is_active||hover" class="k-case-tree-blob-item-expand-btn"><v-icon small color="#666" @click.stop="show_expanded_tile">fas fa-expand</v-icon></span>
					</nobr>
				</div>
			</div>
		</div>
	</div></v-hover>

	<div v-if="node_is_rendered || item_is_document">
		<div v-show="(node_is_open||item_is_document)&&item.children.length>0" class="k-case-tree-blob-item-children" :class="children_css()">
			<v-btn v-if="show_table_mode_btn&&show_table_mode" icon color="#999" style="align-self:center; margin-top:-8px;" @click="toggle_table_mode"><v-icon>fas {{show_table_mode?'fa-circle-minus':'fa-table-cells'}}</v-icon></v-btn>
			<CASEBlobItem v-for="(child_item) in item.children" :key="child_item.tree_key" v-show="child_is_showing(child_item)" :class="child_css()"
				:framework_record="framework_record" :item="child_item" :viewer="viewer"
				@child_closed="child_closed" @child_opened="child_opened"
			/>
			<v-btn v-if="show_table_mode_btn" icon color="#999" style="align-self:center; margin-top:-8px;" @click="toggle_table_mode"><v-icon>fas {{show_table_mode?'fa-circle-minus':'fa-table-cells'}}</v-icon></v-btn>
		</div>
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'
import CASEBlobItem from './CASEBlobItem'

export default {
	name: 'CASEBlobItem',
	components: { CopyBtn, CASEBlobItem, },
	props: {
		item: { type: Object, required: true },
		framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return {} }},
	},
	data() { return {
		node_is_rendered: false,
		node_is_open: false,
		node_is_chosen_local: false,
		// show_all_children: false,
	}},
	computed: {
		...mapState(['framework_records', 'comments', 'start_identifier']),
		...mapGetters(['comments_hash', 'signed_in']),

		in_viewer_context() { return true },
		basic_search_tree_key_hash() { return this.viewer.basic_search_tree_key_hash ? this.viewer.basic_search_tree_key_hash : {} },
		search_result_highlighted_tree_key() { return this.viewer.search_result_highlighted_tree_key ? this.viewer.search_result_highlighted_tree_key : '' },
		batch_edit_mode() { return this.viewer.batch_edit_mode },
		edited_node() { return this.viewer.edited_node },
		editing_document() { return this.viewer.editing_document },
		showing_associated_item_tree() { return this.viewer.association_framework_tree_identifier != null },
		moving() { return this.viewer.show_move_fn != false },
		stats_mode() { return this.viewer.stats_mode },
		maximized() { return this.viewer.maximized },

		pinned_items() { return this.framework_record.pinned_items },
		item_is_pinned() {
			// TODO: account for the fact that an item can be in multiple places in the tree, and this node's item may already be pinned via another node
			return (this.pinned_items.findIndex(x=>x==this.item.tree_key) > -1)
		},

		show_table_mode() {
			// we only show one item at a time in table mode
			return this.framework_record.blob_item_table_key === this.item.tree_key
		},
		show_table_mode_btn() {
			// decide whether or not to show the table mode btn(s) [when table mode is open, we show a close btn on both sides of the table]
			// only allow table mode for md and up (>960px)
			if (!this.$vuetify.breakpoint.mdAndUp) return false

			// only allow table mode for nodes that have grandchildren -- if the node doesn't have grandchildren, it would be the column in the parent's table
			let item_has_grandchildren = false
			for (let child_item of this.item.children) {
				if (child_item.children.length > 0) item_has_grandchildren = true
			}
			if (item_has_grandchildren) {
				// also only show the table btn if (no children are open) or (all children are open [in which case the btn will actually be a - btn -- "exit table mode"])
				// the rationale here is that if one child is open, the user has explicitly decided to browse that child; if we show the table btn in that case it can get unwieldy (too many table btns)
				if (this.no_children_open || this.all_children_open) return true
			}
			return false
		},
		at_least_one_child_open() {
			for (let child_item of this.item.children) {
				if (this.open_nodes[child_item.tree_key]) return true
			}
			return false
		},
		no_children_open() {
			for (let child_item of this.item.children) {
				if (child_item.children.length > 0 && this.open_nodes[child_item.tree_key]) return false
			}
			return true
		},
		parent_all_children_open() {
			if (this.item_is_document) return false
			for (let child_item of this.item.parent_node.children) {
				if (child_item.children.length > 0 && !this.open_nodes[child_item.tree_key]) return false
			}
			return true
		},
		all_children_open() {
			for (let child_item of this.item.children) {
				if (!this.open_nodes[child_item.tree_key]) return false
			}
			return true
		},

		making_associations() { return this.viewer.current_editor && this.viewer.current_editor.make_association },	// this is pretty hackish
		aligning_resources() { return this.viewer.current_editor && this.viewer.current_editor.make_alignment },	// this is pretty hackish

		active_node() { return this.framework_record.active_node },
		chosen_node() { return this.framework_record.chosen_node },
		open_nodes() { return this.framework_record.open_nodes },
		last_clicked_node() { return this.framework_record.last_clicked_node },

		node_btn_style() {
			if (this.node_is_open) return 'transform:rotate(90deg)'
		},
		item_is_document() { return empty(this.item.parent_node) },
		node_is_active() { return (this.active_node == this.item.tree_key) },
		item_is_leaf() {
			return this.item.children.length == 0
		},
		item_is_node() {
			return !this.item_is_leaf
		},
		item_is_last_clicked() {
			// when not in the viewer context
			if (!this.in_viewer_context) {
				// use the last_clicked style if the node's identifier matches highlighted_identifier_override
				if (this.item.cfitem.identifier == this.highlighted_identifier_override) return true
				return false
			}
			return this.last_clicked_node == this.item.tree_key
		},

		tracking_changes() {
			return vapp.case_tree_component && vapp.case_tree_component.tracking_changes
		},
		tracked_change_entities() {
			if (!this.tracking_changes) return {}
			return U.get_archive_comparison_entities(this.item.cfitem.identifier, vapp.case_tree_component.framework_record, vapp.case_tree_component.track_changes_framework_record)
		},
		show_tracked_changes() {
			// if we're tracking changes and there's an item on both sides, highlight the difference between current and archive; otherwise we just show what's in this.item
			return this.tracking_changes && this.tracked_change_entities.current.identifier && this.tracked_change_entities.archived.identifier
		},
		tracked_change_icon() {
			if (!this.tracking_changes) return ''
			return U.tracked_change_icon(this.tracked_change_entities)
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },

		human_coding_scheme() {
			if (this.show_tracked_changes && this.track_changes_fields.humanCodingScheme && this.track_changes_fields.highlight_text_diffs) {
				let s = this.tracked_change_entities.current.humanCodingScheme ?? ''
				let archived = this.tracked_change_entities.archived.humanCodingScheme ?? ''
				let diff = U.diff_string(archived, s)
				return (this.in_viewer_context) ? diff.n : diff.o

			} else {
				return this.highlight_search_terms(this.item.cfitem.humanCodingScheme, 'humanCodingScheme')
			}
		},
		marked_full_statement() {
			if (this.show_tracked_changes && this.track_changes_fields.fullStatement && this.track_changes_fields.highlight_text_diffs) {
				let s, archived

				if (this.item_is_document) {
					s = this.tracked_change_entities.current.title
					archived = this.tracked_change_entities.archived.title
				} else {
					// preserve latex here; then do diff_string, then render latex below
					s = marked(U.preserve_latex(this.tracked_change_entities.current.fullStatement))
					archived = marked(U.preserve_latex(this.tracked_change_entities.archived.fullStatement))
				}

				let diff = U.diff_string(archived, s, 'preserve_tags')
				// show the new value if we're on the left side, or the old value on the right
				diff = (this.in_viewer_context) ? diff.n : diff.o

				return U.render_latex(U.preserve_latex_reverse(diff))

			} else {
				// for the document, return the doc title
				if (this.item_is_document) return this.item.cfitem.title

				let s = U.marked_latex(this.item.cfitem.fullStatement)
				return this.highlight_search_terms(s, 'fullStatement')
			}
		},

		clear_checkboxes_trigger() { return this.framework_record.clear_checkboxes_trigger },
		show_associations_count() {
			// if we're in the viewer context and the viewer's show_associations flag is off, return false
			if (this.in_viewer_context && !this.viewer.show_associations) return false
			return this.$store.state.lst.show_associations && this.association_count > 0
		},
		show_comments_count() {
			if (!this.signed_in) return false
			if (!this.in_viewer_context) return false
			return this.$store.state.lst.show_comments && this.comment_count > 0
		},

		association_count() {
			return this.framework_record.cfo.displayed_association_counts_hash[this.item.cfitem.identifier]
		},
		self_association_count() {
			if (empty(this.framework_record.cfo.displayed_associations_hash[this.item.cfitem.identifier])) return 0
			return this.framework_record.cfo.displayed_associations_hash[this.item.cfitem.identifier].length
		},
		self_comment_count() {
			if (this.comments_hash[this.framework_record.lsdoc_identifier] && this.comments_hash[this.framework_record.lsdoc_identifier][this.item.cfitem.identifier]) {
				return this.comments_hash[this.framework_record.lsdoc_identifier][this.item.cfitem.identifier].length
			}
			return 0
		},
		comment_count() {
			let get_comment_count = (node) => {
				let count = 0
				if (this.comments_hash[this.framework_record.lsdoc_identifier] && this.comments_hash[this.framework_record.lsdoc_identifier][node.cfitem.identifier]) {
					count += this.comments_hash[this.framework_record.lsdoc_identifier][node.cfitem.identifier].length
				}
				for (let i = 0; i < node.children.length; ++i) {
					count += get_comment_count(node.children[i])
				}
				return count
			}

			return get_comment_count(this.item)
		},

		has_direct_comments() {
			if (empty(this.comments_hash[this.framework_record.lsdoc_identifier])) return false
			return !empty(this.comments_hash[this.framework_record.lsdoc_identifier][this.item.cfitem.identifier])
		},
	},
	watch: {
		open_nodes: {immediate: true, handler(val) {
			// if we're not in the full viewer, we'll calculate node_is_open below
			if (!this.in_viewer_context) return

			// else use this watcher, along with node_is_rendered and node_is_open, to only show children when we need to (this greatly improves performance)
			if (this.open_nodes[this.item.tree_key]) {
				if (!this.node_is_rendered) {
					this.node_is_rendered = true
				}
				this.node_is_open = true
			} else {
				this.node_is_open = false
				this.node_is_rendered = false
			}
		}},
		item_is_last_clicked: {immediate: true, handler(val) {
			if (val) {
				// store the component for the item that has had its last_clicked_item_component value most recently changed
				// this is used in CASEFrameworkViewer -> switch_associated_item()
				vapp.last_clicked_item_component = this
			}
		}},
		clear_checkboxes_trigger() {
			// this is a hackish way to clear checkboxes when we're not in viewer mode
			if (this.clear_checkboxes_trigger == true) this.checked_value_local = false
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		item_outer_css() {
			let s
			if (this.item_is_document) {
				s = 'k-case-tree-blob-item-outer-document'
			} else {
				s = 'elevation-1'

				// if the item’s children aren’t showing, or it's a leaf, it should have max-width set
				if (!this.node_is_open || this.item_is_leaf) {
					s += ' k-case-tree-blob-item-outer-max-width'
				}

				// add the last-clicked style if appropriate
				if (this.item_is_last_clicked) {
					s += ' k-case-tree-item-last-clicked'
				}

				// apply color coding by item type, if user hasn't turned it off
				if (this.$store.state.lst.show_color_coded_item_types) {
					s += ' k-case-tree-item-type-color'
					let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == U.item_type_string(this.item.cfitem))
					if (item_type_level) {
						s += (' k-case-tree-item-type-color-' + item_type_level.level_index)
					}
				}

				if (this.basic_search_tree_key_hash[this.item.tree_key]) {
					s += ' k-case-tree-item-search-match'
				}

				if (this.search_result_highlighted_tree_key == this.item.tree_key) {
					s += ' k-case-tree-searched-jumped'
				}

				if (this.item.flashing) s += ' k-case-tree-item-flashing'
			}

			return s
		},

		item_css(hover) {
			let s = ''

			if (this.item_is_leaf) {
				s += ' k-case-tree-item-leaf'
			} else {
				s += ' k-case-tree-item-node'
			}

			return s
		},

		children_css() {
			let s = ''
			if (this.at_least_one_child_open) s += ' k-case-tree-blob-item-children-with-child-open'
			if (this.show_table_mode) s += ' k-case-tree-blob-item-children-table-mode'
			return s
		},

		child_css() {
			let s = ''
			if (this.show_table_mode) s += ' k-case-tree-blob-item-child-table-mode'
			return s
		},

		show_associations_count_css(hover) {
			let s = ''
			if (this.association_count == 0) s += ' k-case-tree-item-associations-count-zero'
			if (hover || this.node_is_active) s += ' k-case-tree-item-associations-count-hovered'
			return s
		},

		show_comments_count_css(hover) {
			let s = ''
			if (this.comment_count == 0) s += ' k-case-tree-item-comments-count-zero'
			if (hover || this.node_is_active) s += ' k-case-tree-item-comments-count-hovered'
			return s
		},

		child_is_showing(item) {
			// we always show the top-level items
			if (this.item_is_document) return true

			// otherwise we always show children of this item
			return this.open_nodes[this.item.tree_key]

			// Note: we used to do this so that by default only one "branch" of the tree would be showing at a time. That was nice in that it let you "focus" on one branch at a time. But it was confusing in some ways too, because it wasn't always clear how to get back to showing everything. And when we implemented the new "table mode", this behavior was too hard to sustain. Look back at commits prior to 11/18/2022 to see the old way this was managed.
		},

		highlight_search_terms(s) { return this.viewer.highlight_search_terms ? this.viewer.highlight_search_terms(s, this.item.tree_key) : s },

		more_info() { this.viewer.more_info(this.item) },
		print_view() { this.viewer.print_view(this.item) },

		toggle_table_mode() {
			if (!this.show_table_mode) {
				// enter table mode for this node: open this node, this node's parents, and this node's children; close everything else
				let obj = {}
				let node = this.item
				while (node.parent_node) {
					obj[node.tree_key+''] = true
					node = node.parent_node
				}
				for (let child_item of this.item.children) {
					obj[child_item.tree_key+''] = true
				}
				this.$store.commit('set', [this.framework_record, 'blob_item_table_key', this.item.tree_key])
				this.$store.commit('set', [this.framework_record, 'open_nodes', obj])

			} else {
				// else exit table mode: close all this node's items (and their descendents)
				this.$store.commit('set', [this.framework_record, 'blob_item_table_key', null])

				let close_descendents = (node) => {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', '*DELETE_FROM_STORE*'])
					for (let child_item of node.children) {
						close_descendents(child_item)
					}
				}

				for (let child_item of this.item.children) {
					close_descendents(child_item)
				}
			}
		},

		item_clicked(evt) {
			let opened, activated

			// if an editor is currently open,
			if (this.edited_node || this.editing_document) {
				// if this is the item/document being edited, just return
				if (this.edited_node == this.item || (this.editing_document && this.item_is_document)) {
					return
				}

				// else the user is currently editing a different item/document, and has clicked to another one.
				// if there aren't any changes pending in the editor, switch to editing the newly-clicked item/document
				if (this.viewer.current_editor && !this.viewer.current_editor.changes_pending) {
					if (this.item_is_document) this.viewer.edit_document()
					else this.viewer.edit_item(this.item)
				} else {
					this.$inform('Changes are pending in the item you’re currently editing. Cancel or save changes to edit another item.')
					return
				}
			}

			// if the item isn't active
			if (!this.node_is_active) {
				// we will activate it
				activated = true

				// if the item is a node and isn't already open, open it too
				if (this.item_is_node && !this.node_is_open) opened = true
			} else {
				// else the item is already active, so deactivate it
				activated = false

				// and if the item is a node, close it too
				if (this.item_is_node) opened = false
			}

			if (activated === true) {
				this.$store.commit('set', [this.framework_record, 'active_node', this.item.tree_key])
				// when we activate an item, set the starting_lsitem_identifier to the item
				this.viewer.set_starting_lsitem_identifier(this.item.cfitem.identifier, this.item.tree_key)

			} else if (activated === false) {
				// clear the active node and starting_lsitem_identifier
				this.viewer.make_node_active('', true)
			}

			if (opened === true) {
				this.open_node(evt)

			} else if (opened === false) {
				this.close_node(evt)
			}

			// no matter what we did, set last_clicked_node, to help the user remember what they last clicked
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.item.tree_key])
		},

		node_btn_clicked(evt) {
			// if the item isn't currently open, open it
			if (!this.node_is_open) {
				this.open_node(evt)

			} else {
				// else close the node, and if it was active, deactivate it
				this.close_node(evt)

				if (this.node_is_active) {
					// clear the active node and starting_lsitem_identifier
					this.viewer.make_node_active('', true)
				}
			}
		},

		open_node(evt) {
			if (!U.meta_or_alt_key(evt)) {
				this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', true])
				this.$emit('child_opened')
			} else {
				// if meta/alt key is held down, open all siblings; note that in blob view there is no blob for the doc, so everything has a parent
				for (let node of this.item.parent_node.children) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', true])
				}
			}
		},

		close_node(evt) {
			if (!U.meta_or_alt_key(evt)) {
				this.$store.commit('set', [this.open_nodes, this.item.tree_key+'', '*DELETE_FROM_STORE*'])
				this.$emit('child_closed')
			} else {
				for (let node of this.item.parent_node.children) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', '*DELETE_FROM_STORE*'])
				}
			}
		},

		// child_opened and child_closed are called (emitted) by children components when a child of this item is opened/closed
		child_opened() {
			// for now we don't have to do anything here; table mode is taking care of these kinds of things
		},
		child_closed() {
			// when a child is closed...
		},

		show_expanded_tile() {
			// when user clicks to expand, make this the last clicked item
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', this.item.tree_key])

			// show the expanded tile using the case_tree_component that's currently showing
			vapp.case_tree_component.show_expanded_item(this.item, this.framework_record)
		},
	}
}
</script>

<style lang="scss">
.k-case-tree-blob-item-children {
	display:flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	padding:0 8px;
	overflow:auto;
}

.k-case-tree-blob-item-children-table-mode {
	flex-wrap: nowrap;
}

.k-case-tree-blob-item-child-table-mode {
	flex: 1 1 0px;
	.k-case-tree-blob-item-outer-max-width {
		width: auto;
	}
}

.k-case-tree-blob-item-children-with-child-open {
	// align-items: flex-start;	// flex-start makes it so that items' heights won't fill
}

.k-case-tree-blob-item-outer {
	font-size:16px;
	line-height:22px;
	margin:2px 6px 10px 6px;
	border:1px solid #ccc;
	border-radius:10px;

	.fa-thumbtack {
		transform:rotate(30deg);
		margin-left:2px;
		margin-right:2px;
	}
}

.k-case-tree-blob-item-outer-document {
	margin:0!important;
	border:0;
}

.k-case-tree-blob-item-outer-max-width {
	width:240px;
}

.k-case-tree-blob-item {
	position:relative;
	padding:4px 8px 4px 4px;

	.k-case-tree-item-associations-count, .k-case-tree-item-comments-count {
		white-space: nowrap;
		padding-top:2px;
		padding-bottom:2px;
	}
}

.k-case-tree-blob-item-node-btn {
	margin-right:4px;
	margin-top:-1px;
	width:24px!important;
	height:24px!important;
	.fa-chevron-circle-right { font-size:18px!important; }
	.fa-dot-circle { font-size:18px!important; }
	.fa-map { font-size:16px!important; }
}

.k-case-tree-blob-item-expand-btn {
	margin-left:2px;
}

.k-case-tree-blob-item-floating-bubbles {

}

.k-case-tree-blob-item-inner {
	display:flex;
	align-items:flex-start;
	justify-content: flex-start;
	max-width:720px;
}

.k-case-tree-blob-item-code-plus-statement {
	cursor:pointer;

	.k-case-tree-item-track-changes-btn {
		margin:0px 0px 0px -2px!important;
		.fas {
			vertical-align:baseline;
			font-size:15px!important;
		}
	}
}

.k-case-tree-item-last-clicked {
	border-color:$v-grey-darken-2!important;
	border-width:2px;
}

.k-case-tree-font-size--2 .k-case-tree-blob-item-outer {
	font-size:12px; line-height:16px;
	margin:2px 5px 8px 5px;
	.k-case-tree-blob-item-inner { max-width:600px; }

	.k-case-tree-blob-item-node-btn {
		margin-right:2px;
		margin-top:-2px;
		width:20px!important;
		height:20px!important;
		.fa-chevron-circle-right { font-size:15px!important; }
		.fa-dot-circle { font-size:15px!important; }
		.fa-map { font-size:14px!important; }
	}
	.k-case-tree-blob-item-outer-max-width {
		// width:240px;
	}
}
.k-case-tree-font-size--1 .k-case-tree-blob-item-outer {
	font-size:14px; line-height:19px;
	.k-case-tree-blob-item-inner { max-width:660px; }

	.k-case-tree-blob-item-node-btn {
		margin-right:3px;
		margin-top:-2px;
		width:22px!important;
		height:22px!important;
		.fa-chevron-circle-right { font-size:16px!important; }
		.fa-dot-circle { font-size:16px!important; }
		.fa-map { font-size:15px!important; }
	}
	.k-case-tree-blob-item-outer-max-width {
		// width:240px;
	}
}

.k-case-tree-font-size-1 .k-case-tree-blob-item-outer {
	font-size:18px; line-height:24px;
	margin:2px 8px 15px 8px;
	.k-case-tree-blob-item-inner { max-width:800px; }

	.k-case-tree-blob-item-node-btn {
		margin-right:4px;
		margin-top:-2px;
		width:25px!important;
		height:25px!important;
		.fa-chevron-circle-right { font-size:19px!important; }
		.fa-dot-circle { font-size:19px!important; }
		.fa-map { font-size:17px!important; }
	}
	.k-case-tree-blob-item-outer-max-width {
		width:280px;
	}
}

.k-case-tree-font-size-2 .k-case-tree-blob-item-outer {
	font-size:20px; line-height:26px;
	margin:2px 8px 15px 8px;
	.k-case-tree-blob-item-inner { max-width:900px; }

	.k-case-tree-blob-item-node-btn {
		margin-right:4px;
		margin-top:-2px;
		width:25px!important;
		height:25px!important;
		.fa-chevron-circle-right { font-size:19px!important; }
		.fa-dot-circle { font-size:19px!important; }
		.fa-map { font-size:17px!important; }
	}
	.k-case-tree-blob-item-outer-max-width {
		width:320px;
	}
}
</style>
