<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper k-editor-wrapper-wide k-resource-alignment-maker" :class="top_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon small style="transform:rotate(45deg)" class="mr-2">fas fa-compress-alt</v-icon> Align Resources</div>
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-tallest" style="font-size:14px">
		<div class="k-case-ie-line mb-5 mt-2">
			<v-btn x-small fab color="#ccc" class="elevation-2" @click="go_to_resource('prev')"><v-icon small>fas fa-arrow-left</v-icon></v-btn>
			<v-btn x-small class="ml-1 elevation-2" fab color="#ccc" @click="go_to_resource('next')"><v-icon small>fas fa-arrow-right</v-icon></v-btn>
			<v-btn class="ml-4" small color="#ccc" @click="show_table"><v-icon small class="mr-2">fas fa-table</v-icon>Table View</v-btn>
			<v-spacer/>
			<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" x-small class="elevation-2" fab color="#ccc"><v-icon small>fas fa-ellipsis-v</v-icon></v-btn></template>
				<v-list dense>
					<v-list-item @click="create_new_resource"><v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Add resource</v-list-item-title></v-list-item>
					<v-list-item @click="start_resource_import"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Import Resources</v-list-item-title></v-list-item>
					<v-list-item @click=""><v-list-item-icon><v-icon small>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Batch Align…</v-list-item-title></v-list-item>
					<v-list-item @click="export_alignments"><v-list-item-icon><v-icon small>fas fa-file-export</v-icon></v-list-item-icon><v-list-item-title>Export Alignments</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</div>

		<div class="k-resource-alignment-current-resource mb-2 elevation-3" :class="current_resource_aligned_to_current_framework?'k-resource-alignment-current-resource-currently-aligned':''">
			<div v-if="current_resource">
				<div v-if="editing_current_resource">
					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Resource ID:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.external_resource_id" placeholder="" autocomplete="new-password" clearable></v-text-field>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Resource URL:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.url" placeholder="" autocomplete="new-password" clearable></v-text-field>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Resource&nbsp; Title:</div>
						<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.title" placeholder="" autocomplete="new-password" clearable></v-text-field>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Resource&nbsp; Description:</div>
						<v-textarea background-color="#fff" outlined dense hide-details v-model="resource_for_editing.description" placeholder="" rows="2" auto-grow clearable></v-textarea>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Alignment keywords:</div>
						<v-textarea background-color="#fff" outlined dense hide-details v-model="resource_for_editing.text" placeholder="" rows="2" auto-grow autocomplete="new-password" clearable></v-textarea>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label">Education&nbsp; level:</div>
						<div class="d-flex" style="width:200px">
							<div style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_low" :items="grades" label="Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
							<div class="ml-1" style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_high" :items="grades" label="High" outlined dense hide-details :menu-props="{top:true}"></v-select></div>
						</div>
						<v-spacer/>
						<v-btn small color="secondary" class="mr-2" @click="edit_resource_cancel">Cancel</v-btn>
						<v-btn small color="primary" :disabled="!edited_resource_changed" @click="edit_resource_save">Save Changes</v-btn>
					</div>
				</div>

				<div v-if="!editing_current_resource" class="k-resource-alignment-maker-resource-fields">
					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label pl-6">Resource ID:</div>
						<div class="k-case-ie-line-value" style="padding-top:2px;" v-html="current_resource.external_resource_id"></div>
						<v-spacer/>
						<v-btn style="margin-top:-2px" small text color="#666" @click="edit_resource_start"><v-icon small class="mr-1">fas fa-edit</v-icon>Edit Resource</v-btn>
					</div>

					<div class="k-case-ie-line" v-if="current_resource.title">
						<div class="k-case-ie-line-label d-flex align-center">
							<v-checkbox v-visible="false" class="shrink mt-0 pt-0 float-left" hide-details></v-checkbox>
							<div>URL:</div>
						</div>
						<div class="k-case-ie-line-value" style="white-space:nowrap; overflow:hidden;"><a :href="current_resource.url" target="_blank" v-html="current_resource.url"></a></div>
					</div>

					<div class="k-case-ie-line" v-if="current_resource.title">
						<div class="k-case-ie-line-label d-flex align-center">
							<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.title" @change="comp_factors_changed"></v-checkbox>
							<div>Title:</div>
						</div>
						<div class="k-case-ie-line-value" v-html="current_resource.title"></div>
					</div>

					<div class="k-case-ie-line" v-if="current_resource.description">
						<div class="k-case-ie-line-label d-flex align-center">
							<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.description" @change="comp_factors_changed"></v-checkbox>
							<div>Description:</div>
						</div>
						<div class="k-case-ie-line-value" v-html="current_resource.description"></div>
					</div>

					<!-- We always show the extra text editor  -->
					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label d-flex align-center">
							<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.text" @change="comp_factors_changed"></v-checkbox>
							<div>Alignment keywords:</div>
						</div>
						<v-textarea background-color="#fff" outlined dense hide-details v-model="current_resource_text" placeholder="" rows="1" autocomplete="new-password" auto-grow clearable></v-textarea>
						<!-- <div class="k-case-ie-line-value" v-html="current_resource.text"></div> -->
					</div>

					<div class="k-case-ie-line" v-if="grade_low">
						<div class="k-case-ie-line-label d-flex align-center">
							<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.education_level" @change="comp_factors_changed"></v-checkbox>
							<div>Ed. level:</div>
						</div>
						<div class="k-case-ie-line-value">{{grade_low.text}}<span v-if="grade_low!=grade_high">–{{grade_high.text}}</span></div>
					</div>

					<div class="k-case-ie-line" v-if="current_resource_existing_alignments_display">
						<div class="k-case-ie-line-label d-flex align-center pt-2">
							<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.existing_alignments" @change="comp_factors_changed"></v-checkbox>
							<div>Current Alignments:</div>
						</div>
						<div class="k-case-ie-line-value" v-html="current_resource_existing_alignments_display"></div>
					</div>

					<div class="k-case-ie-line">
						<div class="k-case-ie-line-label" style="width:auto; padding-top:13px;">Limit alignments to:</div>
						<div class="k-case-ie-line-value">
							<v-btn v-if="!lowest_ancestor&&mode!='choosing_lowest_ancestor'" x-small color="#ccc" @click="toggle_choose_lowest_ancestor">Choose…</v-btn>
							<v-btn v-if="!lowest_ancestor&&mode=='choosing_lowest_ancestor'" x-small color="#ccc" @click="toggle_choose_lowest_ancestor">Cancel</v-btn>
							<span v-if="lowest_ancestor" v-html="lowest_ancestor_display"></span>
							<v-btn v-if="lowest_ancestor" fab x-small color="#ccc" class="elevation-2 ml-2" @click="toggle_choose_lowest_ancestor"><v-icon x-small>fas fa-exchange-alt</v-icon></v-btn>
							<v-btn v-if="lowest_ancestor" fab x-small color="#ccc" class="elevation-2" @click="cancel_lowest_ancestor"><v-icon x-small>fas fa-times</v-icon></v-btn>
						</div>
					</div>
				</div>
			</div>

			<div class="k-resource-alignment-maker-msg mt-4 mb-0 px-2 py-1" :class="flashing?'k-resource-alignment-maker-msg-flashing':''">
				<div class="k-resource-alignment-instructions mt-1 mb-1">
					<div v-if="!mode">Enter or import resource metadata to align resources to standards.</div>
					<div v-if="mode=='choosing_lowest_ancestor'">In the tree at left, choose the “lowest ancestor” of the standards you want to consider for alignments.</div>
					<div v-if="mode=='choosing_alignments'">Choose a standard on the left to create {{current_resource_aligned_to_current_framework?'another':'an'}} alignment.</div>
				</div>

				<div v-if="new_suggestions_enabled" class="d-flex mb-2">
					<v-btn small color="secondary" @click="make_suggestions">Make Suggestions…</v-btn>
					<v-spacer/>
					<v-checkbox class="mt-0 pt-0 ml-2" v-model="leafs_only" hide-details><template v-slot:label><span style="font-size:14px; line-height:17px;">Suggest alignments to standards/elements only</span></template></v-checkbox>
				</div>

				<div v-if="current_resource_assistant_suggestions.length>0" style="border-top:1px solid #999;" class="mt-3 mb-2">
					<div class="my-1"><b>Top suggestions:</b> (click to reveal in tree)</div>
					<div v-for="(sug, index) in current_resource_assistant_suggestions" class="k-resource-alignment-suggestion" @click="reveal_suggestion(sug.node)">
						<v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on" class="k-resource-alignment-suggestion-sim-score"><v-icon x-small v-if="sug.node.cat" small color="green">fas fa-check</v-icon>{{sug.sim_score_final}}</span></template><div v-html="sug.comp_score_tooltip"></div></v-tooltip>
						<span class="k-resource-alignment-suggestion-text" v-html="suggestion_html(sug.node)"></span>
					</div>
					<div v-if="more_suggestions_available" class="text-center mt-3"><v-btn x-small color="secondary" @click="choose_assistant_suggestions">Show More Suggestions</v-btn></div>
				</div>
				<div v-if="current_resource_assistant_suggestions.length==0&&assistant_suggestions[current_resource_id]" style="border-top:1px solid #999;" class="mt-3 mb-2 pt-2"><i>No data available to make suggestions about this resource. Try entering alignment keywords, then click “MAKE SUGGESTIONS” again.</i></div>
			</div>
		</div>
	</div>
	<AlignResourcesTable v-if="table_showing" :framework_record="framework_record" :resources="resources" @go_to_resource="table_go_to_resource" @dialog_cancel="table_showing=false" />
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AlignResourcesImportMixin from './AlignResourcesImportMixin.js'
import AlignResourcesSuggestionsMixin from './AlignResourcesSuggestionsMixin.js'
import AlignResourcesTable from './AlignResourcesTable'

export default {
	components: { AlignResourcesTable },
	mixins: [AlignResourcesImportMixin, AlignResourcesSuggestionsMixin],
	props: {
		framework_record: { required: true },
		viewer: { required: false, default() { return ''} },
	},
	data() { return {
		highlighted_identifier_override: '',
		mode: '',	// empty, choosing_alignments, choosing_lowest_ancestor

		resources: [],
		resource_for_editing: {},
		editing_current_resource: false,
		current_resource_existing_alignments_display_updater: 0,
		save_current_resource_text_debounced: null,
		// previous_current_resource_id: -1,

		delete_confirmed: false,

		flashing: false,
		table_showing: false,
	}},
	computed: {
		...mapState(['framework_records', 'association_type_labels', 'grades', 'user_info']),
		...mapGetters([]),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		top_css() {
			return U.framework_color(this.framework_identifier) + '-editor'
		},
		leafs_only: {
			get() { return this.$store.state.lst.align_leafs_only },
			set(val) { this.$store.commit('lst_set', ['align_leafs_only', val]) }
		},
		current_resource_id: {
			// store the current_resource_id for each framework in localstorage
			get() {
				let s = this.$store.state.lst.align_current_resource_id
				if (s) {
					let o = JSON.parse(s)
					return (o[this.framework_identifier]) ? o[this.framework_identifier]*1 : 0
				} else return 0
			},
			set(val) {
				let o = {}
				let s = this.$store.state.lst.align_current_resource_id
				if (s) o = JSON.parse(s)
				o[this.framework_identifier] = val
				this.$store.commit('lst_set', ['align_current_resource_id', JSON.stringify(o)])
			},
		},
		current_resource() {
			// show/reset current alignments in the tree, if the current_resource_id actually changed
			// (the resource object may instead have been updated, e.g. because a new alignment was created)
			this.$nextTick(x=>{
				this.update_current_alignments_in_tree()
				// this.previous_current_resource_id = this.current_resource_id
			})

			if (this.resources.length == 0 || this.current_resource_id == 0) return null
			return this.resources.find(x=>x.resource_id == this.current_resource_id)
		},
		current_resource_text: {
			get() { return this.current_resource ? this.current_resource.text : '' },
			set(val) {
				this.current_resource.text = val

				// establish the debounce fn if necessary
				if (empty(this.save_current_resource_text_debounced)) {
					this.save_current_resource_text_debounced = U.debounce(() => {
						this.edit_resource_save(true)

						// if the user has entered some text, set the checkbox to true so it'll be used in the suggester
						if (this.current_resource.text) {
							this.comp_factors.text = true
							this.comp_factors_changed()
						}
					}, 1000)
				}
				// call the debounce fn
				this.save_current_resource_text_debounced()
			}
		},
		current_resource_alignments() {
			let arr = []
			for (let a of this.current_resource.alignments) {
				// don't include alignments with 'placeholder' item_identifiers; those just mark the fact that a particular user wants to align a resource to a framework
				if (a.item_identifier != 'placeholder') {
					arr.push(a)
				}
			}
			return arr
		},
		current_resource_aligned_framework_identifiers() {
			let arr = []
			for (let a of this.current_resource_alignments) {
				if (!arr.find(x=>x == a.framework_identifier)) {
					// found a new framework.
					// SIDE EFFECT: if we haven't already fetched this framework's cfo, do so now
					let framework = this.framework_records.find(x=>x.lsdoc_identifier == a.framework_identifier)
					if (!framework.cfo) {
						this.load_framework(a.framework_identifier)
					}
					arr.push(a.framework_identifier)
				}
			}
			return arr
		},
		current_resource_aligned_to_current_framework() {
			if (!this.current_resource) return false
			return (this.current_resource_aligned_framework_identifiers.findIndex(x=>x==this.framework_identifier) > -1)
		},
		current_resource_existing_alignments_display() {
			// SIDE EFFECT: create current_resource_alignments
			if (this.current_resource_existing_alignments_display_updater < 0) return	// allows us to force update

			let html = ''
			// for each framework that has alignments...
			for (let framework_identifier of this.current_resource_aligned_framework_identifiers) {
				let framework = this.framework_records.find(x=>x.lsdoc_identifier == framework_identifier)

				let framework_title = (framework) ? framework.json.CFDocument.title : framework_identifier
				html += sr('<div class="k-case-tree-item-association-framework $1">', U.framework_color(framework_identifier) + '-border')
				html += sr('<div class="k-case-tree-item-association-framework-title $1"><i style="transform:rotate(45deg)" class="fas fa-compress-alt k-case-tree-item-association-icon"></i>$2</div>', U.framework_color(framework_identifier) + '-dark', framework_title)

				// now find this framework's alignments; if the framework_identifier is in current_resource_aligned_framework_identifiers, there must be at least one
				for (let a of this.current_resource_alignments) {
					if (a.framework_identifier == framework_identifier) {
						let item
						if (framework && framework.cfo) {
							// get item, if we've constructed the framework's cfo
							item = framework.cfo.cfitems[a.item_identifier]
						}

						if (!item) {
							item = a.item_identifier
						} else {
							item = U.generate_cfassociation_node_uri_title(item, true)
						}

						html += sr('<div class="k-case-tree-item-association $1">', U.framework_color(framework_identifier) + '-border')
						// if this is an alignment to the current framework, show it as a link to open the standard on the left side
						if (framework_identifier == this.framework_identifier) {
							html += sr('<a href="javascript:vapp.align_resources_editor.current_alignment_clicked(\'$1\')">$2</a>', a.item_identifier, item)
						} else {
							html += item
						}
						html += '</div>'
					}
				}

				html += '</div>'
			}

			return html
		},
		current_resource_assistant_suggestions() {
			if (this.current_resource_id == 0 || !this.assistant_suggestions[this.current_resource_id]) return []
			return this.assistant_suggestions[this.current_resource_id]
		},
		lowest_ancestor: {
			// store the tree_key of the lowest_ancestor for each framework in localstorage
			get() {
				let s = this.$store.state.lst.align_lowest_ancestor
				if (s) {
					let o = JSON.parse(s)
					if (!o[this.framework_identifier]) return null
					return this.framework_record.cfo.tree_nodes_hash[o[this.framework_identifier]]
				} else return null
			},
			set(val) {
				// val will be a node; go from that to the node's tree_key
				let tree_key = val.tree_key
				let o = {}
				let s = this.$store.state.lst.align_lowest_ancestor
				if (s) o = JSON.parse(s)
				o[this.framework_identifier] = tree_key
				this.$store.commit('lst_set', ['align_lowest_ancestor', JSON.stringify(o)])
			},
		},
		lowest_ancestor_display() {
			return U.generate_cfassociation_node_uri_title(this.lowest_ancestor.cfitem)
		},
		edited_resource_changed() {
			return JSON.stringify(this.current_resource) != JSON.stringify(this.resource_for_editing)
		},
		grade_low: {
			get() {
				let resource = (this.editing_current_resource) ? this.resource_for_editing : this.current_resource
				if (!resource || resource.educationLevel.length == 0) return ''
				let el = resource.educationLevel[0]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new lower-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current upper-limit education level
				let el_high = this.resource_for_editing.educationLevel[this.resource_for_editing.educationLevel.length - 1]
				let el_high_index = this.grades.findIndex(g => { return (g.value == el_high || ( !isNaN(el_high*1) && (g.value*1 == el_high*1) )) })

				// if el_high_index is < new_el_index,
				if (el_high_index < new_el_index) {
					// if 9th grade was chosen, default to 12, because most HS courses are 9-12
					if (new_el == '09') el_high_index = this.grades.findIndex(g=>g.value=='12')
					// else just include new_el_index
					else el_high_index = new_el_index
				}

				// include all grades between new_el and el_high
				let arr = []
				for (let i = new_el_index; i <= el_high_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.resource_for_editing.educationLevel = arr
			}
		},
		grade_high: {
			get() {
				let resource = (this.editing_current_resource) ? this.resource_for_editing : this.current_resource
				if (!resource || resource.educationLevel.length == 0) return ''
				let el = resource.educationLevel[resource.educationLevel.length-1]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new upper-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current lower-limit education level
				let el_low = this.resource_for_editing.educationLevel[0]
				let el_low_index = this.grades.findIndex(g => { return (g.value == el_low || ( !isNaN(el_low*1) && (g.value*1 == el_low*1) )) })

				// if el_low_index is > new_el_index, just include new_el_index
				if (el_low_index > new_el_index) el_low_index = new_el_index

				// include all grades between new_el and el_high
				let arr = []
				for (let i = el_low_index; i <= new_el_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.resource_for_editing.educationLevel = arr
			}
		},
		new_suggestions_enabled() {
			if (!this.mode || this.mode == 'choosing_lowest_ancestor') return false

			// TODO: enable the "Make Suggestions" btn iff anything has changed since the suggestion process was last initiated
			return true
		},
	},
	watch: {
		// mode() {
		// 	if (this.mode == 'choosing_alignments') {
		// 	// when choosing_alignments gets set to true, set show_chooser_fn for the left tree; this shows the icons receiving the checks -- and vice-versa
		// 	this.toggle_left_chooser_fn(this.choosing_alignments)
		//
		// 	// flash instructions and clear_left_selection when this value changes
		// 	this.$nextTick(x=>this.flash_assistant_instructions())
		// 	this.clear_left_selection()
		// },
	},
	created() {
		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		this.viewer.current_editor = this

		// this is needed to enable the current alignments to be clicked
		vapp.align_resources_editor = this

		this.initialize()
	},
	mounted() {
	},
	methods: {
		cancel_edit() {
			// clear the chosen_node from the edited framework
			this.clear_left_selection()

			// clear all sim_scores, and cat values
			// this.clear_sim_scores()
			this.clear_cats()

			// clear the featured_node from framework_record
			this.$store.commit('set', [this.framework_record, 'featured_node', ''])

			// clear the show_chooser_fn for the main (left) tree; this hides the btns receiving the checks
			this.viewer.set_show_chooser_fn(false)

			// clear this.viewer.current_editor
			this.viewer.current_editor = null

			this.$emit('dialog_cancel')
		},

		initialize() {
			this.clear_left_selection()

			// get stored resources for this framework
			U.loading_start()
			let payload = {
				service_url: 'get_resources_for_framework',
				user_id: this.user_info.user_id,
				framework_identifier: this.framework_identifier,
			}
			this.$store.dispatch('service', payload).then((result)=>{
				U.loading_stop()

				this.resources = []
				for (let r of result.resources) {
					this.resources.push(new Resource(r))
				}

				if (this.resources.length > 0) {
					// if we have a stored resource_id for the last one being considered for this framework, it'll be computed above; otherwise set to the first resource
					if (this.current_resource_id == 0) this.current_resource_id = this.resources[0].resource_id

					this.mode = 'choosing_alignments'

					// if the user previously set a "limit alignments to" value, mark it now
					this.mark_lowest_ancestor_in_left_tree()

					// show current alignments in the tree
					this.$nextTick(x=>this.update_current_alignments_in_tree())
				}

			}).catch((result)=>{
				U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
			}).finally(()=>{})
		},

		load_framework(lsdoc_identifier) {
			U.loading_start('Loading previously-aligned framework…')
			this.$store.dispatch('get_lsdoc', lsdoc_identifier).then(()=>{
				U.loading_stop()

				// then build the cfo for the framework
				let fr = this.framework_records.find(x=>x.lsdoc_identifier==lsdoc_identifier)
				U.build_cfo(this.$worker, fr.json).then((cfo)=>{
					this.$store.commit('set', [fr, 'cfo', cfo])

					// force update of existing alignments display
					++this.current_resource_existing_alignments_display_updater

					U.loading_stop()
				})
				.catch((e)=>{
					U.loading_stop()
					console.log(e)
				})

			}).catch((e)=>{
				console.log(e)
				U.loading_stop()
				this.$alert('An error occurred when loading the competency framework.').then(x=>this.hide_tree())
			})
		},

		go_to_resource(resource_id) {
			if (resource_id == 'next' || resource_id == 'prev') {
				let i = this.resources.findIndex(x=>x.resource_id == this.current_resource_id)
				i += (resource_id == 'next') ? 1 : -1
				if (i >= this.resources.length) i = 0
				else if (i < 0) i = this.resources.length - 1
				this.current_resource_id = this.resources[i].resource_id
			} else {
				this.current_resource_id = resource_id
			}
		},

		create_new_resource() {
			// actual resource_id's start at 1000, so we'll know that resource_id 1 needs to be saved
			let r = new Resource({resource_id: 1})
			this.resources.push(r)
			this.current_resource_id = 1
			this.$nextTick(x=>this.edit_resource_start())
		},

		edit_resource_start() {
			this.resource_for_editing = $.extend(true, {}, this.current_resource)
			this.editing_current_resource = true
		},

		edit_resource_cancel() {
			// if this was a new resource, kill it from resources
			if (this.resource_for_editing.resource_id == 1) {
				this.go_to_resource('prev')
				this.resources.pop()
			}
			this.resource_for_editing = {}
			this.editing_current_resource = false
		},

		edit_resource_save(auto_save) {
			let resource_to_save
			if (auto_save === true) {
				// if we're autosaving, we're just editing the current resource's text, so create a special object with just this data to send to the server
				resource_to_save = {
					resource_id: this.current_resource_id,
					external_resource_id: this.current_resource.external_resource_id,
					text: this.current_resource.text
				}

			} else {
				// else show the loading indicator, and clear alignments from resource_for_editing before save
				U.loading_start()
				delete(this.resource_for_editing.alignments)
				resource_to_save = this.resource_for_editing
			}

			let payload = {
				service_url: 'save_resources',
				// we stringify the resources in case it's a lot of data
				resources: JSON.stringify([resource_to_save]),
				user_id: this.user_info.user_id,
				framework_identifier: this.framework_identifier,
			}
			this.$store.dispatch('service', payload).then((result)=>{
				if (auto_save !== true) {
					U.loading_stop()

					//////////////////////////////////
					// add/replace the resource to the list of resources being considered for alignment here
					for (let resource of result.resources) {
						resource = new Resource(resource)
						let i = this.resources.findIndex(x=>x.resource_id == resource.resource_id)
						if (i == -1) this.resources.push(resource)
						else this.resources.splice(i, 1, resource)

						// if we just created and saved a new resource, its resource_id will have changed from 1 to the actual value, so set current_resource_id
						if (this.current_resource_id == 1) this.current_resource_id = resource.resource_id
					}

					// cancel resource editing
					this.edit_resource_cancel()
				}
				// if we're auto-saving the extra text, the computed fn will have already updated the resource's text field

			}).catch((result)=>{
				if (auto_save !== true) U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
			}).finally(()=>{})
		},

		toggle_left_chooser_fn(val) {
			if (val) {
				this.viewer.set_show_chooser_fn((component, chosen_node, $event)=>{ this.item_chosen_on_left(chosen_node, $event) })
			} else {
				this.viewer.set_show_chooser_fn(false)
			}
		},

		// show current alignments in the tree
		update_current_alignments_in_tree() {
			// if (this.current_resource_id != this.previous_current_resource_id) {
			// 	this.clear_sim_scores()
			// }
			this.clear_cats()

			if (empty(this.current_resource)) return

			// for each current alignment...
			for (let a of this.current_resource_alignments) {
				// if the alignment is to this framework
				if (a.framework_identifier == this.framework_identifier) {
					// mark this alignment's item's node(s) as cat = aligned
					// get the item
					let item = this.framework_record.cfo.cfitems[a.item_identifier]

					// mark all the item's tree_nodes
					for (let node of item.tree_nodes) {
						this.$store.commit('set', [node, 'cat', 'aligned'])	// cat = current association/alignment type
					}
				}
			}
		},

		// called when user clicks on a current alignment to an item in the current framework
		current_alignment_clicked(item_identifier) {
			// to do this, we do the same thing as revealing a suggested alignment
			let item = this.framework_record.cfo.cfitems[item_identifier]

			// call reveal_suggestion for the first of the item's tree_nodes (if there are multiple)
			// TODO: if there are multiple nodes for this item, we should really be doing this only for the node that's under the current lowest_ancestor
			if (item.tree_nodes.length > 0) {
				this.reveal_suggestion(item.tree_nodes[0])
			}
		},

		clear_left_selection() {
			this.$store.commit('set', [this.framework_record, 'chosen_node', ''])
		},

		item_chosen_on_left(node, $event) {
			if (this.mode == 'choosing_lowest_ancestor') {
				// set lowest_ancestor, then flash the node
				this.lowest_ancestor = node
				this.mark_lowest_ancestor_in_left_tree()

			} else {
				// if alignment already exists, clear it
				let a = this.current_resource_alignments.find(x=>x.framework_identifier == this.framework_identifier && x.item_identifier == node.cfitem.identifier)
				if (a) {
					// ask for confirmation first, the first time we do a delete
					if (!this.delete_confirmed) {
						this.$confirm({
						    title: 'Are you sure?',
						    text: 'Are you sure you want to remove this alignment?',
						    acceptText: 'Remove Alignment',
							acceptColor: 'red',
						}).then(y => {
							this.delete_confirmed = true
							this.item_chosen_on_left(node, $event)
						}).catch(n=>{console.log(n)}).finally(f=>{})
						return
					}

					this.clear_alignment(a.resource_alignment_id)

				} else {
					// else make an alignment for the current resource
					this.make_alignment(node)
				}
			}

			// make this the active node
			this.$store.commit('set', [this.framework_record, 'active_node', node.tree_key+''])
		},

		mark_lowest_ancestor_in_left_tree() {
			if (!this.lowest_ancestor) return

			this.flash_node(this.lowest_ancestor, true)

			// hide all siblings of lowest_ancestor, and all siblings of lowest_ancestor's parents
			this.$store.commit('set', [this.framework_record, 'featured_node', this.lowest_ancestor.tree_key])
			let open_nodes = {}
			let parent_node = this.lowest_ancestor
			while (parent_node) {
				open_nodes[parent_node.tree_key+''] = true
				parent_node = parent_node.parent_node
			}
			this.$store.commit('set', [this.framework_record, 'open_nodes', open_nodes])
			this.clear_left_selection()

			// then set mode to choosing_alignments
			this.mode = 'choosing_alignments'
			this.toggle_left_chooser_fn(true)
		},

		toggle_choose_lowest_ancestor() {
			if (this.mode == 'choosing_lowest_ancestor') {
				// set mode to choosing_alignments
				this.mode = 'choosing_alignments'
			} else {
				this.mode = 'choosing_lowest_ancestor'
			}
			this.toggle_left_chooser_fn(true)
		},

		cancel_lowest_ancestor() {
			this.$store.commit('set', [this.framework_record, 'featured_node', ''])
			this.lowest_ancestor = ''
		},

		make_alignment(node) {
			// we shouldn't be here if current_resource isn't set, but check just in case
			if (!this.current_resource) {
				console.log('make_alignment called but no current_resource set', node)
				return
			}

			// prepare resource data in same form used to import resources; note that we don't have to send any info except resource_id, and note alignments form
			let rdata = {
				resource_id: this.current_resource.resource_id,
				alignments: [{
					fi: this.framework_identifier,
					ii: node.cfitem.identifier
				}]
			}

			let payload = {
				service_url: 'save_resources',
				resources: JSON.stringify([rdata]),
				user_id: this.user_info.user_id,
			}
			U.loading_start()
			this.$store.dispatch('service', payload).then((result)=>{
				U.loading_stop()

				//////////////////////////////////
				// add/replace the resource to the list of resources being considered for alignment here
				for (let resource of result.resources) {
					resource = new Resource(resource)
					let i = this.resources.findIndex(x=>x.resource_id == resource.resource_id)
					if (i == -1) this.resources.push(resource)
					else this.resources.splice(i, 1, resource)
				}

			}).catch((result)=>{
				U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
			}).finally(()=>{})
		},

		clear_alignment(resource_alignment_id) {
			// prepare resource data to clear alignment
			let rdata = {
				resource_id: this.current_resource.resource_id,
				alignments: [{
					clear: resource_alignment_id
				}]
			}

			let payload = {
				service_url: 'save_resources',
				resources: JSON.stringify([rdata]),
				user_id: this.user_info.user_id,
			}
			U.loading_start()
			this.$store.dispatch('service', payload).then((result)=>{
				U.loading_stop()

				//////////////////////////////////
				// add/replace the resource to the list of resources being considered for alignment here
				for (let resource of result.resources) {
					resource = new Resource(resource)
					let i = this.resources.findIndex(x=>x.resource_id == resource.resource_id)
					if (i == -1) this.resources.push(resource)
					else this.resources.splice(i, 1, resource)
				}

			}).catch((result)=>{
				U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
			}).finally(()=>{})
		},

		flash_node(node, multiple) {
			this.$store.commit('set', [node, 'flashing', true])
			setTimeout(x=>this.$store.commit('set', [node, 'flashing', false]), 300)
			if (multiple) {
				setTimeout(x=>this.$store.commit('set', [node, 'flashing', true]), 600)
				setTimeout(x=>this.$store.commit('set', [node, 'flashing', false]), 900)
				setTimeout(x=>this.$store.commit('set', [node, 'flashing', true]), 1200)
				setTimeout(x=>this.$store.commit('set', [node, 'flashing', false]), 1500)
			}
		},

		flash_assistant_instructions() {
			$('.k-resource-alignment-maker-msg').addClass('k-resource-alignment-maker-msg-flashing')

			setTimeout(x=>{
				$('.k-resource-alignment-maker-msg').removeClass('k-resource-alignment-maker-msg-flashing')
			}, 1000)
		},

		show_table() {
			this.table_showing = true
		},

		table_go_to_resource(resource_id) {
			this.table_showing = false
			this.go_to_resource(resource_id)
		},

		export_alignments() {
			// TODO: show an interface to let the user choose:
			//   - which fields to export
			//   - whether or not to export rows for resources that don't have alignments
			//   - whether to export one row per alignment, or one row per resource with comma-separated URIs
			let arr = []

			arr.push([
				'Resource ID',
				'CASE URI(s)',
			])

			for (let r of this.resources) {
				if (r.alignments.length > 0) {
					let alignments = ''
					for (let a of r.alignments) {
						if (a.framework_identifier == this.framework_identifier) {
							let item = this.framework_record.cfo.cfitems[a.item_identifier]
							if (!empty(item)) {
								if (alignments) alignments += ','
								alignments += item.uri
							}
						}
					}
					if (alignments) {
						arr.push([r.external_resource_id, alignments])
					}
				}
			}

			console.log(arr)

			if (arr.length == 1) {
				this.$alert('No resource alignments to export!')
				return
			}

			let filename = sr('resource-alignments-$1.csv', this.framework_identifier)
			U.download_file(CSV.stringify(arr), filename)
		},
	}
}
</script>

<style lang="scss">
.k-resource-alignment-maker {
	.k-case-ie-line-label {
		width:110px;
		// align-self: flex-start;
		text-align:right;
		padding-right:8px;
	}

	.k-resource-alignment-maker-resource-fields {
		.k-case-ie-line {
			margin-bottom:2px;
			align-items: flex-start;
		}

		.k-case-ie-line-label {
			width:120px;
			padding-top:5px;
			text-align:left;
			flex-shrink:0;
		}

		.k-case-ie-line-value {
			padding-top:6px;
		}
	}
}

.k-resource-alignment-current-resource {
	border-radius:12px;
	padding:8px;
	border:2px solid #ccc;
	.k-case-tree-item-association-framework {
		background-color:#fff;
	}

	.k-case-tree-item-association-framework-title {
		font-size:12px;
	}

	.k-case-tree-item-association {
		font-size:12px;
		line-height:15px;
		cursor:default;
		a {
			color:#333;
			text-decoration: none;
		}
		a:hover {
			text-decoration:underline;
		}
	}

	.k-case-tree-item-association-icon {
		font-size:14px!important;
	}
}

.k-resource-alignment-current-resource-currently-aligned {
	background-color:$v-green-lighten-5;
	border-color:$v-green;
}

.k-resource-alignment-maker-msg {
	// background-color:#ddd;
	background-color:$v-amber-lighten-4;
	border-radius:8px;
	text-align:left;
	transition: background-color 0.5s;
}

.k-resource-alignment-maker-msg-flashing {
	background-color:$v-amber-accent-2;
}

.k-resource-alignment-instructions {
	border-radius:6px;
	padding:4px;
	transition: background-color 0.5s;
}

.k-resource-alignment-suggestion {
	white-space:nowrap;
	width:100%;
	overflow:hidden;
	cursor:pointer;
	margin-bottom:2px;
	font-size:13px;

	.k-resource-alignment-suggestion-sim-score {
		width:40px;
		display:inline-block;
		text-align:center;
		// font-size:13px;
		font-weight:bold;
		background-color:#444;
		color:#fff;
		border-radius:3px;
		text-decoration: none!important;
		margin-right:4px;

		.v-icon {
			font-size:11px!important;
			margin-right:2px;
			// margin-left:2px;
			margin-top:-3px;
		}
	}
}

.k-resource-alignment-suggestion-text:hover {
	text-decoration: underline;
}
</style>
