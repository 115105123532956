// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		default_max_suggestions: 5,
		suggestion_threshold: 0,

		// denominators for calculating overall sim scores
		comp_factor_value: {
			item: 250,
			item_lcs: 100,
			parent: 50,

			exact_match_assocs: 600,
			related_to_assocs: 300,

			// the final score will include either exact_match_assocs or related_to_assocs, but not both; then may include some % of the points for item, item_lcs, and parent
			// the denominator will always be
		},

		sim_score_arr: {},
		assistant_suggestions: {},
	}},
	computed: {
		...mapState(['association_type_labels']),
		...mapGetters([]),
		comp_factors: {
			get() {
				let s = this.$store.state.lst.align_comp_factors
				if (s) return JSON.parse(s)
				else return {
					education_level: true,
					existing_alignments: true,

					title: true,
					description: true,
					text: true,

					item: true,
					parent: true,

					item_to_title: true,
					item_to_description: true,
					item_to_text: true,
					item_lcs: true,

					parent_to_title: true,
					parent_to_description: true,
					parent_to_text: true,

					exact_match_assocs: true,
					related_to_assocs: true,
				}
			},
			set(val) {
				this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
			},
		},
		more_suggestions_available() {
			return (this.sim_score_arr[this.current_resource_id] > this.assistant_suggestions[this.current_resource_id])
		},
	},
	watch: {
	},
	methods: {
		comp_factors_changed() {
			console.log(this.comp_factors)
			this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
		},

		// user clicked to start the assistant
		make_suggestions() {
			this.clear_left_selection()

			U.loading_start()

			let comps = {}

			if (this.comp_factors.title && this.current_resource.title) {
				if (this.comp_factors.item) comps.item_to_title = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.title), comp_nodes: [], comp_strings: [], sim_scores: [] }
				if (this.comp_factors.parent) comps.parent_to_title = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.title), comp_nodes: [], comp_strings: [], sim_scores: [] }
			}

			if (this.comp_factors.description && this.current_resource.description) {
				if (this.comp_factors.item) comps.item_to_description = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.description), comp_nodes: [], comp_strings: [], sim_scores: [] }
				if (this.comp_factors.parent) comps.parent_to_description = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.description), comp_nodes: [], comp_strings: [], sim_scores: [] }
			}

			if (this.comp_factors.text && this.current_resource.text) {
				if (this.comp_factors.item) comps.item_to_text = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.text), comp_nodes: [], comp_strings: [], sim_scores: [] }
				if (this.comp_factors.parent) comps.parent_to_text = { model_string: U.normalize_string_for_sparkl_bot(this.current_resource.text), comp_nodes: [], comp_strings: [], sim_scores: [] }
			}

			// reset sim_score_arr for this resource
			this.$set(this.sim_score_arr, this.current_resource_id, [])
			let sim_score_arr = this.sim_score_arr[this.current_resource_id]

			// get comp strings from lowest_ancestor and children
			let top_node = (this.lowest_ancestor) ? this.lowest_ancestor : this.framework_record.cfo.cftree
			let got_comp = this.get_sim_score_comps(top_node, comps)

			console.log(comps, sim_score_arr)

			// send to server to get sparkl-bot comparisons
			if (got_comp) {
				let comps_for_sb = {}
				for (let key in comps) {
					comps_for_sb[key] = {
						model_string: comps[key].model_string,
						comp_strings: comps[key].comp_strings
					}
				}
				let payload = {
					service_url: 'sparkl_bot',
					comps: JSON.stringify(comps_for_sb),	// stringify to avoid limits on number of parameters we can pass
				}
				this.$store.dispatch('service', payload).then((result)=>{
					// we should receive back the in result a sim_scores object, with one array for each each comps[type] -- e.g. sim_scores.item_to_item = [844, 123, 432, ...]
					// put these in place in comps
					for (let type in result.sim_scores) {
						comps[type].sim_scores = result.sim_scores[type]
					}
					this.finish_assistant(comps)

				}).catch((result)=>{
					U.loading_stop()
					console.log(result)
					this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
				}).finally(()=>{})
			} else {
				// if we're not actually computing any sparkl-bot sim scores, just send things through to finish_assistant
				this.finish_assistant(comps)
			}
		},

		is_leaf(cfitem) {
			let type = U.item_type_string(cfitem).toLowerCase()
			return (type.indexOf('standard') > -1 || type == 'element')
		},

		get_sim_score_comps(node, comps) {
			let sim_score_arr = this.sim_score_arr[this.current_resource_id]
			let got_comp = false

			// skip items that aren't a standards or elements if leafs_only is true
			if (!this.leafs_only || this.is_leaf(node.cfitem)) {
				// always initialize sim_score_arr
				let o = {
					node: node,
					tree_key: node.tree_key,
					factors: {},
					sim_score_num: 0,
					sim_score_den: 0,
					sim_score_final: 0,
				}

				// if we're limiting by educationLevel, don't process this guy if the educationLevels don't match. start by assuming they do match; if resource doesn't have educationLevel specified, we go ahead and process other things
				let education_level_match = true
				if (this.comp_factors.education_level && this.current_resource.educationLevel && this.current_resource.educationLevel.length > 0) {
					// resource has educationLevel(s); see if node has educationLevel(s) too
					education_level_match = (node.cfitem.educationLevel && node.cfitem.educationLevel.length > 0)
					if (education_level_match) {
						// if so, temporarily set to false, then set back to true if/when we find an educationLevel that matches
						education_level_match = false
						for (let ell of this.current_resource.educationLevel) {
							ell = (ell+'').toLowerCase()
							if (node.cfitem.educationLevel.findIndex(elr=>(elr+'').toLowerCase() == ell) > -1) {
								education_level_match = true
								break
							}
						}
					}
					// note that we do add a sim_score_arr object even if the ed levels don't match, so that we explicitly mark the item as not being processed because of educationLevel
				}

				// record in sim_score_arr.factors
				o.factors.education_level = education_level_match

				// if (!education_level_match) console.log('Education levels don’t match: ' + node.cfitem.fullStatement)

				sim_score_arr.push(o)

				if (education_level_match) {
					// item
					if (node.cfitem.fullStatement) {
						let fs = U.normalize_string_for_sparkl_bot(node.cfitem.fullStatement)
						if (comps.item_to_title) {
							comps.item_to_title.comp_nodes.push(node.tree_key)
							comps.item_to_title.comp_strings.push(fs)
							got_comp = true
						}
						if (comps.item_to_description) {
							comps.item_to_description.comp_nodes.push(node.tree_key)
							comps.item_to_description.comp_strings.push(fs)
							got_comp = true
						}
						if (comps.item_to_text) {
							comps.item_to_text.comp_nodes.push(node.tree_key)
							comps.item_to_text.comp_strings.push(fs)
							got_comp = true
						}
					}

					// parent, if there is one
					if (node.parent_node && node.parent_node.cfitem.fullStatement) {
						let fs = U.normalize_string_for_sparkl_bot(node.parent_node.cfitem.fullStatement)
						if (comps.parent_to_title) {
							comps.parent_to_title.comp_nodes.push(node.tree_key)
							comps.parent_to_title.comp_strings.push(fs)
							got_comp = true
						}
						if (comps.parent_to_description) {
							comps.parent_to_description.comp_nodes.push(node.tree_key)
							comps.parent_to_description.comp_strings.push(fs)
							got_comp = true
						}
						if (comps.parent_to_text) {
							comps.parent_to_text.comp_nodes.push(node.tree_key)
							comps.parent_to_text.comp_strings.push(fs)
							got_comp = true
						}
					}
				}
			}

			for (let child of node.children) {
				got_comp = this.get_sim_score_comps(child, comps) || got_comp
			}

			return got_comp
		},

		finish_assistant(comps) {
			let sim_score_arr = this.sim_score_arr[this.current_resource_id]

			//////////////////////////////////
			// PROCESS SIM SCORES
			// go through each item we're checking
			for (let o of sim_score_arr) {
				let node = o.node

				// if we aren't checking this item because of educationLevel, short-circuit the rest of the assistant processing below
				if (o.factors.education_level === false) {
					continue
				}

				// look for previous alignments to items associated with this node's item
				// if this node has associations, and this resource has alignments...
				let assocs = this.framework_record.cfo.associations_hash[node.cfitem.identifier]
				if (assocs && assocs.length > 0 && this.current_resource_alignments.length > 0) {
					// go through each alignment
					for (let a of this.current_resource_alignments) {
						// don't consider alignments to the current framework here; suggestions are meant to find *new* alignments to the current framework
						if (a.framework_identifier == this.framework_identifier) continue

						// if this aligned item is associated with this item...
						let ca = assocs.find(x=>x.destinationNodeURI.identifier == a.item_identifier || x.originNodeURI.identifier == a.item_identifier)
						if (ca) {
							// mark it!
							// get associated item so we can show it in the suggestion hover. First get the uri, for the “side” of the association that *isn't* the standard we're currently considering for alignment
							let uri = (ca.destinationNodeURI.identifier == a.item_identifier) ? ca.destinationNodeURI : ca.originNodeURI

							// if the association was made in SparkleSALT, we will have saved the framework identifier at the end of the uri title; extract it
							let assoc_framework_identifier = ''
							let title = uri.title
							if (title.search(/(.*)\s*\(:(\S+):\)/) > -1) {
								title = RegExp.$1
								assoc_framework_identifier = RegExp.$2
							}

							if (!o.factors.assocs) o.factors.assocs = []

							// get denominator for this associationType, and add to sim_score_num and sim_score_den
							// with the limitation that we only want to add up to this.comp_factor_value.exact_match_assocs for all assocs.
							let den = 0
							if (ca.associationType == 'exactMatchOf' || ca.associationType == 'replacedBy') {
								// if this is an exact match, then...
								if (!o.factors.assocs.find(x=>x.type == 'exactMatchOf' || x.type == 'replacedBy')) {
									// if we haven't already found another exact match (we shouldnt, but check just in case), we'll add something...
									// if we already found *another* association...
									if (!o.factors.assocs.find(x=>x.type != 'exactMatchOf' && x.type != 'replacedBy')) {
										// then add exact_match_assocs
										den = this.comp_factor_value.exact_match_assocs
									} else {
										// else add the difference between exact_match_assocs and related_to_assocs, so that we come up to the exact_match_assocs value
										den = this.comp_factor_value.exact_match_assocs - this.comp_factor_value.related_to_assocs
									}
								}
							} else {
								// else it's not an exact match, so add related_to_assocs iff this is the first association we've found
								if (o.factors.assocs.length == 0) {
									den = this.comp_factor_value.related_to_assocs
								}
							}
							o.sim_score_num += den
							o.sim_score_den += den

							// add to o.factors.assocs_points for display purposes
							if (empty(o.factors.assocs_points)) o.factors.assocs_points = 0
							o.factors.assocs_points += den

							// add to factors.assocs after determining the value to add above
							o.factors.assocs.push({
								item_identifier: uri.identifier,
								title: title,
								assoc_framework_identifier: assoc_framework_identifier,
								type: ca.associationType
							})
						}
					}
				}

				// TODO: alignment to this node's parent

				// go through all comps we got back from sparkl-bot
				for (let type in comps) {
					// if this item was checked for this comp,
					let i = comps[type].comp_nodes.findIndex(x=>x==o.tree_key)
					if (i > -1) o.factors[type] = U.normalize_sim_score(comps[type].sim_scores[i])
				}

				// combine sim_scores together for item and parent comparisons
				// for now we'll just use the highest sim_score if we have multiples
				if (this.comp_factors.item) {
					let sim_score = 0
					if (o.factors.item_to_title > sim_score) sim_score = o.factors.item_to_title
					if (o.factors.item_to_description > sim_score) sim_score = o.factors.item_to_description
					if (o.factors.item_to_text > sim_score) sim_score = o.factors.item_to_text

					o.factors.item = Math.round(sim_score / 1000 * this.comp_factor_value.item)

					// add sim_score to numerator
					o.sim_score_num += o.factors.item

					// always add to denominator (even though we don't currently use this value)
					o.sim_score_den += this.comp_factor_value.item
				}

				if (this.comp_factors.parent) {
					let sim_score = 0
					if (o.factors.parent_to_title > sim_score) sim_score = o.factors.parent_to_title
					if (o.factors.parent_to_description > sim_score) sim_score = o.factors.parent_to_description
					if (o.factors.parent_to_text > sim_score) sim_score = o.factors.parent_to_text

					o.factors.parent = Math.round(sim_score / 1000 * this.comp_factor_value.parent)

					// add sim_score to numerator
					o.sim_score_num += o.factors.parent

					// always add to denominator (even though we don't currently use this value)
					o.sim_score_den += this.comp_factor_value.parent
				}

				// item LCS: do this if we're also including title, description, or text
				if (this.comp_factors.item_lcs && (this.comp_factors.title || this.comp_factors.description || this.comp_factors.text)) {
					// add to den, then if we have a right statement...
					o.sim_score_den += this.comp_factor_value.item_lcs
					o.factors.item_lcs = 0

					if (node.cfitem.fullStatement) {
						// get statement
						let statement = U.normalize_string_for_sparkl_bot(node.cfitem.fullStatement)
						let lcs_val = 0

						// calculate lcs_val for the strings we're including; lcs_val will be a decimal (0.0 - 1.0)
						if (this.comp_factors.title) {
							let title = U.normalize_string_for_sparkl_bot(this.current_resource.title)
							let lcs_title = U.get_lcs_val(title, statement)
							if (lcs_title > lcs_val) lcs_val = lcs_title
						}

						if (this.comp_factors.description) {
							let description = U.normalize_string_for_sparkl_bot(this.current_resource.description)
							let lcs_description = U.get_lcs_val(description, statement)
							if (lcs_description > lcs_val) lcs_val = lcs_description
						}

						if (this.comp_factors.text) {
							let text = U.normalize_string_for_sparkl_bot(this.current_resource.text)
							let lcs_text = U.get_lcs_val(text, statement)
							if (lcs_text > lcs_val) lcs_val = lcs_text
						}

						o.factors.item_lcs = Math.round(lcs_val * this.comp_factor_value.item_lcs)
						o.sim_score_num += o.factors.item_lcs
					}
				}

				// calculate final sim_score; here we ignore sim_score_den and just go out of 1000, so we don't have to divide by anything (because the weights add to 1000)
				o.sim_score_final = Math.round(o.sim_score_num)

				// also set sim_score_html -- shown as the tooltip when the user hovers over the sim_score indicator
				o.comp_score_tooltip = this.comp_score_tooltip(comps, o, node.cat)
			}
			// DONE PROCESSING SIM SCORES
			////////////////////////////////////////////

			// sort scores, with highest first
			sim_score_arr.sort((a,b)=>b.sim_score_final-a.sim_score_final)

			// set/reset assistant_suggestions array for this resource, then choose the assistant_suggestions to show
			this.$set(this.assistant_suggestions, this.current_resource_id, [])
			this.choose_assistant_suggestions()

			// // show the first suggestion in the tree
			// console.log('reveal_suggestion')
			// this.reveal_suggestion(sim_score_arr[0].node)

			// note that sim_scores are displayed in CASEItem.vue
			U.loading_stop()
		},

		choose_assistant_suggestions() {
			let sim_score_arr = this.sim_score_arr[this.current_resource_id]
			let assistant_suggestions = this.assistant_suggestions[this.current_resource_id]

			// if we're currently showing some suggestions, we want to show more; otherwise start with default_max_suggestions
			let max_suggestions
			if (assistant_suggestions.length < this.default_max_suggestions) max_suggestions = this.default_max_suggestions
			else max_suggestions = assistant_suggestions.length + 10

			// now reset assistant_suggestions and choose/re-choose them
			// we store the sim_score_arr objects in assistant_suggestions; from there we can get to nodes, as well as the sim_score and other data
			assistant_suggestions = []

			for (let i = 0; i < sim_score_arr.length; ++i) {
				let ssa = sim_score_arr[i]
				let sim_score = ssa.sim_score_final

				// never suggest sim_scores of 0; if we get to a 0, break, because since we're sorted descending, nothing else is going to be > 0
				if (sim_score == 0) break

				// else first item (highest sim score), or other items with the same sim score as index 0, are always suggested
				if (i == 0 || sim_score == sim_score_arr[0].sim_score_final) {
					assistant_suggestions.push(ssa)
					continue
				}

				// if we have enough suggestions, break
				if (assistant_suggestions.length >= max_suggestions) break

				// if we get to here, add this item as a suggestion if it's above the suggestion_threshold
				if (sim_score >= this.suggestion_threshold) {
					assistant_suggestions.push(ssa)
					continue
				}

				// if we get to here, there won't be any others above the threshold, so break
				break
			}

			// // mark suggestions as highlighted
			// for (let node of assistant_suggestions) {
			// 	this.$store.commit('set', [node, 'comp_score_highlighted', true])
			// }

			// get assistant_suggestions into the responsive data var
			this.assistant_suggestions[this.current_resource_id] = assistant_suggestions
		},

		suggestion_html(node) {
			return U.generate_cfassociation_node_uri_title(node.cfitem, true)
		},

		comp_score_tooltip(comps, o, cat) {
			let html = ''

			if (cat) {
				html += sr('<b>RESOURCE IS ALREADY ALIGNED WITH THIS ITEM</b><br>')
			}

			if (o.factors.education_level === false) {
				html += 'Education levels do not match'
				return html
			}

			html += '<ul class="mb-1">'

			if (o.factors.assocs) {
				html += sr('<li style="max-width:500px">$1 / $2 ($3%): Previous $4 to associated $5<ul>', o.factors.assocs_points, this.comp_factor_value.exact_match_assocs, Math.round(o.factors.assocs_points / this.comp_factor_value.exact_match_assocs * 100), U.ps('alignment', o.factors.assocs.length), U.ps('item', o.factors.assocs.length))
				for (let a of o.factors.assocs) {
					html += sr('<li><b>$1</b> $2</li>', this.association_type_labels[a.type], a.title)
				}
				html += '</ul></li>'
			}

			// uncomment below for debugging
			// for (let type in comps) {
			// 	html += sr('<li>$1: $2</li>', o.factors[type], type)
			// }

			if (!empty(o.factors.item)) {
				// html += sr('<li>$3%: Item title/description/text overlap</li>', o.factors.item, this.comp_factor_value.item, Math.round(o.factors.item / this.comp_factor_value.item * 100))
				html += sr('<li>$1 / $2 ($3%): Item title/description/text overlap</li>', o.factors.item, this.comp_factor_value.item, Math.round(o.factors.item / this.comp_factor_value.item * 100))
			}

			if (!empty(o.factors.item_lcs)) {
				// html += sr('<li>$3%: Longest common substring for item and title/description/text</li>', o.factors.item_lcs, this.comp_factor_value.item_lcs, Math.round(o.factors.item_lcs / this.comp_factor_value.item_lcs * 100))
				html += sr('<li>$1 / $2 ($3%): Longest common substring for item and title/description/text</li>', o.factors.item_lcs, this.comp_factor_value.item_lcs, Math.round(o.factors.item_lcs / this.comp_factor_value.item_lcs * 100))
			}

			if (!empty(o.factors.parent)) {
				// html += sr('<li>$3%: Parent title/description/text overlap</li>', o.factors.parent, this.comp_factor_value.parent, Math.round(o.factors.parent / this.comp_factor_value.parent * 100))
				html += sr('<li>$1 / $2 ($3%): Parent title/description/text overlap</li>', o.factors.parent, this.comp_factor_value.parent, Math.round(o.factors.parent / this.comp_factor_value.parent * 100))
			}

			html += '</ul>'
			html += sr('Overall Match Score: <b>$1 / 1000</b>', o.sim_score_final)

			return html
		},

		// reveal a suggested alignment's standard in the left tree
		reveal_suggestion(node) {
			// make sure the node's ancestors are all open
			let arr = []
			let parent_node = node.parent_node
			while (!empty(parent_node)) {
				arr[parent_node.tree_key+''] = true
				// this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}
			this.$store.commit('set', [this.framework_record, 'open_nodes', arr])

			// and make it active
			this.$store.commit('set', [this.framework_record, 'active_node', node.tree_key])
		},

		// clear_sim_scores(node) {
		// 	if (empty(node)) {
		// 		if (!empty(this.lowest_ancestor)) node = this.lowest_ancestor
		// 		else node = this.framework_record.cftree
		// 	}
		// 	if (empty(node)) return
		//
		// 	this.$store.commit('set', [node, 'sim_score', -1])
		// 	this.$store.commit('set', [node, 'comp_score_tooltip', -1])
		// 	this.$store.commit('set', [node, 'comp_score_highlighted', false])
		// 	for (let child of node.children) {
		// 		this.clear_sim_scores(child)
		// 	}
		// },

		clear_cats(node) {
			if (empty(node)) {
				if (!empty(this.lowest_ancestor)) node = this.lowest_ancestor
				else node = this.framework_record.cftree
			}
			if (empty(node)) return

			this.$store.commit('set', [node, 'cat', ''])	// current association type
			for (let child of node.children) {
				this.clear_cats(child)
			}
		},
	}
}
