<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Export items in Satchel text format</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div v-if="confirming">
					<p>This will export the data selected below for the current item and/or the current item’s descendents, using a Satchel-specific text format. You can then edit the content (if you wish), then import the items into this or another framework.</p>
					<div class="d-flex mt-3">
						<div><b>Data to export:</b></div>
						<div class="ml-2">
							<v-checkbox class="mt-0 pt-0" :label="'Full Statement'" v-model="fields.fullStatement" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Human-Readable Code'" v-model="fields.humanCodingScheme" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Item Type'" v-model="fields.itemType" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Education Level'" v-model="fields.educationLevel" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Notes'" v-model="fields.notes" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Supplemental Notes'" v-model="fields.supplementalNotes" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'Identifier'" v-model="fields.identifier" hide-details></v-checkbox>
						</div>
						<div class="ml-8"><b>Items to export:</b></div>
						<div class="ml-2">
							<v-checkbox class="mt-0 pt-0" :label="'This item'" v-model="export_this_item" hide-details></v-checkbox>
							<v-checkbox class="mt-1 pt-0" :label="'This item’s descendents'" v-model="export_descendents" hide-details></v-checkbox>

							<v-checkbox class="mt-4 pt-0" :label="'Export items only (ignore item hierarchy)'" v-model="no_hierarchy" hide-details></v-checkbox>
						</div>
						<v-spacer />
					</div>
					<div class="mt-1"><i><b>Note:</b> only export identifiers if you know what you’re doing! <span v-if="fields.identifier">When importing an item including an identifier, if the item already exists in the receiving framework, the item’s fields will be updated, but you will not see the updates reflected until you reload the framework in your browser.</span></i></div>

				</div>
				<div v-else>
					<p>Here you go:</p>
					<div class="d-flex">
						<v-textarea ref="text_input" outlined hide-details label="" v-model="export_text" rows="25" class="k-satchel-export-textarea"></v-textarea>
						<CopyBtn size="large" :fab="true" color="primary" :val="export_text" class="ml-3"/>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn v-if="confirming" color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
				<v-btn v-if="!confirming" color="secondary" @click="confirming=true"><v-icon small class="mr-2">fas fa-arrow-left</v-icon>Back</v-btn>
				<v-spacer></v-spacer>
				<v-btn v-if="confirming" color="primary" @click="do_it">Do It!<v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
				<v-btn v-if="!confirming" color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<!-- add to includer component, along with import line and components item -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CopyBtn from '../utilities/CopyBtn'

export default {
	components: { CopyBtn },
	props: {
		item: { type: Object, required: true },
		framework_record: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		confirming: true,
		export_text: '',
		export_this_item: true,
		export_descendents: true,
		no_hierarchy: false,
		fields: {
			fullStatement: true,
			humanCodingScheme: true,
			educationLevel: true,
			itemType: true,
			notes: true,
			supplementalNotes: true,
			identifier: false,
		},

	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		do_it() {
			if (!this.export_this_item && !this.export_descendents) {
				this.$alert('You must choose at least one of the “Items to export” options.')
				return
			}

			let item_type_mapping = {
				'grade': 'G',
				'cluster': 'U',
				'pathway': 'P',
				'course': 'C',
				'domain': 'D',
				'standard': 'S',
				'standard': 'S',
				'element': 'E',
			}

			let processed_identifiers = {}
			let get_export_text = (node, level, process_children) => {
				let text = ''

				// don't export anything for the document
				if (node.cfitem.title) {
					level -= 1

				// if we're not processing hierarchy and we've already processed this item, skip it
				} else if (this.no_hierarchy && processed_identifiers[node.cfitem.identifier]) {

				// else process the item...
				} else {
					processed_identifiers[node.cfitem.identifier] = true
					
					// item type
					let item_type_abbrev = '-'
					if (this.fields.itemType) {
						let item_type = U.item_type_string(node.cfitem)
						if (!empty(item_type)) {
							item_type_abbrev = item_type_mapping[item_type.toLowerCase()]
							if (!item_type_abbrev) {
								// for other item types, replace spaces with _
								item_type_abbrev = item_type.replace(/ /g, '_')
							}
						}
					}

					// asterisks to represent levels (unless no_hierarchy is on)
					let stars = '*'
					if (!this.no_hierarchy) for (let i = 1; i < level; ++i) stars += '*'

					// education level
					let education_level = ''
					if (this.fields.educationLevel) {
						education_level = U.grade_level_display(node.cfitem.educationLevel)
						if (education_level) education_level = sr(' [$1]', education_level)
					}

					// identifier
					let identifier = ''
					if (this.fields.identifier) {
						identifier = sr(' {$1}', node.cfitem.identifier)
					}

					// human coding scheme -- have to replace spaces with _'s
					let hcs = ''
					if (this.fields.humanCodingScheme && node.cfitem.humanCodingScheme) hcs = ' :' + node.cfitem.humanCodingScheme.replace(/ /g, '_')

					text = sr('$1 $2$3$4$5 $6\n', item_type_abbrev, stars, education_level, identifier, hcs, node.cfitem.fullStatement)

					// TODO: have a way to export education level and possibly other things; could do [3] or [9-12]

					// export notes and supplementalNotes if requested and there
					if (this.fields.notes && node.cfitem.notes) {
						text += 'NOTES:\n' + node.cfitem.notes + '\n'
					}

					if (this.fields.supplementalNotes) {
						let supplementalNotes = node.cfitem.extensions.supplementalNotes
						if (supplementalNotes) {
							text += 'EX-IMPORT:\n' + supplementalNotes + '\n'
						}
					}

					// add an extra newline for clarity
					text += '\n'
				}

				// now do children, if told to do so
				if (process_children) {
					for (let child of node.children) {
						text += get_export_text(child, level + 1, process_children)
					}
				}

				return text

				// NOTE that if the same item appears multiple times in the tree, it will copied each time
			}

			// if we're exporting the top-level item...
			if (this.export_this_item) {
				// pass this_export_descendents as the process_children paramter
				this.export_text = get_export_text(this.item, 1, this.export_descendents)
			} else {
				// else process children
				this.export_text = ''
				for (let child of this.item.children) {
					this.export_text += get_export_text(child, 1, true)
				}
			}
			this.confirming = false
		}
	}
}
</script>

<style lang="scss">
.k-satchel-export-textarea textarea {
	font-family:monospace;
	font-size:14px;
	line-height:18px!important;
}
</style>
