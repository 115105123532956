<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-menu bottom left v-model="menu_showing" nudge-right="-22">
		<template v-slot:activator="{on}">
			<v-btn v-if="btn_size=='small'" v-on="on" icon :color="color" :style="bstyle" text><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-btn>
			<v-btn v-if="btn_size=='large'" v-on="on" :color="color" class="k-tight-btn" :style="bstyle"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon>Select a {{framework_noun}}…</v-btn>
		</template>
		<v-list dense class="framework_switcher_menu">
			<v-list-item style="border-bottom:1px solid #ccc; margin-top:-8px; margin-bottom:6px;"><v-list-item-icon><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-list-item-icon><v-list-item-title>
				{{(btn_size=='small')?'Switch to framework…':'Select a ' + framework_noun + '…'}}
				<!-- hidden btn to clear the stored framework identifier -->
				<span style="padding-right:24px; margin-left:12px; height:24px; background-color:transparent; cursor:pointer;" @click.stop="clear_stored_identifier"></span>
			</v-list-item-title></v-list-item>

			<v-menu v-for="(cr, index) of category_records" :key="index" :transition="false" offset-x right :nudge-top="($vuetify.breakpoint.width<700)?-36:0" :nudge-left="($vuetify.breakpoint.width<700)?0:12" style="display: block;">
				<template v-slot:activator="{on}"><v-hover v-slot:default="{hover}"><v-list-item v-on="on" style="cursor:pointer;" :style="hover?'background-color:#eee':''">
					<v-list-item-icon><v-icon small>fas fa-layer-group</v-icon></v-list-item-icon><v-list-item-title :style="cr==selected_category?'font-weight:bold; font-style:italic;':''" v-html="cr.title"></v-list-item-title>
					<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
				</v-list-item></v-hover></template>

				<v-list dense style="max-height:calc(100vh - 30px); overflow:auto;">
					<v-list-item v-for="(fr) of cr.framework_records" :key="fr.lsdoc_identifier" @click="view_framework(fr.lsdoc_identifier,$event)"><v-list-item-icon><v-icon small>fas fa-map</v-icon></v-list-item-icon><v-list-item-title style="overflow:visible; white-space:normal" :style="fr==selected_framework?'font-weight:bold; font-style:italic;':''" v-html="fr.json.CFDocument.title"></v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		btn_size: { type: String, required: true },	// small or large
		color: { type: String, required: false, default() { return ''} },
		bstyle: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		menu_showing: false,
		category_records: [],
		selected_category: null,
	}},
	computed: {
		...mapState(['framework_records', 'framework_categories', 'embedded_mode']),
		...mapGetters([]),
		framework_noun() { return this.$store.state.site_config.framework_noun },
		embedded_framework_identifier() { return this.$store.state.lst.embedded_framework_identifier },
		case_tree_component() { return vapp.case_tree_component },
		current_selected_framework_identifier() {
			if (this.embedded_mode) {
				return this.embedded_framework_identifier
			} else {
				return vapp.case_tree_component?.lsdoc_identifier
			}
		},
		selected_framework() { 
			return this.framework_records.find(x=>x.lsdoc_identifier == this.current_selected_framework_identifier) 
		},
	},
	watch: {
		embedded_framework_identifier() {
			// when framework switches, re-create category_records so that the menus will update to reflect the current framework
			this.create_category_records()
		},
		case_tree_component() {
			// ditto when case_tree_component changes (we might end up running create_category_records twice, but that's OK)
			this.create_category_records()
		}
	},
	created() {
	},
	mounted() {
		// note that this component should not be included anywhere until the document list has been loaded (get_lsdoc_list, which is called immediately upon initializing)
		this.create_category_records()
	},
	methods: {
		create_category_records() {
			// this fn is in case_utilities, because we also need it for FrameworkList
			this.category_records = U.create_category_records(false)

			// if a framework is already selected,
			if (this.current_selected_framework_identifier) {
				// look for that framework in the category_records
				let cat_index = this.category_records.findIndex(x=>x.framework_records.find(y=>y.lsdoc_identifier == this.current_selected_framework_identifier))
				// if found, store it in this.selected_category and bump that category to the top of the list
				if (cat_index) {
					this.selected_category = this.category_records.splice(cat_index, 1)[0]
					this.category_records.unshift(this.selected_category)

					// also bump the selected framework to the top of the category's framework list
					let fr_index = this.selected_category.framework_records.findIndex(x=>x.lsdoc_identifier == this.current_selected_framework_identifier)
					let fr = this.selected_category.framework_records.splice(fr_index, 1)[0]
					this.selected_category.framework_records.unshift(fr)
				}
			}
		},

		view_framework(selected_framework_identifier, $event) {
			// if this framework is already showing, just cancel the selection
			if (vapp.case_tree_component?.lsdoc_identifier == selected_framework_identifier) {
				this.$emit('framework_selected')
			} else {
				// changing, so set store.lst value if we're in embedded mode
				if (this.embedded_mode) this.$store.commit('lst_set', ['embedded_framework_identifier', selected_framework_identifier])

				// if a different framework was loaded previously, we have to first remove the CASEFrameworkViewer component, then re-open it a tick later, to force the new framework to load
				if (vapp.case_tree_component?.hide_tree) {
					vapp.case_tree_component.hide_tree()
					setTimeout(()=>{
						vapp.framework_list_component.view_framework([selected_framework_identifier, null, $event])
						this.$emit('framework_selected')
					}, 10)

				} else {
					// else we can just tell framework_list_component to load the framework now
					vapp.framework_list_component.view_framework([selected_framework_identifier, null, $event])
					this.$emit('framework_selected')
				}
			}
		},

		clear_stored_identifier() {
			this.$store.commit('lst_set', ['embedded_framework_identifier', ''])
			this.category = ''
		},
	}
}
</script>

<style lang="scss">
.framework_switcher_menu {
	max-height:calc(100vh - 30px); 
	// width:400px;
	overflow:auto; 
	padding-bottom:0;

	.v-list-item {
		min-height:auto!important;
	}
}

</style>
