<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-dialog v-model="dialog_open" persistent max-width="1000px">
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">{{framework_record.json.CFDocument.title}}</v-card-title>
			<v-card-text style="color:#000;">
				<div v-html="mirror_details"></div>
				<div v-if="user_can_edit"><v-checkbox class="mr-4" :label="'Perform automatic daily update checks from the mirror source'" v-model="mirror_auto_updates" hide-details></v-checkbox></div>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4 pb-4">
				<v-btn v-if="user_can_edit" color="primary" class="k-tight-btn" dark @click="mirror_update_by_user(framework_record)"><v-icon small class="mr-2">fas fa-rotate</v-icon>Check now for Updates</v-btn>
				<v-btn v-if="user_can_edit" color="secondary" small class="ml-3 k-tight-btn" dark @click="update_source_url(framework_record)"><v-icon small class="mr-2">fas fa-link</v-icon>Update Mirror Source URL</v-btn>
				<v-btn v-if="user_info.system_role=='admin'" color="secondary" small class="ml-3 k-tight-btn" dark @click="convert_mirror(framework_record)"><v-icon small class="mr-2">fas fa-link-slash</v-icon>Unlink Framework from Source</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="cancel_clicked" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MirrorMixin from '../../js/MirrorMixin'

export default {
	props: {
		lsdoc_identifier: { type: String, required: true },
	},
	mixins: [MirrorMixin],
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters(['signed_in']),
		framework_record() {
			let o = this.framework_records.find(x=>x.lsdoc_identifier == this.lsdoc_identifier)
			if (empty(o)) return {}
			else return o
		},
		mirror_details() { return this.get_mirror_details() },
		mirror_auto_updates: {
			get() { return (this.framework_record.ss_framework_data.mirror_auto_updates === 'on') },
			set(val) {
				this.set_mirror_auto_updates((val)?'on':'off')
			}
		},
		user_can_edit() {
			if (!this.signed_in) return false
			return vapp.is_granted('edit_framework', this.lsdoc_identifier)
		},
	},
	created() {
		// this.system_role = this.user.system_role
	},
	mounted() {
		// DEBUG
		vapp.mirror_info_component = this
	},
	methods: {
		set_mirror_auto_updates(mirror_auto_updates) {
			let payload = {
				framework_identifier: this.framework_record.lsdoc_identifier,
				mirror_auto_updates: mirror_auto_updates
			}

			U.loading_start()
			U.ajax('save_mirror_settings', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert(result.status)
					return
				}
				console.log(result)

				// we will get back framework_data; update next_auto_update (as well as mirror_auto_updates)
				this.$store.commit('set', [this.framework_record, ['ss_framework_data', 'mirror_auto_updates'], result.framework_data.mirror_auto_updates])
				this.$store.commit('set', [this.framework_record, ['ss_framework_data', 'next_auto_update'], result.framework_data.next_auto_update])

				if (mirror_auto_updates === 'on') {
					this.$alert('Auto-updates have been enabled. The source framework will be checked daily and the mirror will be updated if there are changes in the framework.')
				} else {
					this.$alert('Daily auto-updates have been disabled.')
				}
			})
		},

		update_source_url(framework_record) {
			// Prompt for value
			this.$prompt({
				title: 'Update Mirror Source URL',
				text: 'Enter the new mirror source URL for this framework:',
				initialValue: framework_record.ss_framework_data.mirror_source_rest_api_url,
				acceptText: 'Update',
				hideCancel: false,	// set to true to hide the cancel button
				dialogMaxWidth: 900,
			}).then(mirror_source_rest_api_url => {
				this.update_source_url_finish(framework_record, mirror_source_rest_api_url, false)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		update_source_url_finish(framework_record, mirror_source_rest_api_url, force) {
			mirror_source_rest_api_url = $.trim(mirror_source_rest_api_url)
			if (empty(mirror_source_rest_api_url)) return

			let address_parts = mirror_source_rest_api_url.match(/^(https?:\/\/.+)\/(ims\/case\/v\w+\/cfpackages).*([0-9a-fA-F-]{36})\/?$/i)
			// 'https://www.cpalms.org/Public/ims/case/v1p0/CFPackages/21a98ac7-85fd-42d2-9756-ef5ce6a71de3'.match(/^(https?:\/\/[\w\.:-]+)\/(ims\/case\/v\w+\/cfpackages).*([0-9a-fA-F-]{36})\/?$/i)
			if (address_parts === null || address_parts.length < 2) {
				let format_hint = '<p>Examples:</p>'
					+ '<ul><li>https://case.georgiastandards.org/ims/case/v1p0/CFPackages/27a08dc6-416e-11e7-ba71-02bd89fdd987</li>'
					+ '<li>https://casenetwork.imsglobal.org/ims/case/v1p0/CFPackages/97c883b4-8590-454f-b222-f28298ec9a81</li></ul>'
				this.$alert({title: 'Please enter a valid CASE REST API URL.', text: format_hint, dialogMaxWidth:1000})
				return
			}

			let mirror_url_guid = address_parts[3].toLowerCase()
			// make sure the identifier in the entered API URL matches the framework identifier; if not ask the user to confirm
			if (mirror_url_guid != framework_record.lsdoc_identifier && force !== true) {
				this.$confirm({
					title: 'Are you sure?',
					text: `The identifier (GUID) in the REST API URL you entered<br><nobr style="font-family:courier">(${mirror_url_guid})</nobr> does not match the framework’s identifier<br><nobr style="font-family:courier">(${framework_record.lsdoc_identifier}).</nobr><br><br>Are you sure you want to update the Mirror URL to this value?`,
					acceptText: 'Update Mirror URL',
					acceptColor: 'red',
					dialogMaxWidth: 800,
				}).then(y => {
					this.update_source_url_finish(framework_record, mirror_source_rest_api_url, true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let payload = {
				framework_identifier: framework_record.lsdoc_identifier,
				mirror_source_rest_api_url: mirror_source_rest_api_url
			}

			U.loading_start()
			U.ajax('save_mirror_settings', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					if (result.status == 'curl_error') this.$alert('The REST API URL you entered was not resolvable, so the change was not saved.')
					if (result.status == 'json_error') this.$alert('The REST API URL you entered did not resolve to valid JSON, so the change was not saved.')
					return
				}
				this.$store.commit('set', [framework_record, ['ss_framework_data', 'mirror_source_rest_api_url'], mirror_source_rest_api_url])
				this.$alert('Update complete. Click “CHECK NOW FOR UPDATES” to confirm that the new REST API URL is working properly.')
			})
		},

		get_mirror_details() {
			let last_sync = this.framework_record.ss_framework_data.last_mirror_sync_date
			let status_ok = this.framework_record.ss_framework_data.last_mirror_sync_status.includes('OK')
			let status_color = (status_ok) ? 'style="color:green"' : 'style="color:red"'
			let updates_active = (this.mirror_auto_updates) ? 'Daily update checks <b>enabled</b>' : 'Daily update checks <b>disabled</b>'

			// 'Last Updated' is last successful update, errors logged in framework_archives
			let md = "<p></p>"
				+ '<table class="k-mirror-info-table">'
				+ '<tr><td><b>Framework identifier:</b></td><td> ' + this.framework_record.lsdoc_identifier + '</td></tr>'
				+ '<tr><td><b>Mirror source:</b></td><td style="font-size:12px"> ' + this.framework_record.ss_framework_data.mirror_source_rest_api_url + '</td></tr>'
				+ '<tr><td><b>Last changed:</b></td><td> ' + U.local_last_change_date_time(this.framework_record.json.CFDocument.lastChangeDateTime) + '</td></tr>'
				+ '<tr><td class="pr-3"><b>Mirror update checks:</b></td><td> ' + updates_active + '</td></tr>'
				+ sr('<tr><td><b>Update status:</b></td><td $1><b>$2</b></td></tr>', status_color, this.framework_record.ss_framework_data.last_mirror_sync_status)
				+ '<tr><td><b>Last successful check:</b></td><td> ' + this.formatted_date(last_sync) + '</td></tr>'
				if (this.mirror_auto_updates) {
					md += '<tr><td><b>Next check:</b></td><td> ' + this.formatted_date(this.framework_record.ss_framework_data.next_auto_update) + '</td></tr>'
				}
				md += '</table>'

			return md
		},

		convert_mirror(framework_record) {
			this.$confirm({
				title: 'Unlink Framework from Source',
				text: 'This will unlink the framework from the source URL, meaning that it will no longer be a mirror. This will make it editable here, but it will no longer get updates from the original source.',
				acceptText: 'Unlink Framework',
				dialogMaxWidth: 500,
			}).then(y => {
				U.loading_start()
				U.ajax('convert_mirror', {lsdoc_identifier: framework_record.lsdoc_identifier}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert(result.status)
						return
					}
					// remove the mirror settings from ss_framework_data (which is all the service did)
					this.$store.commit('set', [framework_record.ss_framework_data, 'is_mirror', 'no'])
					this.$store.commit('set', [framework_record.ss_framework_data, 'mirror_source_rest_api_url', ''])
					this.$store.commit('set', [framework_record.ss_framework_data, 'last_mirror_sync_date', ''])
					this.$store.commit('set', [framework_record.ss_framework_data, 'last_mirror_sync_status', ''])
					this.$store.commit('set', [framework_record.ss_framework_data, 'mirror_auto_updates', ''])

					this.cancel_clicked()
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		formatted_date(s) {
			if (empty(s)) return ''
			return  date.format(U.convert_to_local_date(s), 'YYYY-MM-DD HH:mm')
		},

		cancel_clicked() {
			// close the dialog
			this.$emit('mirror_info_cancel')
		},
	},
}
</script>
<style lang="scss">
</style>
