<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="95vw" persistent scrollable><div style="position:relative" class="">
		<v-btn class="k-editor-close-btn" style="margin-right:10px;margin-top:10px;" icon color="primary" @click.stop="close_print_dialog"><v-icon large>fas fa-times-circle</v-icon></v-btn>
		<v-card>
			<v-card-text class="pt-6" style="font-size:16px; background-color:#eee;">
				<div class="d-flex">
					<div class="k-print-settings elevation-3">
						<h3 class="mt-3"><v-btn small icon class="float-right" color="light-blue" @click="U.show_help('export_options')"><v-icon>fas fa-info-circle</v-icon></v-btn> <b>Print Settings:</b></h3>
						<div class="d-flex align-start mt-4">
							<div class="mr-4"><b>Print:</b></div>
							<div>
								<div><v-radio-group v-model="settings.scope" hide-details row class="ma-0 pa-0">
									<v-radio :label="object_type=='item'?'This item only':'Document node only'" value="item"></v-radio>
									<v-radio :label="object_type=='item'?'This item and its descendents':'All framework items'" value="children"></v-radio>
								</v-radio-group></div>
								<div v-if="object_type!='document'" class="mt-1"><v-checkbox class="mt-0 pt-0" :label="'Include framework (document) information'" v-model="settings.include_document" hide-details></v-checkbox></div>
							</div>
						</div>
						<div class="mt-4 d-flex" style="xjustify-content:space-evenly">
							<div class="mr-6" v-show="object_type=='document'||settings.include_document" style="flex:1 1 auto">
								<b>Document (framework) fields:</b>
								<v-checkbox disabled class="mt-0 pt-0" :label="'Title (always included)'" v-model="settings.doc_fields.title" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Adoption Status'" v-model="settings.doc_fields.adoptionStatus" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'CASE URI'" v-model="settings.doc_fields.uri" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Identifier (GUID)'" v-model="settings.doc_fields.identifier" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Version'" v-model="settings.doc_fields.version" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Framework Type'" v-model="settings.doc_fields.frameworkType" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Official Source URL'" v-model="settings.doc_fields.officialSourceURL" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'License'" v-model="settings.doc_fields.license" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Description'" v-model="settings.doc_fields.description" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Notes'" v-model="settings.doc_fields.notes" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Last Change Date'" v-model="settings.doc_fields.lastChangeDateTime" hide-details></v-checkbox>
								<div v-if="settings.fields.lastChangeDateTime||settings.doc_fields.lastChangeDateTime" class="mt-2" style="font-size:12px;"><i>(Dates will be printed in local time)</i></div>
							</div>
							<div v-show="object_type!='document'||settings.scope=='children'" style="flex:1 1 auto">
								<b>Item fields:</b>
								<v-checkbox class="mt-0 pt-0" :label="'Human-Readable Code'" v-model="settings.fields.humanCodingScheme" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Statement'" v-model="settings.fields.fullStatement" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Notes'" v-model="settings.fields.notes" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="exemplar_label" v-model="settings.fields.supplementalNotes" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Item Type'" v-model="settings.fields.CFItemType" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Education Level(s)'" v-model="settings.fields.educationLevel" hide-details></v-checkbox>
								<div v-if="custom_fields">
									<v-checkbox v-for="(def, key) in custom_fields" :key="key" class="mt-0 pt-0" :label="`${key} (custom field)`" v-model="settings.fields[key]" hide-details></v-checkbox>
								</div>
								<v-checkbox class="mt-0 pt-0" :label="'Identifier (GUID)'" v-model="settings.fields.identifier" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'URI'" v-model="settings.fields.uri" hide-details></v-checkbox>
								<v-checkbox class="mt-0 pt-0" :label="'Last Change Date'" v-model="settings.fields.lastChangeDateTime" hide-details></v-checkbox>
								<div v-if="settings.fields.lastChangeDateTime&&object_type!='document'" class="mt-1" style="font-size:12px;"><i>(Dates will be printed in local time)</i></div>
							</div>
						</div>
						<div v-if="show_comment_options" class="mt-2 d-flex">
							<v-checkbox class="mt-0 pt-0" :label="'Include comments'" v-model="settings.comments" hide-details></v-checkbox>
						</div>
						<div v-if="show_association_options" class="mt-2 d-flex">
							<v-checkbox class="mt-0 pt-0" :label="'Include associations'" v-model="settings.associations" hide-details></v-checkbox>
						</div>
						<div class="mt-0 d-flex align-end">
							<div class="mr-4"><b>Font face:</b></div>
							<v-radio-group v-model="settings.font_family" hide-details row>
								<v-radio :label="'Roboto'" value="Roboto"></v-radio>
								<v-radio :label="'Arial'" value="Arial"></v-radio>
								<v-radio :label="'Times New Roman'" value="Times New Roman"></v-radio>
							</v-radio-group>
						</div>
						<div class="mt-0 d-flex align-end">
							<div class="mr-4"><b>Font size:</b></div>
							<v-radio-group v-model="settings.font_size" hide-details row>
								<v-radio :label="'XS'" value="12px"></v-radio>
								<v-radio :label="'S'" value="14px"></v-radio>
								<v-radio :label="'M'" value="16px"></v-radio>
								<v-radio :label="'L'" value="18px"></v-radio>
								<v-radio :label="'XL'" value="20px"></v-radio>
							</v-radio-group>
						</div>
						<div class="mt-4">
							<v-checkbox class="mt-0 pt-0" :label="'Color-code item types'" v-model="settings.color_code" hide-details></v-checkbox>
						</div>
						<div class="mt-2">
							<v-checkbox class="mt-0 pt-0" :label="'Avoid page breaks within items'" v-model="settings.avoid_page_breaks_in_items" hide-details></v-checkbox>
						</div>
						<div class="mt-4">
							<div class="d-flex">
								<b>Introductory Text:</b>
								<v-spacer/>
								<v-btn v-if="settings.intro_text" x-small color="secondary" @click="settings.intro_text=''"><v-icon small class="mr-1">fas fa-times</v-icon>Clear</v-btn>
							</div>
							<div class="mt-1"><froala-wrapper :config="editor_config()" v-model="settings.intro_text" /></div>
						</div>
					</div>

					<div class="k-print-preview elevation-3">
						<div class="mb-3 mr-3 d-flex align-center">
							<h3><b>Print Preview:</b></h3>
							<v-spacer/>
							<v-btn :disabled="!changes_pending" small color="primary" class="k-tight-btn ml-3" @click="write_print_view"><v-icon small class="mr-2">fas fa-arrows-rotate</v-icon> Refresh Preview</v-btn>
							<v-btn :disabled="changes_pending" small color="primary" class="k-tight-btn ml-3" @click="print"><v-icon small class="mr-2">fas fa-print</v-icon> Print</v-btn>
							<v-btn :disabled="changes_pending" small color="primary" class="k-tight-btn ml-3" @click="open_in_new_window"><v-icon small class="mr-2">fas fa-external-link</v-icon> Open In New Window</v-btn>
						</div>
						<iframe name="print_view" class="k-print-view-iframe"></iframe>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</div></v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

var pi_styles = `
<style>
body {
	font-family:$font_family;
	font-size:$font_size;
	line-height:1.2em;
	color:#000;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	color-adjust: exact;
}

.k-diff-old {
	background-color:#ffcdd2;
}
.k-diff-new {
	background-color:#b3e5fc;
}

.fa, .fas, .far {
	text-indent:0;
	width:1.2em;
}

.fa-chevron-circle-down { color:#333; }
.fa-dot-circle { color:#333; }
.fa-map { color:#333; }

.fa-circle { color:#4caf50;; }
.fa-circle-half-stroke { color:#2196f3; }
.fa-circle-plus { color:#9c27b0; }
.fa-circle-xmark { color:#f44336; }

.top-item {

}

.item {
	margin-top:4px;
	border-radius:8px;
	padding:8px 8px;
	border:1px solid #ccc;
}

.item-text {
	margin-left:24px;
	text-indent:-24px;
}

.top-item .item-text {
	/*font-weight:bold;*/
	font-size:1.2em;
	line-height:1.2em;
}

.item .item-text {
	font-weight:normal;
	font-size:1em;
	line-height:1.2em;
}

.item-text p {
	display:inline;
	margin:0 0 0 0;
	text-indent:0;
}

.item-text li {
	text-indent:0;
}

.intro-text {
	font-size:0.9em;
	border-radius:6px;
	border:1px solid #ccc;
	padding:0 8px;
	margin:8px 0 16px 0;
}

.notes, .exemplar {
	font-size:0.9em;
	background-color:#fff;
	border-radius:6px;
	padding:1px 8px;
}

.exemplar {
	padding-top:8px;
	padding-bottom:8px;
}

.comments {

}

.comment {
	font-size:0.9em;
	background-color:#efe;
	border-radius:6px;
	border:1px dashed #cdc;
	padding:2px 8px;
	margin:4px 0;
}

.comment-author {
	font-size:0.8em;
	color:#555;
	margin-bottom:-6px;
	display:flex;
	align-items:flex-start;
}

.comment-body {
}

.comment-icon {
	flex:0 0 10px;
	margin-top:4px;
	margin-right:6px;
}

.associations {
	border:1px solid #ccc;
	background-color:#eee;
	border-radius:6px;
	margin:8px 12px;
}

.association-icon {
	flex:0 0 10px;
	margin-top:2px;
	margin-right:6px;
	color:#fff!important;
	font-size:1.3em;
}

.association-framework-title {
	display:flex;
	font-weight:bold;
	background-color:#555;
	color:#fff;
	padding:1px 0 1px 6px;
	border-radius:6px 6px 0 0;
	margin:-1px;
	font-size:0.7em;
}

.association {
	display:flex;
	align-items:center;
	justify-content: flex-start;
	padding:4px 8px;
	font-size:0.8em;
	line-height:1.2em;
}

.association-type {
	margin-right:8px;
}

.association p:first-of-type {
	display:inline;
}

.fa-play-up::before {
	content: "\\f04b";
	transform: rotate(270deg);
}

.fa-play-down::before {
	content: "\\f04b";
	transform: rotate(90deg);
}

.fa-copy-reverse::before {
	content: "\\f0c5";
	transform: rotate(180deg);
}

.top-item .notes, .top-item .exemplar, .top-item .comments {
	border:1px solid #ccc;
	margin:8px 0 0 0;
}

.children .notes, .children .exemplar, .children .comments {
	border:0;
	margin:8px 12px 0 12px;
}

.notes p, .exemplar p, .intro-text p, .comment p, .association p {
	margin:8px 0;
}

.exemplar hr {
	height:0;
	border-top:1px solid black;
	border-left:0;
	border-right:0;
	border-bottom:0;
}

.exemplar hr:first-of-type {
	display:none;
}

.exemplar-label {
	font-weight:bold;
	font-size:0.9em;
	color:#444;
	text-align:center;
	text-decoration:underline;
}

.fr-view th, .fr-view td {
	padding:4px;
}

.k-big-table {
	table-layout: fixed
}

.k-big-table th {
	font-size:0.7em;
}

.k-big-table td {
	font-size:0.7em;
	vertical-align:top;
}

.fr-view hr {
	page-break-after: avoid;
}

.nobr {
	white-space:nowrap;
}

.doc-extra-info {
	margin-top:8px;
	margin-bottom:12px;
}

.doc-extra-info li {
	padding:0;
	margin-top:4px;
	margin-bottom:0;
	font-size:0.8em;
	color:#444;
}

.extra-info {
	margin:8px 4px 0 4px;
	font-size:0.8em;
	display:flex;
	flex-wrap:wrap;
	justify-content:space-around;
	/* border:1px solid #eee; */
	background-color:#fff;
	border-radius:6px;
	color:#444;
}

.extra-info .nobr {
	margin:2px 4px;
}

.extra-info a, .doc-extra-info a {
	color:#444;
}

.children {
	margin-left:24px;
	margin-top:8px;
/*	border:1px solid #eee;
	border-radius:8px;
	padding:8px;
*/
}

.level-0-children {
	margin-left:0;
}

/* old:
.k-case-tree-item-type-color { background-color:#cfd8dc; }
.k-case-tree-item-type-color-0 { background-color: #e1bee7; }
.k-case-tree-item-type-color-1 { background-color: #c5cae9; }
.k-case-tree-item-type-color-2 { background-color: #b3e5fc; }
.k-case-tree-item-type-color-3 { background-color: #b2dfdb; }
.k-case-tree-item-type-color-4 { background-color: #f0f4c3; }
.k-case-tree-item-type-color-5 { background-color: #ffecb3; }
.k-case-tree-item-type-color-6 { background-color: #d7ccc8; }
.k-case-tree-item-type-color-7 { background-color: #ffcdd2; }
.k-case-tree-item-type-color-8 { background-color: #f8bbd0; }
*/

/* lighten-5: 
.k-case-tree-item-type-color { margin-top:4px; background-color:#eceff1; }
.k-case-tree-item-type-color-0 { background-color: #f3e5f5; }
.k-case-tree-item-type-color-1 { background-color: #e8eaf6; }
.k-case-tree-item-type-color-2 { background-color: #e3f2fd; }
.k-case-tree-item-type-color-3 { background-color: #e0f2f1; }
.k-case-tree-item-type-color-4 { background-color: #f9fbe7; }
.k-case-tree-item-type-color-5 { background-color: #fff8e1; }
.k-case-tree-item-type-color-6 { background-color: #efebe9; }
.k-case-tree-item-type-color-7 { background-color: #ffebee; }
.k-case-tree-item-type-color-8 { background-color: #fce4ec; }
*/

.k-case-tree-item-type-color-neutral {background-color:#eceff1;;}
.k-case-tree-item-type-color { margin-top:1px; background-color:#eceff1;; }
.k-case-tree-item-type-color-0 { background-color: #e8eaf6; }
.k-case-tree-item-type-color-1 { background-color: #f3e5f5; }
.k-case-tree-item-type-color-2 { background-color: #e3f2fd; }
.k-case-tree-item-type-color-3 { background-color: #e0f2f1; }
.k-case-tree-item-type-color-4 { background-color: #f9fbe7; }
.k-case-tree-item-type-color-5 { background-color: #fff3e0; }
.k-case-tree-item-type-color-6 { background-color: #efebe9; }
.k-case-tree-item-type-color-7 { background-color: #ffebee; }
.k-case-tree-item-type-color-8 { background-color: #ede7f6; }
.k-case-tree-item-type-color-9 { background-color: #e1f5fe; }
.k-case-tree-item-type-color-10 { background-color: #e8f5e9; }
.k-case-tree-item-type-color-11 { background-color: #fffde7; }
.k-case-tree-item-type-color-12 { background-color: #fbe9e7; }

/* specified by color */
.k-case-tree-item-type-color-indigo { background-color: #e8eaf6; }
.k-case-tree-item-type-color-purple { background-color: #f3e5f5; }
.k-case-tree-item-type-color-blue { background-color: #e3f2fd; }
.k-case-tree-item-type-color-teal { background-color: #e0f2f1; }
.k-case-tree-item-type-color-lime { background-color: #f9fbe7; }
.k-case-tree-item-type-color-orange { background-color: #fff3e0; }
.k-case-tree-item-type-color-brown { background-color: #efebe9; }
.k-case-tree-item-type-color-red { background-color: #ffebee; }
.k-case-tree-item-type-color-deep-purple { background-color: #ede7f6; }
.k-case-tree-item-type-color-light-blue { background-color: #e1f5fe; }
.k-case-tree-item-type-color-green { background-color: #e8f5e9; }
.k-case-tree-item-type-color-yellow { background-color: #fffde7; }
.k-case-tree-item-type-color-deep-orange { background-color: #fbe9e7; }

.k-mathlive-span {
	font-size:1.2em;
}

@page {
	margin:0.5in;
}

/*
@page{ size:auto; margin:5mm; }

.footer {
	position: fixed;
	bottom: 4px;
	left: 8px;
	width:calc(100vw - 16px);
}

.header {
	position: fixed;
	top: 4px;
	left: 8px;
	width:calc(100vw - 16px);
}
*/

</style>
`

export default {
	props: {
		framework_record: { type: Object, required: true },
		print_node: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		current_preview_settings: '',
		settings: {},
		original_window_title: '',

		// to make the conversion from code in CASEItemTile to here as easy as possible, we set these as responsive properties and use them in fns below
		item: null,
		item_is_document: false,
		in_viewer_context: true,	// could be switched to false if we want to print the archived version...
		tracked_change_entities: null,
		tracked_change_icon: '',
		show_tracked_changes: null,
	}},
	computed: {
		...mapState(['association_type_labels']),
		...mapGetters(['signed_in', 'comments_hash']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		stringified_settings() {
			return JSON.stringify(this.settings)
		},
		changes_pending() {
			return this.current_preview_settings != this.stringified_settings
		},
		object_type() {
			// if print_node's tree_key is 1, it's the document
			return (this.print_node.tree_key == 1) ? 'document' : 'item'
		},
		exemplar_label() {
			return this.framework_record.ss_framework_data.exemplar_label || 'Supplemental Information'
		},
		show_comment_options() {
			// show options for printing comments if user is signed in (and actually has comments??)
			return this.signed_in
		},
		show_association_options() {
			// show options for printing associations if we actually have any assocations to show
			// (we always print in the context of viewing a framework, so it's fine to reference case_tree_component gere)
			return vapp.case_tree_component.has_associations
		},

		// if we're tracking changes, show track changes things...
		tracking_changes() {
			return vapp.case_tree_component && vapp.case_tree_component.tracking_changes
		},
		track_changes_fields() { return this.$store.state.lst.track_changes_fields },
		custom_fields() {
			// currently only printing custom fields for items
			return window.CASE_Custom_Extension_Fields.CFItem ?? null
		},

	},
	watch: {
	},
	created() {
		let s = this.$store.state.lst.print_view_settings
		if (s) {
			s = JSON.parse(s)
		} else {
			s = {}
		}
		if (empty(s.fields)) s.fields = {}
		if (empty(s.doc_fields)) s.doc_fields = {}

		this.settings = $.extend(true, {
			scope: 'children',
			include_document: false,
			fields: {
				humanCodingScheme: true,
				fullStatement: true,
				notes: true,
				supplementalNotes: true,
				CFItemType: false,
				educationLevel: false,
				identifier: false,
				uri: false,
				lastChangeDateTime: false,
			},
			doc_fields: {
				title: true,
				adoptionStatus: true,
				uri: true,
				officialSourceURL: true,
				frameworkType: true,
				license: true,
				version: false,
				identifier: false,
				description: true,
				notes: true,
				lastChangeDateTime: false,
			},
			comments: false,
			associations: false,
			font_size: '16px',
			font_family: 'Roboto',
			color_code: true,
			avoid_page_breaks_in_items: true,
			intro_text: '',
		}, s)

		// add custom fields for CFItem
		if (this.custom_fields) {
			for (let key in this.custom_fields && !this.settings.fields[key]) {
				this.settings.fields[key] = false
			}
		}
	},
	mounted() {
		vapp.print_view = this
		// when we mount, set the title of window.document to the node's text; that way this text will be used as the default title of the PDF if it's printed
		this.original_window_title = window.document.title
		window.document.title = U.generate_cfassociation_node_uri_title(this.print_node.cfitem, 80)

		this.write_print_view()
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				enter: FroalaEditor.ENTER_P,
				// heightMin: 350,
				// zIndex: 2501,
			})
		},

		print() {
			window.print_view.print()
		},

		open_in_new_window() {
			let w = window.open()
			this.write_print_view(w)
		},

		write_print_view(w) {
			if (!w || !w.document) {
				w = window.print_view
				this.current_preview_settings = this.stringified_settings
				// whenever this is called, save settings to lst
				this.$store.commit('lst_set', ['print_view_settings', this.stringified_settings])
			}
			w.document.open()
			w.document.write('<html><head>')
			w.document.write(sr('<title>$1</title>', this.framework_record.json.CFDocument.title))

			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/4.0.10/css/froala_style.min.css" crossorigin="anonymous">')

			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/solid.min.css" integrity="sha512-qzgHTQ60z8RJitD5a28/c47in6WlHGuyRvMusdnuWWBB6fZ0DWG/KyfchGSBlLVeqAz+1LzNq+gGZkCSHnSd3g==" crossorigin="anonymous" referrerpolicy="no-referrer" />')
			w.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/fontawesome.min.css" integrity="sha512-xX2rYBFJSj86W54Fyv1de80DWBq7zYLn2z0I9bIhQG+rxIF6XVJUpdGnsNHWRa6AvP89vtFupEPDP8eZAtu9qA==" crossorigin="anonymous" referrerpolicy="no-referrer" />')

			if (this.settings.font_family == 'Roboto') {
				w.document.write('<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,400;1,900&display=swap" rel="stylesheet">')
			}

			let pi_styles_r = pi_styles.replace(/\$font_size/, this.settings.font_size)
			pi_styles_r = pi_styles_r.replace(/\$font_family/, this.settings.font_family)
			w.document.write(pi_styles_r)

			// MathLive styles; see also U.inject_mathlive_styles
			w.document.write(`<style>${U.mathlive_core_css}</style>`)
			// katex styles, in case we go back to using katex
			// w.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.15.3/dist/katex.min.css" integrity="sha384-KiWOvVjnN8qwAZbuQyWDIbfCLFhLXNETzBQjA/92pIowpC0d2O3nppDGQVgwd2nB" crossorigin="anonymous">')


			w.document.write('</head><body>')

			// start with the date
			w.document.write(`<div style="text-align:center;margin-bottom:16px"><i>Print Date: ${date.format(new Date(), 'MMMM D, YYYY h:mm A')}</i></div>`)

			// if we're printing a node, but the user also wants the document, start with the document
			if (!this.item_is_document && this.settings.include_document) {
				let scope = this.settings.scope
				this.settings.scope = 'item'
				this.write_node(w, this.framework_record.cfo.cftree, 0)
				w.document.write('<br>')
				this.settings.scope = scope
			}

			this.write_node(w, this.print_node, 0)

			// this.write_header_and_footer(w)

			w.document.write('</body></html>')
			w.document.close()
		},

		write_node(w, node, level) {
			// set up track_changes values for this node
			this.item = node
			this.item_is_document = (node.tree_key == 1)
			if (this.tracking_changes) {
				this.tracked_change_entities = U.get_archive_comparison_entities(node.cfitem.identifier, vapp.case_tree_component.framework_record, vapp.case_tree_component.track_changes_framework_record)
				this.tracked_change_icon = sr('<i class="fas $1" style="margin-right:2px;"></i>', U.tracked_change_icon(this.tracked_change_entities))
				this.show_tracked_changes = this.tracking_changes && this.tracked_change_entities.current.identifier && this.tracked_change_entities.archived.identifier
			}

			let outer_class = '', outer_style = ''

			if (level == 0) outer_class += ' top-item'
			else outer_class += ' item'

			if (level > 0 && this.settings.color_code) {
				outer_class += ' k-case-tree-item-type-color'
				let item_type_level = this.framework_record.cfo.item_type_levels.find(x=>x.item_type == U.item_type_string(node.cfitem))
				if (item_type_level) {
					outer_class += (' k-case-tree-item-type-color-' + item_type_level.level_index)
				}
			}

			// if this item doesn't have children, avoid page breaks within the item
			if (this.settings.avoid_page_breaks_in_items && this.settings.scope == 'children' && node.children.length == 0) {
				outer_style = 'break-inside:avoid-page;'
			}

			w.document.write(sr('<div class="$1" style="$2">', outer_class, outer_style))

			if (this.item_is_document) {
				this.write_document(w, node)
			} else {
				this.write_item(w, node, level)
			}

			if (this.settings.scope == 'children' && node.children.length > 0) {
				w.document.write(sr('<div class="children $1">', ((level == 0) ? 'level-0-children' : '')))
				for (let child of node.children) {
					this.write_node(w, child, level + 1)
				}
				w.document.write('</div>')
			}

			w.document.write('</div>')
		},

		write_document(w, node) {
			let html = ''
			html += sr('<div class="item-text">$1<b>$2</b></div>', this.tracked_change_icon, this.marked_full_statement()) // node.cfitem.title)

			// write intro_text (if there) after item text; note that the document is always at level 0
			if (this.settings.intro_text) w.document.write(sr('<div class="intro-text fr-view">$1</div>', this.settings.intro_text))

			let extra = ''
			if (this.settings.doc_fields.adoptionStatus && node.cfitem.adoptionStatus) extra += sr('<li><b>Adoption Status:</b> &nbsp;$1</li>', node.cfitem.adoptionStatus)
			if (this.settings.doc_fields.uri && node.cfitem.uri) extra += sr('<li><b>CASE URI:</b> &nbsp;<a href="$1" target="blank">$1</a></li>', node.cfitem.uri)
			if (this.settings.doc_fields.identifier && node.cfitem.identifier) extra += sr('<li><b>Identifier (GUID):</b> &nbsp;$1</li>', node.cfitem.identifier)
			if (this.settings.doc_fields.version && node.cfitem.version) extra += sr('<li><b>Version:</b> &nbsp;$1</li>', node.cfitem.version)
			if (this.settings.doc_fields.frameworkType && node.cfitem.frameworkType) extra += sr('<li><b>Framework Type:</b> &nbsp;<a href="$1" target="blank">$1</a></li>', node.cfitem.frameworkType)
			if (this.settings.doc_fields.officialSourceURL && node.cfitem.officialSourceURL) extra += sr('<li><b>Official Source URL:</b> &nbsp;<a href="$1" target="blank">$1</a></li>', node.cfitem.officialSourceURL)
			if (this.settings.doc_fields.license && node.cfitem.licenseURI) {
				let license_json = U.get_license_json(this.framework_record)
				if (license_json.licenseText) {
					extra += sr('<li><b>License:</b> &nbsp;$1<div style="margin-top:4px">$2</div></li>', license_json.title, license_json.licenseText)
				}
			}
			if (this.settings.doc_fields.lastChangeDateTime && node.cfitem.lastChangeDateTime) extra += sr('<li><b>Last Change Date:</b> &nbsp;$1</li>', U.local_last_change_date_time(node.cfitem.lastChangeDateTime))
			if (extra) {
				html += sr('<ul class="doc-extra-info">$1</ul>', extra)
			}

			// if (this.settings.doc_fields.publisher && node.cfitem.publisher) extra += sr("<li><b>publisher:</b> $1</li>", node.cfitem.publisher)
			// if (this.settings.doc_fields.subject && node.cfitem.subject) extra += sr("<li><b>subject: $1</li>", node.cfitem.subject)
			// if (this.settings.doc_fields.subjectURI && node.cfitem.subjectURI) extra += sr("<li><b>subjectURI: $1</li>", node.cfitem.subjectURI)
			// if (this.settings.doc_fields.language && node.cfitem.language) extra += sr("<li><b>language:</b> $1</li>", node.cfitem.language)

			if (this.settings.doc_fields.description && node.cfitem.description) html += sr('<div class="notes"><b>Description:</b> $1</div>', this.format_text(node.cfitem.description))
			if (this.settings.doc_fields.notes && node.cfitem.notes) html += sr('<div class="notes">$1</div>', this.format_text(node.cfitem.notes))

			w.document.write(html)
		},

		write_item(w, node, level) {
			let html = this.tracked_change_icon

			if (this.settings.fields.humanCodingScheme && node.cfitem.humanCodingScheme) html += sr('<b style="margin-right:4px">$1</b> ', this.human_coding_scheme())
			if (this.settings.fields.fullStatement) html += this.marked_full_statement()	// this.format_text(node.cfitem.fullStatement)
			
			if (node.children.length > 0) html = sr('<i class="fas fa-chevron-circle-down" style="margin-right:2px"></i>$1', html)
			else html = sr('<i class="fas fa-dot-circle" style="margin-right:2px"></i>$1', html)

			html = sr('<div class="item-text">$1</div>', html)

			// write intro_text (if there) after item text
			if (level == 0 && this.settings.intro_text) html += sr('<div class="intro-text fr-view">$1</div>', this.settings.intro_text)

			if (this.settings.comments) html += this.comment_html(node)

			if (this.settings.fields.notes && node.cfitem.notes) html += sr('<div class="notes">$1</div>', this.marked_notes())

			if (this.settings.fields.supplementalNotes) {
				let exs = node.cfitem.extensions.supplementalNotes
				if (exs) html += sr('<div class="exemplar fr-view"><div class="exemplar-label">$1</div>$2</div>', this.exemplar_label, this.marked_exemplar())
			}

			let extra = ''
			if (this.settings.fields.CFItemType && node.cfitem.CFItemType) extra += sr('<div class="nobr">$1</div>', U.item_type_string(node.cfitem))
			if (this.settings.fields.educationLevel && node.cfitem.educationLevel) extra += sr('<div class="nobr">$1</div>', U.grade_level_display(node.cfitem.educationLevel))
			if (this.settings.fields.identifier) extra += sr('<div class="nobr">$1</div>', node.cfitem.identifier)
			if (this.settings.fields.uri) extra += sr('<div class="nobr"><a href="$1" target="_blank">$1</a></div>', node.cfitem.uri)
			if (this.settings.fields.lastChangeDateTime) extra += sr('<div class="nobr">$1</div>', U.local_last_change_date_time(node.cfitem.lastChangeDateTime))
			for (let key in this.custom_fields) {
				if (this.settings.fields[key] && node.cfitem.extensions[key]) extra += `<div class="nobr"><b>${key}:</b> ${node.cfitem.extensions[key]}</div>`
			}
			if (extra) html += sr('<div class="extra-info">$1</div>', extra)

			if (this.settings.associations) html += this.associations_html(node)

			w.document.write(html)
		},

		highlight_search_terms(s) { return s },		// to ease translation from CASEItemTile
		human_coding_scheme() {
			if (this.show_tracked_changes && this.track_changes_fields.humanCodingScheme && this.track_changes_fields.highlight_text_diffs) {
				let s = this.tracked_change_entities.current.humanCodingScheme ?? ''
				let archived = this.tracked_change_entities.archived.humanCodingScheme ?? ''
				let diff = U.diff_string(archived, s)
				return (this.in_viewer_context) ? diff.n.replace(/(<p>) /g, '$1') : diff.o.replace(/(<p>) /g, '$1')

			} else {
				return this.highlight_search_terms(this.item.cfitem.humanCodingScheme, 'humanCodingScheme')
			}
		},
		marked_full_statement() {
			if (this.show_tracked_changes && this.track_changes_fields.fullStatement && this.track_changes_fields.highlight_text_diffs) {
				let s, archived

				if (this.item_is_document) {
					s = this.tracked_change_entities.current.title
					archived = this.tracked_change_entities.archived.title
				} else {
					// preserve katex here; then do diff_string, then render katex below
					s = marked(U.preserve_latex(this.tracked_change_entities.current.fullStatement))
					archived = marked(U.preserve_latex(this.tracked_change_entities.archived.fullStatement))
				}

				let diff = U.diff_string(archived, s)	// , 'preserve_tags' ??
				// show the new value if we're on the left side, or the old value on the right
				diff = (this.in_viewer_context) ? diff.n : diff.o

				return U.render_latex(U.preserve_latex_reverse(diff)).replace(/(<p>) /g, '$1')

			} else {
				// for the document, return the doc title
				if (this.item_is_document) return this.item.cfitem.title

				let s = U.marked_latex(this.item.cfitem.fullStatement)
				return this.highlight_search_terms(s, 'fullStatement')
			}
		},
		marked_notes() {
			if (!this.item.cfitem.notes) return ''
			let s
			if (this.show_tracked_changes && this.track_changes_fields.notes && this.track_changes_fields.highlight_text_diffs) {
				// preserve katex here; then do diff_string, then render katex below
				s = marked(U.preserve_latex(this.tracked_change_entities.current.notes))
				let archived = marked(U.preserve_latex(this.tracked_change_entities.archived.notes))

				let diff = U.diff_string(archived, s)
				// show the new value if we're on the left side, or the old value on the right
				s = (this.in_viewer_context) ? diff.n : diff.o
				s = U.render_latex(U.preserve_latex_reverse(s)).replace(/(<p>) /g, '$1')
			} else {
				s = U.marked_latex(this.item.cfitem.notes)
				s = this.highlight_search_terms(s, 'notes')
			}

			// make sure all links open in a new window (though notes really shouldn't have links; note that for exemplars froala should ensure _blank is included)
			s = s.replace(/<a /g, '<a target="_blank" ')

			return s	
		},
		marked_exemplar() {
			if (!this.item.cfitem.extensions.supplementalNotes) return ''
			let exs
			if (this.show_tracked_changes && this.track_changes_fields.supplementalNotes && this.track_changes_fields.highlight_text_diffs) {
				// preserve katex here; then do diff_string, then render katex below
				exs = U.preserve_latex(this.tracked_change_entities.current.supplementalNotes)
				if (exs.search(/<\w+[^<]*>/) == -1) exs = marked(exs)

				let archived = U.preserve_latex(this.tracked_change_entities.archived.supplementalNotes)
				if (archived.search(/<\w+[^<]*>/) == -1) archived = marked(archived)

				let diff = U.diff_string(archived, exs, 'preserve_tags')
				// show the new value if we're on the left side, or the old value on the right
				exs = (this.in_viewer_context) ? diff.n : diff.o
				exs = U.render_latex(U.preserve_latex_reverse(exs)).replace(/(<p>) /g, '$1')

			} else {
				// note that examplars can either use html or markdown; use marked if we don't have any html tags
				// also note that if the author uses latex, they shouldn't also try to use markdown, because katex will render the latex as html, so marked() won't be run here
				exs = this.item.cfitem.extensions.supplementalNotes
				if (exs.search(/<\w+[^<]*>/) == -1) exs = U.marked_latex(exs)
				else exs = U.render_latex(exs)
				exs = this.highlight_search_terms(exs, 'exemplar')
			}

			return exs
		},

		comment_html(node) {
			if (!this.comments_hash[this.framework_identifier] || !this.comments_hash[this.framework_identifier][node.cfitem.identifier]) return ''

			let arr = []
			for (let c of this.comments_hash[this.framework_identifier][node.cfitem.identifier]) {
				// framework_identifier should always match, but check just in case
				if (c.parent_comment_id == 0) {
					arr.push(c)
				}
			}

			if (arr.length == 0) return ''

			arr.sort((a,b) => {
				// sort replies so that newer ones come first
				return b.created_at - a.created_at
			})

			let html = '<div class="comments">'
			for (let comment of arr) {
				let d = new Date(comment.created_at * 1000)
				let comment_date = date.format(U.convert_to_local_date(d), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM

				html += '<div class="comment">'
				html += sr('<div class="comment-author"><div class="comment-icon"><i class="fas fa-comment"></i></div><div><b>$1 $2</b> &nbsp; $3</div></div>', comment.first_name, comment.last_name, comment_date)
				html += sr('<div class="comment-body"><div>$1</div></div>', comment.body)
				html += '</div>'
			}

			html += '</div>'

			return html
		},

		associations_html(node) {
			let assoc_arr = this.framework_record.cfo.displayed_associations_hash[node.cfitem.identifier]
			if (!assoc_arr) return ''

			// organize associations by the framework of the other item; we use this to display the associations
			let hash = {}

			// always start with the framework for this item -- we want to show intra-framework associations first
			hash[this.framework_record.json.CFDocument.identifier] = {
				document: this.framework_record.json.CFDocument,
				associations: [],
			}

			for (let assoc of assoc_arr) {
				// get the associated framework for this assoc
				let framework = this.association_framework(node, assoc)

				let framework_identifier
				if (!framework) {
					framework_identifier = 'xxx',
					framework = { title: 'Unknown Framework' }
				} else {
					framework_identifier = framework.identifier
				}

				// insert new hash value if needed
				if (!hash[framework_identifier]) hash[framework_identifier] = {
					document: framework,
					associations: [],
				}

				// then add association
				hash[framework_identifier].associations.push(assoc)
			}

			// if we didn't find any associations with the item's framework, remove that value from the hash
			if (hash[this.framework_record.json.CFDocument.identifier].associations.length == 0) {
				delete hash[this.framework_record.json.CFDocument.identifier]
			}

			// now for each framework...
			let html = ''
			for (let fwid in hash) {
				let fw = hash[fwid]

				html += '<div class="associations">'
				html += sr('<div class="association-framework-title"><div class="association-icon"><i class="fas fa-arrows-alt-h"></i></div><div>$1</div></div>', fw.document.title)

				for (let association of fw.associations) {
					html += '<div class="association">'
					// no type indicator for relatedTos; otherwise show label and possibly an arrow
					if (association.associationType != 'isRelatedTo') {
						// choose association type label and icon based on whether this item is the origin or destination
						let abr = this.$store.state.association_type_icons[association.associationType]	// this is a string for "bidirectional" assoc types, and an array for directional ones
						let label, icon
						if (association.originNodeURI.identifier == node.cfitem.identifier) {
							label = this.$store.state.association_type_labels[association.associationType]
							icon = (typeof(abr) == 'string') ? abr : abr[0]
						} else {
							label = this.$store.state.association_type_labels_reverse[association.associationType]
							icon = (typeof(abr) == 'string') ? abr : abr[1]
						}

						html += `<div style="margin-right:2px"><i class="${icon}"></i></div><div class="association-type">${label}</div>`
					}

					// get the item from the association that isn't this item; then take out the framework pattern if there
					let s = this.associated_item_data(node, association).title.replace(/\s*\(:(\S+):\)\s*$/, '')
					s = U.marked_latex(s)

					html += sr('<div class="association-body">$1</div>', s)
					html += '</div>'
				}

				html += '</div>'
			}

			return html
		},

		association_framework(node, assoc) {
			let aid = this.associated_item_data(node, assoc)

			// look for the pattern / (:framework-identifier:)/ at the end of the associated_item_data's title
			if (aid.title.search(/\s*\(:(\S+):\)\s*$/) > -1) {
				// if we found it, this will be the identifier for the framework this item comes from
				let framework_identifier = RegExp.$1

				// if this is the framework_record, that's the framework we want
				if (framework_identifier == this.framework_record.lsdoc_identifier) {
					return this.framework_record.json.CFDocument
				} else {
					// otherwise we can look that up in framework_record
					return this.framework_record.cfo.associated_documents.find(x=>x.identifier == framework_identifier)
				}

			} else {
				// console.log('aid', aid.identifier)
				// if the associated item is part of the home framework, return it
				if (this.framework_record.cfo.cfitems[aid.identifier]) {
					return this.framework_record.json.CFDocument
				}
			}
			// if we get to here return empty string
			return ''
		},

		associated_item_data(node, assoc) {
			// either the origin or the destination must be this item; get the "uri" data for the other one
			if (assoc.originNodeURI.identifier == node.cfitem.identifier) return assoc.destinationNodeURI
			else return assoc.originNodeURI
		},

		format_text(s, flag) {
			if (flag != 'no_marked') s = U.marked_latex(s)
			else s = U.render_latex(s)

			return s
		},

		write_header_and_footer(w) {
			let header = 'This is the header'
			let footer = 'This is the footer...'
			w.document.write(sr('<div class="header">$1</div>', header))
			w.document.write(sr('<div class="footer">$1</div>', footer))
		},

		close_print_dialog() {
			// before we close, change the window.document.title back
			window.document.title = this.original_window_title
			this.$emit('dialog_cancel')
		},
	}
}
</script>

<style lang="scss">
.k-print-settings {
	height:calc(100vh - 120px);
	overflow:auto;
	flex:0 1 auto;
	width:550px;
	margin-right:12px;
	border:1px solid #ccc;
	border-radius:12px;
	padding:0 12px 12px 12px;
	background-color:#fff;
}

.k-print-preview {
	border-radius:12px;
	padding:12px 12px 4px 12px;
	margin-bottom:2px;
	background-color:#ccc;
	flex:1 0 auto;
}

.k-print-view-iframe {
	background-color:#fff;
	border-radius:3px;
	width:100%;
	height:calc(100vh - 186px);
	border:1px solid black;
}
</style>
