<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template><div>
	<div style="margin:8px -8px 0 -8px"><v-data-table v-if="framework_identifier" light dense
		:headers="headers"
		hide-default-header
		:items="table_rows"
		:footer-props="footer_options"
		class="k-framework-updates-table"
	>
		<template v-slot:header><thead>
			<tr class="k-framework-updates-table-header">
				<th colspan="2" style="cursor:default" class="k-framework-updates-table-th-left"><div class="d-flex align-center" style="margin:0 0 -20px 0px; font-size:16px;"><b class="mr-3">Update Counts by Type/Date</b><v-btn small color="orange darken-2" dark @click="view_framework_archives"><v-icon small class="mr-2">fas fa-archive</v-icon>Show Framework Archives</v-btn></div></th>
				<th colspan="5" class="text-center" style="cursor:default; border-left:1px solid #ccc;border-right:1px solid #ccc; font-size:14px!important;"><b>Updates Since...</b></th>
				<th colspan="1" style="cursor:default" class="k-framework-updates-table-th-right"></th>
			</tr>
			<tr class="k-framework-updates-table-header">
				<th style="white-space:nowrap; padding-left:24px; cursor:default">Update Type</th>
				<th style="white-space:nowrap" class="text-center">Last Change Date</th>

				<th style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('day')" x-small text color="orange darken-3" icon @click="compare_to_archive(update_record['day'],$event)"><v-icon style="font-size:14px">fas fa-table-list</v-icon></v-btn></template>Show changes in a table</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('day')" x-small text color="orange darken-3" class="ml-1" icon @click="track_changes_to_archive(update_record['day'].archive_fn,$event)"><v-icon style="font-size:14px">fas fa-exchange-alt</v-icon></v-btn></template>Track changes</v-tooltip>
					<div style="margin-top:3px">This morning<br>{{ref_dates.day}}</div>
				</th>
				<th style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('week')" x-small text color="orange darken-3" icon @click="compare_to_archive(update_record['week'],$event)"><v-icon style="font-size:14px">fas fa-table-list</v-icon></v-btn></template>Show changes in a table</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('week')" x-small text color="orange darken-3" class="ml-1" icon @click="track_changes_to_archive(update_record['week'].archive_fn,$event)"><v-icon style="font-size:14px">fas fa-exchange-alt</v-icon></v-btn></template>Track changes</v-tooltip>
					<div style="margin-top:3px">1 Week Ago<br>{{ref_dates.week}}</div>
				</th>
				<th style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('month')" x-small text color="orange darken-3" icon @click="compare_to_archive(update_record['month'],$event)"><v-icon style="font-size:14px">fas fa-table-list</v-icon></v-btn></template>Show changes in a table</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('month')" x-small text color="orange darken-3" class="ml-1" icon @click="track_changes_to_archive(update_record['month'].archive_fn,$event)"><v-icon style="font-size:14px">fas fa-exchange-alt</v-icon></v-btn></template>Track changes</v-tooltip>
					<div style="margin-top:3px">1 Month Ago<br>{{ref_dates.month}}</div>
				</th>
				<th style="white-space:nowrap; border-left:1px solid #ccc;" class="text-center">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('3_months')" x-small text color="orange darken-3" icon @click="compare_to_archive(update_record['3_months'],$event)"><v-icon style="font-size:14px">fas fa-table-list</v-icon></v-btn></template>Show changes in a table</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('3_months')" x-small text color="orange darken-3" class="ml-1" icon @click="track_changes_to_archive(update_record['3_months'].archive_fn,$event)"><v-icon style="font-size:14px">fas fa-exchange-alt</v-icon></v-btn></template>Track changes</v-tooltip>
					<div style="margin-top:3px">3 Months Ago<br>{{ref_dates['3_months']}}</div>
				</th>
				<th style="white-space:nowrap; border-left:1px solid #ccc; border-right:1px solid #ccc;" class="text-center">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('year')" x-small text color="orange darken-3" icon @click="compare_to_archive(update_record['year'],$event)"><v-icon style="font-size:14px">fas fa-table-list</v-icon></v-btn></template>Show changes in a table</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="archive_available('year')" x-small text color="orange darken-3" class="ml-1" icon @click="track_changes_to_archive(update_record['year'].archive_fn,$event)"><v-icon style="font-size:14px">fas fa-exchange-alt</v-icon></v-btn></template>Track changes</v-tooltip>
					<div style="margin-top:3px">1 Year Ago<br>{{ref_dates.year}}</div>
				</th>

				<th style="white-space:nowrap; cursor:default" class="text-center">Date Added</th>
			</tr>
		</thead></template>
		<template v-slot:item="{ item }"><tr>
			<td style="vertical-align:middle" class="text-left d-flex align-center pl-2"><nobr v-html="item.label"></nobr></td>
			<td style="vertical-align:middle" class="text-center"><nobr>{{item.lastChangeDateTime}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center">{{item['day']}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center">{{item['week']}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center">{{item['month']}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center">{{item['3_months']}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center">{{item['year']}}</nobr></td>
			<td style="vertical-align:middle; border-left:1px solid #ccc;" class="text-center px-2"><nobr>{{item.date_added}}</nobr></td>
		</tr></template>
	</v-data-table></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: { },
	props: {
		framework_records: { required: true },
		framework_identifier: { type: String, required: true },
		update_records: { type: Object, required: true },
		ref_dates: { type: Object, required: true },
		parent_component: { required: true },
	},
	data() { return {
		headers: [
			{ text: 'Label', align: 'left', sortable: false, value:'title' },
			{ text: 'Last Change Date', align: 'left', sortable: false, value:'lastChangeDateTime' },
			{ text: 'day', align: 'center', sortable: false, value:'day' },
			{ text: 'week', align: 'center', sortable: false, value:'week' },
			{ text: 'month', align: 'center', sortable: false, value:'month' },
			{ text: '3_months', align: 'center', sortable: false, value:'3_months' },
			{ text: 'year', align: 'center', sortable: false, value:'year' },
			// { text: 'Since Date Added', align: 'center', sortable: false, value:'added' },
			{ text: 'Date Added', align: 'left', sortable: false, value:'date_added' },
		],
		footer_options: {
			itemsPerPageOptions: [50,100,-1],
		},
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.framework_identifier) },
		update_record() { return this.update_records[this.framework_identifier] },
		table_rows() {
			if (!this.framework_record) return []
			let arr = [
				{label: '<b>Items:</b> Updates to statement or human-readable code for existing items', field:'item_updates_code_or_statement'},
				{label: '<b>Items:</b> Updates to other properties for existing items', field:'item_updates_not_code_or_statement'},
				{label: '<b>Items:</b> Existing items moved', field:'item_updates_moved'},
				{label: '<b>Items:</b> New items added', field:'items_created'},
				{label: '<b>Items:</b> Items deleted', field:'items_deleted'},
				{label: '<b>Associations:</b> Updates to existing associations', field:'assocs_updated'},
				{label: '<b>Associations:</b> New associations added', field:'assocs_created'},
				{label: '<b>Associations:</b> Associations deleted', field:'assocs_deleted'},
				{label: '<b>Document:</b> Updates to document properties', field:'document_updated'},
			]
			for (let o of arr) {
				o.lastChangeDateTime = this.parent_component.format_date((this.framework_record.ss_framework_data.computed_lastChangeDateTime) ? this.framework_record.ss_framework_data.computed_lastChangeDateTime : this.framework_record.json.CFDocument.lastChangeDateTime, 'time'),

				o.date_added = this.parent_component.format_date(this.update_record.framework_record_created_at)
				o.day = (this.update_record['day'] == '-') ? '' : this.update_record['day'].update_report_summary[o.field]
				o.week = (this.update_record['week'] == '-') ? '' : this.update_record['week'].update_report_summary[o.field]
				o.month = (this.update_record['month'] == '-') ? '' : this.update_record['month'].update_report_summary[o.field]
				o['3_months'] = (this.update_record['3_months'] == '-') ? '' : this.update_record['3_months'].update_report_summary[o.field]
				o.year = (this.update_record['year'] == '-') ? '' : this.update_record['year'].update_report_summary[o.field]
			}
			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.framework_update_report_by_framework = this
	},
	mounted() {
		// if the framework_record doesn't exist, go back to the update_type report
		if (!this.framework_record) {
			this.parent_component.report_type = 'update_type'
		} else {
			this.$store.commit('set', ['last_framework_update_report', this.framework_identifier])
		}
	},
	methods: {
		archive_available(ref_date) {
			return this.update_record[ref_date] != '-'
		},
		view_framework($event) {
			this.parent_component.view_framework(this.framework_identifier, $event)
		},
		view_framework_archives() {
			// this.parent_component.return_to_report_value = 'framework'
			this.parent_component.view_framework_archives(this.framework_identifier)
		},
		track_changes_to_archive(archive_fn) {
			this.parent_component.return_to_report_value = 'framework'
			this.parent_component.track_changes_to_archive(this.framework_identifier, archive_fn)
		},
		compare_to_archive(update_record) {
			this.parent_component.return_to_report_value = 'framework'
			this.parent_component.compare_to_archive(this.framework_identifier, ['current', update_record])
		},
	}
}
</script>

<style lang="scss">
</style>
