<template><div>
	<div v-if="entity_archives" class="mt-4">
		<div v-if="records.length==1" class="text-center"><b>This {{object_type}} has no change history.</b> <br>(In other words, the {{object_type}} has never been altered.)</div>
		<div v-else>
			<div class="k-entity-history-record-outer" v-for="(record, index) in records" :key="index">
				<div class="k-entity-history-record-header" :class="record.header_class">
					<div><b v-html="record.header"></b><i v-if="index==0" class="pl-2">LATEST UPDATE FOR THIS ITEM</i></div>
						<!-- <v-icon v-if="record.has_note" color="amber lighten-4" small class="mr-2" style="margin-top:-4px">fas fa-stamp</v-icon> -->
					<v-spacer/>
					<div><v-tooltip bottom><template v-slot:activator="{on}"><b v-on="on" v-html="record.source"></b></template><div v-html="record.archive_show_note"></div></v-tooltip></div>
				</div>
				<div class="k-entity-history-record-changes">
					<CASEJSONView :object_type="object_type" :object="record.updated_json" :object_to_copy="record.json" :revert_allowed="record.revert_allowed" @revert="revert_property(record, $event)" />
				</div>
			</div>
			<div v-if="user_can_edit&&!editing_enabled&&reverts_available" class="mt-2 mx-auto py-2 text-center" style="font-size:16px;border-top:1px solid #ccc;width:580px;">To revert properties to archived states, you must first <v-btn small color="primary" class="ml-2" @click="check_out_for_editing">enable editing</v-btn></div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASEJSONView from './CASEJSONView'

export default {
	components: { CASEJSONView },
	props: {
		object_type: { type: String, required: true },
		object: { type: Object, required: true },
		framework_record: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		entity_archives: null,
		reverts_available: false,
			// timestamp
			// email
			// archive_type
			// archive_note
			// archive_fn
			// restored_from_timestamp
			// entity_json
	}},
	computed: {
		...mapState(['user_info', 'editing_enabled',]),
		...mapGetters([]),
		user_can_edit() { return vapp.case_tree_component.user_can_edit },
		records() {
			if (!this.entity_archives) return []

			// sort archives with earliest first
			this.entity_archives.sort((a,b) => {
				return b.timestamp - a.timestamp
			})

			// start a record for each archive
			let arr = []
			for (let arch of this.entity_archives) {
				// if entity_json is empty, we'll skip (at least for now...)
				if (empty(arch.entity_json)) {
					console.log('skipping archive with empty entity_json', arch)
					continue
				}

				let o = {}
				o.email = arch.email
				o.timestamp = arch.timestamp

				o.source = 'ARCHIVE'
				o.archive_show_note = arch.archive_note
				// keep this in synch with code in ManageArchives
				if (empty(o.archive_show_note) || o.archive_show_note == '--') {
					o.has_note = false
					if (arch.open_edit_archive == 'yes') o.archive_show_note = 'Current auto-archive'
					else if (arch.archive_type == 'checkout') o.archive_show_note = '<i>Auto-archived prior to edits</i>'
					else if (arch.archive_type == 'prior_to_restore') o.archive_show_note = sr("Archived prior to restoring to archive from $1", this.date_string(arch.restored_from_timestamp))
				}
				o.archive_show_note = sr('<b>Archive:</b> $1<br>($2 - $3)', o.archive_show_note, this.date_string(arch.timestamp), o.email)

				try {
					o.json = U.flatten_extensions(JSON.parse(arch.entity_json))
				} catch(e) {
					console.log('error parsing json', arch, e)
					continue
				}
				arr.push(o)
			}

			// add a record for the current framework
			arr.push({
				email: '',
				source: 'CURRENT FRAMEWORK',
				archive_type: 'current',
				archive_show_note: '<b>Current framework version</b>',
				json: U.flatten_extensions(this.object),
			})

			// now go back through all the potential records assembled above
			let arr2 = []
			let last_json = null
			for (let o of arr) {
				// assume by default that there are no differences between this record and the last one processed
				let different = false

				// if this is the first record processed, we will always show it
				if (last_json == null) {
					o.header = 'Created '
					o.header_class = 'orange darken-2'
					o.updated_json = o.json
					different = true

				// else it's not the first record processed, so...
				} else {
					o.header = 'Updated '
					o.header_class = 'pink darken-2'
					o.updated_json = {}

					// add all updated props in the new json
					for (let prop in o.json) {
						if (JSON.stringify(o.json[prop]) != JSON.stringify(last_json[prop])) {
							// console.log(prop + ' different!', JSON.stringify(o.json[prop]), JSON.stringify(last_json[prop]))
							different = true
							// have to convert objects/arrays to strings here
							if (['educationLevel','conceptKeywords','subject'].includes(prop)) o.updated_json[prop] = U.case_array_html(o.json[prop])
							else if (['CFItemTypeURI','conceptKeywordsURI','licenseURI'].includes(prop)) o.updated_json[prop] = U.case_uri_html(o.json[prop])
							else if (['subjectURI'].includes(prop)) o.updated_json[prop] = U.case_array_of_uris_html(o.json[prop])
							else {
								// highlight differences if both are there, and if this isn't lastChangeDateTime
								if (!empty(last_json[prop]) && prop != 'lastChangeDateTime') {
									let obj = U.diff_string(last_json[prop], o.json[prop])
									o.updated_json[prop] = obj.n
								} else {
									o.updated_json[prop] = o.json[prop]
								}
							}

							// mark newly-added props as [ADDED]
							if (empty(last_json[prop])) o.updated_json[prop] = ' <b class="k-diff-new">[ADDED]</b> ' + o.updated_json[prop]
						}
					}

					// now go through all props in last_json to check for deleted props
					for (let prop in last_json) {
						if (!empty(last_json[prop]) && empty(o.json[prop])) {
							different = true
							o.updated_json[prop] = '<b class="k-diff-new">[DELETED]</b>'
						}
					}
				}

				// if anything changed, unshift onto arr2
				if (different) {
					// add lastChangeDateTime to header; use updated_json.lastChangeDateTime if lastChangeDateTime changed from last_json...
					if (o.updated_json.lastChangeDateTime) {
						o.header += U.local_last_change_date_time(o.updated_json.lastChangeDateTime)
					} else if (o.timestamp) {
						// or the archive date if this comes from an archive
						o.header += this.date_string(o.timestamp)
					} else {
						// if we get to here the item has been changed in the current editing instance, so take lastChangeDateTime from the object
						// ?? not totally sure if this is needed, but I don't think it hurts anything
						o.header += U.local_last_change_date_time(this.object.lastChangeDateTime)
					}

					o.revert_allowed = {}
					// determine all values that are different about this record compared to the *current version* of the object; we can potentially revert to these values
					// we don't currently allow for reverting documents
					if (this.object_type == 'item') {
						for (let prop in o.json) {
							let old_val = this.object[prop] ? JSON.stringify(this.object[prop]) : ''
							let new_val = o.json[prop] ? JSON.stringify(o.json[prop]) : ''
							if (new_val != old_val) {
								this.reverts_available = true
								if (this.editing_enabled) o.revert_allowed[prop] = true
							}
						}
						for (let prop in this.object) {
							if (!empty(this.object[prop]) && empty(o.json[prop])) {
								this.reverts_available = true
								if (this.editing_enabled) o.revert_allowed[prop] = true
							}
						}
					}

					arr2.unshift(o)
					// and set last_json
					last_json = o.json
				}
			}

			return arr2
		},
	},
	watch: {
	},
	created() {
		vapp.case_entity_history = this
	},
	mounted() {
		this.load_change_history()
	},
	methods: {
		load_change_history() {
			// uncomment this if we want to make it so that if we've already loaded the entity archives, just load them back in from the store
			// if (this.$store.state.entity_archives[this.object.identifier]) {
			// 	this.entity_archives = this.$store.state.entity_archives[this.object.identifier]
			// 	return
			// }

			// always retrive from the server
			let payload = {
				user_id: this.user_info.user_id,
				framework_identifier: this.framework_record.lsdoc_identifier,
				entity_type: this.object_type,
				entity_identifier: this.object.identifier,
				
			}
			U.loading_start()
			U.ajax('get_entity_archives', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') { console.log('Error in admin ajax call: ' + result.status); vapp.ping(); return; }

				console.log(result.entity_archives)
				this.entity_archives = result.entity_archives
				this.$store.commit('set', [this.$store.state.entity_archives, this.object.identifier, this.entity_archives])
			});
		},

		date_string(gmt_date) {
			if (gmt_date == -1) return ''
			return date.format(U.convert_to_local_date(gmt_date), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},

		revert_property(record, key) {
			console.log('revert: ', key, record.json[key])
			let flattened_object = U.flatten_extensions(this.object)
			let msg = sr('Are you sure you want to revert property <b>$1</b>?', key)
			msg += sr('<div class="mt-2 mb-1"><b>Current value:</b></div><div class="k-entity-history-confirm-display">$1</div>', (flattened_object[key] ?? '[empty]'))
			msg += sr('<div class="mt-4 mb-1"><b>Will be reverted to value:</b></div><div class="k-entity-history-confirm-display">$1</div>', (record.json[key] ?? '[empty]'))
			this.$confirm({
				title: 'Revert Property: Confirm',
				text: msg,
				acceptText: 'Revert Property',
				dialogMaxWidth: 800,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				// TODO: below code is for items; have to do the analogous changes for the document node if we want to implement this for documents
				// reconstruct json from this.object, then change the reverted property and set lastChangeDateTime to *NOW*
				let json_to_save = new CFItem(this.object).to_json()
				// deal with extensions
				if (CFItem.satchel_extension_fields?.includes(key)) {
					if (empty(record.json[key])) {
						// assuming json_to_save has extensions (it should if the key field is non-empty)...
						if (json_to_save.extensions) {
							// delete this extension
							delete json_to_save.extensions[key]
							// and if extensions is then empty, delete extensions altogether
							if (Object.keys(json_to_save.extensions).length == 0) json_to_save.extensions = '*CLEAR*'
						}
					} else {
						if (!json_to_save.extensions) json_to_save.extensions = {}
						json_to_save.extensions[key] = record.json[key]
					}
				} else {
					if (empty(record.json[key])) {
						json_to_save[key] = '*CLEAR*'
					} else {
						json_to_save[key] = record.json[key]
					}
				}
				json_to_save.lastChangeDateTime = '*NOW*'

				let data = {
					update_item_types: true,	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
					lsdoc_identifier: this.framework_record.lsdoc_identifier,
					CFItems: [
						json_to_save
					],
					CFAssociations: [],
				}

				// if the humancodingscheme or fullstatement changed, update CFAssociations.nodeURI.title values if necessary
				let cfas = U.find_all_is_child_of_associations(this.framework_record.json.CFAssociations, json_to_save.identifier)
				let new_cfa_title = U.generate_cfassociation_node_uri_title(json_to_save)
				for (let cfa of cfas) {
					if (cfa.originNodeURI.title != new_cfa_title) {
						this.$store.commit('set', [cfa.originNodeURI, 'title', new_cfa_title])
						data.CFAssociations.push(new CFAssociation(cfa).to_json())
					}
				}

				this.$store.dispatch('save_framework_data', data).then(()=>{
					// transfer lastChangeDateTime to the updated item's json, and convert '*CLEAR*' back to empty
					json_to_save.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					if (json_to_save[key] === '*CLEAR*') delete json_to_save[key]

					console.log(json_to_save)

					// update the CFItem in the framework json (we know it exists, because we're definitionally reverting a property on an existing item
					let index = this.framework_record.json.CFItems.findIndex(x=>x.identifier == json_to_save.identifier)
					this.$store.commit('set', [this.framework_record.json.CFItems, 'SPLICE', index, json_to_save])

					// transfer the updated property to the cfitem's nodes
					// note: I think all nodes link to the same cfitem object; but it won't hurt to do this as a for loop
					for (let node of this.framework_record.cfo.cfitems[json_to_save.identifier].tree_nodes) {
						if (CFItem.satchel_extension_fields?.includes(key)) {
							if (empty(json_to_save.extensions[key])) this.$store.commit('set', [node.cfitem.extensions, key, '*DELETE_FROM_STORE*'])
							else this.$store.commit('set', [node.cfitem.extensions, key, json_to_save.extensions[key]])
						} else {
							if (empty(json_to_save[key])) this.$store.commit('set', [node.cfitem, key, '*DELETE_FROM_STORE*'])
							else this.$store.commit('set', [node.cfitem, key, json_to_save[key]])
						}

						// also remember to update lastChangeDateTime
						this.$store.commit('set', [node.cfitem, 'lastChangeDateTime', json_to_save.lastChangeDateTime])
					}

					// reset entity_archives so they'll be reloaded when we re-open change history for entities
					this.$store.commit('set', ['entity_archives', {}])

					// and close the dialog
					this.$emit('dialog_cancel')
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		check_out_for_editing() { vapp.case_tree_component.check_out_for_editing() },
	}
}
</script>

<style lang="scss">
.k-entity-history-record-outer {
	padding-bottom:20px;
}

.k-entity-history-record-header {
	display:flex;
	color:#fff;
	padding:4px 8px;
	border-radius:8px;
	margin-bottom:4px;
}

.k-entity-history-confirm-display {
	border:1px solid #666;
	padding:4px;
	margin-left:12px;
	border-radius:6px;
}
</style>
