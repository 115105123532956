var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"page_container",style:({ width: _vm.viewport.width + 'px', height: _vm.viewport.height + 'px' })},[_c('canvas',{ref:"page_render",staticStyle:{"{ position":"'absolute', left: '0px' }"}}),_vm._v(" "),_vm._l((_vm.grouped_items),function(item,index){return _c('div',{key:index,staticClass:"item",style:({ 
            left: item.transform[4] + 'px', 
            bottom: item.transform[5] - 4 + 'px', 
            width: item.width + 2 + 'px',
            height: item.height + 2 + 'px',
            backgroundColor: item.color }),on:{"click":function($event){return _vm.$emit('item_selected', item)}}})}),_vm._v(" "),(_vm.open && _vm.drag_select.dragging)?_c('div',{ref:"drag_preview",style:({
			'border-style': 'dashed',
            'border-color': 'gray', 
            'background-color': 'rgba(0, 0, 0, .1)', 
			position: 'absolute', 
			left: _vm.drag_select.x1 + 'px', 
			bottom: _vm.drag_select.y1 + 'px', 
			width: _vm.drag_select.x2 - _vm.drag_select.x1 + 'px', 
			height: _vm.drag_select.y2 - _vm.drag_select.y1 + 'px'
		})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }