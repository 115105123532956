<template><v-dialog v-model="dialog_open" max-width="95vw" persistent scrollable><v-card>
	<v-card-title style="border-bottom:1px solid #999"><b>Batch Import Items from PDF</b>
		<v-spacer/>
		<v-btn color="primary" @click="get_pdf_file" style="z-index: 1" v-if="pdf_open"><v-icon small class="mr-2">fas fa-file-pdf</v-icon>Switch PDF</v-btn>
	</v-card-title>
	<v-card-text class="mt-3 k-pdf-import-interface-wrapper" style="font-size:16px">
		<div class="mt-2 text-center">
			<v-btn color="primary" @click="get_pdf_file" style="z-index: 1" v-if="!pdf_open"><v-icon small class="mr-2">fas fa-file-pdf</v-icon>Choose PDF</v-btn>
		</div>
		<div style="display: flex; justify-content: center; z-index: -1;" v-show="pdf_open">
			<div>

				<div class="d-flex">
					<v-btn color="secondary" @click="current_page_number--"><v-icon>fas fa-caret-left</v-icon></v-btn>
					<v-slider v-model="current_page_number" min="1" :max="Object.keys(pdf_pages).length" step="1" class="pt-4 px-1" track-color="primary" color="secondary" dense hide-details thumb-label="always" thumb-size="22" style="max-height: 30px;"/>
					<v-btn color="secondary" @click="current_page_number++" style="float: right"><v-icon>fas fa-caret-right</v-icon></v-btn>
				</div>
				
				<div ref="pdf_preview">
					<PdfPreviewPage v-for="(pdf_page, page_number) in grouped_item_pages" :key="page_number" :page_number="Number(page_number)" :grouped_items="pdf_page.grouped_items" :viewport="pdf_page.viewport" :api_pdf_page="pdf_page.api_pdf_page" :open="pdf_page.open" :drag_select="drag_select" v-show="pdf_page.open"
					@item_selected="item_selected"/>
				</div>
			</div>
			<div class="d-flex flex-column" :style="{'max-height': pdf_pages[current_page_number] ? pdf_pages[current_page_number].viewport.height + 45 + 'px' : ''}">
				<v-btn-toggle style="margin-left: 10px;" v-model="current_tool" @change="() => {color_items(current_page_number); property_selected(undefined)}" mandatory>
					<v-btn value="rule_set" style="font-size: 13.5px;">Save</v-btn>
					<v-btn value="group" style="font-size: 13.5px;">Group</v-btn>
					<v-btn value="include/exclude" style="font-size: 13.5px;">Include</v-btn>
					<v-btn value="output" style="font-size: 13.5px;">Structure</v-btn>
					<v-btn value="styles" style="font-size: 13.5px;">Styles</v-btn>
					<v-btn value="info" style="font-size: 13.5px;">Info</v-btn>
				</v-btn-toggle>
				<!--v-card class="tool_card mb-0" v-show="current_tool == 'include/exclude'">
					<v-textarea v-model="selected_property.value" :label="'value'" rows="1" v-if="selected_property" class="currentProperty" auto-grow hide-details outlined dense style="font-family: monospace;"/>
				</v-card-->
				<v-card class="tool_card" v-show="current_tool == 'group'">
					<div style="display: flex; flex-direction: column;">
						<draggable ref="groupRulesList" v-model="group_rules">
							<PdfRule v-for="(rule, index) in group_rules" :key="rule.id" :type="rule.type" :properties.sync="rule.properties" :extension_properties.sync="rule.extension_properties" :property_types="['Aside', 'Same', 'Offset', 'Area', 'Pages', 'Regex', 'Anti Regex', 'Size', 'Font', 'Left', 'Bottom']" :extension_property_types="['Regex', 'Area', 'Next', 'Size', 'Font', 'Left', 'Bottom', 'Aside', 'Same', 'Offset']" :extension_option.sync="rule.extension_option" :collapsed.sync="rule.collapsed" @property_selected="property_selected" @remove_property="(property) => {rule.properties = rule.properties.filter((p) => p != property)}" @remove_extension_property="(extension_property) => {rule.extension_properties = rule.extension_properties.filter((p) => p != extension_property)}" @remove_me="group_rules.splice(index, 1)"/>
						</draggable>
						<span style="border-top-style: dotted; border-color: lightgray; margin-bottom: 5px;"></span>
						<v-btn @click="group_rules.push(new_rule('group'))" depressed style="background-color: lightblue">+ Add Grouping Rule</v-btn>
					</div>
				</v-card>
				<v-card class="tool_card" v-show="current_tool == 'info'">
					<div><b>Text: </b><span ref="stringInfo"></span></div>
					<div><b>Font: </b><span ref="fontInfo"></span></div>
					<div><b>Size: </b><span ref="sizeInfo"></span></div>
					<div><b>Left: </b><span ref="leftInfo"></span></div>
					<div><b>Bottom: </b><span ref="bottomInfo"></span></div>
					<div><b>Width: </b><span ref="widthInfo"></span></div>
					<div><b>Height: </b><span ref="heightInfo"></span></div>
				</v-card>
				<v-card class="tool_card" v-show="current_tool == 'rule_set'">
					<div class="d-flex">
						<v-select :label="'Choose a rule set'" :items="rule_set_options" v-model="rule_set_name" @click="rule_sets = $store.state.lst.PDF_import_interface_rule_sets" hide-details outlined dense/>
						<v-spacer style="width: 5px;"/>
						<v-btn color="orange lighten-2" @click="delete_current_rule_set" depressed style="">Delete</v-btn>
					</div>
					<v-btn class="mt-2" color="blue lighten-3" @click="import_rule_set" block depressed style="">Import Rule Set</v-btn>
					<v-btn class="mt-2" color="purple lighten-3" @click="export_current_rule_set" block depressed style="">Export Rule Set</v-btn>
				</v-card>
				<v-card class="tool_card" v-show="current_tool == 'include/exclude'">
					<div style="display: flex; flex-direction: column;">
						<draggable ref="rulesList" v-model="rules">
							<PdfRule v-for="(rule, index) in rules" :key="rule.id" :type="rule.type" :properties.sync="rule.properties" :extension_properties.sync="rule.extension_properties" :property_types="['Regex', 'Anti Regex', 'Area', 'Pages', 'Size', 'Font', 'Left', 'Bottom']" :extension_property_types="['Regex', 'Area', 'Next', 'Size', 'Font', 'Left', 'Bottom', 'Aside', 'Same', 'Offset']" :extension_option.sync="rule.extension_option" :collapsed.sync="rule.collapsed" @property_selected="property_selected" @remove_property="(property) => {rule.properties = rule.properties.filter((p) => p != property)}" @remove_extension_property="(extension_property) => {rule.extension_properties = rule.extension_properties.filter((p) => p != extension_property)}" @remove_me="rules.splice(index, 1)"/>
						</draggable>
						<span style="border-top-style: dotted; border-color: lightgray; margin-bottom: 5px;"></span>
						<v-btn @click="rules.push(new_rule('include'))" depressed style="margin-bottom: 10px; background-color: lightgreen;">+ Add Include Rule</v-btn>
						<v-btn @click="rules.push(new_rule('exclude'))" depressed style="background-color: pink">+ Add Exclude Rule</v-btn>
					</div>
				</v-card>
				<v-card class="tool_card" v-show="current_tool == 'output'">
					<div class="d-flex">
						<div style="padding: 7px 0;">From page</div>
						<v-text-field type="number" v-model="output_options.start_page" style="margin: 0 5px; max-width: 70px;" hide-details outlined dense/>
						<div style="padding: 7px 0;">to</div>
						<v-text-field type="number" v-model="output_options.end_page" style="margin: 0 5px; max-width: 70px;" hide-details outlined dense/>
					</div>
					<v-spacer class="my-2"/>
					<div style="display: flex; flex-direction: column;">
						<draggable v-model="framework_item_rules">
							<div v-for="(rule, index) in framework_item_rules" @click="highlight_framework_item_rule(rule)" class="mb-2 px-2 pt-2 pb-1" style="border-radius: 8px; background-color: rgba(0, 0, 0, .075);">
								<div class="d-flex mb-1" :style="{'margin-left': rule.level * 30 + 'px'}">
									<v-icon class="ml-1 my-2" @click="()=>{if (rule.level > 0) rule.level--}" :style="{color: rule.highlighted ? 'yellow' : ''}">fas fa-caret-left</v-icon>
									<v-icon class="mr-2 my-2" @click="rule.level++" :style="{color: rule.highlighted ? 'yellow' : ''}">fas fa-caret-right</v-icon>
									<v-text-field :label="'item type'" v-model="rule.prefix" hide-details outlined dense style="font-family: monospace; background-color: rgba(255, 255, 255, .875); margin-right: 2px;"/>
									<v-select :label="'options'" multiple :items="['Starts With Code', 'NOTES', 'Break Lines', 'Sort Children', 'Fix Case']" v-model="rule.options" append-icon="" hide-details outlined dense style="font-family: monospace; background-color: rgba(255, 255, 255, .875);"/>
								</div>
								<PdfRule :type="rule.rule.type" :properties.sync="rule.rule.properties" :extension_properties.sync="rule.rule.extension_properties" :property_types="['Regex', 'Anti Regex', 'Area', 'Size', 'Font', 'Left', 'Bottom']" :extension_property_types="['Regex', 'Area', 'Next', 'Size', 'Font', 'Left', 'Bottom', 'Aside', 'Same', 'Offset']" :extension_option.sync="rule.rule.extension_option" @properties="(properties) => rule.rule.properties = properties" :collapsed.sync="rule.collapsed" @property_selected="property_selected" @remove_property="(property) => {rule.rule.properties = rule.rule.properties.filter((p) => p != property)}" @remove_extension_property="(extension_property) => {rule.rule.extension_properties = rule.rule.extension_properties.filter((p) => p != extension_property)}" @remove_me="framework_item_rules.splice(index, 1)"></PdfRule>
							</div>
						</draggable>
						<span style="border-top-style: dotted; border-color: lightgray; margin-bottom: 5px;"></span>
						<v-btn @click="() => {framework_item_rules.push({level: (framework_item_rules.length > 0) ? (framework_item_rules[framework_item_rules.length-1].level + 1) : 0, prefix: '', rule: new_rule('component'), highlighted: true, option: []}); highlight_framework_item_rule(framework_item_rules[framework_item_rules.length - 1])}" color="yellow" depressed>+ Add Item</v-btn>
					</div>
				</v-card>
				<v-card class="tool_card" v-show="current_tool == 'styles'">
					<div style="display: flex; flex-direction: column;">
						<draggable v-model="style_rules">
							<div v-for="(rule, index) in style_rules" @click="highlight_style_rule(rule)" class="mb-2 px-2 pt-2 pb-1" style="border-radius: 8px; background-color: rgba(0, 0, 0, .075);">
								<div class="d-flex mb-1" :style="{'margin-left': rule.level * 30 + 'px'}">
									<v-select :label="'options'" multiple :items="['Bold', 'Italic', 'List Item']" v-model="rule.options" append-icon="" hide-details outlined dense style="font-family: monospace; background-color: rgba(255, 255, 255, .875);"/>
								</div>
								<PdfRule :type="rule.rule.type" :properties.sync="rule.rule.properties" :extension_properties.sync="rule.rule.extension_properties" :property_types="['Regex', 'Anti Regex', 'Area', 'Size', 'Font', 'Left', 'Bottom']" :extension_property_types="['Regex', 'Area', 'Next', 'Size', 'Font', 'Left', 'Bottom', 'Aside', 'Same', 'Offset']" :extension_option.sync="rule.rule.extension_option" @properties="(properties) => rule.rule.properties = properties" :collapsed.sync="rule.collapsed" @property_selected="property_selected" @remove_property="(property) => {rule.rule.properties = rule.rule.properties.filter((p) => p != property)}" @remove_extension_property="(extension_property) => {rule.rule.extension_properties = rule.rule.extension_properties.filter((p) => p != extension_property)}" @remove_me="style_rules.splice(index, 1)"></PdfRule>
							</div>
						</draggable>
						<span style="border-top-style: dotted; border-color: lightgray; margin-bottom: 5px;"></span>
						<v-btn @click="() => {style_rules.push({rule: new_rule('style'), highlighted: true, option: []}); highlight_style_rule(style_rules[style_rules.length - 1])}" color="orange" depressed>+ Add Styling</v-btn>
					</div>
				</v-card>
			</div>
		</div>
	</v-card-text>
	<v-card-actions class="pa-3 d-flex" style="border-top:1px solid #999">
		<v-btn color="secondary" @click="$emit('cancel_import')">Close</v-btn>
		<v-spacer/>
		<v-btn color="primary" @click="U.copy_to_clipboard(filtered_contents())" :loading="copying_contents" class="k-tight-btn k-nocaps-btn" v-if="pdf_open"><v-icon small class="mr-2">fas fa-copy</v-icon>Copy Processed Contents to Clipboard</v-btn>
		<v-btn color="primary" @click="$emit('paste_contents', filtered_contents())" class="k-tight-btn k-nocaps-btn" v-if="pdf_open"><v-icon small class="mr-2">fas fa-paste</v-icon>Paste Processed Contents to Import</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'
import draggable from "vuedraggable"
import PdfPreviewPage from './PdfPreviewPage.vue'
import PdfRule from './PdfRule.vue'
import CopyBtn from '../utilities/CopyBtn.vue'

import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.js"
import workerSrc from "pdfjs-dist/legacy/build/pdf.worker.entry"
import { match } from 'assert'
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc

// TODO: Use vuetify components, make rule sets changeable and remember last rule set used in each pdf


var next_rule_id = 0

// SEE FOR REFERENCE: ItemImportInterface, ItemCSVImportInterface
export default {
	components: {
		draggable,
		PdfPreviewPage, 
    	PdfRule,
		CopyBtn
	},
	props: {
		editor_component: { required: true },
	},
	data() { return {
		dialog_open: true,
		pdf_name: "",
		pdf_open: false,
		rule_sets: {},
		rule_set_name: undefined,
		last_rule_set_name: undefined,
		current_page_number: 1,
		pdf_pages: {},
		current_tool: "include",
		rules: [],
		group_rules: [],
		drag_select: {
			dragging: false,
			start_x: 0,
			start_y: 0,
			x1: 0,
			y1: 0,
			x2: 0,
			y2: 0
		},
		selected_property: undefined,
		grouping_options: {
			preset: "paragraph",
			rules: []
		},
		output_options: {			
			start_page: 1,
			end_page: 1
		},
		framework_item_rules: [],
		style_rules: [],
		copying_contents: false
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),

		rule_set_options() {
			let options = []
			for (let name in this.rule_sets) {
				if (this.rule_sets[name] != undefined) options.push(name)
			}
			options.sort()
			options.push("+ New Rule Set")
			return options
		},
		
        grouped_item_pages() {
			for (let page_number in this.pdf_pages) {
				let pdf_page = this.pdf_pages[page_number]

				// assign a group_index to each item as it's reference to what group it was sorted into

				let _copied_items = JSON.parse(JSON.stringify(pdf_page.items))
				
				let _next_group_index = 0
				if (_copied_items[0] != undefined) _copied_items[0].group_index = _next_group_index++
				for (let i = 1; i < _copied_items.length; i++) {
					let item = _copied_items[i]
					let matched = false
					for (let j = i - 1; j >= 0; j--) {
						let compare_item = _copied_items[j]
						if (compare_item.group_index == undefined) continue
						if (compare_item.group_index != (_next_group_index - 1)) continue
						// height should match
						// font should match
						// if side by side, other position should match exactly
						let req_matches = true//((compare_item.fontName == item.fontName))
						if (req_matches) {
							let matches = false
							// go through grouping rules
							for (let group_rule of this.group_rules) {
								if (group_rule.matches(compare_item, item)) {
									matches = true
									continue
								}
							}
							// if the items match with any rule then they should be grouped
							if (matches) {
								item.group_index = compare_item.group_index
								matched = true
								continue
							}

						}
					}
					if (!matched && item.str != "" && item.str != " ") {
						// console.log(item, _next_group_index)
						item.group_index = _next_group_index++
					}
					
				}
				let _grouped_items = []
				for (let item of _copied_items) if (item.group_index != undefined) {
					if (_grouped_items[item.group_index] == undefined) {
						_grouped_items[item.group_index] = item
					}
					else {
						_grouped_items[item.group_index] = this.combine_items(_grouped_items[item.group_index], item, true)
					}
				}
				_grouped_items = _grouped_items.filter((item) => { return (item != undefined) })
				pdf_page.grouped_items = _grouped_items
			}
			window.setTimeout(() => this.color_items(this.current_page_number), 10)
			return this.pdf_pages
        }
	},
	watch: {
		current_page_number() {
			if (this.current_page_number == undefined || Object.keys(this.pdf_pages).length < 1) return
			else if (this.current_page_number < 1) {
				this.current_page_number = 1
			}
			else if (this.current_page_number > Object.keys(this.pdf_pages).length) {
				this.current_page_number = Object.keys(this.pdf_pages).length
			}
			else {
				this.color_items(this.current_page_number)
				for (let page_number in this.pdf_pages) {
					this.pdf_pages[page_number].open = page_number == this.current_page_number
				}
			}
			this.save_pdf_info()
		},
		rule_set_name() {
			// edit rule -> switch rule sets -> switch back -> doesn't work
			// edit rule -> close window -> open again -> all good
			// edit rule -> switch rule sets -> close -> open again -> open again
			// problem is not swtiching alone
			// problem only arises when switching back after changing
			//  settings saved -> current settings switched to other from watcher -> original saved settings not loaded in properly
			// PROBLEM JUST HAS TO DO WITH SETTINGS NOT BEING SAVED CORRECTLY
			// Settings get corrupted somehow between switching to another set and switching back

			if (this.rule_set_name == "+ New Rule Set") {
				this.$prompt({
					title: "New Rule Set",
					text: "Your current changes will be carried over to this new rule set. Name your new rule set below.",
					acceptText: "Create"
				}).then(new_rule_set_name => {
					this.rule_set_name = new_rule_set_name
					this.save_rules()
					this.rule_sets = this.$store.state.lst.PDF_import_interface_rule_sets
				}).catch(n => {
					this.rule_set_name = this.last_rule_set_name
				})
			}
			else if (this.rule_set_name != undefined && Object.keys(this.rule_sets).includes(this.rule_set_name)) { // switched to already existing rule set
				if (this.last_rule_set_name != undefined) {
					this.update_rules()
				}
				else this.$confirm({
					title: "Progress Unsaved",
					text: "You have unsaved changes. Are you sure you would like to switch?",
					acceptText: "Switch"
				}).then(() => {
					this.update_rules()
				}).catch(n => {
					this.rule_set_name = this.last_rule_set_name
				})
				
			}
			
			this.save_pdf_info()
		},
		rules: {
			handler() {
				this.color_items(this.current_page_number)
				this.save_rules()
			},
			deep: true
		},
		group_rules: {
			handler() {
				this.color_items(this.current_page_number)
				this.save_rules()
			},
			deep: true
		},
		framework_item_rules: {
			handler() {
				this.color_items(this.current_page_number)
				this.save_rules()
			},
			deep: true
		},
		style_rules: {
			handler() {
				this.color_items(this.current_page_number)
				this.save_rules()
			},
			deep: true
		},
		output_options: {
			handler() {
				this.color_items(this.current_page_number)
				this.save_rules()
			},
			deep: true
		}
	},
	created() {
		vapp.pdf_importer = this

		// check for last opened pdf
		let last_opened_pdf = this.$store.state.PDF_import_interface_last_pdf_data
		if (last_opened_pdf != null) {
			this.load_pdf_file(last_opened_pdf.data, last_opened_pdf.name)
		}
	},
	mounted() {
		this.$refs.pdf_preview.addEventListener("mousedown", e => {
			if (this.selected_property != undefined && this.selected_property.type == "Area") {
				this.drag_select.dragging = true

				let bounding_box = this.$refs.pdf_preview.getBoundingClientRect()
				this.drag_select.start_x = Math.round(e.clientX - bounding_box.left)
				this.drag_select.start_y = Math.round(bounding_box.height - (e.clientY - bounding_box.top))
				this.drag_select.x1 = this.drag_select.start_x
				this.drag_select.y1 = this.drag_select.start_y
				this.drag_select.x2 = this.drag_select.start_x
				this.drag_select.y2 = this.drag_select.start_y
			}
		})

		document.addEventListener("mousemove", e => {
			if (this.drag_select.dragging && this.selected_property != undefined && this.selected_property.type == "Area") {
				
				let bounding_box = this.$refs.pdf_preview.getBoundingClientRect()
				let mouse_x = Math.round(e.clientX - bounding_box.left)
				let mouse_y = Math.round(bounding_box.height - (e.clientY - bounding_box.top))
				
				if (this.drag_select.start_x < mouse_x) {
					this.drag_select.x1 = this.drag_select.start_x
					this.drag_select.x2 = mouse_x
				}
				else {
					this.drag_select.x1 = mouse_x
					this.drag_select.x2 = this.drag_select.start_x
				}
				if (this.drag_select.start_y < mouse_y) {
					this.drag_select.y1 = this.drag_select.start_y
					this.drag_select.y2 = mouse_y
				}
				else {
					this.drag_select.y1 = mouse_y
					this.drag_select.y2 = this.drag_select.start_y
				}
			}
		})
		
		document.addEventListener("mouseup", e => {
			if (this.drag_select.dragging && this.selected_property != undefined && this.selected_property.type == "Area") {
				this.selected_property.value = this.drag_select.x1 + " " + this.drag_select.y1 + ":" + this.drag_select.x2 + " " + this.drag_select.y2
				this.selected_property.selected = false
				this.selected_property = undefined
			}
			this.drag_select.dragging = false
		})
	},
	methods: {
		get_pdf_file() {
			this.$prompt({
				title: 'Choose PDF',
				text: 'Choose the PDF with items (e.g. standards, elements, and “folders”) you wish to import.',
				promptType: 'file',
				acceptText: 'Choose File',
				dialogMaxWidth: 550,
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()

				//////////////////////////////////
				// Read file and parse PDF
				reader.onload = e => {
					console.log(file)
					this.load_pdf_file(e.target.result, file.name)
				}
				// trigger the FileReader to load the file
				reader.readAsBinaryString(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
		load_pdf_file(file, filename) {
			window.setTimeout(async () => {
				
				// parse e.target.result...
				this.pdf_name = filename
				this.$store.commit('set', ['PDF_import_interface_last_pdf_data', {data: file, name: filename}])

				this.pdf_open = true
				const loadingTask = pdfjsLib.getDocument({data: file})
				const pdf = await loadingTask.promise

				let numPages = pdf._pdfInfo.numPages

				this.output_options.start_page = 1
				this.output_options.end_page = numPages

				// create a new set of page objects
				let new_pages = {}

				// console.log(await pdf.getMetadata())
				// const page1 = await pdf.getPage(2)
				//console.log(await page1.getStructTree())
				//console.log(await page1.getTextContent())

				for (let i = 1; i <= numPages; i++) {
						
					const page = await pdf.getPage(i)
					const page_text = await page.getTextContent({normalizeWhitespace: true})
					const page_viewport = page.getViewport({scale: 1.0})
					
					
					let items = page_text.items
					for (let item of items) {
						item.original_height = item.height
						item.color = "lightblue"
					}
					// console.log(JSON.parse(JSON.stringify(items)))

					new_pages[i] = {
						items,
						grouped_items: items,
						viewport: page_viewport,
						api_pdf_page: page,
						open: i == this.current_page_number
					}

					// console.log(JSON.parse(JSON.stringify(items)))

				}

				this.pdf_pages = {}
				window.setTimeout(() => {
					this.pdf_pages = new_pages

					let pdf_save_info = this.$store.state.lst.PDF_import_interface_pdf_data
					console.log(pdf_save_info)
					if (Object.keys(pdf_save_info).includes(this.pdf_name)) {
						this.current_page_number = pdf_save_info[this.pdf_name].last_page_number
						this.last_rule_set_name = "" // change from undefined so it doesn't worry about unsaved changes
						this.rule_set_name = pdf_save_info[this.pdf_name].rule_set
						console.log(this.rule_set_name)
					}
					else this.current_page_number = 1

					this.pdf_open = true;
					this.color_items(this.current_page_number)

					let rule_sets = this.$store.state.lst.PDF_import_interface_rule_sets
					this.rule_sets = rule_sets



				}, 0)

			})

		},
		delete_current_rule_set() {
			this.$confirm({
				title: "Delete Rule Set",
				text: "Are you sure you would like to delete '" + this.rule_set_name + "'?",
				acceptText: "Delete"
			}).then(() => {
				this.$store.commit('lst_set_hash', ['PDF_import_interface_rule_sets', this.rule_set_name, undefined])
				this.rule_sets = this.$store.state.lst.PDF_import_interface_rule_sets
				this.rule_set_name = undefined
				this.last_rule_set_name = this.rule_set_name
			})
		},
		import_rule_set() {
			this.$prompt({
				title: 'Choose Exported Rule Set (.json)',
				text: 'Filename Should Match As Follows: My Rule Set(M_DD_YYYY).json\nWARNING: Rule Sets With Same Name Will Be Overwritten',
				promptType: 'file',
				acceptText: 'Import',
				dialogMaxWidth: 550,
			}).then(file => {
				let filereader = new FileReader()
				
				filereader.onload = e => {
					let name = file.name.slice(0, file.name.slice(0, -14).lastIndexOf("("))
					let json = JSON.parse(e.target.result)
					this.rule_sets[name] = json
					this.rule_set_name = name
					this.save_rules()
					console.log(name, json, file.name.slice(0, -14))
				}
				filereader.readAsText(file)
			})
		},
		export_current_rule_set() {
			this.$confirm({
				title: "Export Rule Set",
				text: "Export a copy of the rule set '" + this.rule_set_name + "'?",
				acceptText: "Download"
			}).then(() => {
				let json = this.$store.state.lst.PDF_import_interface_rule_sets[this.rule_set_name]
				U.download_json_file(json, this.rule_set_name + "(" + new Date(Date.now()).toLocaleDateString() + ")")
			})
		},
		new_rule(type, assigned_properties, assigned_extension_properties, assigned_extension_option) {
			return {
				type,
				id: next_rule_id++,
				properties: assigned_properties || [],
				extension_properties: assigned_extension_properties || [],
				extension_option: assigned_extension_option || "Match Both",
				collapsed: false,
				matches(item_1, item_2, page_number) {
					// for grouping: item_1 is compare_item, item_2 is current_item
					// for including: item_1 is undefined, item_2 is the item to be tested
					
					let matches = this.properties.length > 0 // prove this wrong
					for (let property of this.properties) {
						let transform_index = (property.direction == "Horizontal") ? 4 : 5
						switch(property.type) {
							case "Left": 
								if (Math.abs(item_2.transform[4] - property.value) > property.match_range) matches = false
								break
							case "Bottom": 
								if (Math.abs(item_2.transform[5] - property.value) > property.match_range) matches = false
								break
							case "Size": 
								if (Math.abs(item_2.original_height - property.value) > property.match_range) matches = false
								break
							case "Font": 
								if (item_2.fontName != property.value) matches = false
								break
							case "Regex": 
								try {
									let regex_string = property.value
									let in_brackets = false
									for (let i = regex_string.length; i >= 0; i--) {
										if (regex_string[i] == "]") in_brackets = true
										if (regex_string[i] == "[") in_brackets = false
										if (in_brackets || (i > 0 && regex_string[i-1] == "\\") || (i < regex_string.length && (regex_string[i] == "?" || regex_string[i] == "*" || regex_string[i] == "+" || regex_string[i] == "-"))) continue
										regex_string = regex_string.slice(0, i) + "\\s*" + regex_string.slice(i)
									}
									if (property.regex_perfect_match == "Perfect") regex_string = `^\\s*${regex_string}\\s*$`
									let regex = new RegExp(regex_string) // ^\s*...\s*$
									// check if regex matches item_2.str
									if (!regex.test(item_2.str)) matches = false;
								} catch(e) {
									// invalid regex
									matches = false
								}
								break
							case "Anti Regex": 
								try {
									let regex_string = property.value
									let in_brackets = false
									for (let i = regex_string.length; i >= 0; i--) {
										if (regex_string[i] == "]") in_brackets = true
										if (regex_string[i] == "[") in_brackets = false
										if (in_brackets || (i > 0 && regex_string[i-1] == "\\") || (i < regex_string.length && (regex_string[i] == "?" || regex_string[i] == "*" || regex_string[i] == "+" || regex_string[i] == "-"))) continue
										regex_string = regex_string.slice(0, i) + "\\s*" + regex_string.slice(i)
									}
									if (property.regex_perfect_match == "Perfect") regex_string = `^\\s*${regex_string}\\s*$`
									let regex = new RegExp(regex_string) // ^\s*...\s*$
									// check if regex matches item_2.str
									if (regex.test(item_2.str)) matches = false;
								} catch(e) {
									// invalid regex
									matches = false
								}
								break
							case "Pages": 
								try {
									// ex: 5-6,54-2, 9-8
									let value = property.value
									let in_pages = false
									let next_comma_index
									let counter = 0
									while (!in_pages && next_comma_index != -1 && counter < 500) {
										next_comma_index = value.indexOf(",")
										let current_value = value
										if (next_comma_index != -1) current_value = value.slice(0, next_comma_index)
										if (
											page_number >= Number(current_value.slice(0, current_value.indexOf("-"))) && 
											Number(current_value.slice(current_value.indexOf("-") + 1)) >= page_number
										) in_pages = true
										value = value.slice(next_comma_index + 1)
										counter++
									}
									if (!in_pages) matches = false
								}
								catch {
									matches = false
								}
								break
							case "Area": 
								try {
									let value = property.value
									let x1 = Number(value.slice(0, value.indexOf(" ")))
									let y1 = Number(value.slice(value.indexOf(" ") + 1, value.indexOf(":")))
									let x2 = Number(value.slice(value.indexOf(":") + 1, value.lastIndexOf(" ")))
									let y2 = Number(value.slice(value.lastIndexOf(" ") + 1))
									let item_x = item_2.transform[4] + item_2.width / 2
									let item_y = item_2.transform[5] + item_2.height / 2
									if (item_x < x1 || x2 < item_x || item_y < y1 || y2 < item_y) matches = false
								}
								catch {
									matches = false
								}
								break
							case "Aside": 
								if ((property.direction == "Horizontal") ? (Math.abs(item_1.transform[4] + item_1.width - item_2.transform[4]) > property.match_range) : (Math.abs(item_2.transform[5] + item_2.height - item_1.transform[5]) > property.match_range)) matches = false
								break
							case "Same": 
								if (Math.abs(item_1.transform[transform_index] - item_2.transform[transform_index]) > property.match_range) matches = false
								break
							case "Offset": 
								if (Math.abs(item_1.transform[transform_index] + Number(property.value) - item_2.transform[transform_index]) > property.match_range) matches = false
								break
							default:  // if not one of the above types
								matches = false
								break
						}
					}
					return matches
				},
				extension_matches(matched_item, compare_item, page_items) {

					let matches = this.extension_properties.length > 0 // prove this wrong
					for (let property of this.extension_properties) {
						let transform_index = (property.direction == "Horizontal") ? 4 : 5
						switch(property.type) {
							case "Left": 
								if (Math.abs(compare_item.transform[4] - property.value) > property.match_range) matches = false
								break
							case "Bottom": 
								if (Math.abs(compare_item.transform[5] - property.value) > property.match_range) matches = false
								break
							case "Size": 
								if (Math.abs(compare_item.original_height - property.value) > property.match_range) matches = false
								break
							case "Font": 
								if (compare_item.fontName != property.value) matches = false
								break
							case "Regex": 
								try {
									let regex_string = property.value
									let in_brackets = false
									for (let i = regex_string.length; i >= 0; i--) {
										if (regex_string[i] == "]") in_brackets = true
										if (regex_string[i] == "[") in_brackets = false
										if (in_brackets || (i > 0 && regex_string[i-1] == "\\") || (i < regex_string.length && (regex_string[i] == "?" || regex_string[i] == "*" || regex_string[i] == "+" || regex_string[i] == "-"))) continue
										regex_string = regex_string.slice(0, i) + "\\s*" + regex_string.slice(i)
									}
									if (property.regex_perfect_match == "Perfect") regex_string = `^\\s*${regex_string}\\s*$`
									let regex = new RegExp(regex_string) // ^\s*...\s*$
									// check if regex matches compare_item.str
									if (!regex.test(compare_item.str)) matches = false;
								} catch(e) {
									// invalid regex
									matches = false
								}
								break
							case "Anti Regex": 
								try {
									let regex_string = property.value
									let in_brackets = false
									for (let i = regex_string.length; i >= 0; i--) {
										if (regex_string[i] == "]") in_brackets = true
										if (regex_string[i] == "[") in_brackets = false
										if (in_brackets || (i > 0 && regex_string[i-1] == "\\") || (i < regex_string.length && (regex_string[i] == "?" || regex_string[i] == "*" || regex_string[i] == "+" || regex_string[i] == "-"))) continue
										regex_string = regex_string.slice(0, i) + "\\s*" + regex_string.slice(i)
									}
									if (property.regex_perfect_match == "Perfect") regex_string = `^\\s*${regex_string}\\s*$`
									let regex = new RegExp(regex_string) // ^\s*...\s*$
									// check if regex matches compare_item.str
									if (regex.test(compare_item.str)) matches = false;
								} catch(e) {
									// invalid regex
									matches = false
								}
								break
							case "Area": 
								try {
									let value = property.value
									let x1 = matched_item.transform[4] + Number(value.slice(0, value.indexOf(" ")))
									let y1 = matched_item.transform[5] + Number(value.slice(value.indexOf(" ") + 1, value.indexOf(":")))
									let x2 = matched_item.transform[4] + Number(value.slice(value.indexOf(":") + 1, value.lastIndexOf(" ")))
									let y2 = matched_item.transform[5] + Number(value.slice(value.lastIndexOf(" ") + 1))
									let item_x = compare_item.transform[4] + compare_item.width / 2
									let item_y = compare_item.transform[5] + compare_item.height / 2
									if (item_x < x1 || x2 < item_x || item_y < y1 || y2 < item_y) matches = false
								}
								catch {
									matches = false
								}
								break
							case "Aside": 
								if ((property.direction == "Horizontal") ? (Math.abs(matched_item.transform[4] + matched_item.width - compare_item.transform[4]) > property.match_range) : (Math.abs(compare_item.transform[5] + compare_item.height - matched_item.transform[5]) > property.match_range)) matches = false
								break
							case "Same": 
								if (Math.abs(matched_item.transform[transform_index] - compare_item.transform[transform_index]) > property.match_range) matches = false
								break
							case "Offset": 
								if (Math.abs(matched_item.transform[transform_index] + Number(property.value) - compare_item.transform[transform_index]) > property.match_range) matches = false
								break
							case "Next":
								let matched_index = page_items.indexOf(matched_item)
								let compare_index = page_items.indexOf(compare_item)
								let difference = compare_index - matched_index
								let value = Number(property.value)
								if (value < 0 && (difference >= 0 || difference < value)) matches = false
								else if (value > 0 && (difference <= 0 || difference > value)) matches = false
								else if (value == 0) matches = false
								break
							default:  // if not one of the above types
								matches = false
								break
						}
					}
					return matches
				}
			}
		},
		update_rules() {
			// set rules to the saved data
			this.rules = []
			this.group_rules = []
			this.framework_item_rules = []
			for (let rule of this.rule_sets[this.rule_set_name].rules) {
				this.rules.push(this.new_rule(rule.type, rule.properties, rule.extension_properties, rule.extension_option))
			}
			for (let group_rule of this.rule_sets[this.rule_set_name].group_rules) {
				this.group_rules.push(this.new_rule(group_rule.type, group_rule.properties, group_rule.extension_properties, group_rule.extension_option))
			}
			for (let item_rule of this.rule_sets[this.rule_set_name].framework_item_rules) {
				this.framework_item_rules.push({
					prefix: item_rule.prefix,
					rule: this.new_rule(item_rule.rule.type, item_rule.rule.properties, item_rule.rule.extension_properties, item_rule.rule.extension_option),
					highlighted: item_rule.highlighted,
					level: item_rule.level,
					options: item_rule.options
				})
			}
			for (let item_rule of this.rule_sets[this.rule_set_name].style_rules) {
				this.style_rules.push({
					rule: this.new_rule(item_rule.rule.type, item_rule.rule.properties, item_rule.rule.extension_properties, item_rule.rule.extension_option),
					highlighted: item_rule.highlighted,
					options: item_rule.options
				})
			}
			this.output_options = this.rule_sets[this.rule_set_name].output_options

			this.last_rule_set_name = this.rule_set_name
		},
		save_rules() {
			if (this.rule_set_name != undefined && this.rule_set_name != "") {
				let save_rules = []
				for (let rule of this.rules) {
					save_rules.push({
						type: rule.type,
						properties: JSON.parse(JSON.stringify(rule.properties)),
						extension_properties: JSON.parse(JSON.stringify(rule.extension_properties)),
						extension_option: rule.extension_option
					})
				}

				let save_group_rules = []
				for (let group_rule of this.group_rules) {
					save_group_rules.push({
						type: group_rule.type,
						properties: JSON.parse(JSON.stringify(group_rule.properties)),
						extension_properties: JSON.parse(JSON.stringify(group_rule.extension_properties)),
						extension_option: group_rule.extension_option
					})
				}
				let save_framework_item_rules = []
				for (let item_rule of this.framework_item_rules) {
					save_framework_item_rules.push({
						prefix: item_rule.prefix,
						rule: {
							type: item_rule.rule.type,
							properties: JSON.parse(JSON.stringify(item_rule.rule.properties)),
							extension_properties: JSON.parse(JSON.stringify(item_rule.rule.extension_properties)),
							extension_option: item_rule.rule.extension_option
						},
						highlighted: item_rule.highlighted,
						level: item_rule.level,
						options: item_rule.options
					})
				}
				let save_style_rules = []
				for (let item_rule of this.style_rules) {
					save_style_rules.push({
						rule: {
							type: item_rule.rule.type,
							properties: JSON.parse(JSON.stringify(item_rule.rule.properties)),
							extension_properties: JSON.parse(JSON.stringify(item_rule.rule.extension_properties)),
							extension_option: item_rule.rule.extension_option
						},
						highlighted: item_rule.highlighted,
						options: item_rule.options
					})
				}
				this.$store.commit('lst_set_hash', ['PDF_import_interface_rule_sets', this.rule_set_name, {rules: save_rules, group_rules: save_group_rules, framework_item_rules: save_framework_item_rules, style_rules: save_style_rules, output_options: this.output_options, pdf_last_used: this.pdf_name}])
			}
		},
		save_pdf_info() {
			this.$store.commit('lst_set_hash', ['PDF_import_interface_pdf_data', this.pdf_name, {
				rule_set: this.rule_set_name,
				last_page_number: this.current_page_number
			}])
		},
		item_selected(item) {
			if (this.current_tool == "info") {
				this.$refs.stringInfo.textContent = item.str
				this.$refs.fontInfo.textContent = item.fontName
				this.$refs.sizeInfo.textContent = item.original_height
				this.$refs.leftInfo.textContent = item.transform[4]
				this.$refs.bottomInfo.textContent = item.transform[5]
				this.$refs.widthInfo.textContent = item.width
				this.$refs.heightInfo.textContent = item.height
			}

			if ((this.current_tool == "include/exclude" || this.current_tool == "group" || this.current_tool == "output" || this.current_tool == "styles") && this.selected_property !== undefined) {
				switch(this.selected_property.type) {
					case "Left": 
						this.selected_property.value = item.transform[4]
						break
					case "Bottom": 
						this.selected_property.value = item.transform[5]
						break
					case "Size": 
						this.selected_property.value = item.original_height
						break
					case "Font": 
						this.selected_property.value = item.fontName
						break
					case "Regex": 
						this.selected_property.value = item.str.replace(/[.*+?^${}()|[\]]/g, "\\$&")
						break
					case "Anti Regex": 
						this.selected_property.value = item.str.replace(/[.*+?^${}()|[\]]/g, "\\$&")
						break
					case "Pages": 
						this.selected_property.value = this.current_page_number + "-" + this.current_page_number
						break
				}
				this.selected_property.selected = false
				this.selected_property = undefined
			}
		},
		color_items(page_number) {
			// update coloring
			if (this.pdf_pages[page_number] == undefined) return
			if (Number(page_number) < this.output_options.start_page || this.output_options.end_page < Number(page_number)) {
				for (let item of this.pdf_pages[page_number].grouped_items) item.color = "lightblue"
				return
			}
			
			for (let item of this.pdf_pages[page_number].grouped_items) item.color = "lightblue"
			for (let rule of this.rules) {
				for (let item of this.pdf_pages[page_number].grouped_items) {
					if (rule.matches(undefined, item, page_number)) {
						if (rule.extension_properties.length == 0 || rule.extension_option == "Match Both") item.color = (rule.type == "include") ? "lightgreen" : "pink"
						if (rule.extension_properties.length > 0) for (let compare_item of this.pdf_pages[page_number].grouped_items) {
							if (rule.extension_matches(item, compare_item, this.pdf_pages[page_number].grouped_items)) {
								compare_item.color = (rule.type == "include") ? "lightgreen" : "pink"
							}
						}
					}
				}
			}
			if (this.current_tool == "output") for (let rule of this.framework_item_rules) {
				for (let item of this.pdf_pages[page_number].grouped_items) {
					if (rule.highlighted && rule.rule.matches(undefined, item, page_number)) {
						if (item.color == "lightgreen" && (rule.rule.extension_properties.length == 0 || rule.rule.extension_option == "Match Both")) item.color = "yellow"
						if (rule.rule.extension_properties.length > 0) for (let compare_item of this.pdf_pages[page_number].grouped_items) {
							if (compare_item.color == "lightgreen" && rule.rule.extension_matches(item, compare_item, this.pdf_pages[page_number].grouped_items)) {
								compare_item.color = "yellow"
							}
						}
					}
				}
			}
			if (this.current_tool == "styles") for (let rule of this.style_rules) {
				for (let item of this.pdf_pages[page_number].grouped_items) {
					if (rule.highlighted && rule.rule.matches(undefined, item, page_number)) {
						if (item.color == "lightgreen" && (rule.rule.extension_properties.length == 0 || rule.rule.extension_option == "Match Both")) item.color = "orange"
						if (rule.rule.extension_properties.length > 0) for (let compare_item of this.pdf_pages[page_number].grouped_items) {
							if (compare_item.color == "lightgreen" && rule.rule.extension_matches(item, compare_item, this.pdf_pages[page_number].grouped_items)) {
								compare_item.color = "orange"
							}
						}
					}
				}
			}
			
		},
		filtered_contents() {

			// this.copying_contents = true

			// Course * :41.01400 Grade 3
			// Knowledge_Domain ** Earth and Space Science
			// NOTES: These are the standards for Earth and Space Science...
			// S *** :S3E1 Obtain, evaluate, and communicate information...
			// E **** :S3E1.a Ask questions and analyze data to...
			// EX:
			// ---
			// Supplemental Info Heading

			// This is the text of the supplemental info...

			// how to know what level each item is at
			// LEVELS: Grade level/subject -> category -> component
			// batch imports are generally done independently of the higher order category
			// COMPONENTS: first line is the title -- *** :4.ASD.4 

			// as you type in category regexes, have matches light up yellow
			// have unlimited categories -- each with a regex
			// have a dropdown with preset options: [1.ABC.1, 1.ABC.1.1, list, ]
			// human readable code regex: (\d+)(\.[A-Z]+)(\.\d+)(\.\d?) -- this gets a colon before it no matter what


			// save every line in a JSON
			/*
			items = [
				{
					str: "jfdklajsdl;f",
					children: [

					]
				}
			]
			*/

			let framework_items = []
			let last_framework_item_at_level = []
			last_framework_item_at_level[-1] = {child_items: framework_items}
			let last_framework_item


			// turn into coded form
			let current_framework_item = {
				prefix: "",
				string: "",
				items: [],
				sort_children: false,
				fix_case: false,
				child_items: []
			}
			let current_level = 0
			let last_level = 0 // any level can't be two higher than last normal level
			let last_attempted_level = 0 // last level before constraining
			let forbidden_regex = /^ *$/
			let last_rule_matched
			for (let page_number in this.pdf_pages) {
				if (Number(page_number) < this.output_options.start_page || this.output_options.end_page < Number(page_number)) continue

				let pdf_page = this.pdf_pages[page_number]
				for (let item of pdf_page.grouped_items) {
					item.include = false
					item.rule_matched = undefined
				}
				for (let rule of this.rules) {
					for (let item of pdf_page.grouped_items) {
						if (rule.matches(undefined, item, page_number)) {
							if (rule.extension_properties.length == 0 || rule.extension_option == "Match Both") item.include = (rule.type == "include")
							if (rule.extension_properties.length > 0) for (let compare_item of this.pdf_pages[page_number].grouped_items) {
								if (rule.extension_matches(item, compare_item, pdf_page.grouped_items)) {
									compare_item.include = (rule.type == "include")
								}
							}
						}
					}
				}
				for (let rule of this.framework_item_rules) {
					for (let item of pdf_page.grouped_items) {
						if (rule.rule.matches(undefined, item, page_number)) {
							if (item.include && (rule.rule.extension_properties.length == 0 || rule.rule.extension_option == "Match Both")) item.rule_matched = rule
							if (rule.rule.extension_properties.length > 0) for (let compare_item of pdf_page.grouped_items) {
								if (compare_item.include && rule.rule.extension_matches(item, compare_item, pdf_page.grouped_items)) {
									compare_item.rule_matched = rule
								}
							}
						}
					}
				}
				for (let rule of this.style_rules) {
					for (let item of pdf_page.grouped_items) {
						if (rule.rule.matches(undefined, item, page_number)) {
							if (item.include && (rule.rule.extension_properties.length == 0 || rule.rule.extension_option == "Match Both")) item.style = rule.options
							if (rule.rule.extension_properties.length > 0) for (let compare_item of pdf_page.grouped_items) {
								if (compare_item.include && rule.rule.extension_matches(item, compare_item, pdf_page.grouped_items)) {
									compare_item.style = rule.options
								}
							}
						}
					}
				}



				for (let item of pdf_page.grouped_items) if (item.include && !forbidden_regex.test(item.str)) {
					
					if (item.rule_matched != undefined || (last_rule_matched != undefined && last_rule_matched.options != undefined && last_rule_matched.options.includes("Break Lines"))) {
						// START A NEW LINE
						let rule_matched = item.rule_matched || last_rule_matched
						if (rule_matched.options != undefined && rule_matched.options.includes("NOTES")) {
							// MAKE NOTES ALL WITH ONE PREFIX
							last_framework_item.child_items.push(current_framework_item)
							current_framework_item = {
								prefix: "NOTES: ",
								string: "",
								items: [],
								sort_children: rule_matched.options.includes("Sort Children"),
								fix_case: rule_matched.options.includes("Fix Case"),
								child_items: []
							}

						}
						else { // not a note
							
							let parent_framework_item
							for (let i = current_level-1; i >= -1; i--) {
								if (last_framework_item_at_level[i]) {
									parent_framework_item = last_framework_item_at_level[i]
									break
								}
							}
							// console.log(current_level, last_framework_item_at_level, parent_framework_item)
							parent_framework_item.child_items.push(current_framework_item)
							last_framework_item_at_level[current_level] = current_framework_item
							last_framework_item = current_framework_item
							
							current_level = rule_matched.level

							current_framework_item = {
								prefix: rule_matched.prefix + " ",
								string: "",
								items: [],
								sort_children: (rule_matched.options != undefined) ? rule_matched.options.includes("Sort Children") : false,
								fix_case: (rule_matched.options != undefined) ? rule_matched.options.includes("Fix Case") : false,
								child_items: []
							}
							for (let i = 0; i <= current_level; i++) current_framework_item.string += "*"
							current_framework_item.string += " "

							
						}

						if (rule_matched.options != undefined && rule_matched.options.includes("Starts With Code")) current_framework_item.string += " :" + item.str
						else current_framework_item.items.push(item)

						if (item.rule_matched != undefined) last_rule_matched = item.rule_matched
					}
					else current_framework_item.items.push(item)
				}
			}

			


			// PW: added in merge on 9/8/2023
			let parent_framework_item
			for (let i = current_level-1; i >= -1; i--) {
				if (last_framework_item_at_level[i]) {
					parent_framework_item = last_framework_item_at_level[i]
					break
				}
			}
			parent_framework_item.child_items.push(current_framework_item)

			// PW: removed in merge on 9/8/2023
			// last_framework_item_at_level[current_level-1].child_items.push(current_framework_item)
			
			
			let parse_strings = (framework_items) => {
				for (let framework_item of framework_items) {
					if (framework_item.items.length > 0) {
						framework_item.string += " " + this.style_string((framework_item.fix_case ? framework_item.items[0].str.replace(/\b[A-Z]{2,}\b/g, match=>(match[0] + match.slice(1).toLowerCase())) : framework_item.items[0].str), framework_item.items[0].style)
					}
					for (let i = 1; i < framework_item.items.length; i++) {
						framework_item.string = this.combine_strings(framework_item.string, framework_item.items[i-1], framework_item.items[i], framework_item.fix_case, framework_item.items[i].style)
					}
					parse_strings(framework_item.child_items)
				}
			}
			parse_strings(framework_items)

			let sort = (framework_items) => {
				for (let framework_item of framework_items) {
					if (framework_item.sort_children) {
						framework_item.child_items.sort((a, b) => U.natural_sort(a.string, b.string))
					}
					sort(framework_item.child_items)
				}
			}
			sort(framework_items)

			let coded_strings = []
			let add_strings = (framework_items) => {
				for (let framework_item of framework_items) {
					coded_strings.push(framework_item.prefix + framework_item.string)
					add_strings(framework_item.child_items)
				}
			}
			add_strings(framework_items)

			console.log(framework_items)

			let coded_content = coded_strings.join("\n")
			let extra_spaces = ["  ", "   ", "    ", "     "]
			for (let match of extra_spaces) {
				if (match == null) break
				let match_index = coded_content.indexOf(match)
				while (match_index != -1) {
					coded_content = coded_content.slice(0, match_index) + " " + coded_content.slice(match_index + match.length)
					match_index = coded_content.indexOf(match)
				}
			}

			return coded_content
		},
		property_selected(component) {
			if (this.selected_property !== undefined) this.selected_property.selected = false
			this.selected_property = component
			if (this.selected_property !== undefined) this.selected_property.selected = true
		},
		combine_items(base_item, added_item, add_space) {
			base_item.str = this.combine_strings(base_item.str, base_item, added_item, false)
			
			// calculate new bounds of the combined item
			let left_bound = (base_item.transform[4] < added_item.transform[4]) ? base_item.transform[4] : added_item.transform[4]
			let right_bound = (base_item.transform[4] + base_item.width > added_item.transform[4] + added_item.width) ? base_item.transform[4] + base_item.width : added_item.transform[4] + added_item.width
			let bottom_bound = (base_item.transform[5] < added_item.transform[5]) ? base_item.transform[5] : added_item.transform[5]
			let up_bound = (base_item.transform[5] + base_item.height > added_item.transform[5] + added_item.height) ? base_item.transform[5] + base_item.height : added_item.transform[5] + added_item.height
			base_item.transform[4] = left_bound
			base_item.width = right_bound - left_bound
			base_item.transform[5] = bottom_bound
			base_item.height = up_bound - bottom_bound

			return base_item
		},
		combine_strings(base_string, base_item, added_item, fix_case, style) {

			let on_different_lines = Math.abs(added_item.transform[5] - base_item.transform[5]) > 1
			let apart = (added_item.transform[4] - (base_item.transform[4] + base_item.width)) > 0
			let matched_dash = true
			if (on_different_lines) { // base_string is the end of the last line
				if (base_string.slice(-3) == " - ") base_string = base_string.slice(0, -3)
				else if (base_string.slice(-2) == "- ") base_string = base_string.slice(0, -2)
				else if (base_string.slice(-2) == " -") base_string = base_string.slice(0, -2)
				else if (base_string.slice(-1) == "-") base_string = base_string.slice(0, -1)
				else matched_dash = false
			}
			if ((apart || on_different_lines) && !matched_dash) base_string += " " // seperate words
			// console.log(base_item.str, added_item.str, apart, on_different_lines, matched_dash)
			base_string += this.style_string((fix_case ? added_item.str.replace(/\b[A-Z]{2,}\b/g, match=>(match[0] + match.slice(1).toLowerCase())) : added_item.str), style)
			return base_string
		},
		style_string(string, style) {
			if (style == undefined) return string
			if (style.includes("Bold")) string = `**${string.replace(/^ *| *$/g, "")}**`
			if (style.includes("Italic")) string = `*${string.replace(/^ *| *$/g, "")}*`
			if (style.includes("List Item")) string = `\n* ${string.replace(/^ *| *$/g, "")}`
			return string
		},
		highlight_framework_item_rule(rule) {
			for (let _rule of this.framework_item_rules) {
				if (_rule == rule) _rule.highlighted = true
				else _rule.highlighted = false
			}
		},
		highlight_style_rule(rule) {
			for (let _rule of this.style_rules) {
				if (_rule == rule) _rule.highlighted = true
				else _rule.highlighted = false
			}
		}
	}
}
</script>

<style lang="scss">

.k-pdf-import-interface-wrapper {
	select {
		margin: 2px;
	}

	textarea {
		padding-top: 3.5px!important;
		padding-bottom: 3.5px!important;
		overflow: hidden!important;
		height: 33px;
		transition: height .25s;
	}

	.tool_card {
		width: 500px;
		// max-height: 500px;
		overflow-y: auto;
		
		margin: 10px 0 0 10px;
		padding: 10px;

		background-color: white;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}

	.tool_card::-webkit-scrollbar {
		display: none;
	}
}
</style>