// to run this script: tap cmd-shift-1 on keyboard; enter `sparkl_test_harness_input`
if (!window.custom_scripts) window.custom_scripts = {}
window.custom_scripts.sparkl_test_harness_input = {
	input_required: true,
	fn: function(input) {
/*
Format for input:
Content Standard, Element
isRelatedTo
KG,01,02

Content Standard
isRelatedTo
03

Content Standard, Standard
isRelatedTo
03

*/
		return new Promise((resolve, reject)=>{
			// extra item types and association types to process from input
			let item_types, association_types, education_levels
			let lines = input.split(/\n/)
			let line = $.trim(lines[0])
			if (line) item_types = line.split(/\s*,\s*/)
			line = $.trim(lines[1])
			if (line) association_types = line.split(/\s*,\s*/)
			line = $.trim(lines[2])
			if (line) education_levels = line.split(/\s*,\s*/)
			// console.log(item_types, association_types, education_levels); return

			let fr = vapp.case_tree_component.framework_record.json
			let arr = []
			for (let item of fr.CFItems) {
				// check for designated types
				let CFItemType = U.item_type_string(item)
				if (item_types && !item_types.includes(CFItemType)) continue
				item = extobj(item)
				item.CFItemType = CFItemType

				// check for designated education levels
				if (education_levels) {
					// if input says to include only certain levels an no levels are specified for this item, continue
					if (!item.educationLevel) continue
					// otherwise check levels; not no match, skip
					let ok = false
					for (let s of education_levels) if (item.educationLevel.includes(s)) ok = true
					if (!ok) continue
				}

				// look for associations of the given type
				item.associations = []
				if (association_types) {
					let hash_val = vapp.case_tree_component.framework_record.cfo.associations_hash[item.identifier]
					if (hash_val) for (assoc of hash_val) {
						if (!association_types.includes(assoc.associationType)) continue
						let other_item = (assoc.destinationNodeURI.identifier == item.identifier) ? assoc.originNodeURI : assoc.destinationNodeURI
						item.associations.push({
							hcs: other_item.title.replace(/^(\S+)[\s\S]*/, '$1'),
							uri: other_item,
						})
					}
				}
				arr.push(item)
			}

			arr.sort((a,b)=>{return U.natural_sort(a.humanCodingScheme + ' ' + a.fullStatement, b.humanCodingScheme + ' ' + b.fullStatement)})
			console.log(arr)

			lines = `${arr.length} lines:\n\n`
			for (item of arr) {
				let line = ''
				if (item.humanCodingScheme) line += `#${item.humanCodingScheme} `
				if (item.associations.length > 0) {
					line += '['
					for (let i = 0; i < item.associations.length; ++i) {
						if (i > 0) line += ','
						line += item.associations[i].hcs
					}
					line += '] '
				}
				line += item.fullStatement.replace(/\s+/g, ' ')
				line += '\n'

				lines += line
			}

			resolve(lines)
		})
	},
}