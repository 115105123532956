// to run this script: tap cmd-shift-1 on keyboard; enter `update_derivative_use_aliases`
if (!window.custom_scripts) window.custom_scripts = {}
window.custom_scripts.update_derivative_use_aliases = {
	input_required: false,
	payload: null,
	new_domain: 'satchelcommons.com', // this could be prompted for...
	log: '',

	pre_fn: function() {
		console.warn('in pre_fn')
		return new Promise((resolve, reject)=>{
			this.fn('pre').then((output)=>resolve(output))
		})
	},
	fn: function(input) {
		return new Promise((resolve, reject)=>{
			let add_to_log = (s) => { this.log += `${s}\n` }

			let replace_uri = (o) => {
				let uri = o.uri.replace(/^(https:..)(.*?)\//, `$1${this.new_domain}/`)
				if (uri != o.uri) {
					o.uri = uri
					return o
				} else {
					return false
				}
			}

			if (input == 'pre') {
				let doit = false
				let framework_record = vapp.case_tree_component.framework_record
				let json = framework_record.json
				// console.log(framework_record)

				// get the source framework's document identifier/uri; we should be able to find it like this:
				let source_document = vapp.$store.state.framework_records.find(x=>x.lsdoc_identifier == json.CFDocument.extensions.sourceFrameworkIdentifier)
				if (empty(source_document)) {
					add_to_log(`ERROR: Source document not found`)
					resolve(this.log)
					return
				}
				source_document = source_document.json.CFDocument
				add_to_log(`Source document found: ${source_document.title} / ${source_document.identifier}`)

				add_to_log(``)
				add_to_log(`Initial CFItem count: ${json.CFItems.length}`)
				add_to_log(`Initial CFAssociation count: ${json.CFAssociations.length}`)
				add_to_log(``)

				let payload = {
					update_document_date: 'yes',
					check_out_and_in: 'yes',
					lsdoc_identifier: framework_record.lsdoc_identifier,
					CFItems: [],			// we will add only the updated properties of CFItems
					CFAssociations: [],		// but we will add the entire CFAssociations
				}

				// update the uri for the document
				replace_uri(json.CFDocument)
				payload.CFDocument = {identifier: json.CFDocument.identifier, uri: json.CFDocument.uri}

				// let assocs_to_update = []

				let deleted_exact_matches = 0
				let updated_origin_nodes = 0
				let updated_destination_nodes = 0
				let updated_original_items = 0
				let updated_uris_for_new_items = 0
				let updated_assoc_uri_only = 0
				let updated_assoc_origin_uri_only = 0
				let updated_assoc_destination_uri_only = 0
				let updated_assocs_with_other_changes = 0
				let updated_assocs_for_original_items = 0
				
				let added_assoc_hash = {}
				let framework_b_item_hash_uris_replaced = {}

				// go through all CFItems
				for (let item of json.CFItems) {
					// if this item *doesn't have* a sourceItemIdentifier, it's an item that just belongs in B
					if (!item.extensions?.sourceItemIdentifier) {
						// console.log(`Item doesn’t have sourceItemIdentifier: ${item.humanCodingScheme || ''} ${item.fullStatement.substr(0, 60)}`)
						let o = replace_uri({ identifier: item.identifier, uri: item.uri })	// update uri to point to new_domain
						if (o) {
							payload.CFItems.push(o)
							++updated_uris_for_new_items
							framework_b_item_hash_uris_replaced[item.identifier] = o
						}

					// else this item *has* a sourceItemIdentifier
					} else {
						// if sii == identifier, it's an item that just belongs in B
						if (item.extensions.sourceItemIdentifier == item.identifier) {
							// not sure if this will ever happen; if so we need to do something for it...
							let o = { identifier: item.identifier, uri: item.uri }
							replace_uri(o)

							let extensions = object_copy(item.extensions)
							delete extensions.sourceItemIdentifier
							delete extensions.sourceItemURI
							if (U.object_has_keys(extensions)) o.extensions = extensions
							else o.extensions = '*CLEAR*'

							payload.CFItems.push(o)
							++updated_uris_for_new_items
							framework_b_item_hash_uris_replaced[item.identifier] = o
							console.warn(`Removing sii for original item in B -- NOT CURRENTLY BEING PROCESSED: ${item.humanCodingScheme || ''} ${item.fullStatement.substr(0, 10)}`, o)

						// else it's a derived item, so...
						} else {
							let original_identifier = item.extensions.sourceItemIdentifier
							let original_uri = item.extensions.sourceItemURI

							// for any isChildOf CFAssociations of this item
							let found_assoc_count = 0
							for (let assoc of json.CFAssociations) {
								if (assoc.associationType == 'exactMatchOf') {
									if (assoc.originNodeURI.identifier == item.identifier) {
										// remove exactMatchOfs with the item -- this *is* the item now!
										payload.CFAssociations.push({identifier: assoc.identifier, delete: 'yes'})
										// console.warn(`Deleting exactMatchOf: ${assoc.identifier}`)
										++deleted_exact_matches
										added_assoc_hash[assoc.identifier] = assoc
									}
									continue
								}
								if (assoc.associationType != 'isChildOf') continue
								if (assoc.originNodeURI.identifier == item.identifier) {
									// if origin matches item.identifier, update to point to original item
									assoc.originNodeURI.identifier = original_identifier
									assoc.originNodeURI.uri = original_uri

									// and add the original framework’s document identifier/uri to extensions for the association
									if (empty(assoc.extensions)) assoc.extensions = {}
									assoc.extensions.sourceDocumentIdentifier = source_document.identifier
									assoc.extensions.sourceDocumentURI = source_document.uri

									++found_assoc_count
									++updated_origin_nodes

								} else if (assoc.destinationNodeURI.identifier == item.identifier) {
									// if destination matches item.identifier, update to point to original item, but don't update extensions
									assoc.destinationNodeURI.identifier = original_identifier
									assoc.destinationNodeURI.uri = original_uri

									++found_assoc_count
									++updated_destination_nodes
								
								} else continue		// this is an isChildOf assoc, but not for this item

								// if we get to here we found an assoc for the item, so add to payload if not already there (note that we might also change this assoc later in the loop, if the assoc relates a derived item to another derived item)
								if (!added_assoc_hash[assoc.identifier]) {
									replace_uri(assoc)	// update uri to point to new_domain
									payload.CFAssociations.push(assoc)
									added_assoc_hash[assoc.identifier] = assoc
									++updated_assocs_for_original_items
								}
							}

							if (found_assoc_count == 0) {
								add_to_log(`ERROR: Couldn’t find assoc for ${item.humanCodingScheme || ''} ${item.fullStatement.substr(0, 60)}`)
								resolve(this.log)
								return
							}

							// done processing the item's associations; now make a copy of the item
							let o = object_copy(item)

							// replace the item's identifier/uri with the sourceItemIdentifier/uri
							o.identifier = original_identifier
							o.uri = original_uri

							// remove the sii
							let extensions = object_copy(item.extensions)
							delete extensions.sourceItemIdentifier
							delete extensions.sourceItemURI
							if (U.object_has_keys(extensions)) o.extensions = extensions
							else delete o.extensions

							// push this new item (which is an exact copy of the item from Framework A) onto CFItems, and delete the derivative item
							payload.CFItems.push(o)
							payload.CFItems.push({identifier: item.identifier, delete: 'yes'})

							// add_to_log(`Fixing item from A: ${item.humanCodingScheme || ''} ${item.fullStatement.substr(0, 20)}`)
							++updated_original_items
						}
					}
				}

				// now go back through CFAssociations and fix the uri values if needed
				for (let assoc of json.CFAssociations) {
					let changed = false

					// update the assoc uri to point to new_domain
					if (replace_uri(assoc)) {
						changed = true
						++updated_assoc_uri_only
					}

					// if the destination or origin node is one of the original framework B items, the node's uri also needs to be updated
					if (framework_b_item_hash_uris_replaced[assoc.originNodeURI.identifier]) {
						if (replace_uri(assoc.originNodeURI)) {
							changed = true
							++updated_assoc_origin_uri_only
						}
					}
					if (framework_b_item_hash_uris_replaced[assoc.destinationNodeURI.identifier]) {
						if (replace_uri(assoc.destinationNodeURI)) {
							changed = true
							++updated_assoc_destination_uri_only
						}
					}

					// if changed and not already in payload, add CFAssociation to payload
					if (changed) {
						if (!added_assoc_hash[assoc.identifier]) {
							payload.CFAssociations.push(assoc)
							console.warn(`update uris only for assoc [${assoc.originNodeURI.title}] / [${assoc.destinationNodeURI.title}]`, object_copy(assoc))
						} else ++updated_assocs_with_other_changes
					} else {
						if (!added_assoc_hash[assoc.identifier]) console.warn('unchanged assoc:', object_copy(assoc))
					}
				}

				add_to_log(`updated uris for Framework B items: ${updated_uris_for_new_items}`)
				add_to_log(`updated Framework A items: ${updated_original_items}`)
				add_to_log(`deleted exactMatches: ${deleted_exact_matches}`)
				add_to_log(`updated origin_nodes: ${updated_origin_nodes}`)
				add_to_log(`updated destination_nodes: ${updated_destination_nodes}`)
				add_to_log(`updated assocs to refer origin and/or destination to Framework A items: ${updated_assocs_for_original_items}`)
				add_to_log(`updated assoc only: updated assoc uri: ${updated_assoc_uri_only}`)
				add_to_log(`updated assoc only: updated origin uri: ${updated_assoc_origin_uri_only}`)
				add_to_log(`updated assoc only: updated destination uri: ${updated_assoc_destination_uri_only}`)
				add_to_log(`updated uris for existing assocs, with other changes also made: ${updated_assocs_with_other_changes}`)
				add_to_log('')
				add_to_log(`total CFItem updates: ${payload.CFItems.length}`)
				add_to_log(`total CFAssociation updates: ${payload.CFAssociations.length}`)
				add_to_log(`${updated_original_items} + ${updated_original_items} + ${updated_uris_for_new_items} = ${updated_original_items*2+updated_uris_for_new_items}`)
				add_to_log(``)
				add_to_log('CLICK RUN TO SAVE CHANGES')
				add_to_log(``)
				add_to_log('NOTE: framework json has already been altered; if you don’t save changes, you should refresh your browser.')

				console.warn('done', payload)
				this.payload = payload
			
			// input isn't 'pre', so user has clicked to save changes
			} else {
				vapp.$store.dispatch('save_framework_data', this.payload).then(()=>{
					vapp.$alert('Reload to see updated framework data').then(x=>{
						window.location.reload()
					})
				})
			}

			resolve(this.log)
		})
	},

}