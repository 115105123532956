<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>User Admin Tool</b>
			<v-spacer></v-spacer>
			<v-btn icon @click="show_help" color="light-blue"><v-icon large>fas fa-info-circle</v-icon></v-btn>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="all_users" style="clear:both">
				<div class="py-4 d-flex">
					<v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
					></v-text-field>
					<v-btn class="ml-8" color="primary" @click="new_user_clicked"><v-icon small class="mr-2">fas fa-plus</v-icon> Create New User</v-btn>
				</div>

				<v-data-table light dense
					:headers="headers"
					:items="rows"
					:sort-by="['system_role_sort','created_at']"
					:sort-desc="[false,true]"
					:must-sort="true"
					:custom-filter="table_search_filter"
					:search="search"
					hide-default-footer
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-admin-users-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td><v-btn icon x-small class="mr-2" @click="edit_user(item.user_id)"><v-icon>fas fa-edit</v-icon></v-btn>{{item.last_name}}, {{item.first_name}} ({{item.email}})</td>
						<td class="text-center"><nobr>{{system_role_display(item.system_role)}}</nobr></td>
						<td class="text-center"><nobr>{{date_string(item.created_at)}}</nobr></td>
						<td class="text-center"><nobr>{{date_string(item.last_login)}}</nobr></td>
						<td class="text-center">{{item.login_count}}</td>
						<td class="text-center">{{opt_in_display(item.notification_settings)}}</td>
					</tr></template>
				</v-data-table>
			</div>
			<AdminUserEdit v-if="user_being_edited" :user="user_being_edited" @editor_cancel="user_being_edited=null" @user_edited="user_edited" @user_deleted="user_deleted" @send_token_login="send_token_login"></AdminUserEdit>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminUserEdit from './AdminUserEdit'

export default {
	components: { AdminUserEdit },
	props: {
	},
	data() { return {
		dialog_open: true,
		all_users: null,
		headers: [
			{ text: 'User', align: 'left', sortable: true, value:'name_search' },
			{ text: 'Rights', align: 'center', sortable: true, value:'system_role_sort' },
			{ text: 'Date Created', align: 'center', sortable: true, value:'created_at' },
			{ text: 'Last Login', align: 'center', sortable: true, value:'last_login' },
			{ text: 'Login Ct.', align: 'center', sortable: true, value:'login_count' },
			{ text: 'Opt In', align: 'center', sortable: true, value:'opt_in_sort' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
	}},
	computed: {
		...mapState(['user_info']),
		rows() {
			let arr = []
			for (let user of this.all_users) {
				// start with all the user fields
				let o = $.extend(true, {}, user)

				// and a searchable value for name/email
				o.name_search = sr('$1 $2 $3', o.last_name, o.first_name, o.email).toLowerCase()

				// sort by opt in status
				if (o.notification_settings?.on == 'yes') o.opt_in_sort = 1
				else o.opt_in_sort = 0

				// add a sort val
				if (o.system_role == 'admin') o.system_role_sort = 1
				else if (o.system_role == 'editor') o.system_role_sort = 2
				else if (o.system_role == 'reviewer') o.system_role_sort = 3
				else o.system_role_sort = 4
				arr.push(o)
			}
			return arr
		}
	},
	created() {
		// DEBUG
		vapp.all_users_component = this
		this.load_admin_user_list()
	},
	mounted() {
	},
	methods: {
		show_help() { vapp.show_help('roles_system') },

		load_admin_user_list() {
			let payload = {
				user_id: this.user_info.user_id,
			}
			U.loading_start()
			U.ajax('admin_get_all_users', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				// remove unsignedin@cglt.com user
				let index = result.all_users.findIndex(x=>x.email == 'unsignedin@cglt.com')
				if (index > -1) result.all_users.splice(index, 1)

				this.all_users = result.all_users
				// format for each admin_user: { user_id, system_role, email, first_name, last_name, created_at, last_login, login_count }
			});
		},

		system_role_display(system_role) {
			if (system_role == 'admin') return 'System Admin'
			if (system_role == 'editor') return 'System Editor'
			if (system_role == 'reviewer') return 'System Reviewer'
			else return 'Default'
		},

		date_string(gmt_date) {
			if (empty(gmt_date)) return '–'
			if (gmt_date == 'TODAY') return 'Just Now'
			return date.format(U.convert_to_local_date(gmt_date), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},

		// initially, framework update report is the only opt-in option
		opt_in_display(notification_settings) {
			if (notification_settings && notification_settings.on == 'yes') return 'yes'
			return 'no'
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check email/name
			if (item.name_search.search(re) > -1) return true

			// check rights
			if (this.system_role_display(item.system_role).search(re) > -1) return true

			// if we get to here return false
			return false
		},

		edit_user(user_id) {
			this.user_being_edited = this.all_users.find(x=>x.user_id==user_id)
		},

		user_edited(payload) {
			payload.user_id = this.user_info.user_id
			console.log(payload)

			U.loading_start()
			U.ajax('admin_save_user', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				// if we just created a new user, send "invitation email"
				if (payload.user_id_being_edited == 0) {
					this.send_token_login({user_email: this.user_being_edited.email, body_html: sr('An account has been created for this email address ($1), allowing you to sign in to $2 as a registered user.<br/><br/>Click the following link to sign in for the first time. This link can only be used once, and will expire in 24 hours. After clicking the link to sign in, you can set or change your account password.', this.user_being_edited.email, this.$store.state.site_config.app_name)})
				}

				// result.saved_user_id will contain the new user_id if this is a new user
				this.user_being_edited.user_id = result.saved_user_id
				this.user_being_edited.first_name = payload.first_name
				this.user_being_edited.last_name = payload.last_name
				this.user_being_edited.system_role = payload.system_role

				// close user editor
				this.user_being_edited = null
			});
		},

		user_deleted() {
			// if user_id was 0, it was a new user, so just delete
			if (this.user_being_edited.user_id == 0) {
				this.all_users.splice(this.all_users.length-1, 1)
				this.user_being_edited = null
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete user <b>$1</b>?', this.user_being_edited.email),
			    acceptText: 'Delete User',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				let payload = {
					user_id: this.user_info.user_id,
					user_id_to_delete: this.user_being_edited.user_id,
				}
				U.loading_start()
				U.ajax('admin_delete_user', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in admin ajax call: ' + result.status); vapp.ping(); return;
					}

					let index = this.all_users.findIndex(x=>x==this.user_being_edited)
					this.all_users.splice(index, 1)

					// close user editor
					this.user_being_edited = null
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		new_user_clicked() {
			this.$prompt({
				title: 'Add New User',
				text: 'Enter the new user’s email address:',
				initialValue: '',
				acceptText: 'Add User',
			}).then(email => {
				email = $.trim(email).toLowerCase()

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.new_user_clicked()})
					return
				}
				for (let identifier in this.all_users) {
					if (this.all_users[identifier].email == email) {
						this.search = email
						this.$alert('This user is already in the system. Click the edit button next to the user’s name to edit their account data.')
						return
					}
				}
				this.new_user_create(email)
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		new_user_create(email) {
			let u = new User_Info()
			u.user_id = 0
			u.system_role = ''
			u.email = email
			u.first_name = ''
			u.last_name = ''
			u.created_at = 'TODAY'
			u.last_login = ''
			u.login_count = 0
			this.all_users.push(u)
			this.user_being_edited = this.all_users[this.all_users.length-1]
		},

		send_token_login(payload) {
			// send token_signin_email, with token lasting 24 hours and sending in received body_html (which may be empty)
			payload.token_duration = 60*24
			U.loading_start()
			U.ajax('send_token_signin_email', payload, result=>{
				U.loading_stop()
				let msg
				if (result.status != 'ok') {
					console.log('Error in token signin ajax call: ' + result.status)
					msg = 'The one-time sign-in link could not be sent.'
				} else if (payload.body_html) {
					// if we sent a body_html, it means that this was a new user, so show a corresponding message
					msg = 'The new user was created, and an email has been sent to the user with a one-time sign-in link. After clicking that link to sign in, they can change their password. Please let the new user know that <b>the link will be valid for only 24 hours</b>, and that they should <b>check their spam or junk folder</b> if they don’t receive the link.'
				} else {
					msg = 'One-time sign-in link sent. Please let the new user know that <b>the link will be valid for only 24 hours</b>, and that they should <b>check their spam or junk folder</b> if they don’t receive the link.'
				}
				this.$alert(msg)
			})

		},
	}
}
</script>

<style lang="scss">
.k-admin-users-table {
	td {font-size:12px!important;}
}
</style>
