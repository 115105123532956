// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		save_imported_items(data) {
			let framework_record = this.framework_record
			let cfo = framework_record.cfo
			let lsdoc_identifier = framework_record.lsdoc_identifier

			console.log('save_imported_items')
			// data should include lsdoc_identifier, CFItems, and CFAssociations

			// Note that this is very similar to the end of the process in ItemCopyInterface.vue
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// add CFItems and CFAssociations to json
				for (let cfi of data.CFItems) {
					// update lastChangeDateTime json and in the cfo.cfitem
					if (cfi.lastChangeDateTime == '*NOW*') {
						cfi.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime

						// update the cfo as well
						let cfo_cfitem = cfo.cfitems[cfi.identifier]
						this.$store.commit('set', [cfo_cfitem, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					}

					this.$store.commit('set', [framework_record.json.CFItems, 'PUSH', cfi])
				}
				for (let cfa of data.CFAssociations) {
					// update lastChangeDateTime json before pushing CFAssociation
					if (cfa.lastChangeDateTime == '*NOW*') {
						cfa.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					this.$store.commit('set', [framework_record.json.CFAssociations, 'PUSH', cfa])
				}

				// clear new_imported_item_identifiers
				this.new_imported_item_identifiers = []

			}).catch((e)=>{
				console.log(e)
				// in case of failure...
				this.$alert('Error saving imported items')
				this.cancel_item_import_review()
				// // remove cfitems from the cfo
				// for (let cfi of data.CFItems) {
				// 	// find the item's node in the cfo tree and delete it
				// 	let tree_node = cfo.cfitems[cfi.identifier].tree_nodes[0]
				// 	let index = tree_node.parent_node.children.findIndex(x=>x==tree_node)
				// 	if (index == -1) {
				// 		console.log('error deleting child node from parent')
				// 	} else {
				// 		this.$store.commit('set', [tree_node.parent_node.children, 'SPLICE', index])
				// 	}

				// 	// then delete it from cfitems
				// 	this.$store.commit('set', [cfo.cfitems, cfi.identifier, '*DELETE_FROM_STORE*'])
				// }
			})

		},

		cancel_item_import_review() {
			// clear new_imported_item_identifiers
			this.new_imported_item_identifiers = []
			this.refresh_lsdoc()
		},
	}
}
