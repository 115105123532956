<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1200" persistent scrollable>
	<v-card>
		<v-card-title class="py-2 pr-2 pl-5" style="border-bottom:1px solid #ccc">
			<b>All Framework Comments</b>
			<v-spacer/>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" fab x-small class="mr-3" color="primary" @click="download_comments"><v-icon small>fas fa-download</v-icon></v-btn></template>Download comments to CSV file</v-tooltip>
			<v-icon color="light-blue" class="mr-2" large @click="U.show_help('show_all_comments')">fas fa-info-circle</v-icon>
			<!-- note that any signed-in user can create a comment group (and you'll only be viewing this component if you're signed in) -->
			<v-btn v-if="signed_in" small text color="primary" @click="viewer.show_comment_group_manager=true" class="mr-2"><v-icon small class="mr-2">fas fa-users</v-icon>Manage Comment Groups</v-btn>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text style="font-size:16px; color:#000;">
			<div class="pt-4 d-flex align-center">
				<div style="flex:0 1 80px" class="mr-2"><v-select dense outlined hide-details v-model="comments_sort_order" :items="[{value:'date',text:'Date'},{value:'item',text:'Item'}]" label="Sort By"></v-select></div>
				<div v-if="signed_in" style="flex:0 1 120px" class="mr-2"><v-select style="width:120px" dense outlined hide-details v-model="comments_selected_group" :items="group_array" label="Comment Group"></v-select></div>
				<div v-if="signed_in" style="flex:0 1 120px" class="mr-2"><v-select style="width:120px" dense outlined hide-details v-model="comments_selected_user" :items="user_array" label="Comment Author"></v-select></div>
				<div v-if="signed_in" style="flex:0 1 160px" class="mr-2"><v-select style="width:160px" dense outlined hide-details v-model="comments_selected_suggested_edits" :items="suggested_edits_array" label="Suggested Edits"></v-select></div>
				<div v-if="signed_in" class="mr-2" v-show="n_resolved"><v-checkbox class="mt-0 pt-0" v-model="comments_show_resolved" hide-details><template v-slot:label><nobr style="font-size:14px;margin-left:-4px;">Show Resolved ({{n_resolved}})</nobr></template></v-checkbox></v-select></div>
				<v-spacer/>
				<div style="flex:1 1 60%; max-width:500px;"><v-text-field
					v-model="search"
					prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
					label="Search" single-line hide-details outlined dense background-color="#fff"
				></v-text-field></div>
			</div>

			<v-data-table light dense
				:headers="headers"
				:items="framework_comments"
				:custom-filter="table_search_filter"
				:search="search"
				:footer-props="footer_options"
				:items-per-page="10"
				:options="table_options"
				@update:items-per-page="update_items_per_page"
				class="k-framework-comments-table"
			>
				<template v-slot:item="{ item }"><tr :class="row_class(item)" v-show="comments_show_resolved||!item.comment.resolved">
					<td class="text-left pt-3 pl-1"><nobr><a @click="item_clicked(item)"><v-icon x-small class="mr-1" color="primary">fas fa-arrow-circle-right</v-icon>{{item.item_string}}</a></nobr></td>
					<td class="text-center pt-3 px-0"><nobr v-html="item.row_date_string"></nobr></td>
					<td class="text-left pr-0" style="width:580px; max-width:580px"><TileComment :comment="item.comment" :render_latex="true" /></td>
				</tr></template>
			</v-data-table>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TileComment from './TileComment'

export default {
	components: { TileComment },
	props: {
		framework_record: { type: Object, required: true },
		starting_comment_id: { type: Number, required: false, default() { return -1 } },
		viewer: { required: false },
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Item', align: 'left', sortable: false, value:'item_string' },
			// { text: 'Group', align: 'left', sortable: false, value:'group' },
			{ text: 'Date', align: 'center', sortable: false, value:'commenter' },
			{ text: 'Comment/Replies', align: 'left', sortable: false, value:'body' },
		],
		footer_options: {
			itemsPerPageOptions: [5,10,50,100],	// don't include -1 (all) option, because the table can get too hard to draw with > 100 items
		},
		search: '',
		group_array: [],
		user_array: [],
		table_options: {
			itemsPerPage: 10,
			page: 1,
		},
		suggested_edits_array: [
			{value:'all', text:'ALL: Show all comments'},
			{value:'no_suggestions', text:'NO SUGGESTED EDITS: Show only comments that don’t include suggested edits'},
			{value:'unapplied', text:'UNAPPLIED: Show comments with suggested edits that have NOT been applied'},
			{value:'applied', text:'APPLIED: Show comments with suggested edits that HAVE been applied'},
		],
	}},
	computed: {
		...mapState(['user_info', 'comment_groups']),
		...mapGetters(['signed_in', 'comments_hash']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		comments_sort_order: {
			// 'date' or 'item'
			get() { return this.$store.state.lst.comments_sort_order },
			set(val) { this.$store.commit('lst_set', ['comments_sort_order', val]) }
		},
		comments_selected_group: {
			get() { 
				let x = this.$store.state.comments_selected_group
				if (!this.viewer.public_review_on && x == -1) x = -2
				return x
			},
			set(val) { this.$store.commit('set', ['comments_selected_group', val]) }
		},
		comments_selected_user: {
			get() { return this.$store.state.comments_selected_user},
			set(val) { this.$store.commit('set', ['comments_selected_user', val])}
		},
		comments_selected_suggested_edits: {
			get() { return this.$store.state.comments_selected_suggested_edits},
			set(val) { this.$store.commit('set', ['comments_selected_suggested_edits', val])}
		},
		comments_show_resolved: {
			get() { return this.$store.state.comments_show_resolved},
			set(val) { this.$store.commit('set', ['comments_show_resolved', val])}
		},
		framework_comments() {
			let create_row = (c, item_string, item_string_search) => {
				let d = new Date(c.created_at * 1000)
				let commenter = c.first_name + ' ' + c.last_name

				// TODO: show group identifier
				let group = 'P'

				// get text of suggested edits for searchability
				let suggested_edits_search = ''
				if (c.suggested_edits) {
					for (let key in c.suggested_edits) {
						suggested_edits_search += c.suggested_edits[key]
					}
					suggested_edits_search = U.html_to_text(suggested_edits_search).toLowerCase()
				}

				return {
					comment: c,
					item_string: item_string,
					group: group,
					date: date.format(U.convert_to_local_date(d), 'M/D/YYYY h:mm A'),
					commenter: commenter,
					body: c.body,

					top_level: c.parent_comment_id == 0,

					item_string_search: item_string_search,
					group_search: group.toLowerCase(),
					commenter_search: commenter.toLowerCase(),
					body_search: U.html_to_text(c.body).toLowerCase(),
					suggested_edits_search: suggested_edits_search,
				}
			}

			// - Comments must be sortable by item, or by date
			// - Either way, we need to keep threads together
			// - So:
			// 	- Make list of top-level comments
			// 	- update search and sort data from replies
			// 	- Sort top-level comments by latest reply date or item
			// 	- Use the TileComment component to show top-level comments and replies in the table

			// create group_array and user_array as we go
			this.group_array = [
				{value:-2, text: 'ALL GROUPS'},
				{value:0, text: 'Personal Comments'},
			]
			if (this.viewer.public_review_on) this.group_array.push({value:-1, text: 'Public Review Comments'}),

			this.user_array = [
				{value:-1, text: 'ALL USERS'},
			]

			if (!this.comments_hash[this.framework_identifier]) return []
			let top_level_rows = []

			// for each item that has comments in this framework...
			let sort_item_index = 0
			let missing_items = 0
			let found_items = 0
			for (let item_identifier in this.comments_hash[this.framework_identifier]) {
				let cfitem = (item_identifier == this.framework_identifier) ? this.framework_record.json.CFDocument : this.framework_record.cfo.cfitems[item_identifier]

				// there could be comments entered for items that no longer exist...
				if (!cfitem) {
					++missing_items
					continue
				} else {
					++found_items
				}

				let item_string = U.generate_cfassociation_node_uri_title(cfitem, 40)
				let item_string_search = item_string.toLowerCase()
				sort_item_index += 1

				for (let c of this.comments_hash[this.framework_identifier][item_identifier]) {
					// add to user_array if not already there
					if (this.user_array.findIndex(x=>x.value == c.author_user_id) == -1) {
						this.user_array.push({value: c.author_user_id, text: c.first_name + ' ' + c.last_name})
					}

					// for each top-level comment attached to this identifier...
					if (c.parent_comment_id == 0) {
						// add to group_array if not already there
						if (this.group_array.findIndex(x=>x.value == c.comment_group_id) == -1) {
							let cg = this.comment_groups.find(x=>x.comment_group_id == c.comment_group_id)
							if (cg) this.group_array.push({value: c.comment_group_id, text: cg.name})	// should always be found
						}

						// if a group filter is selected, don't include the thread if it doesn't match comments_selected_group
						if (this.comments_selected_group != -2) {
							if (c.comment_group_id != this.comments_selected_group) {
								continue
							}
						}

						// if comments_selected_suggested_edits isn't 'all', filter accordingly
						if (this.comments_selected_suggested_edits != 'all') {
							if (this.comments_selected_suggested_edits == 'no_suggestions') {
								if (c.suggested_edits) continue
							} else {
								if (!c.suggested_edits) continue
								if (this.comments_selected_suggested_edits == 'unapplied' && c.suggested_edits.applied) continue
								if (this.comments_selected_suggested_edits == 'applied' && !c.suggested_edits.applied) continue
							}
						}

						// create row for the top-level comment
						let parent_row = create_row(c, item_string, item_string_search)

						// sort_date_timestamp is by default the created_date of the top-level comment; but this could be updated by a reply
						parent_row.sort_date_timestamp = c.created_at
						// sort_item_index goes by the tree_key of the first tree_node for the item
						parent_row.sort_item_index = oprop(this.framework_record.cfo.cfitems[item_identifier], 'tree_nodes', 0, 'tree_key') || 0

						// if a user filter is selected, don't include the thread if comments_selected_user didn't contribute; start by checking the top comment
						let selected_user_included_in_thread
						if (this.comments_selected_user == -1) {
							selected_user_included_in_thread = true
						} else {
							// if there is a comments_selected_user, start by setting selected_user_included_in_thread based on the top comment
							// if a reply matches comments_selected user, we'll set selected_user_included_in_thread to true below
							if (c.author_user_id == this.comments_selected_user) selected_user_included_in_thread = true
							else selected_user_included_in_thread = false
						}

						// now add search/sort data from replies to this parent row; also check for user filter
						parent_row.replies = []
						for (let cc of this.comments_hash[this.framework_identifier][item_identifier]) {
							if (cc.parent_comment_id == c.comment_id) {
								// check user filter if necessary
								if (this.comments_selected_user != -1) {
									if (cc.author_user_id == this.comments_selected_user) {
										selected_user_included_in_thread = true
									}
								}

								let child_row = create_row(cc, item_string, item_string_search)
								parent_row.commenter_search += ' ' + child_row.commenter_search
								parent_row.body_search += ' ' + child_row.body_search
								parent_row.suggested_edits_search += ' ' + child_row.suggested_edits_search

								// update top-level sort_date
								if (cc.created_at > parent_row.sort_date_timestamp) {
									parent_row.sort_date_timestamp = cc.created_at
								}
							}
						}

						// if selected_user_included_in_thread is false at this point, don't include the thread
						if (!selected_user_included_in_thread) {
							continue
						}

						// sort replies by date so that older ones come first
						parent_row.replies.sort((a,b) => {
							return a.created_at - b.created_at
						})

						// add row_date_string
						let d = new Date(parent_row.sort_date_timestamp * 1000)
						parent_row.row_date_string = date.format(U.convert_to_local_date(d), 'M/D/YYYY h:mm A')

						top_level_rows.push(parent_row)
					}
				}
			}

			// if comments_selected_group isn't amongst the computed group_array, set to -2; same for comments_selected_user (but there -1)
			if (this.group_array.findIndex(x=>x.value == this.comments_selected_group) == -1) this.comments_selected_group = -2
			if (this.user_array.findIndex(x=>x.value == this.comments_selected_user) == -1) this.comments_selected_user = -1

			// sort top-level comments by current criteria
			top_level_rows.sort((a,b) => {
				// if sort order is item, first sort by item
				if (this.comments_sort_order == 'item') {
					if (a.sort_item_index != b.sort_item_index) {
						return a.sort_item_index - b.sort_item_index
					}
				}
				// if sort order is date, or the items are the same, sort by date (newest first, so biggest timestamp first)
				return b.sort_date_timestamp - a.sort_date_timestamp
			})

			// console.log('missing_items: ' + missing_items + ' / found items: ' + found_items)

			return top_level_rows
		},
		n_resolved() {
			let n = 0
			for (let row of this.framework_comments) {
				if (row.comment.resolved) ++n
			}
			return n
		},
	},
	watch: {
	},
	created() {
		// DEBUG
		vapp.framework_comments_table = this
	},
	mounted() {
		// set itemsPerPage to value from store
		this.table_options.itemsPerPage = this.$store.state.lst.comments_table_items_per_page

		// if we get a starting_comment_id, make sure that comment is showing
		if (this.starting_comment_id > -1) {
			let i
			for (i = 0; i < this.framework_comments.length; ++i) {
				if (this.framework_comments[i].comment.comment_id == this.starting_comment_id) break
			}
			if (i < this.framework_comments.length) {
				let page = Math.floor(i / this.table_options.itemsPerPage) + 1	// options.page is 1-indexed
				setTimeout(x=>this.table_options.page = page, 0)	// not sure why this has to be in a timeout, but it does (nextTick doesn't work)
			}
		}
	},
	methods: {
		update_items_per_page(val) {
			// when the user chooses a different items-per-page value, save in store so we can restore that number when the table re-opens later
			this.$store.commit('lst_set', ['comments_table_items_per_page', val])
		},

		row_class(item) {
			return ''
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check name
			if (item.group_search.search(re) > -1) return true
			else if (item.item_string_search.search(re) > -1) return true
			else if (item.date.search(re) > -1) return true
			else if (item.commenter_search.search(re) > -1) return true
			else if (item.body_search.search(re) > -1) return true
			else if (item.suggested_edits_search.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		item_clicked(o) {
			// find the first tree_key for this identifier
			let tree_key
			if (o.comment.item_identifier == this.framework_identifier) {
				tree_key = '1'
			} else {
				let cfitem = this.framework_record.cfo.cfitems[o.comment.item_identifier]
				tree_key = cfitem.tree_nodes[0].tree_key
			}

			// do the same thing we would do if we'd found the item in a search and clicked on it
			this.$emit('search_result_clicked', {tree_key:tree_key})
			this.$emit('dialog_cancel')
		},

		download_comments() {
			let arr = [[
				'CASE Identifier',
				'Competency Statement',
				'Comment Date',
				'Commenter Name',
				'Comment Text and Replies',
			]]
			for (let item of this.framework_comments) {
				// start with identifier, item_string, and comment date
				let line = [item.comment.item_identifier, item.item_string, item.row_date_string]

				// add name of original commenter
				let name = item.comment.first_name
				if (item.comment.last_name) name += ' ' + item.comment.last_name
				line.push(name)

				// plain-text body of comment... 
				let s = ''
				s += U.html_to_text(item.comment.body)

				// ... plus replies if there were any
				let carr = this.comments_hash[item.comment.framework_identifier]
				if (carr) carr = carr[item.comment.item_identifier]
				if (carr) {
					carr = carr.filter((comment) => {
						return (comment.parent_comment_id == item.comment.comment_id && !empty(comment.body))
					})

					carr.sort((a,b) => {
						// sort replies so that older ones come first
						return a.created_at - b.created_at
					})

					for (let reply of carr) {
						let name = reply.first_name
						if (reply.last_name) name += ' ' + reply.last_name

						let d = new Date(reply.created_at * 1000)
						let fdate = date.format(U.convert_to_local_date(d), 'M/D/YYYY h:mm A')
						s += `\n--- Reply from ${name} at ${fdate}:\n${U.html_to_text(reply.body)}`
					}
				}

				line.push(s)

				arr.push(line)
			}

			let date_string = date.format(new Date(), 'YYYY-MM-DD')
			let filename = `${this.framework_record.json.CFDocument.title}-Comments-${date_string}.csv`
			U.download_file(CSV.stringify(arr), filename)
		},
	}
}
</script>

<style lang="scss">
.k-framework-comments-table {
	margin-top:8px;
	th {
		white-space:nowrap;
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:2px!important;
		padding-bottom:2px!important;
		// height:20px!important;
		border-color:transparent!important;
	}
}
</style>
