<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<div class="k-framework-usage-chart elevation-3" v-if="usage_data">
		<highcharts ref="graph" class="chart" :options="chartOptions"></highcharts>
		<div class="k-framework-usage-chart-options">
			<div style="pointer-events:auto; width:280px" class="mx-auto"><v-select v-model="usage_graph_dataset" :items="usage_graph_dataset_options" label="" dense outlined background-color="#fff" hide-details></v-select></div>
			<div class="k-framework-usage-chart-options-stats">
				<div>Total {{count_type_label}}: <b>{{framework_usage_data['total_'+count_type]}}</b></div>
				<div>Most recent day ({{most_recent_day_data[2]}}): <b>{{most_recent_day_data[1]}}</b></div>
				<div>Daily average (last {{smoother_days}} days): <b>{{most_recent_smoothed_data[1]}}</b></div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		lsdoc_identifier: { type: String, required: true },
		count_type: { type: String, required: true },
	},
	data() { return {
		counts_loaded: false,
		usage_graph_dataset_options: [
			{ value:"raw", text:"Raw daily usage" },
			{ value:"smoothed", text:"Smoothed daily usage" },
			{ value:"cum", text:"Cumulative usage" },
		],
		smoother_days: 21,
	}},
	computed: {
		...mapState(['user_info', 'usage_data']),
		usage_graph_dataset: {
			get() { return this.$store.state.lst.usage_graph_dataset },
			set(val) { this.$store.commit('lst_set', ['usage_graph_dataset', val]) }
		},
		framework_usage_data() { return this.usage_data.framework_usage_by_doc[this.lsdoc_identifier] },
		count_type_label() { return this.count_type == 'webapp' ? 'Web App unique views' : 'CASE API retrieval calls' },
		most_recent_day_data() { return this.framework_usage_data[this.count_type+'_raw'][this.framework_usage_data[this.count_type+'_raw'].length-1] },
		most_recent_smoothed_data() { return this.framework_usage_data[this.count_type+'_smoothed'][this.framework_usage_data[this.count_type+'_smoothed'].length-1] },
		chartOptions() {
			// parse the framework usage file if we haven't already done so
			if (!this.usage_data.framework_usage) this.parse_framework_usage_file()

			// if this.lsdoc_identifier is "all", first compile the stats for all frameworks
			if (this.lsdoc_identifier == 'all') {
				for (let ds in this.usage_data.framework_usage) {
					let o = {fa_unsigned:0, fa_signed:0, cau:0}
					let dd = this.usage_data.framework_usage[ds]
					for (let docid in dd) {
						if (!empty(dd[docid].fa_unsigned)) {
							console.log(dd[docid].fa_unsigned)
							o.fa_unsigned += dd[docid].fa_unsigned*1
							o.fa_signed += dd[docid].fa_signed*1
							o.cau += dd[docid].cau*1
						}
					}
					this.$store.commit('set', [dd, 'all', o])
				}
			}
			console.log(this.usage_data.framework_usage)

			// calculate this framework's usage data if not yet calculated
			if (!this.usage_data.framework_usage_by_doc[this.lsdoc_identifier]) {
				let data = {
					total_webapp: 0,
					total_api: 0,
					webapp_cum: [],
					api_cum: [],
					webapp_raw: [],
					api_raw: [],
					webapp_smoothed: [],
					api_smoothed: [],
				}
				// first count totals and get cumulative values
				for (let ds in this.usage_data.framework_usage) {
					let dd = this.usage_data.framework_usage[ds]
					let dts = dd.date.getTime()
					if (dd[this.lsdoc_identifier]) {
						let webapp = dd[this.lsdoc_identifier].fa_signed*1 + dd[this.lsdoc_identifier].fa_unsigned*1
						let api = dd[this.lsdoc_identifier].cau*1
						data.total_webapp += webapp
						data.total_api += api
						data.webapp_cum.push([dts, data.total_webapp])
						data.api_cum.push([dts, data.total_api])
					}
				}

				// then get daily raw and smoothed values; only start tracking once we reach 1/100 of the total usage, so that you don't have to worry about the "long tail" when the framework is initially being developed
				let smoothed_value = (val, queue) => {
					queue.push(val)
					if (queue.length >= this.smoother_days) {
						queue.shift()
					}
					let sum = 0
					for (let i = 0; i < queue.length; ++i) sum += queue[i]
					return (sum / queue.length).toFixed(1)*1
				}
				
				let temp_webapp = 0
				let temp_api = 1
				let webapp_queue = []
				let api_queue = []
				for (let ds in this.usage_data.framework_usage) {
					let dd = this.usage_data.framework_usage[ds]
					let dts = dd.date.getTime()
					if (dd[this.lsdoc_identifier]) {
						let webapp = dd[this.lsdoc_identifier].fa_signed*1 + dd[this.lsdoc_identifier].fa_unsigned*1
						let smoothed_webapp = smoothed_value(webapp, webapp_queue)
						temp_webapp += webapp
						if (temp_webapp > data.total_webapp/100) {
							data.webapp_raw.push([dts, webapp, ds])
							data.webapp_smoothed.push([dts, smoothed_webapp, ds])
						}

						let api = dd[this.lsdoc_identifier].cau*1
						let smoothed_api = smoothed_value(api, api_queue)
						temp_api += api
						if (temp_api > data.total_api/100) {
							data.api_raw.push([dts, api, ds])
							data.api_smoothed.push([dts, smoothed_api, ds])
						}
					}
				}

				this.$store.commit('set', [this.usage_data.framework_usage_by_doc, this.lsdoc_identifier, data])
			}

			let data = this.usage_data.framework_usage_by_doc[this.lsdoc_identifier][this.count_type + '_' + this.usage_graph_dataset]

			let o = {
				chart: {
					type: 'line',
					height: 360,
					zoomType: 'xy',
					resetZoomButton: {
						position: {
							align: 'right',
							x: 0,
						},
					},
					fontFamily: 'Arial, sans-serif',
					events: {
						render: function() {
							// console.log('chart rendered!')
							// vapp.chart = this
						}
					},
				},
				title: { text: null },
				xAxis: {
					type: 'datetime',
					title: null,
					visible: true,
					labels: {
						formatter: function() {
							return date.format(new Date(this.value), 'M/D/YY')
						}
					},
				},
				yAxis: {
					title: { text: this.count_type_label },
					allowDecimals: false,
				},
				tooltip: {
					formatter: function() {
						return date.format(new Date(this.x), 'dd M/D/YY') + ': ' + this.y
					}
				},
				plotOptions: {
				},
				legend: {
					enabled: false,
				},
				credits: { enabled: false },
				series: [{ 
					name: 'Series Name!', 
					marker: { symbol: 'circle', fillColor: '#7986cb'}, 
					color:'#7986cb', 
					data: data 
				}],
			}
			
			return o
		},
	},
	created() {
		this.$store.dispatch('get_usage_data')
	},
	mounted() {
	},
	methods: {
		parse_framework_usage_file() {
			// do the basic parsing of the usage data file into one item per date
			// this is set up so that we only do this once per page load
			// the code above then parses individual frameworks

			let lines = this.usage_data.files.framework_access_counts.split("\n")
			let data = {}
			for (let line of lines) {
				// 2021-12-03:{"d56d1aaa-f540-4a15-95e8-b1954645de23":{"fa_unsigned":0,"fa_signed":1,"cau":0},"00fcf0e2-b9c3-11e7-a4ad-47f36833e889":{"fa_unsigned":0,"fa_signed":1,"cau":0}}
				line = $.trim(line)
				if (line.search(/((\d+)-(\d+)-(\d+)):(.*)/) > -1) {
					let json = RegExp.$5
					let ds = RegExp.$1
					let date = new Date(RegExp.$2*1, RegExp.$3*1-1, RegExp.$4)
					data[ds] = JSON.parse(json)
					data[ds].date = date
				}
			}
			this.$store.commit('set', [this.usage_data, 'framework_usage', data])
			this.$store.commit('set', [this.usage_data, 'framework_usage_by_doc', {}])
		},
	}
}
</script>

<style lang="scss">
.k-framework-usage-chart {
	position:relative;
	background-color:#222;
	border-radius:12px;
	margin-bottom:16px;
	padding:8px 0;
}

.k-framework-usage-chart-options {
	pointer-events: none;
	position:absolute;
	display:flex;
	align-items:center;
	left:120px;
	top:10px;
	// width:70%;
	.k-framework-usage-chart-options-stats {
		text-align:center;
		background-color:rgba(255,255,255,0.8);
		font-size:12px;
		line-height:17px;
		display:inline-block;
		margin-left:12px;
		padding:4px 8px;
		border-radius:8px;
	}
}
</style>
