<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1300" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Resources <v-icon color="#000">fas fa-long-arrow-alt-right</v-icon> Standards Alignments</b>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div style="clear:both">
				<div class="py-4 d-flex"><v-spacer />
					<v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 600px"
					></v-text-field>
				<v-spacer /></div>

				<v-data-table light dense
					:headers="headers"
					:items="resources_for_table"
					:sort-by="[]"
					:sort-desc="[]"
					:must-sort="false"
					:custom-filter="table_search_filter"
					:search="search"
					hide-default-footer
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-resource-alignment-table"
				>
					<template v-slot:item="{ item }"><v-hover v-slot:default="{hover}" open-delay="200" close-delay="100"><tr :class="item.aligned?'k-resource-alignment-table-aligned-row':''">
						<td @click="$emit('go_to_resource', item.resource.resource_id)" style="cursor:pointer"><v-btn icon small class="mr-2" @click=""><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>{{item.external_resource_id}}</td>
						<td class="text-left"><div class="k-resource-alignment-table-metadata-display" :class="hover?'k-resource-alignment-table-metadata-display-showing':''" v-html="item.metadata_display"></div></td>
						<td class="text-center"><nobr>{{item.education_level_for_table}}</nobr></td>
						<td class="text-left"><div v-html="item.alignments_display"></div></td>
					</tr></v-hover></template>
				</v-data-table>
			</div>
		</v-card-text>
		<v-card-actions class="mt-3">
			<v-spacer></v-spacer>
			<v-btn color="primary" @click="$emit('dialog_cancel')" class="mr-1">Done</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		resources: { type: Array, required: true },
		framework_record: { required: true },
	},
	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Resource ID', align: 'left', sortable: true, value:'external_resource_id' },
			{ text: 'Title/Description/Keywords', align: 'left', sortable: false, value:'metadata' },
			{ text: 'Education Level', align: 'center', sortable: true, value:'education_level_for_table' },
			{ text: 'Alignments', align: 'left', sortable: true, value:'alignments_display' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
	}},
	computed: {
		...mapState(['user_info', 'grades']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		resources_for_table() {
			let arr = []
			for (let r of this.resources) {
				let o = {
					resource: r,
					external_resource_id: r.external_resource_id,
					education_level_for_table: U.grade_level_display(r.educationLevel, this.grades)
				}

				// create searchable metadata field and text to display in the title/description/keywords column
				o.metadata = o.education_level_for_table.toLowerCase()
				o.metadata_display = ''
				if (r.title) {
					o.metadata_display += sr('<div class="my-1"><b>Title:</b> $1</div>',r.title)
					o.metadata += ' ' + r.title.toLowerCase()
				}
				if (r.description) {
					o.metadata_display += sr('<div class="my-1"><b>Description:</b> $1</div>',r.description)
					o.metadata += ' ' + r.description.toLowerCase()
				}
				if (r.text) {
					o.metadata_display += sr('<div class="my-1"><b>Keywords:</b> $1</div>',r.text)
					o.metadata += ' ' + r.text.toLowerCase()
				}

				// create text to display in the alignments column
				o.alignments_display = ''
				let other_framework_alignment_count = 0
				for (let a of r.alignments) {
					if (a.item_identifier == 'placeholder') continue
					// for alignments to the current framework, specify individually with generate_cfassociation_node_uri_title
					if (a.framework_identifier == this.framework_identifier) {
						// mark so we can make the row green
						o.aligned = true

						let item = this.framework_record.cfo.cfitems[a.item_identifier]
						if (item) {
							if (item.humanCodingScheme) item = item.humanCodingScheme
							else item = U.generate_cfassociation_node_uri_title(item, false)
						} else {
							item = a.item_identifier
						}
						if (o.alignments_display) o.alignments_display += ', '
						o.alignments_display += item

					} else {
						// count other alignments
						++other_framework_alignment_count
					}
				}

				// add alignments_display to searchable metadata prior to adding count of other-framework alignments
				o.metadata += ' ' + o.alignments_display
				if (o.alignments_display) o.alignments_display += '&nbsp;&nbsp;'
				o.alignments_display += sr('<b class="grey--text">[$1]</b>', other_framework_alignment_count)

				arr.push(o)
			}
			return arr
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check fields
			if (item.metadata.search(re) > -1) return true

			// if we get to here return false
			return false
		},

	}
}
</script>

<style lang="scss">
.k-resource-alignment-table {
	th {
		white-space:nowrap;
	}
	th, td {font-size:14px!important;}
}

.k-resource-alignment-table-metadata-display {
	height:28px;
	overflow:hidden;
}

.k-resource-alignment-table-metadata-display-showing {
	height:auto;
	overflow: visible;
}

.k-resource-alignment-table-aligned-row {
	background-color:$v-green-lighten-5;
}
</style>
