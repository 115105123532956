<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div v-if="item">
	<div class="k-case-tree-item-tile-full-screen-overlay" @click="hide_expanded_item"></div>
	<CASEItemTile :home_framework_record="framework_record" :item="item" :item_framework_record="item_framework_record" :viewer="viewer" :expanded_tile="true" @full_screen_compressed="hide_expanded_item" @more_info="more_info(item)" />
	<div class="k-case-tree-item-tile-full-screen-history">
		<v-btn :disabled="history_pointer==0" icon small color="#666" @click="move_via_history(-1)" class="mr-0"><v-icon>fas fa-circle-arrow-left</v-icon></v-btn>
		<v-btn :disabled='history_pointer==(history.length-1)' icon small color="#666" @click="move_via_history(1)" class="ml-0"><v-icon>fas fa-circle-arrow-right</v-icon></v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASEItemTile from './CASEItemTile'

export default {
	components: { CASEItemTile },
	props: {
		// item: { required: true },
		// the received framework_record here is the "home" framework that the user is viewing; we may show a tile for an item from an associated framework
		framework_record: { required: true },
		viewer: { required: true },
	},
	data() { return {
		item: null,
		item_framework_record: null,
		history: [],
		history_pointer: -1,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
		item() {

		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_item(item, item_framework_record) {
			this.item = item
			this.item_framework_record = item_framework_record

			// push new item to history, unless this is the current item in history
			let last = this.history[this.history_pointer]
			if (!last || last.item.cfitem.identifier != item.cfitem.identifier) {
				// if history_pointer wasn't already at the end of history, delete everything beyond history_pointer
				if (last && this.history_pointer != this.history.length-1) {
					this.history.splice(this.history_pointer+1, 100000)
				}

				this.history.push({item:item, item_framework_record:item_framework_record})
				this.history_pointer = this.history.length-1
			}
		},

		move_via_history(delta) {
			this.history_pointer += delta
			this.item = this.history[this.history_pointer].item
			this.item_framework_record = this.history[this.history_pointer].item_framework_record
		},

		hide() {
			this.item = null
			this.item_framework_record = null
		},

		hide_expanded_item() {
			this.viewer.hide_expanded_item()
		},

		more_info() {
			this.viewer.more_info(this.item)
		},
	}
}
</script>

<style lang="scss">
.k-case-tree-item-tile-full-screen-overlay {
	position:fixed;
	z-index:198;
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	background-color:rgba(0,0,0,0.3);
	cursor:pointer;
}

.k-case-tree-item-tile-outer-full-screen {
	position:fixed;
	z-index:199;
	left:12px;
	top:116px;
	// height:800px;
	margin-top:0;
	// max-height:calc(100vh - 128px);
	width:1080px;
	margin-left:calc(50vw - 540px);
	max-width:calc(100vw - 24px);

	.k-case-tree-item-tile {
		// max-height:100%;
		// max-height:800px;
		max-height:calc(100vh - 148px);
	}

	.k-big-table {
		th { font-size:0.8em; }
		td { font-size:0.8em; }
	}
}

.k-case-tree-item-tile-full-screen-history {
	position:fixed;
	z-index:199;
	left:12px;
	top:90px;
	margin-left:calc(50vw - 40px);

	width:80px;
	padding:4px 0;
	// height:24px;
	background-color:#fff;
	border-radius:8px;
	border:1px solid #ccc;
	text-align:center;
}

@media (max-width: 1104px) {
	.k-case-tree-item-tile-outer-full-screen {
		margin-left:0;
	}
}
</style>
