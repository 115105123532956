<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" max-width="860" persistent scrollable><v-card>
<v-card-title style="border-bottom:1px solid #999">
	<b>Export to Canvas spreadsheet format</b>
	<v-spacer></v-spacer>
	<v-btn color="secondary" x-small fab @click="$emit('dialog_cancel')"><v-icon>fas fa-xmark</v-icon></v-btn>
</v-card-title>
<v-card-text class="mt-3" style="font-size:16px; line-height:20px;">
	<div>
		<div>This tool will allow you to download a CSV file in Canvas <a href="https://canvas.instructure.com/doc/api/file.outcomes_csv.html" target="_blank">“Outcomes Import Format”</a>, which you can then upload to a Canvas course or district Canvas implementation. See this <a href="https://community.canvaslms.com/t5/Instructor-Guide/How-do-I-import-outcomes-for-a-course/ta-p/702">Canvas help document</a> for more information.</div>
		<div class="mt-3">To start the process, choose which framework item types should be considered “outcomes” in Canvas. Other item types will be imported as “groups", which will preserve (at least to some extent) the “tree structure” of the framework as represented in {{site_config.app_name}}.</div>
		<div class="d-flex align-end">
			<div>
				<h3 class="mt-3 mb-2"><b>Outcome types:</b></h3>
				<div v-for="(val, item_type) in item_types" :key="item_type" class="pt-1 ml-2">
					<div class="d-flex">
						<v-checkbox class="mt-0 pt-0" :label="item_type" v-model="item_types[item_type]" hide-details></v-checkbox>
						<div class="ml-2"><v-btn x-small text color="secondary" @click="get_item_type_example(item_type)">Sample<v-icon v-if="item_type_examples[item_type]" small class="ml-2">fas fa-recycle</v-icon></v-btn></div>
					</div>
					<div v-show="item_type_examples[item_type]" class="ml-8 mb-2" style="font-size:14px; line-height:18px;" v-html="item_type_examples[item_type]"></div>
				</div>
			</div>
			<v-spacer/>
			<div class="ml-8"><v-btn :disabled="!ready_to_process||ready_to_download" color="primary" @click="process_rows">Prepare CSV <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn></div>
		</div>
	</div>
	<div v-if="ready_to_download" class="mt-4">
		<div class="d-flex align-end">
			<div>
				<h3>Ready to Download</h3>
				<div class="mt-3">Click to download a CSV file with:
					<ul>
						<li>{{rows.length}} total {{U.ps('row',rows.length)}}<ul>
							<li>{{group_count}} “{{U.ps('group', outcome_count)}}”</li>
							<li>{{outcome_count}} “{{U.ps('outcome', outcome_count)}}”</li>
						</ul></li>
					</ul>
				</div>
			</div>
			<v-spacer/>
			<div class="ml-8"><v-btn color="primary" @click="download_csv">Download CSV <v-icon small class="ml-2">fas fa-file-arrow-down</v-icon></v-btn></div>
		</div>
	</div>
</v-card-text>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
// components: { CanvasImport },
	props: {
		framework_record: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		ready_to_process: false,
		ready_to_download: false,
		rows: [],
		outcome_count: 0,
		group_count: 0,
		item_types: {},
		item_type_examples: {},
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),
	},
	watch: {
		item_types: {ximmediate: true, deep: true, handler(val) {
			this.$store.commit('lst_set_hash', ['canvas_export_item_types', this.framework_record.lsdoc_identifier, this.item_types])
			this.ready_to_download = false
			for (let i in this.item_types) {
				if (this.item_types[i]) {
					this.ready_to_process = true
					return
				}
			}
			this.ready_to_process = false
		}},
	},
	created() {
		let o = {}
		let oo = this.$store.state.lst.canvas_export_item_types[this.framework_record.lsdoc_identifier] || {}
		for (let item_type of this.framework_record.cfo.item_types) {
			o[item_type] = oo[item_type] ?? false
		}
		this.item_types = o
	},
	mounted() {
	},
	methods: {
		get_item_type_example(item_type) {
			let keys = Object.keys(this.framework_record.cfo.cfitems)
			U.shuffle_array(keys)
			for (let key of keys) {
				let cfi = this.framework_record.cfo.cfitems[key]
				if (U.item_type_string(cfi) == item_type) {
					this.$set(this.item_type_examples, item_type, U.generate_cfassociation_node_uri_title(cfi, 150, true))
					return
				}
			}
			this.$set(this.item_type_examples, item_type, '???')
		},

		process_rows() {
			// https://canvas.instructure.com/doc/api/file.outcomes_csv.html
			// vendor_guid, object_type, title,             description,             display_name, calculation_method, calculation_int, workflow_state, parent_guids, 
			// b,           group,       Child group,       child group description, G-1.1,        XX,                 XX,              active,         a,
			// c,           outcome,     Learning Standard, outcome description,     LS-100,       decaying_average,   40,              active,         a b, 

			// https://docs.google.com/spreadsheets/d/1EjskpRdNPQ0clxav8Wd9mdGOwcd-fjQG/edit?gid=940659245#gid=940659245
			// DV puts HCS in "title"; has a combo of HCS and fullStatement in both "description" and "display_name"; does not use "friendly_description"

			let rows = [], outcome_count = 0, group_count = 0
			rows.push([
				'vendor_guid',
				'object_type',
				'title',
				'description',
				'display_name',
				'calculation_method',
				'calculation_int',
				'workflow_state',
				'parent_guids',
			])

			let add_row = (node, parent_guid) => {
				let cfi = node.cfitem

				let fs = cfi.title || cfi.fullStatement

				let title = cfi.humanCodingScheme ? cfi.humanCodingScheme : fs
				let object_type = this.item_types[U.item_type_string(cfi)] ? 'outcome' : 'group'
				let description = (cfi.humanCodingScheme ? cfi.humanCodingScheme + ': ' : '') + fs

				// display_name is limited to max 255 characters
				let display_name = U.truncate_to_word(description, 255)

				// for 'group's, Canvas doesn't show the description, so have to put the fullStatement in the title
				if (object_type == 'group') title = description

				if (object_type == 'outcome') outcome_count += 1
				else group_count += 1

				let row = [
					cfi.identifier,		// vendor_guid
					object_type,		// object_type
					title,				// title
					description,		// description
					display_name,		// display_name
					'',					// calculation_method
					'',					// calculation_int
					'active',			// workflow_state
					parent_guid,		// parent_guids
				]
				rows.push(row)

				// children are put under this item if this item is a group, or under the parent_guid if this item is an outcome
				let child_parent = (object_type == 'group') ? cfi.identifier : parent_guid

				for (let child_node of node.children) {
					add_row(child_node, child_parent)
				}
			}

			add_row(this.framework_record.cfo.cftree, '')
			console.log(rows)

			this.rows = rows
			this.group_count = group_count
			this.outcome_count = outcome_count
			this.ready_to_download = true
			this.ready_to_process = false
		},

		download_csv() {
			let date_string = date.format(new Date(), 'YYYY-MM-DD')
			let filename = `${this.framework_record.json.CFDocument.title}-CANVAS-OUTCOMES-${date_string}.csv`
			U.download_file(CSV.stringify(this.rows), filename)
		}
	}
}
</script>

<style lang="scss">
</style>