<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="k-track-changes-legend" :style="`height:${height}px`" @click="toggle_legend">
	<div class="pa-2">
		<div class="d-flex"><v-icon color="green" class="mr-2">fas fa-circle</v-icon><div>Item <b>unchanged</b>, no updated children</div></div>
		<div class="d-flex"><v-icon color="amber darken-3" class="mr-2" style="transform:rotate(90deg)">fas fa-circle-play</v-icon><div><b>Children updated</b>, but item unchanged</div></div>
		<div class="d-flex"><v-icon color="blue" class="mr-2">fas fa-circle-half-stroke</v-icon><div>Item has been <b>updated</b></div></div>
		<div class="d-flex"><v-icon color="purple" class="mr-2">fas fa-circle-plus</v-icon><div>Item is <b>new</b></div></div>
		<div class="d-flex"><v-icon color="red" class="mr-2">fas fa-circle-xmark</v-icon><div>Item has been <b>deleted</b></div></div>
		<!-- <div class="d-flex"><v-icon color="xxx" class="mr-2">fas fa-circle</v-icon><div>XXX</div></div> -->
	</div>
	<div style="position:absolute; right:4px; bottom:4px;"><v-btn small icon color="orange darken-3" @click.stop="toggle_legend"><v-icon>fas fa-circle-xmark</v-icon></v-btn></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { TrackChangesLegend },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		showing: true,
		height: 132,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_legend(val) {
			if (typeof(val) != 'boolean') {
				// if val isn't boolean, we switch to showing if height is currently 0, or hiding otherwise
				val = (this.height == 0)
			}
			if (val) this.height = 132
			else this.height = 0
			if (!val) setTimeout(x=>{ $('.k-track-changes-legend').hide() }, 240)
			else $('.k-track-changes-legend').show()
			this.showing = val
			console.log(this.showing)
		},
	}
}
</script>

<style lang="scss">
.k-track-changes-legend {
	position:fixed;
	right:0px;
	bottom:32px;
	height:132px;
	overflow:hidden;
	transition:height 0.25s;
	z-index:199;

	// background-color:rgba(240,240,240,0.85);
	border:4px solid $v-orange-darken-3;
	background-color:#eee;
	// opacity:0.95;
	color:#666;
	border-radius:10px 10px 0 0;
	white-space:nowrap;
	font-size:15px;
	line-height:22px;
	.v-icon {
		font-size:18px;
	}
}
</style>