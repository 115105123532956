<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title>
		<div>{{new_user ? 'Create' : 'Edit'}} User <b class="mx-1">{{user.email}}</b> {{(new_user) ? '' : ('(' + user.last_name + ', ' + user.first_name + ')')}}</div>
		<v-spacer></v-spacer>
		<v-btn icon @click="show_help" color="light-blue"><v-icon large>fas fa-info-circle</v-icon></v-btn>
	</v-card-title>
	<v-card-text style="color:#000;">
		<div class="d-flex align-center mt-3">
			<v-text-field class="mr-1" dense outlined hide-details label="First Name" v-model="first_name"></v-text-field>
			<v-text-field class="ml-1" dense outlined hide-details label="Last Name" v-model="last_name"></v-text-field>
		</div>
		<div class="d-flex align-center justify-center mt-3">
			<v-text-field v-if="!use_oidc_login" class="mr-1" dense outlined hide-details label="Enter a new password (optional)" v-model="password" autocomplete="new-password"></v-text-field>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!use_oidc_login" x-small fab color="primary" class="mr-3" @click="generate_random_password"><v-icon small>fas fa-shuffle</v-icon></v-btn></template>Generate random password</v-tooltip>
			<v-select class="ml-1" style="flex:0 0 340px" outlined hide-details v-model="system_role" :items="system_role_options" label="System Role" solo hide-details dense></v-select>
		</div>
		<div v-if="!use_oidc_login&&!new_user" class="mt-2">
			<v-btn small color="secondary" class="k-tight-btn" @click="send_password_reset"><v-icon small class="mr-2">fas fa-envelope</v-icon> Send One-Time Sign-In Link…</v-btn>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn v-if="!use_oidc_login&&user.user_id!=0" color="red" dark @click="$emit('user_deleted')"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete User</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="cancel_clicked" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits" class="k-tight-btn"><v-icon small class="mr-2">fas fa-check</v-icon> {{new_user ? 'Create user and send invitation email' : 'Save Changes'}}</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		user: { type: Object, required: true },
		warn_for_non_default_rights: { type: Boolean, required: false, default() { return false }},
	},
	data() { return {
		dialog_open: true,
		first_name: this.user.first_name,
		last_name: this.user.last_name,
		password: '',
		system_role: this.user.system_role,
	}},
	computed: {
		...mapState(['user_info', 'use_oidc_login']),
		new_user() { return empty(this.user.last_name) },
		system_role_options() {
			let arr = [
				{value:'admin', text: 'System Admin'},
				{value:'editor', text: 'System Editor'},
				{value:'reviewer', text: 'System Reviewer (view private)'},
				{value:'', text: 'System Default Rights (view public)'},
			]
			return arr
		},
	},
	created() {
		// this.system_role = this.user.system_role
	},
	mounted() {
		// DEBUG
		vapp.user_edit = this
	},
	methods: {
		show_help() { vapp.show_help('roles_system') },

		save_edits(flag) {
			// if this is a new user, a password must be specified, unless we're allowing for oidc_login
			if (!this.use_oidc_login) {
				this.password = $.trim(this.password)
				if (this.user.user_id == 0 && empty(this.password)) {
					this.$alert('You must set the password for a new user. Click the <i class="fas fa-shuffle"></i> button to generate a random password.<br><br><b>Note:</b> once the new user is created, a one-time sign-in link will be sent to the user via email, which will be valid for 24 hours. Clicking this link will sign the user in, after which they can change their password themselves.')
					return
				}
			}

			this.first_name = $.trim(this.first_name)
			this.last_name = $.trim(this.last_name)
			if (empty(this.first_name) || empty(this.last_name)) {
				this.$alert('You must specify the first and last name for the user.')
				return
			}

			if (this.warn_for_non_default_rights && flag != 'confirmed' && this.system_role != '') {
				let rl = U.capitalize_word(this.system_role)
				this.$confirm({
				    title: 'Are you sure?',
				    text: sr('Are you sure you want to give this new user system-level $1 rights? If you want to limit the user’s access to designated frameworks, you should give them System-level Default Rights.', rl),
				    acceptText: sr('Grant $1 Rights', rl),
					dialogMaxWidth: 800
				}).then(y => {
					this.save_edits('confirmed')
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let payload = {
				user_id_being_edited: this.user.user_id,
				email: this.user.email,
				first_name: this.first_name,
				last_name: this.last_name,
				password: this.password,
				system_role: this.system_role || '*CLEAR*'
			}

			this.$emit('user_edited', payload)
		},

		cancel_clicked() {
			// if this was a new user, delete; otherwise just close the dialog
			if (this.user.user_id == 0) this.$emit('user_deleted')
			else this.$emit('editor_cancel')
		},

		generate_random_password() {
			let p = ''
			let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
			for (let i = 0; i < 12; ++i) {
				p += chars[U.random_int(chars.length)]
			}
			this.password = p
		},

		send_password_reset() {
			this.$confirm({
				title: 'One-Time Sign-In Link',
				text: 'Click the button below to send a one-time sign-in link to the user, which will be valid for 24 hours. This will allow the user to sign in to ' + this.$store.state.site_config.app_name + ' by clicking the link; then they can change their password themselves.',
				acceptText: 'Send One-Time Sign-In Link'
			}).then(y=>{
				// if sending token login link from here, don't add any extra message to the start of the email
				this.$emit('send_token_login', {user_email: this.user.email, body_html: ''})
			})
		},
	}
}
</script>

<style lang="scss">
</style>
