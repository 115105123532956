<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Import Framework as Mirror</b></v-card-title>
			<v-card-text class="mt-3 k-reduce-file-size-checkboxes" style="font-size:16px">
				<!-- <p>Import a framework as a mirror from the framework’s “source of truth” repository.</p> -->
				<div>A mirrored framework can be viewed, searched, annotated, and commented on; but its content cannot be changed, except by updates from the source framework.</div>
				<div class="mt-2">Enter the CASE “CFPackages” API url for each framework you wish to mirror. Example:</div>
				<div class="mt-1" style="font-size:14px"><b>https://case.georgiastandards.org/ims/case/v1p0/CFPackages/e9dd7229-3558-4df2-85c6-57b8938f6180</b></div>
				<div class="d-flex align-center justify-center mt-3">
					<v-textarea class="mr-1" dense outlined hide-details label="CFPackages API url" v-model="repo_full_address" auto-grow rows="4"></v-textarea>
				</div>
				<div class="mt-6">Check the box below to have the system automatically check mirror source frameworks daily for updates. If you don’t enable auto-updates, you can manually check for updates at any time. (And you can always enable or disable auto-updates later.)</div>
				<div class="mt-2 d-flex align-end">
					<v-checkbox class="mr-4 mt-0" :label="'Perform automatic daily update checks from source framework'" v-model="mirror_auto_updates" hide-details></v-checkbox>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn small color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn small color="primary" @click="import_framework_mirror"><v-icon small class="mr-2">fas fa-file-import</v-icon>Import Mirror</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: { },
	data() { return {
		dialog_open: true,
		repo_full_address: '',
		mirror_auto_updates: true,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
	},
	watch: {
	},
	created() {

	},
	mounted() {
	},
	methods: {

		import_framework_mirror(force_import) {
			let mirror_source_rest_api_urls = []
			let mirror_framework_identifiers = []
			let mirror_categories = []
			let current_category = ''
			let lines = this.repo_full_address.split('\n')

			for (let line of lines) {
				line = $.trim(line)
				// skip blank lines
				if (!line) continue

				// if we get a category line, store it
				if (line.search(/^category:\s*(.*)/) > -1) {
					current_category = RegExp.$1
					continue
				}

				// require a full url (we used to be more lenient, and deal with other values)
				if (line.search(/^http/i) < 0) {
					line = sr('https://$1', line)
				}

				// do some basic checking on the urls...
				let address_parts = line.match(/^(https?:\/\/.+)\/(ims\/case\/v\w+\/cfpackages).*([0-9a-fA-F-]{36})/i)
				if (address_parts === null || address_parts.length < 2) {
					let format_hint = '<p>Examples:</p>'
						+ '<ul><li>https://case.georgiastandards.org/ims/case/v1p0/CFPackages/27a08dc6-416e-11e7-ba71-02bd89fdd987</li>'
						+ '<li>https://casenetwork.imsglobal.org/ims/case/v1p0/CFPackages/97c883b4-8590-454f-b222-f28298ec9a81</li></ul>'
					this.$alert({title: 'Please enter a valid CASE REST API URL for the target framework.', text: format_hint, dialogMaxWidth:1000})
					return
				}

				let identifier = address_parts[3].toLowerCase()

				if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(identifier)) {
					this.$alert(sr('Framework_identifier [$1] is not a valid UUID.', identifier))
					return
				}

				mirror_source_rest_api_urls.push(line)
				mirror_framework_identifiers.push(identifier)
				mirror_categories.push(current_category)
			}

			if (mirror_source_rest_api_urls.length == 0) return

			// if you're trying to mirror more than one framework at a time, warn here that we'll force updates if necessary
			if (mirror_source_rest_api_urls.length > 1 && force_import != 'force_import') {
				this.$confirm({
					title: 'Import multiple frameworks',
					text: sr('Warning: If you attempt to import multiple frameworks all at once, the system will assume that you wish to override any issues that may arise, i.e. that the framework is already mirrored via a different source URL, or that the framework is currently published as an editable framework (in which case it will be overridden with the framework imported from the mirror). Do you wish to proceed anyway?'),
					acceptText: 'Import Frameworks',
					dialogMaxWidth: 800
				}).then(y => {
					this.import_framework_mirror('force_import')
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let payload = {
				user_id: this.user_info.user_id,
				mirror_framework_identifier: mirror_framework_identifiers,
				mirror_source_rest_api_url: mirror_source_rest_api_urls,
				mirror_categories: mirror_categories,
				mirror_auto_updates: (this.mirror_auto_updates) ? 'on' : 'off',
			}

			if (typeof(force_import) !== 'undefined' && force_import === 'force_import') { payload['force_import'] = 'yes' }

			U.loading_start()
			U.ajax('import_mirror_framework', payload, result=>{
				U.loading_stop()

				if (result.status !== 'ok') {
					// options if framework is on server
					// - if mirror and same mirror url notify user
					// - if mirror and different url, notify user then update url along with other import stuff
					// - if not a mirror, notify user and conver to mirror and import
					if (result.status === 'mirror_exists_same_domain') {
						this.$alert(sr('The specified framework is already being mirrored from $1 on this instance of $2.', mirror_source_rest_api_urls[0], this.$store.state.site_config.app_name))

					} else if (result.status === 'mirror_exists_different_domain') {
						this.$confirm({
							title: 'Change mirror source?',
							text: sr('The specified framework is already being mirrored on this instance of $1, but the framework is currently mirrored from a different CASE REST API ($2). Would you like to re-import from the new CASE REST API ($3)? If you do so, future updates will also be fetched from the new CASE server.', this.$store.state.site_config.app_name, result.mirror_source_rest_api_url, mirror_source_rest_api_urls[0]),
							acceptText: 'Import Mirror',
							dialogMaxWidth: 800
						}).then(y => {
							this.import_framework_mirror('force_import')
						}).catch(n=>{console.log(n)}).finally(f=>{})

					} else if (result.status === 'editable_exists') {
						this.$confirm({
							title: 'Convert to mirror?',
							text: sr('The specified framework is currently published as an editable framework (not a mirror) on this instance of $1. You can convert it to a mirrored framework if you wish. If you do so, the current framework will be archived and replaced with the mirrored framework.', this.$store.state.site_config.app_name),
							acceptText: 'Convert To Mirror',
							dialogMaxWidth: 800
						}).then(y => {
							this.import_framework_mirror('force_import')
						}).catch(n=>{console.log(n)}).finally(f=>{})

					} else {
						this.$alert(result.status); vapp.ping(); return;
					}

				} else {
					if (mirror_source_rest_api_urls.length > 1) {
						this.$alert({
							text: 'Reload the window to show the imported mirrors.',
							acceptText: 'Reload'
						}).then(y=>{
							window.location.reload()
						})

						return
					}

					// finish import
					let json
					try {
						json = JSON.parse(result.mirror_json)
					} catch(e) {
						console.log(e)
						this.$alert('The uploaded file was not valid JSON (an error occurred when attempting to parse the file).')
						return
					}
					// if no CFDocument, it's not valid json (this would be unreachable code for mirror import, mirror import service will fail)
					// TODO: do more validation...
					if (!json.CFDocument) {
						this.$alert('The imported mirror JSON did not include a CFDocument property, it is not a valid CASE framework file.')
						// roll back actions completed by import_mirror_framework service
						U.ajax('delete_framework', {lsdoc_identifier: mirror_framework_identifiers[0]}, result=>{});
						this.$emit('dialog_cancel')
						return
					}

					// missing identifiers are unrecoverable errors
					// if other required fields are missing values, let user decide on completing import
					let rv = this.validate_import_json(json)
					let error_html = ''
					let warn_intro = '<div>There are errors in the framework listed below. Do you want to continue importing as a mirror? You will not be able to edit and fix these problems in a framework mirror.</div><br>'
					let rollback_intro = '<div>There are unrecoverable errors in this mirror framework. Import can not be completed.</div><br>'

					// list errors by CF type, we can use field level: rollback|warn to visually que severity
					if (rv.cfd_errors.length > 0) {
						error_html += '<b>CFDocument:</b> ' +  rv.cfd_errors[0].identifier + '<br>'
						for (let cfd_err of rv.cfd_errors) { error_html += sr('Error: $1<br>', cfd_err.error) }
					}
					if (rv.cfi_errors.length > 0) {
						error_html += '<b>CFItems:</b><br>'
						for (let cfi_err of rv.cfi_errors) { error_html += sr('Identifier: $1 Error: $2<br>', cfi_err.identifier, cfi_err.error) }
					}
					if (rv.cfa_errors.length > 0) {
						error_html += '<b>CFAssociations:</b><br>'
						for (let cfa_err of rv.cfa_errors) { error_html += sr('Identifier: $1 Error: $2<br>', cfa_err.identifier, cfa_err.error) }
					}

					if (rv.status !== 'ok') {
						// roll back file save and framework db record completed by import_mirror_framework service
						if (rv.status === 'rollback') {
							U.ajax('delete_framework', {lsdoc_identifier: mirror_framework_identifiers[0]}, result=>{});
							this.$alert({
								title: '',
								text: rollback_intro + error_html,
								dialogMaxWidth: 800
							})
							this.$emit('dialog_cancel')
							return
						}

						this.$confirm({
							title: 'Continue import with errors?',
							text: warn_intro + error_html,
							acceptText: 'Complete Import',
							dialogMaxWidth: 800,
						}).then(y => {
							this.complete_import_mirror(json, result.mirror_framework_data)
							console.log('importing mirror with errors')
						}).catch(n => {
							U.ajax('delete_framework', {lsdoc_identifier: mirror_framework_identifiers[0]}, result=>{});
							this.$emit('dialog_cancel')
							return
						}).finally(f=>{})

					} else {
						this.complete_import_mirror(json, result.mirror_framework_data)
					}
				}
			});
		},

		// similar to complete_import_framework, but don't go through save_framework_data
		// mirrored framework file has been imported by import_mirror_framework and we just need to add to client
		complete_import_mirror(json, mirror_settings) {
			json.lsdoc_identifier = json.CFDocument.identifier

			let cfd_json = new CFDocument(json.CFDocument).to_json()	// it's probably overkill to do these transformation, but it shouldn't hurt

			let index = this.framework_records.findIndex(x=>x.lsdoc_identifier == cfd_json.identifier)
			if (index == -1) {
				let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json})

				framework_record.ss_framework_data.is_mirror = 'yes'
				framework_record.ss_framework_data.mirror_source_rest_api_url = mirror_settings.mirror_source_rest_api_url
				framework_record.ss_framework_data.mirror_auto_updates = mirror_settings.mirror_auto_updates
				framework_record.ss_framework_data.last_mirror_sync_status = mirror_settings.last_mirror_sync_status
				framework_record.ss_framework_data.last_mirror_sync_date = mirror_settings.last_mirror_sync_date
				framework_record.ss_framework_data.next_auto_update = mirror_settings.next_auto_update

				this.$store.commit('set', [this.framework_records, 'PUSH', framework_record])
			} else {
				// if we're "re-importing" an existing framework, create a new framework_record object, copying in old_framework's ss_framework_data; then when we open it we'll reload the new json from the server
				// note that if this is the case we won't have updated the ss_framework_data record in the DB, so we should get these values back when we reload
				let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json}, $.extend(true, {}, this.framework_records[index].ss_framework_data))

				framework_record.ss_framework_data.is_mirror = 'yes'
				framework_record.ss_framework_data.mirror_source_rest_api_url = mirror_settings.mirror_source_rest_api_url
				framework_record.ss_framework_data.mirror_auto_updates = mirror_settings.mirror_auto_updates
				framework_record.ss_framework_data.last_mirror_sync_status = mirror_settings.last_mirror_sync_status
				framework_record.ss_framework_data.last_mirror_sync_date = mirror_settings.last_mirror_sync_date
				framework_record.ss_framework_data.next_auto_update = mirror_settings.next_auto_update

				this.$store.commit('set', [this.framework_records, 'SPLICE', index, framework_record])
			}

			this.$emit('dialog_cancel')

			// then open the framework
			this.$emit('view_framework', json.lsdoc_identifier)
		},

		// validate framework json, return details failing element(s) and failure status 'ok'|'warn'|'rollback'
		// - ok: no elements failed cfobj.is_valid()
		// - rollback: framework can't be loaded into framework viewer without a fatal error, import will be rolled back
		// - warn: a field required by spec is not available, but framework can successfully load into framework viewer
		validate_import_json(json) {
			let rv = { status: 'ok', msg: '', cfd_errors: [], cfi_errors: [], cfa_errors: [] }
			// identifier is always a falal error
			let cfd_reqs = { rollback: ['lastChangeDateTime'], warn: ['uri', 'creator', 'title'] }
			let cfi_reqs = { rollback: ['lastChangeDateTime'], warn: ['uri', 'fullStatement'] }
			let cfa_reqs = { rollback: ['lastChangeDateTime'], warn: ['uri', 'associationType'] }


			// validate the CFDocument json
			// if framework missing CFDocument or CFDocument.identifier the import will fail in import_mirror_framework service
			let cfd_obj = new CFDocument(json.CFDocument)
			if (!cfd_obj.is_valid()) {
				// abort and rollback import if missing an identifier
				if (empty(cfd_obj.identifier)) {
					rv.status = 'rollback'
					rv.cfd_errors.push({identifier:'XXX', error: 'identifier missing', level: 'rollback'})
				} else {
					for (let i = 0; i < cfd_reqs['rollback'].length; ++i) {
						if (empty(cfd_obj[cfd_reqs['rollback'][i]])) {
							rv.status = 'rollback'
							rv.cfd_errors.push({identifier: cfd_obj.identifier, error: sr('$1 missing', cfd_reqs['rollback'][i]), level: 'rollback'})
						}
					}
					for (let i = 0; i < cfd_reqs['warn'].length; ++i) {
						if (empty(cfd_obj[cfd_reqs['warn'][i]])) {
							if (rv.status !== 'rollback') rv.status = 'warn'
							rv.cfd_errors.push({identifier: cfd_obj.identifier, error: sr('$1 missing', cfd_reqs['warn'][i]), level: 'warn'})
						}
					}
				}
			}

			// check the required fields in CFItems
			for(let item of json.CFItems) {
				let cfi_obj = new CFItem(item)
				if (!cfi_obj.is_valid()) {
					// abort and rollback import if missing an identifier
					if (empty(cfi_obj.identifier)) {
						rv.status = 'rollback'
						rv.cfd_errors.push({identifier:'XXX', error: 'identifier missing', level: 'rollback'})
					} else {
						for (let i = 0; i < cfi_reqs['rollback'].length; ++i) {
							if (empty(cfi_obj[cfi_reqs['rollback'][i]])) {
								rv.status = 'rollback'
								rv.cfi_errors.push({identifier: cfi_obj.identifier, error: sr('$1 missing', cfi_reqs['rollback'][i]), level: 'rollback'})
							}
						}
						for (let i = 0; i < cfi_reqs['warn'].length; ++i) {
							if (empty(cfi_obj[cfi_reqs['warn'][i]])) {
								if (rv.status !== 'rollback') rv.status = 'warn'
								rv.cfi_errors.push({identifier: cfi_obj.identifier, error: sr('$1 missing', cfi_reqs['warn'][i]), lelvel: 'warn'})
							}
						}
					}
				}
			}

			// check required fields and also validate origin & destination node uris
			for(let association of json.CFAssociations) {
				let cfa_obj = new CFAssociation(association)
				if (!cfa_obj.is_valid()) {
					// abort and rollback import if missing an identifier
					if (empty(cfa_obj.identifier)) {
						rv.status = 'rollback'
						rv.cfd_errors.push({identifier:'XXX', error: 'identifier missing', level: 'rollback'})
					} else {
						for (let i = 0; i < cfa_reqs['warn'].length; ++i) {
							if (empty(cfa_obj[cfa_reqs['warn'][i]])) {
								if (rv.status !== 'rollback') rv.status = 'warn'
								rv.cfa_errors.push({identifier: cfa_obj.identifier, error: sr('$1 missing', cfa_reqs['warn'][i])})
							}
						}
						if (!cfa_obj.originNodeURI.is_complete()) {
							rv.cfa_errors.push({identifier: cfa_obj.identifier, error: 'originNodeURI is incomplete'})
						}
						if (!cfa_obj.destinationNodeURI.is_complete()) {
							rv.cfa_errors.push({identifier: cfa_obj.identifier, error: 'destinationNodeURI is incomplete'})
						}
					}
				}
			}

			return rv
		},
	},

}
</script>
<style lang="scss">

</style>
