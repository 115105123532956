<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper k-editor-wrapper-wide k-case-move-editor" :class="top_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon class="mr-1">fas fa-arrows-alt</v-icon> Move items</div>
	</div>
	<div class="ma-2" style="font-size:14px">
		<div class="text-center">Drag and drop using the <v-icon small>fas fa-arrows-alt</v-icon> icons to rearrange items in the framework.</div>

		<div class="mt-1 text-center"><i>To allow a leaf in the tree to receive children, click the <v-icon small>fas fa-circle-dot</v-icon> icon for the leaf item.</i></div>
		<div v-if="!viewer.show_move_node_conversion" class="mt-1 text-center">
			<div class="ma-1 text-center"><i>Click the button below to “dock” a node in the tree to the right side of the screen.</i></div>
			<div class="mt-2"><v-btn small color="secondary" @click="start_move_node_conversion"><v-icon small class="mr-2">fas fa-arrow-right</v-icon>Dock Node…</v-btn><v-btn small class="ml-4" color="primary" @click="cancel_edit"><v-icon small class="mr-2">fas fa-times</v-icon> Done Moving</v-btn></div>
		</div>

		<div v-if="viewer.show_move_node_conversion" class="mt-1 text-center">
			<i>Click the title of the “node” item you’d like to dock to the right side of the window, to facilitate moving items from that node.</i>
			<div class="mt-2"><v-btn small color="secondary" @click="finish_move_node_conversion">CANCEL</v-btn></div>
		</div>
	</div>

	<!-- <div v-show="moving_item_with_click" class="px-4 pb-4 pt-2" style="font-size:16px">
		<div v-if="!click_moved_item_parent_node">In the framework tree, click the green <v-icon small color="green">fas fa-check-circle</v-icon> next to the item you want to be the new <b>parent</b> or <b>sibling</b> of this item. You'll then be asked to specify whether to move the item below, before, or after the chosen item and confirm the move.</div>

		<div v-else>
			<v-radio-group v-model="click_moved_item_parent_relationship" hide-details class="mt-0 pb-2">
				<v-radio value="child" label="Make this item a CHILD of the chosen item"></v-radio>
				<v-radio value="sibling_before" label="Make this item a SIBLING, BEFORE the chosen item"></v-radio>
				<v-radio value="sibling_after" label="Make this item a SIBLING, AFTER the chosen item"></v-radio>
			</v-radio-group>
		</div>

		<div class="mt-3 text-center">
			<v-btn small color="secondary" @click="cancel_move_with_click"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel Move</v-btn>
			<v-btn v-if="click_moved_item_parent_relationship" class="ml-2" small color="primary" @click="complete_move"><v-icon small class="mr-2">fas fa-check</v-icon> Confirm Move</v-btn>
		</div>
	</div> -->

	<!-- <div class="k-case-item-editor-buttons">
		<v-spacer></v-spacer>
		<v-btn small color="primary" @click="cancel_edit"><v-icon small class="mr-2">fas fa-times</v-icon> Done Moving</v-btn>
	</div> -->
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import ItemImporter from './ItemImporter'

export default {
	// components: { ItemImporter },
	props: {
		framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return ''} },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		checked_items: [],

		moving_item_with_click: false,
		click_moved_item_parent_node: null,
		click_moved_item_parent_relationship: null,
	}},
	computed: {
		...mapState(['framework_records']),
		...mapGetters([]),
		cfo() { return this.framework_record.cfo },
		lsdoc_identifier() { return this.framework_record.lsdoc_identifier },
		CFDocument() { return this.framework_record.json.CFDocument },
		framework_maximized() {
			if (empty(this.viewer)) return false
			return this.viewer.maximized
		},
		top_css() {
			return U.framework_color(this.CFDocument.identifier) + '-editor'
		},
	},
	watch: {
	},
	created() {
		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		this.viewer.current_editor = this
	},
	mounted() {
	},
	methods: {
		cancel_edit() {
			// make sure any pending click item move isn't happening anymore
			if (this.moving_item_with_click) {
				this.cancel_move_with_click()
			}

			// we may have moved the item that was active prior to batch-editing, which would have changed its key; if so clear the active flag
			if (empty(this.cfo.tree_nodes_hash[this.framework_record.active_node])) {
				// clear the active node and starting_lsitem_identifier
				this.viewer.make_node_active('', true)
			}

			// if viewer.move_mode_flag is 'reopen_side_by_side', reopen the sidebyside editor
			if (this.viewer.move_mode_flag == 'reopen_side_by_side') {
				this.viewer.side_by_side_editor_head_identifier = this.viewer.move_mode_param
			}
			this.viewer.move_mode_flag = null
			this.viewer.move_mode_param = null

			this.$emit('dialog_cancel')
		},

		item_move_drag_complete(evt) {
			// TODO: can't move the item into another parent if the parent already has that item!!!

			console.log(evt)
			let moved_key = $(evt.item).attr('data-tree-key')
			let from_parent_key = $(evt.from).attr('data-move-parent-key')
			let to_parent_key = $(evt.to).attr('data-move-parent-key')
			let moved_node_new_index = evt.newIndex

			console.log(sr('Move $1 from $2 to $3 -- index $4', moved_key, from_parent_key, to_parent_key, moved_node_new_index))

			let moved_node = this.framework_record.cfo.tree_nodes_hash[moved_key]
			let from_parent_node = this.framework_record.cfo.tree_nodes_hash[from_parent_key]
			let to_parent_node = this.framework_record.cfo.tree_nodes_hash[to_parent_key]

			let data = {
				lsdoc_identifier: this.lsdoc_identifier,
				CFAssociations: [],
			}

			// find the association for the item we're moving
			let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, moved_node.cfitem.identifier, from_parent_node.cfitem.identifier)
			if (index == -1) {
				this.$alert('Error completing the item move.')	// this shouldn't happen
				return
			}

			// change the association so it's pointing to the new parent; we'll add it to data below
			let target_association = this.framework_record.json.CFAssociations[index]
			this.$store.commit('set', [target_association.destinationNodeURI, 'title', U.generate_cfassociation_node_uri_title(to_parent_node.cfitem)])
			this.$store.commit('set', [target_association.destinationNodeURI, 'identifier', to_parent_node.cfitem.identifier])
			this.$store.commit('set', [target_association.destinationNodeURI, 'uri', to_parent_node.cfitem.uri])

			// set lastChangeDateTime to *NOW* so server will update to the correct timestamp
			target_association.lastChangeDateTime = '*NOW*'

			// remove the original_node from its previous parent's [children], wherever the parent might exist; don't delete the cfitem though (that's what the 'true' as the last parameter does)
			U.delete_node_from_cfo(this.cfo, moved_node, true)

			// add the node to the new parent's children array, at the right place in the array
			U.add_child_to_cfo(moved_node.cfitem, to_parent_node, this.$store, this.cfo, moved_node_new_index, moved_node.children, false)

			// NOTE: delete_node_from_cfo and add_child_to_cfo both take care of updating the item anywhere it exists in the tree, in the case that a parent is replicated in multiple places

			// other childrens' sequences may have also changed, so update the CFAssociations of all the parent's children
			// we have to do this for every parent node where the item exists; but we have to be careful to only update data once
			let data_updated = false
			for (let parent_node of to_parent_node.cfitem.tree_nodes) {
				// go through all this parent_node's children (including the moved item, which is now in parent_node.children) and update the sequenceNumbers
				// note that we have to make sequenceNumbers 1-based instead of 0-based, because OpenSALT doesn't deal properly with sequenceNumbers of 0 (it puts such items at the bottom of the list)
				for (let i = 0; i < parent_node.children.length; ++i) {
					let cn = parent_node.children[i]

					// for forms' sake, update the sequence in the node, although we really only use these node.sequence values when the tree is originally created
					this.$store.commit('set', [cn, 'sequence', i+1])

					// update the child's CFAssociation sequence and save to data (only do this once if we're handling multiple instances of the parent node)
					if (!data_updated) {
						let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, cn.cfitem.identifier, cn.parent_node.cfitem.identifier)
						if (index > -1) {
							console.log(sr('updating association $1 sequenceNumber from $2 to $3', this.framework_record.json.CFAssociations[index].identifier, this.framework_record.json.CFAssociations[index].sequenceNumber, i+1))
							this.$store.commit('set', [this.framework_record.json.CFAssociations[index], 'sequenceNumber', i+1])
							// add to the list of CFAssociations to save to the server
							data.CFAssociations.push(new CFAssociation(this.framework_record.json.CFAssociations[index]).to_json())
						}
					}
				}

				// set data_updated to true here so that we only add CFAssociations to data when we process the first parent
				data_updated = true
			}

			console.log(data)

			// save the updated cfassociations
			data.show_spinner = false	// don't the spinner for moves
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// when done, update the CFAssociations' change dates
				for (let cfa of data.CFAssociations) {
					if (cfa.lastChangeDateTime == '*NOW*') {
						let json_CFA = this.framework_record.json.CFAssociations.find(x=>x.identifier == cfa.identifier)
						this.$store.commit('set', [json_CFA, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					}
				}
			})
		},

		start_move_node_conversion() {
			this.viewer.start_move_node_conversion()
		},
		finish_move_node_conversion() {
			this.viewer.finish_move_node_conversion()
		},

		// not currently used
		move_item_with_click() {
			this.moving_item_with_click = true
			this.viewer.set_show_chooser_fn((component, chosen_node, $event)=>{
				// set click_moved_item_parent_node; the user is then prompted to choose the relationship between this item and the chosen item
				this.click_moved_item_parent_node = chosen_node
			})
		},

		complete_move() {
			// NOTE: update this to make sure sequenceNumbers are 1-based when we bring back this UI option.
			let moved_item_sequence

			// if we're making the item a child of the clicked item...
			if (this.click_moved_item_parent_relationship == 'child') {
				// if the new parent currently doesn't have any children, sequence is 1
				if (this.click_moved_item_parent_node.children.length == 0) moved_item_sequence = 1
				// else put it at the end; so sequence is one past the current last item
				else moved_item_sequence = this.click_moved_item_parent_node.children[this.click_moved_item_parent_node.children.length-1].sequence + 1

			// else we're making the item a sibling of the clicked item...
			} else {
				// if we're moving *before* the clicked item, the new sequence is the clicked item's sequence (see the move algorithm below); otherwise it's one higher
				moved_item_sequence = this.click_moved_item_parent_node.sequence
				if (this.click_moved_item_parent_relationship == 'sibling_after') moved_item_sequence += 1

				// and in either of these cases, the new parent is actually the parent of the clicked item
				this.click_moved_item_parent_node = this.click_moved_item_parent_node.parent_node
			}

			console.log(moved_item_sequence)

			let data = {
				lsdoc_identifier: this.lsdoc_identifier,
				CFAssociations: [],
			}

			// find the association for the item we're moving
			let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, this.original_node.cfitem.identifier, this.original_node.parent_node.cfitem.identifier)
			if (index == -1) {
				this.$alert('Error completing the item move.')	// this shouldn't happen
				return
			}

			// change the association so it's pointing to the new parent; change the sequence; then push it to data
			let target_association = this.framework_record.json.CFAssociations[index]
			this.$store.commit('set', [target_association.destinationNodeURI, 'title', U.generate_cfassociation_node_uri_title(this.click_moved_item_parent_node.cfitem)])
			this.$store.commit('set', [target_association.destinationNodeURI, 'identifier', this.click_moved_item_parent_node.cfitem.identifier])
			this.$store.commit('set', [target_association.destinationNodeURI, 'uri', this.click_moved_item_parent_node.cfitem.uri])
			this.$store.commit('set', [target_association, 'sequenceNumber', moved_item_sequence])
			data.CFAssociations.push(new CFAssociation(target_association).to_json())

			// set lastChangeDateTime to *NOW* so server will update to the correct timestamp
			target_association.lastChangeDateTime = '*NOW*'

			// remove the original_node from its previous parent's [children], wherever the parent might exist; don't delete the cfitem though
			U.delete_node_from_cfo(this.cfo, this.original_node, true)

			// add the node to the new parent's [children] at the right sequence number
			// at the same time, update other childrens' sequences if necessary, and add to data.CFAssociations
			// we have to do this for every parent node where the item exists; but we have to be careful to only update data once
			let data_updated = false
			let parent_nodes = this.click_moved_item_parent_node.cfitem.tree_nodes
			for (let parent_node of parent_nodes) {
				let spliced_in = false
				let new_sequence
				for (let i = 0; i < parent_node.children.length; ++i) {
					let cn = parent_node.children[i]
					// if the moved item is moving before this child...
					if (moved_item_sequence <= cn.sequence) {
						// if we haven't spliced the moved item in, do so now
						if (!spliced_in) {
							this.$store.commit('set', [parent_node.children, 'SPLICE', i, 0, this.original_node])
							spliced_in = true
							new_sequence = moved_item_sequence

							// since we just inserted a new item in the list, increment i accordingly
							++i
						}
						// increment new_sequence
						new_sequence += 1

						// change this child's sequence number to new_sequence
						this.$store.commit('set', [cn, 'sequence', new_sequence])

						// and update the child's CFAssociation sequence (only do this once if we're handling multiple instances of the parent node)
						if (!data_updated) {
							let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, cn.cfitem.identifier, cn.parent_node.cfitem.identifier)
							if (index > -1) {
								this.$store.commit('set', [this.framework_record.json.CFAssociations[index], 'sequenceNumber', new_sequence])
								// add to the list of CFAssociations to save to the server
								data.CFAssociations.push(new CFAssociation(this.framework_record.json.CFAssociations[index]).to_json())
							}
						}
					}
				}

				// set data_updated to true here so that we only add CFAssociations to data when we process the first parent
				data_updated = true

				// if we didn't splice in the new item above, it must go at the end of the list
				if (!spliced_in) {
					this.$store.commit('set', [parent_node.children, 'PUSH', this.original_node])
				}
				// update the sequence and parent_node for original_node
				this.$store.commit('set', [this.original_node, 'sequence', moved_item_sequence])
				this.$store.commit('set', [this.original_node, 'parent_node', parent_node])
			}

			console.log(data)

			// save the updated cfassociations
			this.$store.dispatch('save_framework_data', data).then(()=>{
				for (let cfa of data.CFAssociations) {
					if (cfa.lastChangeDateTime == '*NOW*') {
						let json_CFA = this.framework_record.json.CFAssociations.find(x=>x.identifier == cfa.identifier)
						this.$store.commit('set', [json_CFA, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					}
				}

				// make sure the parent is open in the tree
				this.$emit('make_node_open', this.click_moved_item_parent_node.tree_key)

				// clean up the moving flags
				this.cancel_move_with_click()
			})

		},

		cancel_move_with_click() {
			this.moving_item_with_click = false
			this.click_moved_item_parent_node = null
			this.click_moved_item_parent_relationship = null
			this.viewer.set_show_chooser_fn(false)
		},

	}
}
</script>

<style lang="scss">
.k-case-move-editor {
	width:560px;
}
</style>
