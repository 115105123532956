<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Sort items</h3>

	<p>When reviewing a framework, it can be helpful to sort items in the Table View. The Satchel default view is the <v-icon small>fa-solid fa-tree</v-icon> <strong>tree view.</strong> To navigate to the <strong>Table View</strong>, filter, and sort items:

		<ol type="1">
			<li>Click on the <strong>TABLE VIEW</strong> icon <img srcset="/docimages/table_view_icons.png 2x " class="k-help-img"></li>
			<li>Click <img srcset="/docimages/show_filters_red.png 2.8x " class="k-help-img"></li>
			<li>Under <strong>Show item types:</strong> click the item types to include in the sorting </li>
			<li>Under <strong>Show fields:</strong> click the fields to display at the top of the table</li>
			<li>Click <img srcset="/docimages/hide_filters_red.png 2.6x " class="k-help-img"></li>
			<li>To sort ascending, click once on a table field</li>
			<li>To sort descending, click twice on a table field</li>
			<li>To sort items with associations, click <Strong>ASSOCIATIONS</Strong> in the top right corner and then repeat steps two through seven.</li>
		</ol>

		To return to the tree view, click either <img srcset="/docimages/return_to_tree.png 2.8x " class="k-help-img"> towards the top center left of the page or the <v-icon small>fa-solid fa-tree</v-icon> icon in the top right corner of the page.
	</p>










</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	