// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		default_max_suggestions: 8,
		suggestion_threshold: 0,

		suggestion_criteria_descriptions: {
			fullStatement: 'Item statement matches',
			humanCodingScheme: 'Human-readable code matches',
			educationLevel: 'Education level matches',
			itemType: 'Item type matches',
			siblings: 'Sibling item statement matches',
			parents: 'Parent item statement matches',
			// existing_assocs: 'Other associations',
			search: 'Search terms',
			// advanced: 'Use ADVANCED SEARCH<i class="fas fa-robot mx-2 grey--text text-darken-3"></i>'
		},

		suggestion_criteria_weights: {
			fullStatement: 100,
			humanCodingScheme: 100,
			educationLevel: 100,
			itemType: 100,
			search: 100,
			siblings: 100,
			parents: 100,
			existing_assocs: 100,
		},

		// TODO: make some of the following configurable
		ignore_education_level_mismatches: false,
		ignore_siblings: true,
		basic_search_weighting: 0.5,
		text_weighting_with_sb: 0.25,	// weight to give "plain-text" search similarity when we're also doing sb search (but not currently used)
		hcs_num_mult: 6,		// setting hcs_num_mult and/and hcs_char_mult less than 10 scale partial matches so they aren't as influential
		hcs_char_mult: 6,

		assistant_stage: '',
		comp_score_arr: [],
		assistant_suggestions: [],
		revealed_suggestion_node: null,
		right_featured_node: '',
		// auto_suggest: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		suggestion_criteria() { return this.$store.state.lst.association_suggestion_criteria },
		more_suggestions_available() {
			return (this.comp_score_arr.length > this.assistant_suggestions.length)
		},
		keywords: {
			get() { return this.$store.state.lst.make_association_keywords },
			set(val) { 
				this.$store.commit('lst_set', ['make_association_keywords', val]) 
				// set suggestion_criteria.search based on whether or not keywords is empty
				if (empty(val)) {
					this.set_suggestion_criteria('search', false)
				} else {
					this.set_suggestion_criteria('search', true)
				}
			}
		},
		lowest_ancestor: {
			// store the tree_key of the lowest_ancestor for each framework in localstorage
			// default value is null (not chosen)
			// note that here we reference the right framework, not the left framework
			get() {
				if (!this.right_framework_identifier || !this.right_framework_record) return null

				let s = this.$store.state.lst.make_association_lowest_ancestor
				if (s) {
					let o = JSON.parse(s)
					let tree_key = (o[this.right_framework_identifier]) ? o[this.right_framework_identifier] : null
					if (tree_key) return this.right_framework_record.cfo.tree_nodes_hash[tree_key*1]
					else return null
				} else return null
			},
			set(val) {
				// val should come in as empty or a node; get the tree_key if it's a node
				let tree_key = (val === null) ? '' : val.tree_key
				let o = {}
				let s = this.$store.state.lst.make_association_lowest_ancestor
				if (s) o = JSON.parse(s)
				o[this.right_framework_identifier] = tree_key
				this.$store.commit('lst_set', ['make_association_lowest_ancestor', JSON.stringify(o)])
			},
		},
		auto_suggest: {
			get() { return this.suggestion_criteria.auto_suggest },
			set(val) { this.set_suggestion_criteria('auto_suggest', val) },
		},
	},
	watch: {
		right_node() {
			if (this.right_node != null && this.assistant_stage == 'choose_lowest_ancestor') {
				this.lowest_ancestor_chosen()
			}
		},
	},
	methods: {
		initialize_assistant() {
			if (this.lowest_ancestor) this.reset_to_lowest_ancestor()
			this.suggestion_criteria.search = !empty(this.keywords)

			// since auto_suggest is managed by a checkbox, it's easier to store it as a separate data property
			// this.auto_suggest = 
		},

		set_suggestion_criteria(key, val) {
			// if user clicks on the "Search terms" menu item in the criteria menu...
			if (key == 'search' && typeof(val) != 'boolean') {
				// if criterion is currently on, it means search terms are currently filled in, so clear them
				if (this.suggestion_criteria.search) {
					this.keywords = ''
				} else {
					// else search terms are currently empty, so focus in the text field
					this.$refs.suggestion_search_terms.focus()
				}
				return
			}

			let o = Object.assign({}, this.suggestion_criteria)
			if (typeof(val) != 'boolean') val = !o[key]
			o[key] = val
			this.$store.commit('lst_set', ['association_suggestion_criteria', o]) 
		},

		clear_suggestions() {
			// note that clear_comp_scores clears out any checkmarks next to items associated to the previous left node selection
			this.clear_comp_scores()
			this.assistant_suggestions = []
			this.assistant_stage = ''
		},

		reset_for_cancel_edit() {
			this.clear_suggestions()
			this.clear_right_selection()
			this.open_nodes_right = {}
			this.right_featured_node = ''
		},

		reset_to_lowest_ancestor() {
			this.clear_suggestions()

			// make sure nothing is chosen on the right
			this.clear_right_selection()

			// hide all siblings of lowest_ancestor, and all siblings of lowest_ancestor's parents
			// (if there is no lowest ancestor, this means the top-level items in the framework will be left showing)
			if (this.lowest_ancestor) this.right_featured_node = this.lowest_ancestor.tree_key
			this.open_nodes_right = {}
			let parent_node = this.lowest_ancestor
			while (parent_node) {
				this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}
		},

		reset_lowest_ancestor() {
			this.lowest_ancestor = null
			this.clear_suggestions()
			this.clear_right_selection()
			this.open_nodes_right = {}
			this.right_featured_node = ''
		},

		initialize_lowest_ancestor_chooser() {
			if (this.lowest_ancestor) {
				this.reset_lowest_ancestor()
			} else {
				this.clear_right_selection()
				this.right_featured_node = ''
			}
			this.clear_left_selection()
			this.clear_suggestions()

			// hide chooser circles on the left
			this.clear_left_chooser_fn()

			this.$nextTick(x=>this.flash_assistant_instructions())
			this.assistant_stage = 'choose_lowest_ancestor'
		},

		cancel_lowest_ancestor_chooser() {
			this.reset_to_lowest_ancestor()
			// show chooser circles on the left again
			this.set_left_chooser_fn()
		},

		// user chose the lowest ancestor node
		lowest_ancestor_chosen() {
			// note the lowest ancestor node, then flash it
			this.lowest_ancestor = this.right_node

			this.flash_node(this.right_node, true)
			this.flash_assistant_instructions()

			this.reset_to_lowest_ancestor()

			// show chooser circles on the left again
			this.set_left_chooser_fn()

			// reset nodes again
			this.clear_left_selection()
			this.clear_right_selection()
		},

		run_assistant() {
			if (!this.left_node) {
				this.$inform('You must choose an item on the left to have the system suggest associations on the right.')
				return
			}

			// reset things back to the lowest ancestor
			this.reset_to_lowest_ancestor()

			// set up item_comps for elements where we're going to compare the text of things
			let item_comps = {}

			// start with fullStatement
			if (this.suggestion_criteria.fullStatement) {
				item_comps.fullStatement = {
					model_string: U.normalize_string_for_sparkl_bot(this.left_node.cfitem.fullStatement),
					comp_nodes: [],	comp_strings: [], sb_sim_scores: [],
				}
			}

			// if left_node has a parent with a fullStatement, compare to right nodes' parents' fullStatements
			if (this.suggestion_criteria.parents && this.left_node.parent_node && this.left_node.parent_node.cfitem.fullStatement) {
				item_comps.parents = {
					model_string: U.normalize_string_for_sparkl_bot(this.left_node.parent_node.cfitem.fullStatement),
					comp_nodes: [],	comp_strings: [], sb_sim_scores: [],
				}
			}

			// if left_node has at least one sibling with a fullStatement, to compare to right nodes' siblings' fullStatements
			if (this.suggestion_criteria.siblings && this.left_node.parent_node && this.left_node.parent_node.children.length > 1) {
				// concatenate fullStatements of all siblings (don't include left_node itself)
				let model_string = ''
				for (let child_node of this.left_node.parent_node.children) {
					if (child_node != this.left_node && child_node.cfitem.fullStatement) {
						model_string += ' ' + U.normalize_string_for_sparkl_bot(child_node.cfitem.fullStatement)
					}
				}
				model_string = $.trim(model_string)
				if (model_string) item_comps.siblings = { model_string: model_string, comp_nodes: [], comp_strings: [], sb_sim_scores: [], }
			}

			// search terms
			this.keywords = $.trim(this.keywords)
			if (this.suggestion_criteria.search && this.keywords) {
				item_comps.search = { model_string: U.normalize_string_for_sparkl_bot(this.keywords), comp_nodes: [],	comp_strings: [], sb_sim_scores: [], }
			}

			// get comp strings for the right side -- lowest_ancestor (or the document node) and children
			// this also initializes comp_score_arr
			this.comp_score_arr = []
			let got_sb_comp = this.get_right_side_comps(this.lowest_ancestor ?? this.right_framework_record.cfo.cftree, item_comps)

			// if we're doing advanced searches, send to server to get sparkl-bot comparisons
			if (this.suggestion_criteria.advanced && got_sb_comp) {
				let comps_for_sb = {}
				for (let key in item_comps) {
					comps_for_sb[key] = {
						model_string: item_comps[key].model_string,
						comp_strings: item_comps[key].comp_strings
					}
				}
				let payload = {
					service_url: 'sparkl_bot',
					comps: JSON.stringify(comps_for_sb),	// stringify to avoid limits on # of params
				}
				U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					// we should receive back the in result a sim_scores object, with one array for each each item_comps[type] -- e.g. sim_scores.fullStatement = [844, 123, 432, ...]
					// put these in place in item_comps
					for (let type in result.sim_scores) {
						item_comps[type].sb_sim_scores = result.sim_scores[type]
					}
					this.finish_assistant(item_comps)
					U.loading_stop()

				}).catch((result)=>{
					U.loading_stop()
					console.log(result)
					this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
				}).finally(()=>{})
			} else {
				// if we're not actually computing any sparkl-bot sim scores, just send things through to finish_assistant
				this.finish_assistant(item_comps)
			}
		},

		get_right_side_comps(right_node, item_comps) {
			// if this right item *is* the left item (or an alias of the left item), skip both it and its children
			if (right_node.cfitem.identifier == this.left_node.cfitem.identifier) return false

			// if ignore_siblings is true and this right item is a sibling of the left item, skip both it and its children
			if (right_node.parent_node && right_node.parent_node.children.find(x=>x == this.left_node)) return false

			let process_item = true
			// skip the document node if we get it (just process the document node's children)
			if (!right_node.cfitem.fullStatement) process_item = false
			
			// also skip aliases -- that is, only process each *item* once, even if the item appears in multiple places, because associations are item->item
			if (this.comp_score_arr.includes(x=>x.identifier == right_node.cfitem.identifier)) process_item = false

			let got_sb_comp = false
			if (process_item) {
				// always initialize the comp_score_arr object
				let o = {
					tree_key: right_node.tree_key,
					identifier: right_node.cfitem.identifier,
					factors: {},
					comp_score_num: 0,
					comp_score_den: 0,
					comp_score_final: 0,
				}
				this.comp_score_arr.push(o)

				// humanCodingScheme match
				if (this.suggestion_criteria.humanCodingScheme) {
					let left_hcs = this.left_node.cfitem.humanCodingScheme
					// if left node doesn't have a hcs match is 100 if the right side also doesn't have an hcs, or 0 otherwise
					if (!left_hcs) {
						o.factors.humanCodingScheme = (right_node.cfitem.humanCodingScheme) ? 0 : 100
					
					// else left node has an hcs...
					} else {
						// so if right node doesn't have one, match is 0
						if (!right_node.cfitem.humanCodingScheme) {
							o.factors.humanCodingScheme = 0
						} else {
							function split_to_segs(hcs) {
								let a1 = hcs.split(/\b/)
								// convert numbers, K/PK, and single lower-case letters to numeric values
								for (let i = 0; i < a1.length; ++i) {
									let s = a1[i]
									if (is_numeric(s)) a1[i] = s * 1
									else if (s == 'K') a1[i] = 0
									else if (s == 'PK') a1[i] = -1
									else if (s.length == 1 && s >= 'a' && s <= 'z') a1[i] = s.charCodeAt(0) - 96 	// 'a' == 1
								}

								let arr = []
								for (let i = 0; i < a1.length; ++i) {
									let s = a1[i]

									// combine numbers separated by dashes into the average of the two numbers
									if ((s == '-' || s == '–') && arr.length > 0 && a1.length > i+1 && is_numeric(arr[arr.length-1]) && is_numeric(a1[i+1])) {
										arr[arr.length-1] = ((arr[arr.length-1]*1 + a1[i+1]*1) / 2) + ''
										++i
									} else if ([' ', '.', '-', '–', ':'].includes(s)) {
										continue
									} else {
										arr.push(s+'')
									}
								}
								return arr
							}

							// split codes into segments, then for each segment...
							let left_segs = split_to_segs(left_hcs)
							let right_segs = split_to_segs(right_node.cfitem.humanCodingScheme)
							let num = 0
							let den = 0
							for (let i = 0; i < left_segs.length || i < right_segs.length; ++i) {
								den += 1
								// if either segment is empty, the other must be filled, so num+=0
								if (!left_segs[i] || !right_segs[i]) continue	

								let ln = left_segs[i]
								let rn = right_segs[i]

								// if segments are exactly the same...
								if (ln == rn) {
									// if they're numeric, or a single lower-case letter, +0.75 (could be configurable); or +1 otherwise
									// (we make it less than 1 so that non-numeric matches weight higher; but note that we need to arrange things so that this value is greater than the maximum num addend for a number that differs by 1, below
									if (is_numeric(ln) || ln.search(/^[a-z]/) > -1) num += 0.75
									else num += 1

								} else {
									// if both segments are numbers or single a-z characters (which would have been converted above), 
									if (is_numeric(ln) && is_numeric(rn)) {
										// compare by number/char sequence; by capping the match value at 5, we increase the separation between immediately adjacent grades (e.g. RL.5.3 - RL.6.3) and more distant grades (e.g. RL.5.3 - RL.7.3)
										let val = Math.abs(ln - rn)
										if (val > 5) val = 5
										num += ((5 - val) / 50) * this.hcs_num_mult

										// give a tiny bit higher weight to an "up" than a "down"
										if (rn > ln) num += 0.05

									} else {
										// else split into one-character "words" and use string_similarity_words to compare
										num += (U.string_similarity_words(ln.split('').join(' '), rn.split('').join(' '))) / 10 * this.hcs_char_mult
									}
								}
							}
							o.factors.humanCodingScheme = Math.round((num / den) * 100)
						}
					}
				}

				// itemType match
				if (this.suggestion_criteria.itemType) {
					// if left node doesn't have an itemType, ignore it
					let left_itemType = U.item_type_string(this.left_node.cfitem)
					if (left_itemType) {
						// match is either 100 or 0
						o.factors.itemType = (left_itemType == U.item_type_string(right_node.cfitem)) ? 100 : 0
					}
					// console.log(sr('$1: $2', o.factors.itemType, U.item_type_string(right_node.cfitem)))
				}

				// existing_assocs
				if (false && this.suggestion_criteria.existing_assocs) {
					// TODO: modify this code...
					o.factors.sibling_to_sibling_assocs = 0

					if (this.left_node.parent_node.children.length > 1) {
						let sibling_assoc_count = 0
						// if the right node also has siblings...
						if (right_node.parent_node && right_node.parent_node.children.length > 1) {
							// go through each sibling of the item on the left
							for (let left_sib of this.left_node.parent_node.children) {
								if (left_sib == this.left_node) continue

								// look in cfo.associations_hash of the framework for left_sib's non-isChildOf associations
								let assocs = this.framework_record.cfo.associations_hash[left_sib.cfitem.identifier]
								if (empty(assocs)) continue

								// go through each sibling of the item on the right
								for (let right_sib of right_node.parent_node.children) {
									if (right_sib == right_node) continue

									// if left_sib and right_sib are associated, bump up sibling_assoc_count
									if (assocs.findIndex(x=>x.destinationNodeURI.identifier == right_sib.cfitem.identifier || x.originNodeURI.identifier == right_sib.cfitem.identifier) > -1) {
										++sibling_assoc_count
									}
								}
							}
						}

						if (sibling_assoc_count > 0) {
							// for now at least this is just all-or-nothing
							o.factors.sibling_to_sibling_assocs = this.comp_factor_value.sibling_to_sibling_assocs
							o.comp_score_num += o.factors.sibling_to_sibling_assocs
						}
					}
				}

				// get educationLevel match if we're using it
				if (this.suggestion_criteria.educationLevel) {
					o.factors.educationLevel = 0
					let lel = this.left_node.cfitem.educationLevel
					let rel = right_node.cfitem.educationLevel
					// if left node doesn't have an educationLevel, or if it's a range > 7 grade levels wide, ignore it
					if (lel && lel.length > 0 && lel.length <= 7) {
						// left_node has educationLevel(s); see if right_node has educationLevel(s) with a range <= 7 grade levels too
						if (rel && rel.length > 0 && rel.length <= 7) {
							// it does. look for overlap
							for (let ell of lel) {
								ell = (ell+'').toLowerCase()
								// if we find an overlapping grade...
								if (rel.findIndex(elr=>(elr+'').toLowerCase() == ell) > -1) {
									// set to 100 if the two spans match exactly (including if they're both a single grade), or 60 they overlap but don't exactly match
									if (lel[0] == rel[0] && lel[lel.length-1] == rel[rel.length-1]) {
										o.factors.educationLevel = 100
									} else {
										o.factors.educationLevel = 60
									}
									break
								}
							}
							// if no exact match...
							if (!o.factors.educationLevel) {
								let o0 = {index:0}
								let lower_left = this.$store.state.grades.find(x=>x.value == lel[0]) ?? o0
								let upper_left = this.$store.state.grades.find(x=>x.value == lel[lel.length-1]) ?? o0
								let lower_right = this.$store.state.grades.find(x=>x.value == rel[0]) ?? o0
								let upper_right = this.$store.state.grades.find(x=>x.value == rel[rel.length-1]) ?? o0
								// calculate diff, e.g. if left is 5 and right is 6, this will give us 1; if left is K and right is 8, we'll get 9
								let diff = Math.abs(((upper_left.index + lower_left.index) / 2 - (upper_right.index + lower_right.index) / 2))
								if (diff > 5) o.factors.educationLevel = 0
								else o.factors.educationLevel = 100 * (11 - diff) / 10 - 50
								// the above algorithm makes it so if grade is off by 1, we get 50, off by 2, we get 40, and so on
							}
						}
						// else left item has an educationLevel and right doesn't, so match remains 0
					}
				}

				// if we're ignoring education mismatches, don't process this guy's text if the educationLevels don't match exactly; otherwise get the item's item_comps
				if (this.ignore_education_level_mismatches != true || o.factors.educationLevel == 100) {
					// fullStatement to fullStatement
					if (item_comps.fullStatement) {
						item_comps.fullStatement.comp_nodes.push(right_node.tree_key)
						item_comps.fullStatement.comp_strings.push(U.normalize_string_for_sparkl_bot(right_node.cfitem.fullStatement))
						got_sb_comp = true
					}

					// parent to parent, if there is a parent
					if (item_comps.parents && right_node.parent_node && right_node.parent_node.cfitem.fullStatement) {
						item_comps.parents.comp_nodes.push(right_node.tree_key)
						item_comps.parents.comp_strings.push(U.normalize_string_for_sparkl_bot(right_node.parent_node.cfitem.fullStatement))
						got_sb_comp = true
					}

					// sibling to sibling -- concatenate fullStatements of all siblings (don't include left_node itself)
					if (item_comps.siblings && right_node.parent_node && right_node.parent_node.children.length > 1) {
						let comp_string = ''
						for (let child_node of right_node.parent_node.children) {
							if (child_node != right_node && child_node.cfitem.fullStatement) {
								comp_string += ' ' + U.normalize_string_for_sparkl_bot(child_node.cfitem.fullStatement)
							}
						}
						comp_string = $.trim(comp_string)

						if (comp_string) {
							item_comps.siblings.comp_nodes.push(right_node.tree_key)
							item_comps.siblings.comp_strings.push(comp_string)
							got_sb_comp = true
						}
					}

					// search terms -- we match these to the fullStatement
					if (item_comps.search) {
						item_comps.search.comp_nodes.push(right_node.tree_key)
						item_comps.search.comp_strings.push(U.normalize_string_for_sparkl_bot(right_node.cfitem.fullStatement))
						got_sb_comp = true
					}
				}
			}

			for (let child of right_node.children) {
				got_sb_comp = this.get_right_side_comps(child, item_comps) || got_sb_comp
			}

			return got_sb_comp
		},

		finish_assistant(item_comps) {
			let stop_words, search_term_res
			if (this.suggestion_criteria.search && this.keywords) {
				let arr = U.create_search_re(this.keywords)
				search_term_res = arr[0]
				stop_words = arr[1]
			}

			// go through each item we're checking
			let max_comp_score = 0
			for (let o of this.comp_score_arr) {
				let right_node = this.right_framework_record.cfo.tree_nodes_hash[o.tree_key+'']

				// see if the items are already associated
				let assocs = this.framework_record.cfo.associations_hash[this.left_node.cfitem.identifier]
				if (assocs) {
					let ca = assocs.find(x=>x.destinationNodeURI.identifier == right_node.cfitem.identifier || x.originNodeURI.identifier == right_node.cfitem.identifier)
					if (ca) {
						this.$store.commit('set', [right_node, 'cat', ca.associationType])	// current association type
					}
				}
				
				let add_factor_to_comp_score = (factor, o) => {
					// for the factors where we do full-text searches, start with the full-text search (we calculated the other factors in get_right_side_comps)
					if (['fullStatement', 'parents', 'siblings', 'search'].includes(factor)) {
						// Note: item_comps could be empty, e.g., if you have sibling match chosen but there are no siblings
						if (empty(item_comps[factor])) {
							o.factors[factor] = 0
						} else {
							// get index of this item's text_comp record, which could be -1 if the right-side item didn't have something to compare to
							let i = item_comps[factor].comp_nodes.findIndex(x=>x==o.tree_key)

							// if not in item_comps[factor], the item has no relevant value for this factor (e.g. it's a parent where the parent is the document), so factor = 0
							if (i == -1) {
								o.factors[factor] = 0
							
							// if not using advanced search, ...
							} else if (!this.suggestion_criteria.advanced) {
								// if we're using search terms, but not matching on fullStatement, don't include the search factor directly -- but the search terms will be used below to influence other factors
								if (factor == 'search' && !this.suggestion_criteria.fullStatement) return

								// start with string_similarity_words
								o.factors[factor] = Math.round(U.string_similarity_words(item_comps[factor].model_string, item_comps[factor].comp_strings[i]) * 100)
								// let cfitem = this.right_framework_record.cfo.cfitems[o.identifier]
								// if (factor == 'search' && cfitem.humanCodingScheme == 'SL.5.5') console.log(sr('$1: $2', o.factors[factor], item_comps[factor].comp_strings[i]))

							// we're doing an advanced search, so pull from sb_sim_scores
							} else {
								o.factors[factor] = U.normalize_sim_score(item_comps[factor].sb_sim_scores[i])
								// alt version: combine the two
								// let sim_score = (i == -1) ? 0 : Math.round(U.string_similarity_words(item_comps[factor].model_string, item_comps[factor].comp_strings[i]) * 100)
								// if (this.suggestion_criteria.advanced && i != -1) sim_score = (this.text_weighting_with_sb * sim_score) + ((1 - this.text_weighting_with_sb) * U.normalize_sim_score(item_comps[factor].sb_sim_scores[i]))
							}
						}
					}

					// if we have search terms, use our search algorithm, which allows for regular expressions and such, to boost matching items for HCS and FS
					if ((factor == 'humanCodingScheme' || factor == 'fullStatement') && this.suggestion_criteria.search && this.keywords && search_term_res.length > 0 && this.right_framework_record.cfo.cfitems[o.identifier]) {
						let cfitem = this.right_framework_record.cfo.cfitems[o.identifier]
						let search_field
						if (factor == 'humanCodingScheme' && cfitem.humanCodingScheme) search_field = cfitem.humanCodingScheme
						if (factor == 'fullStatement' && cfitem.fullStatement) search_field = cfitem.fullStatement

						// weight the original factor by 1 - basic_search_weighting
						o.factors[factor] = Math.round((1 - this.basic_search_weighting) * o.factors[factor])

						// then if we got a search field and it doesn't include stop words...
						if (search_field && (!stop_words || !U.string_includes_stop_word(stop_words, search_field))) {
							// then if we find a match, boost the matching value by basic_search_weighting
							// this should make it so that items that match the search terms are always boosted to the top of the suggestion list
							if (U.strings_match_search_term_res(search_term_res, [search_field])) {
								o.factors[factor] += Math.round(this.basic_search_weighting * 100)
							}
						}

						// TODO: somewhere else, match identifier and sourceItemIdentifier if the search term is a GUID
					}

					// now add the scaled factor comp_score to comp_score_num, and update comp_score_den
					let den = this.suggestion_criteria_weights[factor]
					o.comp_score_num += o.factors[factor] / 100 * den
					o.comp_score_den += den
				}

				if (this.suggestion_criteria.fullStatement) add_factor_to_comp_score('fullStatement', o)
				if (this.suggestion_criteria.parents) add_factor_to_comp_score('parents', o)
				if (this.suggestion_criteria.siblings) add_factor_to_comp_score('siblings', o)
				if (this.suggestion_criteria.humanCodingScheme) add_factor_to_comp_score('humanCodingScheme', o)
				if (this.suggestion_criteria.educationLevel) add_factor_to_comp_score('educationLevel', o)
				if (this.suggestion_criteria.itemType) add_factor_to_comp_score('itemType', o)
				if (this.suggestion_criteria.existing_assocs) add_factor_to_comp_score('existing_assocs', o)
				// if we're doing a non-advanced search, we don't actually include the search factor in the comp_score calculation
				if (this.suggestion_criteria.search && this.keywords) add_factor_to_comp_score('search', o)
				
				// calculate final comp_score (comp_score_final will already be 0 if den is 0)
				if (o.comp_score_den > 0) {
					o.comp_score_final = Math.round(o.comp_score_num / o.comp_score_den * 100)
					// console.log(sr('$1 / $2 = $3', o.comp_score_num, o.comp_score_den, o.comp_score_final))
				}

				if (o.comp_score_final > max_comp_score) max_comp_score = o.comp_score_final
			}

			for (let o of this.comp_score_arr) {
				let right_node = this.right_framework_record.cfo.tree_nodes_hash[o.tree_key+'']

				// set comp_score for the node, scaling by max_comp_score
				this.$store.commit('set', [right_node, 'comp_score', Math.round((o.comp_score_final / max_comp_score) * 100)])

				// also set comp_score_html -- shown as the tooltip when the user hovers over the comp_score indicator
				this.$store.commit('set', [right_node, 'comp_score_tooltip', this.comp_score_tooltip(o, right_node.cat)])
			}
	
			// sort scores, with highest first
			this.comp_score_arr.sort((a,b)=>b.comp_score_final-a.comp_score_final)

			// choose the assistant_suggestions to show
			this.choose_assistant_suggestions()

			// show the first suggestion in the tree that isn't already associatied
			if (this.assistant_suggestions.length > 0) {
				let i
				for (i = 0; i < this.assistant_suggestions.length; ++i) {
					if (!this.assistant_suggestions[i].cat) break
				}
				if (i < this.assistant_suggestions.length) this.reveal_suggestion(this.assistant_suggestions[i])
			}

			// console.log('W.5.2: ' + this.comp_score_arr.findIndex(x=>this.right_framework_record.cfo.cfitems[x.identifier].humanCodingScheme == 'W.5.2'))

			// note that comp_scores are displayed in CASEItem.vue
			this.assistant_stage = 'suggestions_showing'

			this.$nextTick(x=>this.flash_assistant_instructions())
		},

		choose_assistant_suggestions() {
			// if we're currently showing some suggestions, we want to show more; otherwise start with default_max_suggestions
			let max_suggestions
			if (this.assistant_suggestions.length < this.default_max_suggestions) max_suggestions = this.default_max_suggestions
			else max_suggestions = this.assistant_suggestions.length + 10

			this.assistant_suggestions = []

			for (let i = 0; i < this.comp_score_arr.length; ++i) {
				let tree_key = this.comp_score_arr[i].tree_key
				let comp_score = this.comp_score_arr[i].comp_score_final
				let node = this.right_framework_record.cfo.tree_nodes_hash[tree_key+'']

				// never suggest comp_scores of 0; if we get to a 0, break, because since we're sorted descending, nothing else is going to be > 0
				if (comp_score == 0) break

				// // else first item (highest comp_score) is always suggested
				// if (i == 0 || comp_score == this.comp_score_arr[0].comp_score_final) {
				// 	this.assistant_suggestions.push(node)
				// 	continue
				// }

				// if we have enough suggestions, break
				if (this.assistant_suggestions.length >= max_suggestions) break

				// if we get to here, add this item as a suggestion if it's above the suggestion_threshold
				if (comp_score >= this.suggestion_threshold) {
					this.assistant_suggestions.push(node)
					continue
				}

				// if we get to here, there won't be any others above the threshold, so break
				break
			}

			// mark suggestions as highlighted
			for (let node of this.assistant_suggestions) {
				this.$store.commit('set', [node, 'comp_score_highlighted', true])
			}
		},

		suggestion_html(node) {
			return U.generate_cfassociation_node_uri_title(node.cfitem, true)
		},

		comp_score_tooltip(o, current_association_type) {
			let html = ''

			if (current_association_type) {
				html += sr('<b>ITEMS ARE ALREADY ASSOCIATED ($1)</b><br>', this.$store.state.association_type_labels[current_association_type])
			}

			if (o.factors.education_level === 0 && this.ignore_education_level_mismatches) {
				html += 'Education levels do not match'
				return html
			}

			let elements = []
			for (let factor in this.suggestion_criteria_descriptions) {
				if (!empty(o.factors[factor])) {
					elements.push(sr('<li data-sort="$1">$1%: $2</li>', o.factors[factor], this.suggestion_criteria_descriptions[factor]))
				}
			}

			// elements.sort()
			html += '<ul class="mb-1">'
			html += elements.join('')

			html += '</ul>'
			html += sr('Overall Match Score: <b>$1 / 100</b>', o.comp_score_final)

			return html
		},

		raw_comp_score(node) {
			return this.comp_score_arr.find(x=>x.tree_key == node.tree_key).comp_score_final
		},

		make_association_from_shortcut() {
			if (!this.revealed_suggestion_node || !this.left_node) {
				return	// too hard to explain what went wrong...
			}
			this.right_node = this.revealed_suggestion_node
			this.make_association('right')
		},

		reveal_suggestion(node) {
			// start by hiding all nodes
			this.open_nodes_right = {}

			// open the suggested node
			let parent_node = node.parent_node
			while (!empty(parent_node)) {
				this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}

			// highlight it and set revealed_suggestion_node (setting highlighted_identifier_override will ensure that the item's statement will be wrapped)
			this.highlighted_identifier_override = node.cfitem.identifier
			this.revealed_suggestion_node = node

			// scroll to it if necessary
			this.$nextTick(x=>{
				let node_jq = $(this.$el).find(sr('[data-case-tree-item-tree-key=$1]', node.tree_key))
				if (node_jq.length == 0) {
					console.log('can’t scroll in reveal_suggestion')
					return
				}

				// make sure the suggested item is showing in its tree-scroll-wrapper
				let $ctsr = node_jq.parents('.k-case-tree-scroll-wrapper')
				vapp.$vuetify.goTo(node_jq[0], {container: $ctsr[0], offset:50, duration:200})
			})
		},

		clear_comp_scores(node) {
			if (empty(node)) {
				if (!this.right_framework_record) return
				if (!empty(this.lowest_ancestor)) node = this.lowest_ancestor
				else node = this.right_framework_record.cfo.cftree
			}

			this.$store.commit('set', [node, 'comp_score', -1])
			this.$store.commit('set', [node, 'comp_score_tooltip', -1])
			this.$store.commit('set', [node, 'comp_score_highlighted', false])
			this.$store.commit('set', [node, 'cat', ''])	// current association type
			for (let child of node.children) {
				this.clear_comp_scores(child)
			}

			this.highlighted_identifier_override = ''
			this.revealed_suggestion_node = null
		},

		flash_assistant_instructions() {
			$('.k-association-assistant-instructions').addClass('k-associations-maker-created-msg-flashing')

			setTimeout(x=>{
				$('.k-association-assistant-instructions').removeClass('k-associations-maker-created-msg-flashing')
			}, 1000)
		},
	}
}
