<template><div class="k-case-item-importer mt-0">
	<div class="pt-1 mt-2" style="border-top:1px solid #999; color:black; font-size:18px;"><b>Batch Import Child Items</b> 
		<v-btn x-small class="float-right ml-2 mt-1 k-tight-btn" color="primary" @click="pdf_import_interface_showing = true">PDF Interface</v-btn>
		<ItemPDFImportInterface v-if="pdf_import_interface_showing" @cancel_import="pdf_import_interface_showing=false" @paste_contents="contents => {raw_text_for_import = contents; pdf_import_interface_showing = false;}" :editor_component="this" />
		<v-btn x-small class="float-right ml-2 mt-1 k-tight-btn" color="primary" @click="show_advanced_options=!show_advanced_options">Options</v-btn>
	</div>

	<div v-show="show_advanced_options" class="mt-2">
		<div class="k-case-ie-line d-flex align-center py-1">
			<div class="k-case-ie-line-label mr-2">Delimiter:</div>
			<v-radio-group v-model="delimiter" hide-details row>
				<v-radio style="margin-top:-24px" label="Any whitespace character" value="spaces"></v-radio>
				<v-radio style="margin-top:-24px" label="Tabs only" value="tabs"></v-radio>
			</v-radio-group>
		</div>
		<div class="k-case-ie-line d-flex">
			<div class="mr-2">“Pre-Script”:</div>
			<div><v-select v-model="pre_script" :items="pre_script_select_items" label="" dense outlined hide-details></v-select></div>
		</div>
		<div class="k-case-ie-line d-flex">
			<div class="mr-2">“Post-Script”:</div>
			<div><v-select v-model="post_script" :items="post_script_select_items" label="" dense outlined hide-details></v-select></div>
		</div>
		<div class="k-case-ie-line">
			<div style="flex:1 1 auto">
				<div class="k-case-ie-line-label">Parsing algorithm:</div>
				<v-radio-group v-model="parse_algo" hide-details class="mt-0 ml-2">
					<v-radio background-color="#fff" class="mb-1" label="1. Each line is always parsed as a competency" value="algo1"></v-radio>
					<v-radio background-color="#fff" class="mb-1" label="2. Only process lines that start with human-readable codes (admin only)" value="algo2"></v-radio>
				</v-radio-group>

				<v-checkbox class="mt-2 ml-2 pt-0" :label="'Convert newlines to spaces'" v-model="convert_newlines_to_spaces" hide-details></v-checkbox>
				<v-checkbox class="mt-2 ml-2 pt-0" :label="'Update existing items only (ignore new items and item hierarchy)'" v-model="no_hierarchy" hide-details></v-checkbox>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="parse_algo=='algo2'">
			<div class="k-case-ie-line-label mr-3 text-right">Human-Readable<br>Code Stem (e.g. “AFNR”):</div>
			<v-text-field background-color="#fff" outlined dense hide-details v-model="hcs_prefix" placeholder="" autocomplete="new-password" clearable></v-text-field>
		</div>

		<div class="mt-1" v-show="parse_algo=='algo1'">
		Sample import lines:
<pre v-if="delimiter=='spaces'" class="elevation-2 pa-1 mt-1 mb-2" style="border-radius:6px; border:1px solid #ccc;">
Course * :41.01400 Grade 3
Knowledge_Domain ** Earth and Space Science
S *** :S3E1 Obtain, evaluate, and communicate information...
E **** :S3E1.a Ask questions and analyze data to...
</pre>
<pre v-if="delimiter=='tabs'" class="elevation-2 pa-1 mt-1 mb-2" style="border-radius:6px; border:1px solid #ccc;">
Course   Grade 3
   Knowledge Domain   Earth and Space Science
      Standard   :S3E1 Obtain, evaluate, and communicate...
         Element   :S3E1.a Ask questions and analyze data to...
</pre>
	</div>
	</div>
	<div v-if="!show_advanced_options&&pre_script" class="mt-1 red--text text--darken-2"><ul><li>Running Pre-Script: <b>{{pre_script_select_items.find(x=>x.value==pre_script).text}}</b></li></ul></div>
	<div v-if="!show_advanced_options&&post_script" class="mt-1 red--text text--darken-2"><ul><li>Running Post-Script: <b>{{post_script_select_items.find(x=>x.value==post_script).text}}</b></li></ul></div>

	<div class="mt-1 pt-2 text-center" style="border-top:1px solid #999">Paste item text below, then click “PROCESS...” to continue.</div>
	<div class="d-flex align-center mt-2 mb-3">
		<v-btn small color="secondary" class="mr-2" @click="$emit('cancel_import')">Cancel Import</v-btn>
		<v-btn small color="primary" @click="import_children">Process Imported Item Text…</v-btn>
		<v-spacer/>
		<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=true"><v-icon>fas fa-expand</v-icon></v-btn>
	</div>
	<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area" outlined dense autofocus hide-details v-model="raw_text_for_import" placeholder="" rows="20"></v-textarea>

	<v-dialog v-model="ta_dialog_open" max-width="95vw" persistent scrollable>
		<v-card style="background-color:#eee">
			<v-card-text>
				<div class="mt-4" v-show="parse_algo=='algo1'">
<pre class="elevation-2 pa-1 mt-1 mb-2 white" style="border-radius:6px; border:1px solid #ccc;">
Course * :41.01400 Grade 3
Knowledge_Domain ** Earth and Space Science
NOTES: These are the standards for Earth and Space Science...
S *** :S3E1 Obtain, evaluate, and communicate information...
E **** :S3E1.a Ask questions and analyze data to...
EX:
---
Supplemental Info Heading

This is the text of the supplemental info...
</pre>
				</div>
				<div class="d-flex align-center mt-2 mb-3">
					<v-btn small color="secondary" class="mr-2" @click="ta_dialog_open=false;$emit('cancel_import')">Cancel Import</v-btn>
					<v-btn small color="primary" @click="import_children">Process Imported Item Text…</v-btn>
					<v-spacer/>
					<v-btn small color="#333" dark fab class="ml-2" @click="ta_dialog_open=false"><v-icon>fas fa-compress</v-icon></v-btn>
				</div>
				<v-textarea background-color="#fff" class="k-case-item-importer-raw-text-area-max" autofocus outlined dense hide-details v-model="raw_text_for_import" placeholder="" rows="40"></v-textarea>
			</v-card-text>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ItemPDFImportInterface from './ItemPDFImportInterface'

export default {
	components: { ItemPDFImportInterface },
	props: {
		editor_component: { required: true },
		educationLevel: { type: Array, required: false, default() { return []} },
		language: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		hcs_prefix: '',
		raw_text_for_import: '',
		pre_script_select_items: [
			{ value: '', text: 'None'},
			{ value: 'pre_script_edsby_course_import', text: 'Edsby course import' },
			{ value: 'pre_script_cl_evidence_statements_import', text: 'CL ES import' },
			{ value: 'pre_script_sc_course_code_import', text: 'South Carolina course codes import' },
			{ value: 'pre_script_asn', text: 'asn import' },
		],
		post_script_select_items: [
			{ value: '', text: 'None'},
			{ value: 'post_script_ccsd_unit', text: 'Columbia County SD - Units' },
			{ value: 'post_script_ccsd_module', text: 'Columbia County SD - Modules' },
		],

		// TODO: gui controls for these
		hcs_prefix_number_separator: '-',
		create_trees: true,
		statement_options: 'single_line',	// or 'multiple_lines'
		read_to_period: true,
		order_by_hcs: true,
		algo2_item_types: ['Standard', 'Element'],
		bullet_continues_item: false,
		ta_dialog_open: false,
		no_hierarchy: false,
		pdf_import_interface_showing: false,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		// note that show_advanced_options and convert_newlines_to_spaces are in the store directly, not lst, so they will be reset when the user reloads
		show_advanced_options: {
			get() { return this.$store.state.import_interface_show_advanced_options },
			set(val) { this.$store.commit('set', ['import_interface_show_advanced_options', val]) },
		},
		convert_newlines_to_spaces: {
			get() { return this.$store.state.import_interface_convert_newlines_to_spaces },
			set(val) { this.$store.commit('set', ['import_interface_convert_newlines_to_spaces', val]) },
		},
		delimiter: {
			get() { return this.$store.state.lst.import_delimiter },
			set(val) { this.$store.commit('lst_set', ['import_delimiter', val]) }
		},
		pre_script: {
			get() { 
				let ps = this.$store.state.lst.import_pre_script
				if (this.pre_script_select_items.find(x=>x.value==ps)) return ps
				else return ''
			},
			set(val) { this.$store.commit('lst_set', ['import_pre_script', val]) }
		},
		post_script: {
			get() { 
				let ps = this.$store.state.lst.import_post_script
				if (this.post_script_select_items.find(x=>x.value==ps)) return ps
				else return ''
			},
			set(val) { this.$store.commit('lst_set', ['import_post_script', val]) }
		},
		parse_algo: {
			get() {
				// system admins can choose between algo1 and algo2; for other users, just use algo1
				if (this.user_info.system_role == 'admin') return this.$store.state.lst.import_parse_algo
				else return 'algo1'
			},
			set(val) {
				this.$store.commit('lst_set', ['import_parse_algo', val])
			}
		},
	},
	watch: {
	},
	created() {
		vapp.item_import_component = this
	},
	mounted() {
	},
	methods: {
		editor_config() {
			let config = U.get_froala_config({
				// placeholderText: '',
				enter: FroalaEditor.ENTER_BR,
				heightMin: 420,
				pastePlain: true,	// doesn't seem to do what I want it to do
				editorClass: 'k-froala-plain-text',
				toolbarButtons: ['bold', 'italic', 'clearFormatting', 'html', 'fullscreen'],
				// toolbarButtons: {
				// 	moreRich: {buttons: ['bold', 'italic', 'insertLink', 'insertImage',
				// 		'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align',]
				// 		, buttonsVisible: 8},	// , 'quote', 'outdent', 'indent'
				// 	moreMisc: {buttons: ['fullscreen', 'paragraphFormat', 'insertVideo', 'specialCharacters', 'formatUL', 'formatOL', 'insertHR', 'insertTable', 'clearFormatting', 'html'], buttonsVisible: 1, align:'right'}
				// },
			})
			return config
		},
		import_children() {
			////////////////////////////////

			// internal fn for processing cfitems
			let process_cfitem = (line_number, hcs, full_statement) => {
				// for algo2, see if this is a duplicate
				let prev_cfitem = cfitems.find(x=>x.humanCodingScheme == hcs)
				if (this.parse_algo == 'algo2' && !empty(prev_cfitem)) {
					// if we found an already-processed standard with the same hcs, check to see if the full_statements are the same
					if (prev_cfitem.fullStatement == full_statement) {
						log.push(sr('$1: Duplicate competency found; statements match <span style="color:#999">[$2]</span>', line_number, hcs))
					} else {
						process_error(line_number, sr('Found duplicate competency for $1 with different statement. <span style="color:#999"><b>Original:</b> $2 / <b>New:</b> $3</span>', hcs, prev_cfitem.fullStatement, full_statement))
					}
					return
				}

				// if second arg (hcs) is an object, it should include all the properties we want to set
				let cfitem
				if (typeof(hcs) == 'object') {
					// in this case, if we received an identifier and an item with that identifier already exists, start with the existing item
					if (hcs.identifier) {
						cfitem = this.editor_component.framework_record.cfo.cfitems[hcs.identifier]
						if (cfitem) {
							// this copies the data in the existing cfitem
							cfitem = new window.CFItem(cfitem).to_json()
							// change/add any values in hcs
							for (let prop in hcs) {
								cfitem[prop] = hcs[prop]
							}
						}
					}

					// if we didn't find an existing cfitem, just use the hcs object
					if (!cfitem) cfitem = hcs

				} else {
					cfitem = {
						humanCodingScheme: hcs,
						fullStatement: full_statement,
						extensions: {}
					}
				}

				cfitems.push(cfitem)
				log.push(sr('$1: Competency processed: <span style="color:#999">[$2]</span>', line_number, cfitem.fullStatement))

				// return cfitem
				return cfitem
			}

			// internal fn for processing markdown
			let process_markdown = (line) => {
				// if convert_newlines_to_spaces, clean up spaces -- reduce any number of space chars to a single space (lines never include \n's) -- but leave tabs alone
				if (this.convert_newlines_to_spaces) line = line.replace(/ +/g, ' ')

				// replace starting bullets with *
				line = line.replace(/^[•●]/, '*')

				// replace starting 'o's with '    *' (four spaces), which makes it a nested list
				line = line.replace(/^o\s+/, '    * ')

				return line
			}

			// internal fn for processing errors
			let process_error = (line_number, error) => {
				log.push(sr('<div class="my-3">$1: <b class="red" style="padding:3px 3px; border-radius:3px; color:#fff;">Error:</b> $2</div>', line_number, error))
				++error_count
			}

			// internal fn for processing non-error info
			let process_info = (line_number, statement) => {
				log.push(sr('$1: $2', line_number, statement))
			}

			////////////////////////////////

			// THIS WAS FOR FROALA, which we're not doing anymore
			// convert <br>s (with surrounding space) to line breaks; marked will convert everything back to neat paragraphs
			// let lines = $.trim(this.raw_text_for_import.replace(/\s*<br.*?>\s*/g, '\n'))
			let lines = $.trim(this.raw_text_for_import)
			if (empty(lines)) {
				this.$alert('You must enter item text to import.')
				return
			}

			this.hcs_prefix = $.trim(this.hcs_prefix)
			if (this.parse_algo == 'algo2' && empty(this.hcs_prefix)) {
				this.$alert('For parsing algorithm 2, you must enter a “Human-Readable Code Stem”. For example, if the codes for the competencies you’re importing have the format “AFNR-BAS-1”, “AFNR-BAS-1.1”, etc., use “ANFR” or “ANFR-BAS” as the code stem.')
				return
			}

			let cfitems = []
			let hcs = ''
			let full_statement = ''
			let hcs_prefix_re = new RegExp('^(' + this.hcs_prefix + '\\S*)[.:]?(\\s|$)\\s*(.*)')
			let algo1_hcs_suffix = 0
			let log = []
			let error_count = 0
			let last_cfitem_data = {
				level: 0,
			}
			let last_cfitem
			let processing_exemplar = false
			let processing_notes = false
			let last_line_was_hr = false

			lines = lines.split('\n')

			// run pre-script if we have one
			if (this.pre_script) {
				lines = this[this.pre_script](lines)
				if (typeof(lines) == 'string') {
					this.$alert('<b>Pre-Script error:</b> ' + lines)
					return
				}
			}

			let line_number = 0
			let star_length_correction = -1
			let previous_star_length = 0
			///////////////////////////////////////
			// go through each line
			for (let line of lines) {
				++line_number
				
				// remove comments, which must start with //
				line = line.replace(/\/\/.*/, '')

				// if convert_newlines_to_spaces, trim line, but leave tabs at the start
				if (this.convert_newlines_to_spaces) line = line.replace(/^ *(.*)\s*$/, '$1')
				console.log(line)
				// line = $.trim(line)

				// skip blank lines in some circumstances
				if (empty(line)) {
					// unless we're processing an exemplar or notes (we need to allow for extra line breaks in exemplars and notes)?
					// if (!processing_exemplar && !processing_notes) continue
					// skip for algo2?
					if (this.parse_algo == 'algo2') continue
				}

				// if bullet_continues_item is true, change bullet characters to something that will parse nicely
				if (this.bullet_continues_item) {
					//  is what gets pasted in from the PDF for a bullet
					line = line.replace(/^\s*/, '/ • ')

					// let's also translate `a.` to '/ a. ' as well
					line = line.replace(/^([a-z]\.)\s+/, '/ $1 ')
				}

				///////////////////////////////////////////////////////
				// for algo1 (always one line -> one standard)...
				if (this.parse_algo == 'algo1') {
					// format:
					// G * :41.01400 Grade 3
					// D ** Earth and Space Science
					// S *** :S3E1 Obtain, evaluate, and communicate information about the physical attributes of rocks and soils.

					// The item_type and *'s are required; the HCS is optional

					// Note: If a line following a standard starts with "NOTES:", it will be parsed as the note field for the previous standard

					// Note: we don't allow for including a grade level because the importer will inherit the grade level(s) of the parent

					let cfitem_data = {
						level: last_cfitem_data.level,	// start with the last-processed level
						humanCodingScheme: '',
						extensions: {},
					}

					// look for the start of an item, the format of which depends on the delimiter
					let found_start_of_item = false
					let item_type, stars
					if (this.delimiter == 'tabs') {
						// tabs: look for any number of tabs, which indicates the level, then an item type, then another tab, then the rest of the line
						found_start_of_item = (line.search(/^(\t*)([^\t]+)\t(.*)/) == 0)
						if (found_start_of_item) {
							item_type = RegExp.$2
							stars = RegExp.$1.length	// this represents the "level"
							line = $.trim(RegExp.$3)
						}
						
					} else {
						// spaces: look for start of line, then (item_type (no spaces)), then a single space, then one or more asterisks, then a single space
						// (note that you *must* have that single space after the asterisks)
						found_start_of_item = (line.search(/^(\S+\s)(\*+)\s(.*)/) == 0)
						if (found_start_of_item) {
							item_type = RegExp.$1
							stars = RegExp.$2.length - 1	// this represents the "level"
							line = $.trim(RegExp.$3)
						}
					}

					if (found_start_of_item) {
						item_type = $.trim(item_type)

						// set level (zero-based) based on number of stars
						cfitem_data.level = stars
						// if star_length_correction is < 0, we're on the first non-blank line
						if (star_length_correction < 0) {
							// for future lines, we will subtract star_length_correction, which we set here to the first line's star_length
							// (so if the first line is one-star [as it really should be], it will be 0, meaning no correction)
							star_length_correction = cfitem_data.level
						}
						cfitem_data.level -= star_length_correction

						if (cfitem_data.level - previous_star_length > 1) process_error(line_number, 'Star levels do not match: ' + line)
						previous_star_length = cfitem_data.level

						// if we got a 'DD-' value for the item_type, we should be in a derivative framework and we're copying an item from the original framework
						if (item_type.search(/DD-(.*)/) > -1) {
							let derived_hcs = RegExp.$1

							// look for an item in the original whose hcs matches derived_hcs
							let oitem
							if (this.editor_component.viewer.is_derivative) {
								oitem = this.editor_component.viewer.derivative_original_json.CFItems.find(x=>x.humanCodingScheme == derived_hcs)
							}
							if (!oitem) {
								process_error(line_number, 'Couldn’t find DD human-readable code in original framework: ' + line)
								continue
							} else {
								// found the item; merge all data from the item into cfitem
								cfitem_data = $.extend(true, cfitem_data, oitem)

								// clear identifier/URI
								delete cfitem_data.identifier
								delete cfitem_data.uri

								// if the item doesn't already have a sourceItemIdentifier/URI, set it
								if (!cfitem_data.extensions?.sourceItemIdentifier) {
									cfitem_data.extensions.sourceItemIdentifier = oitem.identifier
									cfitem_data.extensions.sourceItemURI = oitem.uri
								}
								// note that we continue to process the item below, because we might get a new humanCodingScheme
							}
						
						// else if we got a item_type...
						} else if (!empty(item_type)) {
							// convert _ to space, so that the user can enter fully-spelled-out types with spaces
							item_type = item_type.replace(/_/g, ' ')

							// TODO: make this mapping configurable?
							let item_type_mapping = {
								G: "Grade",
								U: "Cluster",
								P: "Pathway",
								C: "Course",
								D: "Domain",
								S: "Standard",
								E: "Element",
							}
							if (item_type_mapping[item_type]) {
								cfitem_data.CFItemType = item_type_mapping[item_type]

							// allow for '-' meaning "no item type"; in this case item_type is empty
							} else if (item_type != '-') {
								cfitem_data.CFItemType = item_type
							}
						}

						// record that we're not currently processing an exemplar or notes for this item
						processing_exemplar = false
						processing_notes = false
						last_line_was_hr = false

						// we'll continue processing the line below

					// look for NOTES: line
					} else if (line.search(/^NOTES:\s*(.*)/) == 0) {
						// if no item has been processed yet, error
						if (empty(last_cfitem)) {
							process_error(line_number, 'Found an NOTES line, but no item has been processed yet: ' + line)
							continue
						}

						// add this note line to the last cfitem (note that we do '+=' below in case we started with an existing item in a derivative framework)
						let notes = $.trim(RegExp.$1)
						if (empty(last_cfitem.notes)) last_cfitem.notes = ''
						else last_cfitem.notes += '\n\n'
						last_cfitem.notes += process_markdown(notes)
						process_info(line_number, sr('Started notes: $1', notes))

						// record that we're currently processing notes for this item, and not processing exemplars
						processing_exemplar = false
						processing_notes = true
						continue

					// look for EX(EMPLAR): line
					} else if (line.search(/^(EX(EMPLAR|AMPLE|-IMPORT)?):\s*(.*)/) == 0) {
						// if no item has been processed yet, error
						if (empty(last_cfitem)) {
							process_error(line_number, 'Found an EXEMPLAR line, but no item has been processed yet: ' + line)
							continue
						}

						// if we're already processing an exemplar, report error
						if (processing_exemplar) {
							process_error(line_number, 'Found an EXEMPLAR line, but we’re already processing an exemplar: ' + line)
							continue
						}

						// if keyword is 'EX-IMPORT' the exemplar was exported in Satchel text format, so don't do markdown processing
						processing_exemplar = RegExp.$1

						// add this exemplar line to the last cfitem
						let supplementalNotes = $.trim(RegExp.$3)

						// if (processing_exemplar == 'EX-IMPORT') last_cfitem.supplementalNotes = supplementalNotes.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>')
						if (processing_exemplar == 'EX-IMPORT') last_cfitem.extensions.supplementalNotes = supplementalNotes
						else last_cfitem.extensions.supplementalNotes = process_markdown(supplementalNotes)
						process_info(line_number, sr('Started exemplar: $1', supplementalNotes))

						// record that we're not currently processing notes for this item
						processing_notes = false
						continue

					// else unlabeled line; if we're processing an exemplar or notes, add to the exemplar or notes
					} else if (processing_exemplar) {
						// if keyword was 'EX-IMPORT' the exemplar was exported in Satchel text format, so don't do markdown processing
						if (processing_exemplar == 'EX-IMPORT') {
							last_cfitem.extensions.supplementalNotes += '\n' + line
							// last_cfitem.extensions.supplementalNotes += '\n' + line.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>')
						} else {
							// '---' means an hr
							if (line.search(/^---+/) > -1) {
								line = '\n---\n'
								last_line_was_hr = true
							} else if (last_line_was_hr) {
								// for the line following an hr, make it bold/italic, unless the line was blank, in which case we skip it
								if (empty(line)) continue

								line = sr('\n***$1***\n', line)
								last_line_was_hr = false
							}
							last_cfitem.extensions.supplementalNotes += '\n' + process_markdown(line)
						}

						process_info(line_number, sr('Added exemplar line: $1', line))
						continue

					} else if (processing_notes) {
						last_cfitem.notes += '\n' + process_markdown(line)
						process_info(line_number, sr('Added notes line: $1', line))
						continue

					// else add to the last fullStatement
					} else {
						if (!last_cfitem) {
							if (!empty(line)) process_error(line_number, 'Unparsed line: ' + line)
						} else {
							last_cfitem.fullStatement += '\n' + process_markdown(line)
						}
						continue
					}

					// if we get to here, continue processing an item definition line

					// look for educationLevel at the start of the rest of the line
					if (line.search(/^\[(.*?)\](.*)/) == 0) {
						let gld = RegExp.$1
						line = $.trim(RegExp.$2)
						cfitem_data.educationLevel = U.grade_level_display_to_educationLevel(gld)
					}

					// look for identifier at the start of the rest of the line
					if (line.search(/^\{(.*?)\}(.*)/) == 0) {
						cfitem_data.identifier = RegExp.$1
						line = $.trim(RegExp.$2)

						// if we're only processing existing items and the item isn't already in the framework, this is an error
						if (this.no_hierarchy) {
							if (!this.editor_component.framework_record.cfo.cfitems[cfitem_data.identifier]) {
								process_error(line_number, sr('Item with identifier <b>$1</b> not already in framework; this item will be skipped', cfitem_data.identifier))
								// but note that we don't disrupt processing here; we'll take the item out of cfitems below
							}

						// if the identifier string starts with "SID" it means we want to make a sourceItemIdentifier copy of the specified item
						} else if (cfitem_data.identifier.search(/^SID-(.*)/) > -1) {
							cfitem_data.identifier = RegExp.$1
							let oitem = this.editor_component.framework_record.json.CFItems.find(x=>x.identifier == cfitem_data.identifier)
							if (!oitem) {
								process_error(line_number, sr('sourceItemIdentifier copy of item with identifier <b>$1</b> requested, but couldn’t find an item with this identifier', cfitem_data.identifier))

							} else {
								console.log(oitem)
								// start with the original item data
								cfitem_data = $.extend(true, cfitem_data, oitem)
								// make sure the we inherit the item type of the original (the item type might have been set to something different in cfitem_data)
								cfitem_data.CFItemType = oitem.CFItemType

								// clear identifier/URI
								delete cfitem_data.identifier
								delete cfitem_data.uri

								// if the item doesn't already have a sourceItemIdentifier/URI, set it
								if (empty(cfitem_data.extensions)) cfitem_data.extensions = {}
								if (!cfitem_data.extensions.sourceItemIdentifier) {
									cfitem_data.extensions.sourceItemIdentifier = oitem.identifier
									cfitem_data.extensions.sourceItemURI = oitem.uri
								}

								console.log(cfitem_data)
							}

						} else {
							// else if the item *is* already in the framework, it *might* be an error, so report it...
							if (this.editor_component.framework_record.cfo.cfitems[cfitem_data.identifier]) {
								process_error(line_number, sr('Item with identifier <b>$1</b> already exists (this may or may not be an error…)', cfitem_data.identifier))
							}
						}
					}

					// look for HCS at the start of the rest of the line
					if (line.search(/^\:(\S+)\s*(.*)/) == 0) {
						cfitem_data.humanCodingScheme = RegExp.$1
						line = RegExp.$2

						// strip ':' off the end of the HCS if there
						cfitem_data.humanCodingScheme = cfitem_data.humanCodingScheme.replace(/:$/, '')

						// replace '_'’s with spaces in the HCS
						cfitem_data.humanCodingScheme = cfitem_data.humanCodingScheme.replace(/_/g, ' ')
					}

					// whatever's left of the line is the fullStatement -- but not if we're processing a sourceItemIdentifier copy
					if (!cfitem_data.extensions.sourceItemIdentifier) {
						cfitem_data.fullStatement = line
					}

					// note last_cfitem in case we get a note on the next line
					last_cfitem = process_cfitem(line_number, cfitem_data)

					// record last_cfitem_data for use in parsing next item
					last_cfitem_data = cfitem_data

					// hcs = ''	// make sure this is empty for next pass through the loop, and when the loop finishes
					continue
				}
				// END OF ALGO1
				///////////////////////////////////////////////////////

				///////////////////////////////////////////////////////
				// ALGO2
				// if hcs_prefix_number_separator is set,
				if (this.hcs_prefix_number_separator) {
					// then if the line starts with a number and hcs is currently empty,
					if (hcs == '' && line.search(/^\d+/) == 0) {
						// add the hcs_prefix + the separator to the start of the line
						line = this.hcs_prefix + this.hcs_prefix_number_separator + line
						// this transforms, e.g., `2.1 Standard text` to `ML.2.1 Standard text`, so that the code below will recognize this line as the start of a standard
					}
				}

				/////////////////////////////////////////////////////////////////////
				// if we find a valid human coding scheme at the start of the line...
				if (line.search(hcs_prefix_re) == 0) {
					let r1 = RegExp.$1
					let r2 = $.trim(RegExp.$3)

					// for single line mode...
					if (this.statement_options == 'single_line') {
						// if we have an hcs we're already processing, there must be an error
						if (!empty(hcs)) {
							process_error(line_number, sr('Couldn’t find a statement for the previous code ($1)', hcs))
						}

						// if this line included the full_statement,
						if (!empty(r2)) {
							// if we're in read_to_period mode,
							if (this.read_to_period) {
								// if the line ends in a period or $, process the statement right away;
								if (r2.search(/(\.|\$)$/) != -1) {
									// if line ends in $, remove the dollar sign first
									if (RegExp.$1 == '$') r2 = r2.replace(/\$$/, '')

									process_cfitem(line_number, r1, r2)

								// else register the full_statement and wait for more lines below
								} else {
									hcs = r1
									full_statement = r2
									process_info(line_number, sr('Found competency code: $1; started processing statement <span style="color:#999">[$2]</span>', hcs, full_statement))
								}

							// else not in read_to_period mode, so process the statement right away
							} else {
								process_cfitem(line_number, r1, r2)
							}

						// else set hcs
						} else {
							hcs = r1
							full_statement = ''
							process_info(line_number, sr('Found competency code: $1', hcs))
						}

					// else multiple line mode...
					} else {
						// if we have a full_statement to go with the previous hcs, process it; else error
						if (!empty(full_statement)) {
							process_cfitem('-', hcs, full_statement)
						} else {
							process_error('-', sr('Couldn’t find a statement for the previous code ($1)', hcs))
						}

						// set hcs and set full_statement to whatever was after the hcs (if anything)
						hcs = r1
						full_statement = r2
						process_info(line_number, sr('Found competency code: $1', hcs))
					}

				/////////////////////////////////////////////////
				// else it's a line that didn't start with an hcs
				} else {
					// if we're in 'single_line' mode...
					if (this.statement_options == 'single_line') {
						// if we have an hcs we're currently processing
						if (!empty(hcs)) {
							// if we're in "read_to_period" mode, we must be waiting for text to accompany the hcs
							if (this.read_to_period) {
								if (!empty(full_statement)) full_statement += ' '
								full_statement += line

								// if the full_statement ends in a period now, process this competency
								if (full_statement.search(/(\.|\$)$/) != -1) {
									// if line ends in $, remove the dollar sign first
									if (RegExp.$1 == '$') full_statement = full_statement.replace(/\$$/, '')

									process_cfitem(line_number, hcs, full_statement)
									// then clear hcs and full_statement
									hcs = ''
									full_statement = ''
								}

							// else not read_to_period mode
							} else {
								// if full_statement is currently empty, this line is the full_statement for the hcs; process it
								if (empty(full_statement)) {
									process_cfitem(line_number, hcs, line)
									// then clear hcs (full_statement is already clear)
									hcs = ''

								// else we have an hcs and a full statement already; this shouldn't happen but account for it just in case
								} else {
									process_error(line_number, 'Code and statement already exist...')
								}
							}

						// else we don't have an hcs we're currently processing and we're in single line mode, so skip the line (no error)
						} else {
							process_info(line_number, sr('Skipping line <span style="color:#999">[$1]</span>', line))
						}

					// else we're in 'multiple_line' mode
					} else {
						// if we have an hcs we're currently process, add to full_statement
						if (!empty(hcs)) {
							// we could add an option whether or not to separate lines with line breaks; for now we'll collapse them
							if (!empty(full_statement)) full_statement += ' '
							full_statement += line
							// we don't have to log this line, because it'll be logged when the competency finishes processing

						// else we're not currently processing an hcs, so skip the line
						} else {
							process_info(line_number, sr('Skipping line <span style="color:#999">[$1]</span>', line))
						}
					}
				}
			}
			// finished going through each line
			///////////////////////////////////////

			// if we have an un-processed hcs...
			if (!empty(hcs)) {
				// then if we're in single_line mode, it's an error
				if (this.statement_options == 'single_line') {
					process_error('-', sr('Reached end of text with an unprocessed code ($1)', hcs))

				// else we're in multiple_line mode...
				} else {
					// if we have a full_statement to go with the hcs, process it; else it's an error
					if (!empty(full_statement)) {
						process_cfitem('-', hcs, full_statement)
					} else {
						process_error('-', sr('Reached end of text with an unprocessed code ($1)', hcs))
					}
				}
			}

			// easy natural sort algorithm that actually seems to work!
			// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
			if (this.parse_algo == 'algo2') {
				cfitems.sort((a, b) => a.humanCodingScheme.localeCompare(b.humanCodingScheme, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))
			}

			// for algo2, if this.create_trees is true, put children under parents -- up to three levels
			// also add CFItemType from item_type array
			if (this.create_trees && this.parse_algo == 'algo2') {
				let current_parent_1 = ''
				let current_parent_2 = ''
				for (let cfitem of cfitems) {
					let hcs = cfitem.humanCodingScheme
					if (current_parent_2 && hcs.indexOf(current_parent_2) == 0) {
						// e.g. cp2 = AFNR-BAS-1.1 and hcs = AFNR-BAS-1.1a
						cfitem.level = 2

					} else if (current_parent_1 && hcs.indexOf(current_parent_1) == 0) {
						// e.g. cp1 = AFNR-BAS-1 and hcs = AFNR-BAS-1.1
						cfitem.level = 1
						current_parent_2 = hcs
						if (this.algo2_item_types.length > 1) cfitem.CFItemType = this.algo2_item_types[0]
						else if (this.algo2_item_types.length > 0) cfitem.CFItemType = this.algo2_item_types[0]

					} else {
						// e.g. hcs = ANFR-BAS-1 or ANFR-BAS-2
						cfitem.level = 0
						current_parent_1 = hcs
						current_parent_2 = ''
					}
					// note that this algorithm would mistakenly put ANFR-BAS-10 a level under ANFR-BAS-1 if they come right after each other, but that shouldn't happen

					// if we have algo2_item_types
					if (this.algo2_item_types.length > 0) {
						// add item_type for this level, or last item_type as default
						if (this.algo2_item_types.length >= cfitem.level) {
							cfitem.CFItemType = this.algo2_item_types[cfitem.level]
						} else {
							cfitem.CFItemType = this.algo2_item_types[this.algo2_item_types.length-1]
						}
					}
				}
			}

			// final array...
			let final_arr = []
			let updated_word = ''
			for (let cfitem of cfitems) {
				// use remove_line_breaks_from_text_field on notes, fullStatements, and abbreviatedStatements, if convert_newlines_to_spaces is on
				if (this.convert_newlines_to_spaces) {
					if (cfitem.fullStatement) cfitem.fullStatement = U.remove_line_breaks_from_text_field(cfitem.fullStatement)
					if (cfitem.abbreviatedStatement) cfitem.abbreviatedStatement = U.remove_line_breaks_from_text_field(cfitem.abbreviatedStatement)
					if (cfitem.notes) cfitem.notes = U.remove_line_breaks_from_text_field(cfitem.notes)
				}
				// just trim supplementalNotes, since they may have html
				if (cfitem.extensions.supplementalNotes) cfitem.extensions.supplementalNotes = $.trim(cfitem.extensions.supplementalNotes)

				// convert exemplar from markdown to html
				// console.log(cfitem.extensions.supplementalNotes)
				if (cfitem.extensions.supplementalNotes) {
					cfitem.extensions.supplementalNotes = marked(cfitem.extensions.supplementalNotes).replace(/\s*\n\s*/g, ' ')
				}
				// console.log(cfitem.extensions.supplementalNotes)

				// if we're processing items only...
				if (this.no_hierarchy) {
					updated_word = '<b>updated</b>'
					// then if the item *isn't* already in the framework, don't process it (note that we reported an error about the item above)
					let old_item = this.editor_component.framework_record.cfo.cfitems[cfitem.identifier]
					if (!old_item) {
						continue
					} else {
						// else only process it if the item changed
						let before = JSON.stringify(new window.CFItem(old_item).to_json())
						let after = JSON.stringify(new window.CFItem(cfitem).to_json())
						// if anything changed, update lastChangeDateTime and add to final_arr
						if (before != after) {
							cfitem.lastChangeDateTime = '*NOW*'
							console.log('before copy: ' + before)
							console.log('afterx copy: ' + after)
						} else {
							// else skip the item
							console.log('skipping unchanged:' + before)
							continue
						}
					}
				}
				final_arr.push(cfitem)
			}
			cfitems = final_arr

			// show log and give user options for what to do
			let error_text = ''
			if (error_count > 0) error_text = sr('; <span class="red white--text" style="padding:3px 6px; border-radius:4px;">$1 possible $2</span>', error_count, U.ps('error', error_count))
			let text = sr('<div style="margin-top:-10px; margin-bottom:8px;">Processed $1 lines; found $2 $3 competencies$4.</div>', line_number, cfitems.length, updated_word, error_text)

			text += '<div class="mb-1">Competencies:</div>'
			text += '<div style="margin-left:16px; font-size:14px; line-height:18px;">'
			for (let cfitem of cfitems) {
				let margin = (25 * (cfitem.level+1)) + 'px'
				let item_type = (!empty(cfitem.CFItemType)) ? sr(' <i>[$1]</i> ', cfitem.CFItemType) : ''
				let ed_level = (!empty(cfitem.educationLevel) && cfitem.educationLevel.length > 0) ? sr(' [$1] ', U.grade_level_display(cfitem.educationLevel)) : ''
				let identifier = (!empty(cfitem.identifier)) ? sr(' {$1} ', cfitem.identifier) : ''
				let hcs = (!empty(cfitem.humanCodingScheme)) ? sr('<b>$1:</b> ', cfitem.humanCodingScheme) : ''
				let notes = (!empty(cfitem.notes)) ? sr(' <b>NOTES:</b> $1', cfitem.notes.replace(/\n/g, ' / ')) : ''
				let supplementalNotes = (!empty(cfitem.extensions.supplementalNotes)) ? sr(' <b>EXEMPLAR:</b> <span style="font-family:monospace;font-size:12px">$1</span>', cfitem.extensions.supplementalNotes.replace(/</g, '&lt;')) : ''
				text += sr('<div style="margin-left:$1; text-indent:-35px; margin-top:4px;">• $2$3$4$5$6$7$8</div>', margin, hcs, ed_level, identifier, cfitem.fullStatement, item_type, notes, supplementalNotes)
			}
			text += '</div>'

			text += '<div class="mt-4 mb-2"><b>Processing log:</b></div>'
			text += '<div class="k-case-item-importer-report"><div class="k-case-item-importer-report-inner">'
			for (let log_line of log) text += sr('<div style="margin-left:50px; text-indent:-50px;">$1</div>', log_line)
			text += '</div></div>'

			let acceptText = (this.no_hierarchy) ? sr('Update $1 $2', cfitems.length, U.ps('Item', cfitems.length)) : sr('Add $1 Child $2', cfitems.length, U.ps('Item', cfitems.length))
			this.$confirm({
			    title: 'Processing Results',
			    text: text,
			    acceptText: acceptText,
				dialogMaxWidth: 950,
			}).then(y => {
				this.complete_save(cfitems)
			}).catch(n=>{console.log(n)}).finally(f=>{})

			console.log(cfitems)
		},

		complete_save(cfitems_data) {
			// get some things from the editor
			let CFItem, original_node
			if (this.editor_component.editor_type == 'document') {
				original_node = this.editor_component.cftree
			} else {
				original_node = this.editor_component.original_node
				CFItem = this.editor_component.CFItem
			}
			let CFDocument = this.editor_component.CFDocument
			let framework_record = this.editor_component.framework_record
			let cfo = framework_record.cfo
			let lsdoc_identifier = framework_record.lsdoc_identifier

			let data = {
				update_item_types: true,	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
				lsdoc_identifier: lsdoc_identifier,
				CFItems: [],
				CFAssociations: [],
			}

			let parent_node = [original_node, null, null]
			this.editor_component.viewer.new_imported_item_identifiers = []
			// for each to-be-processed cfitem_data...
			for (let cfitem_data of cfitems_data) {
				// if no_hierarchy is true, we *skip* any items that don't already exist
				if (this.no_hierarchy) {
					if (!cfo.cfitems[cfitem_data.identifier]) {
						console.log('skipping item cfitem_data.identifier')
						continue
					}
				}

				//////// first follow the pattern of what we do when adding a new sibling or child item (add_sibling)

				// if we're not updating existing items, merge with data from the current CFItem for empty fields (if we don't have CFItem, we're adding top-level items to the document)
				if (!this.no_hierarchy) {
					if (CFItem) {
						// only do this for language and educationLevel for now
						if (!cfitem_data.language && CFItem.language) cfitem_data.language = CFItem.language
						if (!cfitem_data.educationLevel && CFItem.educationLevel) cfitem_data.educationLevel = CFItem.educationLevel	// .concat([])
						// if (!cfitem_data.alternativeLabel && CFItem.alternativeLabel) cfitem_data.alternativeLabel = CFItem.alternativeLabel
						// if (!cfitem_data.CFItemType && CFItem.CFItemType) cfitem_data.CFItemType = CFItem.CFItemType
						// if (!cfitem_data.CFItemTypeURI && CFItem.CFItemTypeURI) cfitem_data.CFItemTypeURI = CFItem.CFItemTypeURI
						// if (!cfitem_data.conceptKeywords && CFItem.conceptKeywords) cfitem_data.conceptKeywords = CFItem.conceptKeywords
						// if (!cfitem_data.conceptKeywordsURI && CFItem.conceptKeywordsURI) cfitem_data.conceptKeywordsURI = CFItem.conceptKeywordsURI
						// if (!cfitem_data.licenseURI && CFItem.licenseURI) cfitem_data.licenseURI = CFItem.licenseURI

					} else {
						// if we don't have CFItem, we're adding top-level items to the document; the only thing we get from the document is the language
						if (!cfitem_data.language && CFDocument.language) cfitem_data.language = CFDocument.language
					}
				}

				// use the CFItem constructor, with complete_data and to_json, to get a new identifier, uri, etc. (if necessary)
				let new_CFItem = new window.CFItem(cfitem_data)
				if (!this.no_hierarchy) {
					new_CFItem.complete_data(CFDocument)	// note that this sets lastChangeDateTime to *NOW*; if no_hierarchy mode is on, we only change dates for updated items, above
				}

				// console.log(cfitem_data.level, new_CFItem.to_json())

				// see if the item is already a child of the parent_node...
				let child_node, sequenceNumber, node_was_added
				if (!this.no_hierarchy) {
					if (cfo.cfitems[cfitem_data.identifier]) {
						child_node = parent_node[cfitem_data.level].children.find(x=>x.cfitem.identifier == cfitem_data.identifier)
					}

					// if it's not already a child of the parent_node, add it to the cfo (if it is already a child, we're just updating the item)
					if (!child_node) {
						// determine the sequenceNumber for the new item -- have to do this before we add the node
						sequenceNumber = U.get_next_sequenceNumber(parent_node[cfitem_data.level], framework_record)
						// console.log('new item sequenceNumber: ' + sequenceNumber)

						// add to cfo
						child_node = U.add_child_to_cfo(new_CFItem.to_json(), parent_node[cfitem_data.level], this.$store, cfo)

						node_was_added = true
					} else {
						console.log('NODE ALREADY EXISTED: ' + cfitem_data.fullStatement.substr(0,20))
					}

					// this item will be the parent node for subsequent items at the next level
					parent_node[cfitem_data.level+1] = child_node
				}

				////////// then follow the pattern for what we do in save_changes

				new_CFItem = new_CFItem.to_json()

				// push json for CFItems; it was already created in add_child_to_cfo
				data.CFItems.push(new_CFItem)
				this.editor_component.viewer.new_imported_item_identifiers.push(new_CFItem.identifier)

				// if node_was_added, create an 'isChildOf' association for the item, and add to data
				if (node_was_added) {
					let child_association = U.create_association(parent_node[cfitem_data.level].cfitem, new_CFItem, sequenceNumber, CFDocument)
					data.CFAssociations.push(child_association.to_json())
				}
			}

			// make sure max-editor is closed
			this.ta_dialog_open = false

			// if we have a post_script, call it
			if (this.post_script) {
				this[this.post_script](data)
			}

			console.log(data)

			// Show import confirm and wait for user to save
			this.editor_component.viewer.show_import_confirm = true;
			this.editor_component.viewer.import_data = data; // note that we *don't* want viewer.import_data to be reactive
			this.editor_component.cancel_edit();

			// call save_imported_items in editor_component.viewer
			// this.editor_component.viewer.save_imported_items(data)

			if (this.no_hierarchy) {
				this.$alert('Note that you must update your browser window to see updates to existing items.')
			}
		},

		////////////////////////////////////////
		// pre scripts: these can run before the import script runs
		pre_script_sc_course_code_import(lines) {
			let arr = []
			let last_subject = '', last_grades = ''
			let grade_mappings = {
				'1Grades PreK-6': ['PK-06', 'Grades PreK–6'],
				'2Grades 7th-8th': ['07-08', 'Grades 7–8'],
				'3Grades 9th-12th': ['09-12', 'Grades 9–12'],
				'All Grade Levels': ['PK-12', 'All Grade Levels'],
			}
			for (let line of lines) {
				// each line should include these values:
				let [subject_code, subject_text, course_code, course_text, grades, notes] = line.split('\t')

				// extract educationLevel from the grades value
				let [educationLevel, grade_string] = grade_mappings[grades]

				// When subject changes, create new *
				if (last_subject != subject_code) {
					arr.push(`Subject * [${educationLevel}] :${subject_code} ${subject_text}`)
					last_subject = subject_code
					last_grades = ''	// always create a new grade when we create a new domain
				}

				// When grade changes, create new **
				if (last_grades != grades) {
					arr.push(`Grade_Span ** [${educationLevel}] ${grade_string}`)
					last_grades = grades
				}

				// every line has a course
				arr.push(`Course *** [${educationLevel}] :${course_code} ${course_text}`)
				
				// add notes if there
				if (!empty(notes)) {
					// notes = notes.replace(/ XX /g, '\n')
					// console.warn(notes)
					// arr.push(`NOTES:\n${notes}`)
					notes = notes.split(/ XX /)
					arr.push('NOTES:')
					for (let n of notes) {
						arr.push(n)
						arr.push('')
					}
				}
			}

			return arr
		},

		pre_script_cl_evidence_statements_import(lines) {
			let arr = []
			let last_grade = ''
			let last_domain = ''
			let last_cluster = ''
			let grade_mappings = {
				'0': ['KG', 'Kindergarten'],
				'1': ['01', 'Grade 1'],
				'2': ['02', 'Grade 2'],
				'3': ['03', 'Grade 3'],
				'4': ['04', 'Grade 4'],
				'5': ['05', 'Grade 5'],
				'6': ['06', 'Grade 6'],
				'7': ['07', 'Grade 7'],
				'8': ['08', 'Grade 8'],
				'A': ['09-12', 'High School: Algebra'],
				'F': ['09-12', 'High School: Functions'],
				'G': ['09-12', 'High School: Geometry'],
				'N': ['09-12', 'High School: Number and Quantity'],
				'S': ['09-12', 'High School: Statistics & Probability'],

				'00': ['KG', 'Kindergarten'],
				'01': ['01', 'Grade 1'],
				'02': ['02', 'Grade 2'],
				'03': ['03', 'Grade 3'],
				'04': ['04', 'Grade 4'],
				'05': ['05', 'Grade 5'],
				'06': ['06', 'Grade 6'],
				'07': ['07', 'Grade 7'],
				'08': ['08', 'Grade 8'],
				'9-10': ['09-10', 'Grade 9-10'],
				'11-12': ['11-12', 'Grade 11-12'],
				'K-12': ['KG-12', 'Grade K-12'],
			}
			for (let line of lines) {
				// each line should include these values:
				let [grade, domain_code, domain_text, cluster_code, cluster_text, es_code, es_text, notes] = line.split('\t')

				// get educationLevel and grade_text for the grade
				let [educationLevel, grade_text] = grade_mappings[grade]

				if (!empty(domain_code)) domain_code = ':' + domain_code
				if (!empty(cluster_code)) cluster_code = ':' + cluster_code

				// When grade changes, create new *
				if (last_grade != grade) {
					arr.push(`Grade_Level * [${educationLevel}] ${grade_text}`)
					last_grade = grade
					last_domain = ''	// always create a new cluster when we create a new domain
				}

				// When Domain changes, create new **
				if (last_domain != domain_text) {
					arr.push(`Domain ** [${educationLevel}] ${domain_code} ${domain_text}`)
					last_domain = domain_text
					last_cluster = ''	// always create a new cluster when we create a new domain
				}

				// When Cluster changes, create new ***
				if (last_cluster != cluster_text) {
					arr.push(`Cluster *** [${educationLevel}] ${cluster_code} ${cluster_text}`)
					last_cluster = cluster_text
				}

				// every line has an evidence statment -- ****
				arr.push(`Evidence_Statement **** [${educationLevel}] :${es_code} ${es_text}`)
				
				// add notes if there
				if (!empty(notes)) {
					// notes = notes.replace(/ XX /g, '\n')
					// console.warn(notes)
					// arr.push(`NOTES:\n${notes}`)
					notes = notes.split(/ XX /)
					arr.push('NOTES:')
					for (let n of notes) {
						arr.push(n)
						arr.push('')
					}
				}
			}

			return arr
		},

		pre_script_edsby_course_import(lines) {
			function educationLevel_from_grades(grades) {
				let s = ''
				for (let grade of grades) {
					if (s) s +=','
					if (grade == '0K') s += 'KG'
					else if (grade < 10) s += '0' + grade
					else s += grade
				}
				return s
			}

			let line_number = 0
			// first line should be COURSES
			let line = $.trim(lines.shift())
			if (line != 'COURSES') return 'First line is not `COURSES`'
			++line_number

			line = $.trim(lines.shift())
			++line_number

			let subjects = []
			let course_hash = {}

			// read until we get to the COMPETENCIES line
			while (lines.length > 0) {
				// note that the first iteration here will skip the header line for courses
				line = $.trim(lines.shift())
				++line_number
				
				// skip blank lines
				if (!line) continue

				if (line == 'COMPETENCIES') break

				// SchoolID	CourseID	CourseName	ShortName	Subject	Grade	CourseType	Summary	Prerequisite	Standard	_opx	Notes
				// *	SK.0105	Arts Education 01	ART01	Arts Education	1	
				let fields = line.split('\t')
				let course_id = fields[1]
				let course_name = fields[2]
				let short_name = fields[3]
				let subject_name = fields[4]
				let grade = fields[5]
									
				// create object for each new subject encountered, with array for courses
				let so = subjects.find(x=>x.subject_name == subject_name)
				if (!so) {
					so = {
						subject_name: subject_name,
						courses: [],
						grades: [],
					}
					subjects.push(so)
				}

				// create object for each course encountered, with array for competencies
				let co = so.courses.find(x=>x.course_name == course_name)
				if (!co) {
					co = {
						course_id: course_id,
						course_name: course_name,
						short_name: short_name,
						// in the current sample we seem to always have one grade per course, but be prepared...
						grades: [grade],
						comps: [],
					}

					so.courses.push(co)

					// also create hash for courses
					course_hash[co.course_id] = co

					// add grade to subject array
					if (!so.grades.includes(co.grade)) so.grades.push(co.grade)
				}
			}

			// if we didnt end with the COMPETENCIES line, that's an error
			if (line != 'COMPETENCIES') return 'Competencies not found'
			lines.shift()	// skip header line
			++line_number

			while (lines.length > 0) {
				line = $.trim(lines.shift())
				++line_number
				// skip blank lines
				if (!line) continue

				// add each competency to its course

				// SchoolID	REGION	CourseID	ItemType	Name	Body
				// *	SK	SK.0010	Document.LearningStandard	ID	Intellectual Domain: Intellectual Development Involves: Knowledge and concepts; Creativity and representation; Communication skills; Emerging literacy; Problem solving abilities; Numeracy exploration.
				// *	SK	SK.0010	Document.LearningStandard	ID.ELAK1	I can apply what I know to texts by making predictions, asking and responding to questions, and expressing my feelings.
				let fields = line.split('\t')

				let item_type = fields[3]
				if (item_type == 'Document.LearningStandard') item_type = 'Learning_Standard'
				else if (typeof(item_type) != 'string') {
					return `item_type not found (line ${line_number}): ${line}`
				} else item_type = item_type.replace(/(\s|\.)/g, '_')

				let comp = {
					course_id: fields[2],
					item_type: item_type,
					hcs: fields[4],
					statement: fields[5],
				}
				let course = course_hash[comp.course_id]
				if (!course) return 'course_id not found in competency listing: line ' + line_number

				course.comps.push(comp)
			}

			console.log(subjects)

			// now go through and create items for each subject, course, and competency
			// S *** :S3E1 Obtain, evaluate, and communicate information...
			let arr = []
			for (let subject of subjects) {
				arr.push(`Subject * ${subject.subject_name}`)

				for (let course of subject.courses) {
					let grades = educationLevel_from_grades(course.grades.sort())
					arr.push(`Course ** [${grades}] :${course.course_id} ${course.course_name}`)

					// sort comps by hcs
					course.comps.sort((a,b) => U.natural_sort(a.hcs, b.hcs))

					let last_first_level_hcs = ''
					for (let comp of course.comps) {
						// allow for two levels of hierarchy
						let level = '***'
						// if last_hcs is 'ID' and this one is 'ID.ELAK1', bump up one star; otherwise leave at 3
						if (comp.hcs.includes('.') && last_first_level_hcs && comp.hcs.includes(last_first_level_hcs)) {
							level += '*'
						} else {
							last_first_level_hcs = comp.hcs
						}
						arr.push(`${comp.item_type} ${level} [${grades}] :${comp.hcs} ${comp.statement}`)
					}
				}
			}

			// sort comps numerically? SK.0605
			// other ones to check: SK.0900
			return arr
		},

		/**
		 * E * [02-04] :8.SC.1 Evaluate movement concepts with a variety of activities, alone and with others.
		 */
		 pre_script_asn(lines) {
			lines = lines.join('\n')
			const json = JSON.parse(lines)
			function built_tree_from_json(data) {
				const lookup = {};

				// Step 1: Initialize lookup table with children arrays
				Object.keys(data).forEach(key => {
				    lookup[key] = { ...data[key], children: [] };
				});

				// Step 2: Recursively attach children based on 'hasChild' field
				Object.keys(data).forEach(key => {
					const item = data[key];
					const node = lookup[key];
					// Attach the children if 'hasChild' exists
					if (item["http://purl.org/gem/qualifiers/hasChild"]) {
						item["http://purl.org/gem/qualifiers/hasChild"].forEach(child => {
							const child_id = child.value;
							if (lookup[child_id]) {
								node.children.push(lookup[child_id]); // Attach the child
							} else {
								console.warn(`Child with ID ${child_id} not found`);
							}
						});
					}
				});
				// Step 3: Find the root nodes (nodes without 'isChildOf') and return the tree
				const tree = Object.keys(data)
				.filter(key => !data[key]["http://purl.org/gem/qualifiers/isChildOf"]) // Root nodes have no parents
				.map(key => lookup[key]);
				return tree;
			}
			const tree = built_tree_from_json(json);
			// console.log(JSON.stringify(tree, null, 2));
			// console.log(tree)
			function create_import_text(node, stars, is_document=true) {
				let result = '';
				// Skip the document item(root passed in)
				if (!is_document) {
					if (node['http://purl.org/ASN/schema/core/statementLabel']) {
						const statement_label = node['http://purl.org/ASN/schema/core/statementLabel'][0].value;
						result += `${statement_label.split(' ').join('_')} `;
					}
					else {
						result += `- `;
					}
					result += '*'.repeat(stars)
					// If the current item has a description, add it to the result
					if (node['http://purl.org/dc/terms/educationLevel']) {
						const education_level = node['http://purl.org/dc/terms/educationLevel']
						const first_grade_level = education_level[0].value.split('/').pop()
						const last_grade_level = education_level[education_level.length-1].value.split('/').pop()
						console.log(first_grade_level, last_grade_level, education_level)
						result += ` [${first_grade_level}-${last_grade_level}] `
					}
					if (node['http://purl.org/ASN/schema/core/statementNotation']) {
						const human_readable_code = node['http://purl.org/ASN/schema/core/statementNotation'][0].value;
						result += ` :${human_readable_code}`;
					}
					if (node['http://purl.org/dc/terms/description']) {
						const description = node['http://purl.org/dc/terms/description'][0].value;
						result += ` ${description}\n`;
					}
				}
				if (node.children && node.children.length > 0) {
					node.children.forEach(child_node => {
						result += create_import_text(child_node, stars + 1, false);  // Recursive call for children
					});
				}
				return result;
			}
			const import_statement = create_import_text(tree[1], 0); // Start from the root node of the tree
			return import_statement.split('\n')
		},

		////////////////////////////////////////
		// post scripts: these can run after the import script has run
		post_script_ccsd_unit(data) {
			let find_descendent_by_hcs = (parent_node, hcs) => {
				for (let child of parent_node.children) {
					if (child.cfitem.humanCodingScheme == hcs) {
						return child
					}
					if (child.children.length > 0) {
						let rv = find_descendent_by_hcs(child, hcs)
						if (rv) return rv
					}
				}
				return null
			}

			console.log('post_script_ccsd unit', data)
			let errors = []
			let framework_record = this.editor_component.framework_record
			let cfo = framework_record.cfo
			let cfdocument = this.editor_component.CFDocument
			let original_node = this.editor_component.original_node
			let original_cfitem = original_node.cfitem

			let current_unit = null
			let current_unit_number = 'XXX'
			let sequence = 0
			for (let ii = 0; ii < data.CFItems.length; ++ii) {
				let item = data.CFItems[ii]
				// when we encounter a unit, set current_unit
				if (item.CFItemType == 'Unit') {
					current_unit = item
					sequence = 0
					current_unit_number = item.humanCodingScheme.replace(/.*\.U(\w+)$/, '$1')
					if (current_unit_number == item.humanCodingScheme) {
						errors.push(sr('Couldn’t find unit number for: ' + item.humanCodingScheme + ': ' + item.fullStatement))
						current_unit_number = 'XXX'
					}
					continue
				}

				++sequence

				// Place LFs under appropriate standards, and fill in unit numbers
				if (item.CFItemType == 'Learning Focus') {
					// get the isChildOf association
					let assoc = data.CFAssociations.find(x=>x.originNodeURI.identifier == item.identifier)

					// get the node for the child item -- there should only be one
					let child_node = cfo.cfitems[item.identifier].tree_nodes[0]

					// replace the unit number if necessary
					if (item.humanCodingScheme && item.humanCodingScheme.search(/UX+/) > -1) {
						console.log('replacing unit number: ' + current_unit_number)
						assoc.originNodeURI.title = assoc.originNodeURI.title.replace(/UX+/, 'U' + current_unit_number)
						item.humanCodingScheme = item.humanCodingScheme.replace(/UX+/, 'U' + current_unit_number)
						this.$store.commit('set', [child_node.cfitem, 'humanCodingScheme', item.humanCodingScheme])
						console.log('replacing unit number: ' + current_unit_number + ': ' + assoc.originNodeURI.title)
					}

					// find the corresponding competency in the parent folder
					let parent_hcs = item.humanCodingScheme.replace(/\.LF\b.*/, '')
					let parent_node = find_descendent_by_hcs(original_node, parent_hcs)
					// console.log(sr('$3: $1 / $2', item.humanCodingScheme, parent_hcs, parent_node ? parent_node.humanCodingScheme : '???'))
					if (!parent_node) {
						errors.push(sr('Couldn’t find parent of item with HCS <b>$1</b> ($2)', item.humanCodingScheme, parent_hcs))
						continue
					}

					// if the parent already has a child with this hcs, delete this item -- this happens for standards that are repeated in different units
					if (parent_node.children.find(x=>x.cfitem.humanCodingScheme == item.humanCodingScheme)) {
						console.log('deleting duplicate: ' + item.humanCodingScheme)
						let index = data.CFAssociations.findIndex(x=>x==assoc)
						data.CFAssociations.splice(index, 1)
						// remove the item from data.CFItems, then adjust ii
						data.CFItems.splice(ii, 1)
						--ii
						U.delete_node_from_cfo(cfo, child_node, true)
						continue
					}
					
					// switch the association to be a child of this parent
					assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(parent_node.cfitem)
					assoc.destinationNodeURI.identifier = parent_node.cfitem.identifier,
					assoc.destinationNodeURI.uri = parent_node.cfitem.uri

					// if the parent is a standard, we want the LF to be the first child
					if (parent_node.cfitem.CFItemType.includes('Standard')) {
						assoc.sequenceNumber = 1
						// remove the original_node from its previous parent's children, then add the node to the new parent's children array
						U.delete_node_from_cfo(cfo, child_node, true)
						U.add_child_to_cfo(child_node.cfitem, parent_node, this.$store, cfo, 0)

						// go through all this parent_node's other children save updates to the sequenceNumbers
						for (let i = 0; i < parent_node.children.length; ++i) {
							let cn = parent_node.children[i]

							// update the child's CFAssociation sequence and save to data
							let index = U.find_cfassociation_index(framework_record.json.CFAssociations, cn.cfitem.identifier, cn.parent_node.cfitem.identifier)
							if (index > -1) {
								// note that we save sequenceNumber as i+2, since the new item is child #1
								this.$store.commit('set', [framework_record.json.CFAssociations[index], 'sequenceNumber', i+2])
								data.CFAssociations.push(new CFAssociation(framework_record.json.CFAssociations[index]).to_json())
							}
						}

					// else we want the LF to be the last child
					} else {
						assoc.sequenceNumber = U.get_next_sequenceNumber(parent_node, framework_record)
						// remove the original_node from its previous parent's children, then add the node to the new parent's children array
						U.delete_node_from_cfo(cfo, child_node, true)
						U.add_child_to_cfo(child_node.cfitem, parent_node, this.$store, cfo, assoc.sequenceNumber)
					}
				}

				// if current_unit isn't null, add isPartOf relation between the item to the unit
				if (current_unit) {
					data.CFAssociations.push(U.create_association(current_unit, item, sequence, cfdocument, 'isPartOf'))
				}
			}

			// Place the Units in the Units folder
			let unit_folder_node
			for (let item of data.CFItems) {
				if (item.fullStatement == 'Units') {
					unit_folder_node = cfo.cfitems[item.identifier].tree_nodes[0]
					continue
				}
				if (item.CFItemType == 'Unit') {
					// get the isChildOf association
					let assoc = data.CFAssociations.find(x=>x.originNodeURI.identifier == item.identifier)

					// switch the association to be a child of this parent, and update the sequenceNumber
					assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(unit_folder_node.cfitem)
					assoc.destinationNodeURI.identifier = unit_folder_node.cfitem.identifier,
					assoc.destinationNodeURI.uri = unit_folder_node.cfitem.uri
					assoc.sequenceNumber = U.get_next_sequenceNumber(unit_folder_node, framework_record)

					// remove the original_node from its previous parent's children, then add the node to the new parent's children array
					let unit_node = cfo.cfitems[item.identifier].tree_nodes[0]
					U.delete_node_from_cfo(cfo, unit_node, true)
					U.add_child_to_cfo(unit_node.cfitem, unit_folder_node, this.$store, cfo, assoc.sequenceNumber)
				}
			}

			// Place the top-level standards items in the Standards folder
			for (let item of data.CFItems) {
				if (item.fullStatement == 'Standards') {
					let standards_folder_node = cfo.cfitems[item.identifier].tree_nodes[0]

					// go through all children of the original node
					let sn = 0
					for (let i = 0; i < original_node.children.length; ++i) { 
						let child_node = original_node.children[i]

						// skip Units and Standards folders
						if (child_node.cfitem.fullStatement == 'Standards' || child_node.cfitem.fullStatement == 'Units') continue

						let index = U.find_cfassociation_index(framework_record.json.CFAssociations, child_node.cfitem.identifier, child_node.parent_node.cfitem.identifier)
						if (index != -1) {
							++sn

							// don't bother updating json association; we're instructing the operator to reload right after we save
							let assoc = new CFAssociation(framework_record.json.CFAssociations[index])
							assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(standards_folder_node.cfitem)
							assoc.destinationNodeURI.identifier = standards_folder_node.cfitem.identifier,
							assoc.destinationNodeURI.uri = standards_folder_node.cfitem.uri
							assoc.sequenceNumber = sn
							data.CFAssociations.push(assoc.to_json())

							U.delete_node_from_cfo(cfo, child_node, true)
							U.add_child_to_cfo(child_node.cfitem, standards_folder_node, this.$store, cfo, assoc.sequenceNumber, child_node.children)

							// decrement i, because we just took an item out of original_node.children
							--i
						} else {
							console.log("Yikes!")
						}
					}

					break
				}
			}

			console.log('post_script_ccsd unit - after', data)

			if (errors.length > 0) {
				this.$alert({
					title: 'Post-Script possible errors',
					text: sr('<div style="font-size:12px">$1</div>', errors.join('<br>')),
					dialogMaxWidth: 950,
				})
			} else {
				this.$alert('Post-Script successful. Please reload after reviewing and saving imported items.')
			}
		},

		post_script_ccsd_module(data) {
			let find_descendent_by_hcs = (parent_node, hcs) => {
				for (let child of parent_node.children) {
					if (child.cfitem.humanCodingScheme == hcs) {
						return child
					}
					if (child.children.length > 0) {
						let rv = find_descendent_by_hcs(child, hcs)
						if (rv) return rv
					}
				}
				return null
			}

			console.log('post_script_ccsd module', data)
			let errors = []
			let framework_record = this.editor_component.framework_record
			let cfo = framework_record.cfo
			let cfdocument = this.editor_component.CFDocument
			let original_node = this.editor_component.original_node
			let original_cfitem = original_node.cfitem

			let current_unit = null
			let current_unit_number = 'XXX'
			let sequence = 0
			for (let ii = 0; ii < data.CFItems.length; ++ii) {
				let item = data.CFItems[ii]
				// when we encounter a unit, set current_unit
				if (item.CFItemType == 'Module') {
					current_unit = item
					sequence = 0
					current_unit_number = item.humanCodingScheme.replace(/.*\.M(\w+)$/, '$1')
					if (current_unit_number == item.humanCodingScheme) {
						errors.push(sr('Couldn’t find unit/module number for: ' + item.humanCodingScheme + ': ' + item.fullStatement))
						current_unit_number = 'XXX'
					}
					continue
				}

				++sequence

				// Place LFs under appropriate standards, and fill in unit numbers
				if (item.CFItemType == 'Learning Focus') {
					// get the isChildOf association
					let assoc = data.CFAssociations.find(x=>x.originNodeURI.identifier == item.identifier)

					// get the node for the child item -- there should only be one
					let child_node = cfo.cfitems[item.identifier].tree_nodes[0]

					// replace the unit number if necessary
					if (item.humanCodingScheme && item.humanCodingScheme.search(/MX+/) > -1) {
						console.log('replacing unit number: ' + current_unit_number)
						assoc.originNodeURI.title = assoc.originNodeURI.title.replace(/MX+/, 'M' + current_unit_number)
						item.humanCodingScheme = item.humanCodingScheme.replace(/MX+/, 'M' + current_unit_number)
						this.$store.commit('set', [child_node.cfitem, 'humanCodingScheme', item.humanCodingScheme])
						console.log('replacing unit number: ' + current_unit_number + ': ' + assoc.originNodeURI.title)
					}

					// find the corresponding competency in the parent folder
					let parent_hcs = item.humanCodingScheme.replace(/\.LF\b.*/, '')
					let parent_node = find_descendent_by_hcs(original_node, parent_hcs)
					// console.log(sr('$3: $1 / $2', item.humanCodingScheme, parent_hcs, parent_node ? parent_node.humanCodingScheme : '???'))
					if (!parent_node) {
						errors.push(sr('Couldn’t find parent of item with HCS <b>$1</b> ($2)', item.humanCodingScheme, parent_hcs))
						continue
					}

					// if the parent already has a child with this hcs, delete this item -- this happens for standards that are repeated in different units
					if (parent_node.children.find(x=>x.cfitem.humanCodingScheme == item.humanCodingScheme)) {
						console.log('deleting duplicate: ' + item.humanCodingScheme)
						let index = data.CFAssociations.findIndex(x=>x==assoc)
						data.CFAssociations.splice(index, 1)
						// remove the item from data.CFItems, then adjust ii
						data.CFItems.splice(ii, 1)
						--ii
						U.delete_node_from_cfo(cfo, child_node, true)
						continue
					}
					
					// switch the association to be a child of this parent
					assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(parent_node.cfitem)
					assoc.destinationNodeURI.identifier = parent_node.cfitem.identifier,
					assoc.destinationNodeURI.uri = parent_node.cfitem.uri

					// if the parent is a standard, we want the LF to be the first child
					if (parent_node.cfitem.CFItemType.includes('Standard')) {
						assoc.sequenceNumber = 1
						// remove the original_node from its previous parent's children, then add the node to the new parent's children array
						U.delete_node_from_cfo(cfo, child_node, true)
						U.add_child_to_cfo(child_node.cfitem, parent_node, this.$store, cfo, 0)

						// go through all this parent_node's other children save updates to the sequenceNumbers
						for (let i = 0; i < parent_node.children.length; ++i) {
							let cn = parent_node.children[i]

							// update the child's CFAssociation sequence and save to data
							let index = U.find_cfassociation_index(framework_record.json.CFAssociations, cn.cfitem.identifier, cn.parent_node.cfitem.identifier)
							if (index > -1) {
								// note that we save sequenceNumber as i+2, since the new item is child #1
								this.$store.commit('set', [framework_record.json.CFAssociations[index], 'sequenceNumber', i+2])
								data.CFAssociations.push(new CFAssociation(framework_record.json.CFAssociations[index]).to_json())
							}
						}

					// else we want the LF to be the last child
					} else {
						assoc.sequenceNumber = U.get_next_sequenceNumber(parent_node, framework_record)
						// remove the original_node from its previous parent's children, then add the node to the new parent's children array
						U.delete_node_from_cfo(cfo, child_node, true)
						U.add_child_to_cfo(child_node.cfitem, parent_node, this.$store, cfo, assoc.sequenceNumber)
					}
				}

				// if current_unit isn't null, add isPartOf relation between the item to the unit
				if (current_unit) {
					data.CFAssociations.push(U.create_association(current_unit, item, sequence, cfdocument, 'isPartOf'))
				}
			}

			// Place the Units in the Units folder
			let unit_folder_node
			for (let item of data.CFItems) {
				if (item.fullStatement == 'Modules') {
					unit_folder_node = cfo.cfitems[item.identifier].tree_nodes[0]
					continue
				}
				if (item.CFItemType == 'Module') {
					// get the isChildOf association
					let assoc = data.CFAssociations.find(x=>x.originNodeURI.identifier == item.identifier)

					// switch the association to be a child of this parent, and update the sequenceNumber
					assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(unit_folder_node.cfitem)
					assoc.destinationNodeURI.identifier = unit_folder_node.cfitem.identifier,
					assoc.destinationNodeURI.uri = unit_folder_node.cfitem.uri
					assoc.sequenceNumber = U.get_next_sequenceNumber(unit_folder_node, framework_record)

					// remove the original_node from its previous parent's children, then add the node to the new parent's children array
					let unit_node = cfo.cfitems[item.identifier].tree_nodes[0]
					U.delete_node_from_cfo(cfo, unit_node, true)
					U.add_child_to_cfo(unit_node.cfitem, unit_folder_node, this.$store, cfo, assoc.sequenceNumber)
				}
			}

			// Place the top-level standards items in the Standards folder
			for (let item of data.CFItems) {
				if (item.fullStatement == 'Standards') {
					let standards_folder_node = cfo.cfitems[item.identifier].tree_nodes[0]

					// go through all children of the original node
					let sn = 0
					for (let i = 0; i < original_node.children.length; ++i) { 
						let child_node = original_node.children[i]

						// skip Units and Standards folders
						if (child_node.cfitem.fullStatement == 'Standards' || child_node.cfitem.fullStatement == 'Modules') continue

						let index = U.find_cfassociation_index(framework_record.json.CFAssociations, child_node.cfitem.identifier, child_node.parent_node.cfitem.identifier)
						if (index != -1) {
							++sn

							// don't bother updating json association; we're instructing the operator to reload right after we save
							let assoc = new CFAssociation(framework_record.json.CFAssociations[index])
							assoc.destinationNodeURI.title = U.generate_cfassociation_node_uri_title(standards_folder_node.cfitem)
							assoc.destinationNodeURI.identifier = standards_folder_node.cfitem.identifier,
							assoc.destinationNodeURI.uri = standards_folder_node.cfitem.uri
							assoc.sequenceNumber = sn
							data.CFAssociations.push(assoc.to_json())

							U.delete_node_from_cfo(cfo, child_node, true)
							U.add_child_to_cfo(child_node.cfitem, standards_folder_node, this.$store, cfo, assoc.sequenceNumber, child_node.children)

							// decrement i, because we just took an item out of original_node.children
							--i
						} else {
							console.log("Yikes!")
						}
					}

					break
				}
			}

			console.log('post_script_ccsd module - after', data)

			if (errors.length > 0) {
				this.$alert({
					title: 'Post-Script possible errors',
					text: sr('<div style="font-size:12px">$1</div>', errors.join('<br>')),
					dialogMaxWidth: 950,
				})
			} else {
				this.$alert('Post-Script successful. Please reload after reviewing and saving imported items.')
			}
		},
	}
}
</script>

<style lang="scss">
.k-case-item-importer {
	textarea {
		font-size: 12px;
		line-height:16px!important;
		max-height:calc(100vh - 450px);
	}
}

// the below styles are also used for ItemCopyInterface
.k-case-item-importer-report {
	overflow: auto;
}

.k-case-item-importer-report-inner {
	white-space:nowrap;
	font-size: 12px;
    line-height: 14px;
}

.k-case-item-importer-raw-text-area {
	margin-bottom:8px;
	textarea {
		overflow:auto!important;
		font-family:monospace;
		font-size:14px;
		line-height:18px!important;
	}
}

.k-froala-plain-text.fr-box.fr-basic .fr-element.fr-view {
	font-family:monospace!important;
	font-size:14px!important;
}

.k-case-item-importer-raw-text-area-max {
	textarea {
		overflow:auto!important;
		font-family:monospace;
		font-size:14px;
		line-height:18px!important;
		height:calc(85vh - 180px);
		margin:0 auto;
	}
}
</style>
