<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="95vw" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<div><b>Custom Script Runner:</b> {{custom_script_name}}</div>
				<v-spacer/>
				<v-btn color="primary" :disabled="!cs" class="mr-3" @click="run">   Run<v-icon small class="ml-1 mr-3">fas fa-circle-arrow-right</v-icon></v-btn>
				<v-btn color="secondary" @click="$emit('dialog_cancel')">Done <v-icon small class="ml-2">fas fa-circle-xmark</v-icon></v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="d-flex">
					<div v-if="input_required" class="mr-2" style="flex:0 1 50%">
						<div class="mb-1"><b>Input:</b></div>
						<div><v-textarea class="k-custom-script-text-area" outlined hide-details label="" placeholder="Type response here" v-model="input"></v-textarea></div>
					</div>
					<div :style="`flex:0 1 ${input_required?'50':'100'}%`">
						<div class="mb-1"><b>Output:</b></div>
						<div><v-textarea class="k-custom-script-text-area" outlined hide-details label="" placeholder="Type response here" v-model="output"></v-textarea></div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import './custom_scripts/ga_ela_progressions.js'
import './custom_scripts/sparkl_test_harness_input.js'
import './custom_scripts/update_derivative_use_aliases.js'

export default {
// components: { CustomScriptRunner },
	props: {
		custom_script_name: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		input_required: true,
		output: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		cs() { return window.custom_scripts[this.custom_script_name] },
		input: {
			get() { return this.$store.state.lst.last_custom_script_input },
			set(val) { this.$store.commit('lst_set', ['last_custom_script_input', val]) }
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		if (!this.cs) {
			this.$alert(`Error: custom script “${this.custom_script_name}” not found`)
		} else {
			this.input_required = this.cs.input_required

			// if we have a pre_fn, run it
			if (this.cs.pre_fn) {
				this.cs.pre_fn().then((output)=>this.output = output)
			}
		}
	},
	methods: {
		run() {
			this.cs.fn(this.input).then((output)=>this.output = output)
		}
	}
}
</script>

<style lang="scss">
.k-custom-script-text-area {
	textarea {
		overflow:auto!important;
		font-family:monospace;
		font-size:14px;
		line-height:18px!important;
		height:calc(100vh - 220px);
	}
}
</style>