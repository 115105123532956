import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 1. Define route components. These can be imported from other files
import BadRoute from './components/BadRoute'
import FrameworkList from './components/frameworks/FrameworkList'
import ResourceSetList from './components/resources/ResourceSetList'
import ResourceSet from './components/resources/ResourceSet'

// 2. Define some routes
const routes = [
	{ path: '*', component: BadRoute },
	{ path: '', name: 'framework_list', component: FrameworkList, props: route => ({
		case_tree_lsdoc_identifier: '',
		starting_lsitem_identifier: '',
		query: route.query
	}) },
	{ path: '/frameworks', redirect: { name: 'framework_list' } },

	{ path: '/resources', name: 'resource_set_list', component: ResourceSetList, props: route => ({
		query: route.query
	}) },
	{ path: '/resources/:resource_set_id', name: 'resource_set', component: ResourceSet, props: route => ({
		resource_set_id: route.params.resource_set_id*1,
		resource_id: 0,
		query: route.query
	}) },
	{ path: '/resources/:resource_set_id/:resource_id', name: 'resource_edit', component: ResourceSet, props: route => ({
		resource_set_id: route.params.resource_set_id*1,
		resource_id: route.params.resource_id*1,
		query: route.query
	}) },

	// the following three redirects should ease the transition from OpenSALT to Satchel
	{ path: '/cfdoc', redirect: { name: 'framework_list' } },
	{ path: '/cftree/doc/*', redirect: { name: 'framework_list' } },
	{ path: '/cftree/item/*', redirect: { name: 'framework_list' } },

	{ path: '/crosswalk/:crosswalk_lsdoc_identifier_1/:crosswalk_lsdoc_identifier_2', name: 'crosswalk', component: FrameworkList, props: route => ({
		crosswalk_lsdoc_identifiers: [route.params.crosswalk_lsdoc_identifier_1, route.params.crosswalk_lsdoc_identifier_2],
		query: route.query
	}) },

	// { path: '/resourcealign/:filename', name: 'resourcealign', component: FrameworkList, props: route => ({
	// 	filename: route.params.filename,
	// 	query: route.query
	// }) },

	{ path: '/:case_tree_lsdoc_identifier', name: 'framework', component: FrameworkList, props: route => ({
		case_tree_lsdoc_identifier: route.params.case_tree_lsdoc_identifier,
		starting_lsitem_identifier: '',
		query: route.query
	}) },
	{ path: '/:case_tree_lsdoc_identifier/:starting_lsitem_identifier', name: 'framework_item', component: FrameworkList, props: route => ({
		case_tree_lsdoc_identifier: route.params.case_tree_lsdoc_identifier,
		starting_lsitem_identifier: route.params.starting_lsitem_identifier,
		query: route.query
	}) },
	{ path: '/:case_tree_lsdoc_identifier/:starting_lsitem_identifier/:starting_tree_key', name: 'framework_item_with_key', component: FrameworkList, props: route => ({
		case_tree_lsdoc_identifier: route.params.case_tree_lsdoc_identifier,
		starting_lsitem_identifier: route.params.starting_lsitem_identifier,
		starting_tree_key: route.params.starting_tree_key,
		query: route.query
	}) },

]

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
	// for local development, base is ''; on the server, base is '/'
	base: (document.location.href.indexOf('localhost') > -1) ? '' : '/',
	// history mode will make the url work as, e.g., '/sparklsalt/xxxx'; without history mode, it will be sparklsalt/index.html#/xxxx
	mode: 'history',
	routes // short for `routes: routes`
})

// override push and replace functions to avoid useless "NavigationDuplicated" errors
// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const _push = router.__proto__.push
router.__proto__.push = function push (...args) {
	return _push.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

const _replace = router.__proto__.replace
router.__proto__.replace = function replace (...args) {
	return _replace.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

// 4. export router object for inclusion in main.js
export default router
