<template>
    <div :class="[type, 'rule', collapsed ? 'collapsed' : 'not-collapsed']" @mousedown="$emit('property_selected', undefined)" @click="e => {expand_from_click(e); $emit('click', e);}">
      <!--div class="d-flex mb-1 mx-5" style="border-radius: 15px; background-color: white;">
        <div class="ml-1" style="background-color: transparent;">
          Matches: 
        </div>
        <div style="font-family: sans-serif;">
          <v-icon @click="$emit('find_highlight', -1)">fas fa-caret-left</v-icon>
          <v-icon @click="$emit('find_highlight', +1)">fas fa-caret-right</v-icon>
          
        </div>
      </div-->
      <div>
        <div v-for="(property, index) in properties_computed" class="d-flex" :key="index" :class="['property']">
          <v-select :disabled="collapsed" :label="'type'" v-model="property.type" :items="property_types" @change="$emit('property_selected', property)" append-icon="" class="select--menu" hide-details dense outlined style="max-width: 80px; max-height: 40px;"/>
          <v-textarea :disabled="collapsed" :label="'value'" type="text" hide-details dense outlined style="min-width: 150px; transition: background-color .25s; overflow: hidden;" v-model="property.value" v-show="(property.type != 'Aside' && property.type != 'Same')" @focus="e => {$emit('property_selected', property); property.in_focus = true;}" @click="$emit('property_selected', property)" @blur="property.in_focus = false" rows="1" :auto-grow="false && property.in_focus" no-resize :class="[{ currentProperty: property.selected }]"/>
          <v-select :disabled="collapsed" :label="'direction'" v-model="property.direction" :items="['Horizontal', 'Vertical']" v-show="(property.type == 'Aside' || property.type == 'Same' || property.type == 'Offset')" append-icon="" hide-details dense outlined style="min-width: 0px; max-height: 40px;"/>
          <v-text-field type="number" :disabled="collapsed" :label="'range'" hide-details dense outlined style="min-width: 60px; max-width: 80px; max-height: 40px;" v-model="property.match_range" v-show="(property.type != 'Font' && property.type != 'Regex' && property.type != 'Anti Regex' && property.type != 'Pages' && property.type != 'Area' && property.type != 'Next')"/>
          <v-select :disabled="collapsed" :label="'mode'" v-model="property.regex_perfect_match" :items="['Perfect', 'Any']" v-show="(property.type == 'Regex' || property.type == 'Anti Regex')" append-icon="" hide-details dense outlined style="max-width: 80px; max-height: 40px;"/>
          <v-btn @click="$emit('remove_property', property)" style="min-width: 0; padding: 0 0 0 1px; margin-left: 4px; background-color: transparent; box-shadow: none; color: white;">&#10006</v-btn>
        </div>
      </div>
      <div>
        <v-select :disabled="collapsed" :label="'extension options'" class="mb-1 mx-4" v-show="extension_properties && extension_properties.length > 0" v-model="extension_option_computed" :items="['Match Both', 'Match Only Extended']" append-icon="" hide-details dense outlined style="background-color: rgba(255, 255, 255, .8); font-family: monospace;"/>
      </div>
      <div>
        <div v-for="(property, index) in extension_properties_computed" class="d-flex" :key="index" :class="['property']">
          <v-select :disabled="collapsed" :label="'type'" v-model="property.type" :items="extension_property_types" @change="$emit('property_selected', property)" append-icon="" class="select--menu" hide-details dense outlined style="max-width: 80px; max-height: 40px;"/>
          <v-textarea :disabled="collapsed" :label="'value'" type="text" hide-details dense outlined style="min-width: 150px; transition: background-color .25s; overflow: hidden;" v-model="property.value" v-show="(property.type != 'Aside' && property.type != 'Same')" @focus="e => {$emit('property_selected', property); property.in_focus = true;}" @click="$emit('property_selected', property)" @blur="property.in_focus = false" rows="1" :auto-grow="false && property.in_focus" no-resize :class="[{ currentProperty: property.selected }]"/>
          <v-select :disabled="collapsed" :label="'direction'" v-model="property.direction" :items="['Horizontal', 'Vertical']" v-show="(property.type == 'Aside' || property.type == 'Same' || property.type == 'Offset')" append-icon="" hide-details dense outlined style="min-width: 0px; max-height: 40px;"/>
          <v-text-field type="number" :disabled="collapsed" :label="'range'" hide-details dense outlined style="min-width: 60px; max-width: 80px; max-height: 40px;" v-model="property.match_range" v-show="(property.type != 'Font' && property.type != 'Regex' && property.type != 'Anti Regex' && property.type != 'Pages' && property.type != 'Area' && property.type != 'Next')"/>
          <v-select :disabled="collapsed" :label="'mode'" v-model="property.regex_perfect_match" :items="['Perfect', 'Any']" v-show="(property.type == 'Regex' || property.type == 'Anti Regex')" append-icon="" hide-details dense outlined style="max-width: 80px; max-height: 40px;"/>
          <v-btn @click="$emit('remove_extension_property', property)" style="min-width: 0; padding: 0 0 0 1px; margin-left: 4px; background-color: transparent; box-shadow: none; color: white;">&#10006</v-btn>
        </div>
      </div>
      <div>
        <v-btn @click="add_property" small depressed :disabled="collapsed" style="background-color: rgba(255, 255, 255, .8);">+ Property</v-btn>
        <v-btn @click="add_extension_property" small depressed :disabled="collapsed" style="background-color: rgba(255, 255, 255, .8);" v-if="extension_properties">+ Extension</v-btn>
        <div style="float: right; display: inline; opacity: 1.0;">
          <v-btn ref="collapse_button" @click="collapsed_computed = !collapsed_computed" small depressed style="max-height: 50px; display: inline; background-color: rgba(255, 255, 255, .8); opacity: 1.0;" v-if="extension_properties">{{ collapsed_computed ? "Expand" : "Collapse" }}</v-btn>
          <v-btn @click="$emit('remove_me')" small depressed style="max-height: 50px; display: inline; background-color: rgba(255, 255, 255, .8); opacity: 1.0;">&#10006</v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>


  export default {
    name: 'PdfRule',
    components: {},
    data() { return {}},
    props: {
      type: String, // "exclude" or "include" or "group"
      property_types: Array,
      properties: Array,
      extension_option: String,
      extension_property_types: Array,
      extension_properties: Array,
      collapsed: Boolean
    },
    computed: {
      properties_computed: {
        get() {
          return this.properties
        },
        set(properties) {
          console.log(properties)
          this.$emit("update:properties", properties)
        }
      },
      extension_properties_computed: {
        get() {
          return this.extension_properties
        },
        set(extension_properties) {
          console.log(extension_properties)
          this.$emit("update:extension_properties", extension_properties)
        }
      },
      extension_option_computed: {
        get() {
          return this.extension_option
        },
        set(extension_option) {
          console.log(extension_option)
          this.$emit("update:extension_option", extension_option)
        }
      },
      collapsed_computed: {
        get() {
          return this.collapsed
        },
        set(collapsed) {
          console.log(collapsed)
          this.$emit("update:collapsed", collapsed)
        }
      }
    },
    emits: ["property_selected", "remove_property", "remove_me"],
    created() {
    },
    methods: {
      add_property() {
        console.log(this.collapsed)
        this.properties_computed.push({
          selected: false,
          in_focus: false,
          type: undefined,
          value: "",
          direction: "Horizontal",
          match_range: 0,
          regex_perfect_match: "Perfect"
        })
      },
      add_extension_property() {
        this.extension_properties_computed.push({
          selected: false,
          in_focus: false,
          type: undefined,
          value: "",
          direction: "Horizontal",
          match_range: 0,
          regex_perfect_match: "Perfect"
        })
      },
      expand_from_click(e) {
        if (!this.$refs.collapse_button.$el.contains(e.target)) this.collapsed_computed = false
      }
    },
    watch: {
    },
  }
  </script>
  
  <style scoped>

  .include {
    background-color: lightgreen;
  }

  .exclude {
    background-color: pink;
  }

  .group {
    background-color: lightblue;
  }

  .component {
    background-color: yellow;
  }

  .style {
    background-color: orange;
  }


  .rule {
      margin-bottom: 5px;
      padding: 8px 10px 10px 10px;
      border-radius: 5px;
      overflow: hidden;
      transition: opacity .25s, box-shadow .25s, max-height .25s;
  }

  .collapsed {
  }

  .rule > div > * {
    max-height: 50px;
    opacity: 1.0;
    transition: margin .25s, padding .25s, max-height .25s, opacity .25s;
  }

  .collapsed > div > * {
    margin: 0;
    padding: 0;
    max-height: 0;
    opacity: 0.0;
  }

  .d-flex > * {
    margin-right: 2px;
    padding: 0 !important;
    background-color: rgba(255, 255, 255, .875);
    font-family: monospace;
  }

    .property {
        margin-bottom: 5px;
        padding: 5px;
        background-color: rgba(0, 0, 0, .1);
        border-radius: 5px;
        transition: background-color .25s, transform .25s;
    }

    select {
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        font-family: monospace;
    }

  .currentProperty {
    background-color: rgba(255, 255, 255, .5);
  }

	input[type=text], input[type=number] {
        padding-left: 5px;
		margin: 2px;
        font-family: monospace;
        border-radius: 5px;
        background-color: white;
    }

    .d-flex > * .v-input__slot {
      padding: 0 !important;
    }



  </style>
  