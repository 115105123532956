<template><div class="k-csv-item-importer mt-2 mb-2">
	<div class="text-center" style="color:black; font-size:18px;"><b>Batch Import Child Items from CSV</b></div>

	<div class="mt-2 text-center">
		<v-btn color="primary" @click="get_csv_file"><v-icon small class="mr-2">fas fa-file-csv</v-icon>Choose {{(csv_fields.length>0)?'New':''}} CSV File</v-btn>
	</div>

	<div class="mt-4" style="font-size:16px; border-top:1px solid #999" v-if="csv_fields.length>0">
		<div class="text-center pt-2 pink--text text--darken-4">Parsed <b>{{non_blank_csv_fields}} columns</b> and <b>{{item_lines.length}} non-blank lines</b></div>

		<div class="k-csv-item-importer-section">
			<div class="mb-2 grey--text text--darken-2"><b>Map each column to its corresponding CASE item property:</b></div>
			<div v-for="(field, i) in csv_fields" :key="i"><div v-if="field!=''" class="d-flex align-center mb-1">
				<!-- the v-show="field" attribute makes it so that empty fields aren't shown here -->
				<div style="flex:0 1 50%" class="mr-3 text-right">
					<v-btn small icon color="primary" @click="show_samples(i)"><v-icon small>fas fa-info-circle</v-icon></v-btn>
					<span :style="case_fields[i]?'font-weight:bold':''">{{field}}</span>
				</div>
				<div style="flex:0 0 200px; width:200px;">
					<v-select v-model="case_fields[i]" :items="case_field_options" dense outlined hide-details></v-select>
				</div>
			</div></div>
		</div>
		<div class="k-csv-item-importer-section">
			<div class="mb-2 grey--text text--darken-2"><b>Additional options:</b></div>
			<div class="mb-2"><v-checkbox class="mt-0 pt-0" style="align-items:flex-start" :label="'Skip first row in CSV file'" v-model="extra_settings.skip_first_row" hide-details></v-checkbox></div>
			<div><v-checkbox class="mt-0 pt-0" style="align-items:flex-start" :label="'If Human Coding Scheme ends in “x”, use line as notes field for preceding standard'" v-model="extra_settings.x_is_notes" hide-details></v-checkbox></div>
		</div>
		<div class="text-center mt-2"><v-btn small color="primary" @click="process_lines">Process Lines</v-btn></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		editor_component: { required: true },
		educationLevel: { type: Array, required: false, default() { return []} },
		language: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		item_lines: [],
		csv_fields: [],
		csv_fields_string: '',
		case_fields: [],
		case_field_options: [
			{ value:"", text: ""},
			{ value:"fullStatement", text:"Full Statement" },
			{ value:"humanCodingScheme", text:"Human Coding Scheme" },
			{ value:"notes", text:"Notes" },
			{ value:"CFItemType", text:"Item Type" },
			{ value:"language", text:"Language" },
			{ value:"educationLevel", text:"Education Level (comma-separated)" },
			{ value:"grade_low", text:"Grade Low" },
			{ value:"grade_high", text:"Grade High" },
			{ value:"identifier", text:"Identifier" },
			{ value:"uri", text:"URI" },
			{ value:"conceptKeywords", text:"Concept Keywords" },
			{ value:"abbreviatedStatement", text:"Abbreviated Statement" },

			{ value:"smartLevel", text:"“Smart Level”" },
			{ value:"parentHCS", text:"“Parent Human Coding Scheme”" },
		],
		extra_settings: {
			x_is_notes: false,
			skip_first_row: false,
		},
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		non_blank_csv_fields() {
			let i = 0
			for (let field of this.csv_fields) if (field) ++i
			return i
		},
	},
	watch: {
		case_fields() {
			console.log('case_field_options updated')
			U.local_storage_set(this.csv_fields_string, JSON.stringify({case_fields:this.case_fields, extra_settings:this.extra_settings}))
		},
		'extra_settings.x_is_notes'() {
			console.log('extra_settings updated')
			U.local_storage_set(this.csv_fields_string, JSON.stringify({case_fields:this.case_fields, extra_settings:this.extra_settings}))
		},
		'extra_settings.skip_first_row'() {
			console.log('extra_settings updated')
			U.local_storage_set(this.csv_fields_string, JSON.stringify({case_fields:this.case_fields, extra_settings:this.extra_settings}))
		},
	},
	created() {
		vapp.csv_importer = this
	},
	mounted() {
	},
	methods: {
		get_csv_file() {
			this.$prompt({
				title: 'Choose CSV File',
				text: 'Choose a CSV-formatted (or TSV-formatted) file specifying the items (e.g. standards, elements, and “folders”) you wish to import. The first row of the file should specify labels for each column. When you click the “PROCESS FILE” button, the file will be evaluated, and you’ll be asked to match up columns from the file with CASE item properties.',
				promptType: 'file',
				acceptText: 'Choose File',
				dialogMaxWidth: 550,
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()

				//////////////////////////////////
				// Read file and parse rows

				reader.onload = e => {
					let text = e.target.result
					// parse text; if first line includes a tab, assume it's a TSV; otherwise assume CSV
					let table
					if (text.split('\n')[0].indexOf('\t') > -1) {
						table = TSV.parse(text)
					} else {
						table = CSV.parse(text)
					}

					if (table.length < 2) {
						this.$alert('The uploaded file included fewer than two lines (or the file was malformed in some way). An item import must include at least one line of headers and at least one line specifying an item.')
						return
					}

					// parse item rows: for each non-header row...
					this.item_lines = []
					for (let i = 1; i < table.length; ++i) {
						let line = []
						let non_blank_detected = false
						for (let val of table[i]) {
							val = $.trim(val)
							line.push(val)
							if (!empty(val)) non_blank_detected = true
						}

						// skip blank lines
						if (non_blank_detected) {
							this.item_lines.push(line)
						}
					}

					if (this.item_lines.length == 0) {
						this.$alert('No non-blank item lines detected.')
						return
					}

					// parse headers in line 0
					this.csv_fields_string = 'csv_fields_string-'
					this.csv_fields = []
					this.case_fields = []
					for (let i = 0; i < table[0].length; ++i) {
						let field = $.trim(table[0][i])
						if (!empty(field)) {
							this.csv_fields_string += '-' + field
							// check to see if there is any data for this field in the item lines; if not, don't include the field
							if (this.item_lines.findIndex(line=>!empty($.trim(line[i]))) == -1) {
								field = ''
							}
						}
						this.csv_fields.push(field)
						// if the column header matches a CASE field name exactly, do an "auto-match"
						let index = this.case_field_options.findIndex(o=>o.value.toLowerCase()==field.toLowerCase())
						if (index > -1) {
							this.case_fields.push(this.case_field_options[index].value)
						} else {
							this.case_fields.push('')
						}
					}
					if (this.non_blank_csv_fields == 0) {
						this.$alert('The first line of the file must include headers for each column; no non-blank fields were detected.')
						return
					}

					// if we have field correspondences saved for this csv_fields_string, restore
					let s = U.local_storage_get(this.csv_fields_string)
					if (!empty(s)) {
						console.log(s)
						let o = JSON.parse(s)
						this.case_fields = o.case_fields

						// use some care when retrieving extra_settings to allow for future additions
						for (let key in this.extra_settings) {
							if (o.extra_settings[key] != undefined) {
								this.extra_settings[key] = o.extra_settings[key]
							}
						}
					}
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		show_samples(field_index, max_count) {
			if (empty(max_count)) max_count = 15
			let hash = {}
			for (let line of this.item_lines) {
				let val = line[field_index]
				if (!empty(val)) {
					val += ' '	// this keeps the hash values going in order
					if (!hash[val]) hash[val] = 1
					else ++hash[val]
				}
			}

			let html = ''
			let count = 0
			for (let val in hash) {
				if (count < max_count) html += sr('<li>$1</i></b> ($2)</li>', val, hash[val])
				++count
			}

			let title = sr('Values for column <i>$1</i> ($2)', this.csv_fields[field_index], count)

			if (count > max_count) {
				html += sr('<li class="mt-2"><b>… plus $1 additional $2</b></li>', (count - max_count), U.ps('value', (count - max_count)))
			}
			this.$confirm({
				title: title,
				text: html,
				dialogMaxWidth:700,
				cancelText: 'Show All Values',
				hideCancel: (max_count == 999999)
			}).then(y=>{}).catch(n=>{
				this.show_samples(field_index, 999999)
			}).finally(f=>{})
		},

		process_lines(identifiers_confirmed) {
			// TODO: make sure each field is only chosen once
			// TODO: make sure we have smartLevel OR parentHCS, or neither, but not both?
			// TODO: warn about identifiers

			if (!this.case_fields.includes('fullStatement')) {
				this.$alert('You must specify a “fullStatement” column.')
				return
			}

			// if identifier is specified, make sure they are valid identifiers
			if (this.case_fields.includes('identifier')) {
				let sample_identifier = this.item_lines[this.item_lines.length-1][this.case_fields.findIndex(x=>x=='identifier')]
				if (!U.is_uuid(sample_identifier)) {
					this.$alert(sr('There is at least one row in the CSV file where the value in the column you specified as the “identifier” ($1) is not a properly-formed GUID.', sample_identifier))
					return
				}
				// and if they are identifiers, make sure the user really wants to do this
				if (identifiers_confirmed !== 'confirmed') {
					this.$confirm({
					    title: 'Are you sure?',
					    text: 'Are you sure you want to import items with CASE identifiers specified in the CSV file? Only click “CONFIRM” if you know what you’re doing!',
					    acceptText: 'Confirm',
						acceptColor: 'red',
						dialogMaxWidth: 600,
					}).then(y => {
						this.process_lines('confirmed')
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}
			}

			// if skip_first_row is true, shift item_lines
			if (this.extra_settings.skip_first_row) {
				this.item_lines.shift()
			}

			let CFDocument = this.editor_component.CFDocument
			let framework_record = this.editor_component.framework_record
			let cfo = framework_record.cfo
			let lsdoc_identifier = framework_record.lsdoc_identifier

			let data = {
				lsdoc_identifier: lsdoc_identifier,
				CFItems: [],
				CFAssociations: [],
			}

			// create items and get tree_determiners
			let items = []
			let tree_determiners = []
			for (let line of this.item_lines) {
				let o = {}
				let tree_determiner = 'orphan'
				let grade_low, grade_high

				// fill in specified fields
				for (let i = 0; i < this.case_fields.length; ++i) {
					let field = this.case_fields[i]
					if (field == 'parentHCS' || field == 'smartLevel') tree_determiner = line[i]
					else if (field == 'grade_low') grade_low = line[i]
					else if (field == 'grade_high') grade_high = line[i]
					else if (field) o[field] = line[i]
				}

				// if we got grade_low / grade_high, and we didn't directly load an educationLevel, convert to educationLevel
				if (!o.educationLevel && (!empty(grade_low) || !empty(grade_high))) {
					if (empty(grade_low)) grade_low = grade_high
					if (empty(grade_high)) grade_high = grade_low

					// if grade_low/grade_high is a number, convert to a string using state.grades, using the assumption that 0 = kindergarten
					if (!isNaN(grade_low * 1)) {
						grade_low = this.$store.state.grades.find(x=>(x.index - 2) == grade_low)
						if (empty(grade_low)) {
							console.log('bad grade_low: ' + grade_low)
							grade_low = ''
						} else {
							grade_low = grade_low.value
						}
					}
					if (!isNaN(grade_high * 1)) {
						grade_high = this.$store.state.grades.find(x=>(x.index - 2) == grade_high)
						if (empty(grade_high)) {
							console.log('bad grade_high: ' + grade_high)
							grade_high = ''
						} else {
							grade_high = grade_high.value
						}
					}
					if (grade_low == grade_high) {
						o.educationLevel = grade_low
					} else {
						o.educationLevel = U.grade_level_display_to_educationLevel(grade_low + '-' + grade_high)
					}
				}

				// deal with "x" HCSs
				if (this.extra_settings.x_is_notes && o.humanCodingScheme && o.humanCodingScheme.search(/x$/) > -1) {
					// if the previous item already had notes, add a space; then add this item's fullStatement
					if (items[items.length-1].notes) items[items.length-1].notes += ' '
					items[items.length-1].notes = o.fullStatement
					console.log('processed example: ' + (items.length-1))
					continue
				}

				// create valid CFItem (adding identifier, if necessary, plus URI and lastChangeDateTime), then push to items
				o = new CFItem(o)
				o.complete_data(CFDocument)

				items.push(o)
				tree_determiners.push(tree_determiner)
			}
			console.log(items, tree_determiners)

			////////////////////////////////////
			// now process isChildOf associations. start by getting the original_node, which is the document or item we're importing under
			let original_node = (this.editor_component.editor_type == 'document') ? this.editor_component.cftree : this.editor_component.original_node

			let parent_node_next_sequenceNumber = U.get_next_sequenceNumber(original_node, framework_record)
			let new_nodes = []
			this.editor_component.viewer.new_imported_item_identifiers = []

			for (let i = 0; i < tree_determiners.length; ++i) {
				let td = tree_determiners[i]

				// store item json to data.CFItems now, after examples have been processed
				data.CFItems.push(items[i].to_json())

				// save identifier to viewer.new_imported_item_identifiers
				this.editor_component.viewer.new_imported_item_identifiers.push(items[i].identifier)

				// get item json for further work below; note that we need a different copy here than the one we put in CFItems
				let item_json = items[i].to_json()

				let parent_node, sequenceNumber

				// if we don't have a tree_determiner for an item, or there isn't a smartLevel or parent column specified, the item goes directly under the original_node
				if (!td || td == 'orphan') {
					parent_node = original_node
					sequenceNumber = parent_node_next_sequenceNumber
					++parent_node_next_sequenceNumber

				// process smartLevels if there; a smartLevel will be, e.g., `4.3.2`,
				// which means that this item should be sequenceNumber 2 under the item with smartLevel `4.3`
				} else if (this.case_fields.find(x=>x=='smartLevel')) {
					// separate smartLevel into parent and child; if this doesn't work, smartLevel is malformed
					if (td.search(/^([\d\.]+?)(\.(\d+))?$/) == -1) {
						// in this case we will add the item to the top level
						// TODO: better error handling?
						parent_node = original_node
						sequenceNumber = parent_node_next_sequenceNumber
						++parent_node_next_sequenceNumber
						console.log('error 1: ' + i + ': ' + td)

					} else {
						// smartLevel is well-formed...
						let parent_smartLevel = RegExp.$1
						let extracted_sequenceNumber = RegExp.$3

						// if there is a RegExp.$3, find the parent item that matches RegExp.$1
						if (!empty(extracted_sequenceNumber)) {
							let parent_index = tree_determiners.findIndex(x=>x==parent_smartLevel)
							if (parent_index == -1) {
								// in this case we will add the item to the top level
								// TODO: better error handling?
								parent_node = original_node
								sequenceNumber = parent_node_next_sequenceNumber
								++parent_node_next_sequenceNumber
								console.log(sr('error 2 ($1): $2 | $3 | $4', i, td, parent_smartLevel, extracted_sequenceNumber))

							} else {
								parent_node = new_nodes[parent_index]
								sequenceNumber = extracted_sequenceNumber*1

								console.log(sr('processed $1: $2', parent_smartLevel, sequenceNumber))
							}

						} else {
							// if there is no RegExp.$3, RegExp.$1 is the sequence and the parent is the original_node
							parent_node = original_node
							// add RegExp.$1 to parent_node_next_sequenceNumber, then subtract 1, to get the sequenceNumber for import
							sequenceNumber = parent_node_next_sequenceNumber + parent_smartLevel - 1
							console.log(sr('processed -: $1', sequenceNumber))
						}
					}

				// else process parentHCSs if there
				} else if (this.case_fields.find(x=>x=='parentHCS')) {
					// find the item in new_nodes whose hcs matches td
					parent_node = new_nodes.find(x=>x.cfitem.humanCodingScheme == td)
					if (!parent_node) {
						parent_node = original_node
						sequenceNumber = parent_node_next_sequenceNumber
						++parent_node_next_sequenceNumber
						console.log(sr('processed $1 (not found): $2', td, sequenceNumber))

					} else {
						sequenceNumber = U.get_next_sequenceNumber(parent_node, framework_record)
						console.log(sr('processed $1: $2', td, sequenceNumber))
					}

				// else process parentIdentifiers if there
				} else if (this.case_fields.find(x=>x=='parentIdentifiers')) {
					// TODO: implement this if we want to use it...
					parent_node = original_node
					sequenceNumber = parent_node_next_sequenceNumber
					++parent_node_next_sequenceNumber
				}

				// add to cfo
				new_nodes[i] = U.add_child_to_cfo(item_json, parent_node, this.$store, cfo, sequenceNumber)

				// create an 'isChildOf' association for the item, and add to data
				let child_association = U.create_association(parent_node.cfitem, item_json, sequenceNumber, CFDocument)
				data.CFAssociations.push(child_association.to_json())

				// TODO: deal with supplementalNotes/exemplar?
			}

			console.log('done!', data)

			this.$alert(sr('Processed <b>$1 new $2</b>. Newly-added items are shown in the framework tree in red, but have not yet been saved. Click the “SAVE IMPORTED ITEMS” button in the lower-left corner to confirm the additions, or click “CANCEL IMPORT” to discard the new items.', data.CFItems.length, U.ps('item', data.CFItems.length)))

			// show the import confirmer, then close the import/editor interface
			this.editor_component.viewer.show_import_confirm = true
			this.editor_component.viewer.import_data = data		// note that we *don't* want viewer.import_data to be reactive
			this.editor_component.cancel_edit()
		},
	}
}
</script>

<style lang="scss">
.k-csv-item-importer {
}

.k-csv-item-importer-section {
	border:1px solid #ccc;
	border-radius:8px;
	padding:8px;
	margin:8px;
}

</style>
