<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="1200" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Framework Access Report (all frameworks)</b>
			<v-spacer></v-spacer>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<FrameworkUsageChart lsdoc_identifier="all" :count_type="count_type" />

			<div v-if="framework_access_summary" style="clear:both">
				<div class="py-4 d-flex">
					<v-spacer/>
					<div style="flex:0 0 20%" class="mr-1"><v-select v-model="count_type" :items="count_types" label="Access Type" outlined dense hide-details></v-select></div>
					<div style="flex:0 0 50%" class="ml-1"><v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff"
					></v-text-field></div>
					<v-spacer/>
				</div>

				<v-data-table light dense
					:headers="headers"
					:items="framework_access_summary"
					:sort-by="['last_access_date']"
					:sort-desc="[true]"
					:must-sort="true"
					:custom-filter="table_search_filter"
					:search="search"
					:footer-props="footer_options"
					class="k-framework-access-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td class="text-left d-flex align-center"><v-btn fab icon x-small class="mr-2" @click="show_access_detail(item.framework_record)"><v-icon small>fas fa-level-down-alt</v-icon></v-btn>{{item.title}}</td>
						<td class="text-center"><nobr style="font-size:9px">{{item.framework_identifier}}</nobr></td>
						<td class="text-center"><nobr>{{item.last_access_date ? date_string(item.last_access_date) : '—'}}</nobr></td>
						<td class="text-center">{{item.access_count}}</td>
					</tr></template>
				</v-data-table>
			</div>
			<FrameworkAccessReportList v-if="show_access_detail_dialog"
				:framework_record="access_list_framework_record"
				@dialog_cancel="show_access_detail_dialog=false" />
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkAccessReportList from './FrameworkAccessReportList'
import FrameworkUsageChart from './FrameworkUsageChart'
export default {

	components: { FrameworkAccessReportList, FrameworkUsageChart },

	props: {
	},

	data() { return {
		dialog_open: true,
		headers: [
			{ text: 'Title', align: 'center', sortable: true, value:'title' },
			{ text: 'Framework Identifier', align: 'center', sortable: true, value:'framework_identifier' },
			{ text: 'Last Access', align: 'center', sortable: true, value:'last_access_date' },
			{ text: 'Access Count', align: 'center', sortable: true, value:'access_count' },
		],
		footer_options: {
			itemsPerPageOptions: [50,100,-1],
		},
		search: '',
		show_access_detail_dialog: false,
		access_list_framework_record: null,
		count_types: [
			{ value:"webapp", text:"Web App" },
			{ value:"api", text:"CASE API" },
		],
	}},

	computed: {
		...mapState(['framework_records']),
		...mapGetters([]),

		// get/store count_type (webapp or api) in localstorage
		count_type: {
			get() { return this.$store.state.lst.framework_access_report_count_type },
			set(val) { this.$store.commit('lst_set', ['framework_access_report_count_type', val]) }
		},
		// Get list of framework total access counts from framework table (counts and last access date in framework_records)
		framework_access_summary() {
			let summary_list = []

			for (let i = 0; i < this.framework_records.length; ++i) {
				let summary_row = {}

				summary_row.framework_record = this.framework_records[i]
				summary_row.framework_identifier = this.framework_records[i].lsdoc_identifier
				summary_row.title = this.framework_records[i].json.CFDocument.title
				summary_row.access_count = this.framework_records[i].ss_framework_data['access_count_' + this.count_type]
				summary_row.last_access_date = (summary_row.access_count == 0) ? '' : this.framework_records[i].ss_framework_data['last_access_date_' + this.count_type]

				summary_list.push(summary_row)
			}

			return summary_list
		},
	},

	created() {
	},

	mounted() {
	},

	methods: {
		show_access_detail(framework_identifier, title) {
			this.access_list_framework_record = framework_identifier
			this.show_access_detail_dialog = true
		},

		date_string(gmt_date) {
			return date.format(U.convert_to_local_date(gmt_date), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check searchable fields
			if (item.framework_identifier.toLowerCase().search(re) > -1) return true
			else if (item.title.toLowerCase().search(re) > -1) return true
			else if (this.date_string(item.last_access_date).toLowerCase().search(re) > -1) return true
			else if (item.access_count.toString().search(re) > -1) return true

			// if we get to here return false
			return false
		},
	}
}
</script>

<style lang="scss">
.k-framework-access-table {
	margin-top:8px;
	th {
		white-space:nowrap;
	}
	td {
		font-size:12px!important;
		line-height:16px;
		vertical-align:top;
		padding-top:2px!important;
		padding-bottom:2px!important;
		border-color:transparent!important;
	}
}

</style>
