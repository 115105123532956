<template>
<v-snackbar
  	  v-if="shown"
      v-model="shown"
      :color="color"
      :bottom="snackbarY === 'bottom'"
      :left="snackbarX === 'left'"
      :right="snackbarX === 'right'"
      :timeout="snackbarTimeout"
      :top="snackbarY === 'top'"
  >
	<div class="k-inform-content">
		<div v-html="text"></div>
		<v-btn text :ripple="false" @click="close()" small class="pa-0 ma-0 simple-dialog-snackbar-dismiss-btn"><span v-html="closeText"></span></v-btn>
	</div>
</v-snackbar>
</template>

<script>
    export default {
        name : "Snackbar",
        props : {
            snackbarX : String,
            snackbarY : String,
            color : String,
            snackbarTimeout : Number,
            closeText : String,
            text : String,
            callback_fn: Function,
        },
        data()
        {
            return {
                shown : true
            }
        },
        methods :
        {
            show()
            {
                this.shown = true;
                if( this.timeout )
                {
                    setTimeout( () => this.$emit( "close" ), this.timeout + 300 );
                }
            },
            close()
            {
                this.shown = false;
                // call the callback_fn, which will by default be an empty fn
                this.callback_fn()
                setTimeout( () => this.$emit( "close" ), 500 );
            }
        }
    }
</script>

<style scoped>
.k-inform-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin:0 -20px 0 0;
	width:100%;
}
</style>
