<template>
    <v-card class="page_container" :style="{ width: viewport.width + 'px', height: viewport.height + 'px' }">
        <canvas ref="page_render" style="{ position: 'absolute', left: '0px' }">
        </canvas>
        <div class="item" v-for="(item, index) in grouped_items" :key="index" :style="{ 
            left: item.transform[4] + 'px', 
            bottom: item.transform[5] - 4 + 'px', 
            width: item.width + 2 + 'px',
            height: item.height + 2 + 'px',
            backgroundColor: item.color }"
            @click="$emit('item_selected', item)">
        </div>
		<div ref="drag_preview" :style="{
			'border-style': 'dashed',
            'border-color': 'gray', 
            'background-color': 'rgba(0, 0, 0, .1)', 
			position: 'absolute', 
			left: drag_select.x1 + 'px', 
			bottom: drag_select.y1 + 'px', 
			width: drag_select.x2 - drag_select.x1 + 'px', 
			height: drag_select.y2 - drag_select.y1 + 'px'
		}" v-if="open && drag_select.dragging"></div>
    </v-card>
</template>

<script>

export default {
    name: 'PdfPreviewPage',
    data() { return {
        selected_item: undefined
    }},
    props: {
        page_number: Number,
        grouped_items: Array,
        viewport: Object,
        api_pdf_page: Object,
        open: Boolean,
        drag_select: Object,
    },
    emits: ["item_selected"],
    methods: {
        check_for_hover(event) {
            for (let item of this.grouped_items) {
                let x = event.offsetX
                let y = this.viewport.height - event.offsetY
                if (x > item.transform[4] && x < item.transform[4] + item.width && y > item.transform[5] - item.height && y < item.transform[5]) {
                    console.log(item.str)
                }
            }
        }
    },
    watch: {
    },
    mounted() {
        let canvas = this.$refs.page_render
        canvas.width = this.viewport.width
        canvas.height = this.viewport.height
        let ctx = canvas.getContext("2d")
        this.api_pdf_page.render({ canvasContext: ctx, viewport: this.viewport })
        //ctx.drawImage(canvas, 0, 0, this.viewport.width, this.viewport.height, 0, 0, this.viewport.width / 2, this.viewport.height / 2)
    },
    computed: {
    }
}
</script>

<style scoped>

.page_container {
    position: relative;
    margin-top: 10px;
}

.item {
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;
    line-height: 1;
    opacity: 0.375;
    transition: background-color .1s, border-color .1875s;
}

.item:hover {
    background-color: lightblue;
    border-color: black;
}

</style>
