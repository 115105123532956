<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Manage User Rights for Framework</b>
			<v-spacer></v-spacer>
			<v-btn icon @click="show_help" color="light-blue"><v-icon large>fas fa-info-circle</v-icon></v-btn>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-if="all_users" style="clear:both">
				<div class="py-4 d-flex">
					<v-spacer/>
					<v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
					></v-text-field>
					<v-spacer/>
					<v-btn class="ml-8" color="primary" @click="add_framework_user"><v-icon small class="mr-2">fas fa-plus</v-icon>Add Framework User</v-btn>
				</div>

				<v-data-table light dense
					:headers="headers"
					:items="rows"
					:sort-by="['framework_role_sort','created_at']"
					:sort-desc="[false,true]"
					:must-sort="true"
					:custom-filter="table_search_filter"
					:search="search"
					hide-default-footer
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-admin-users-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td>{{item.last_name}}, {{item.first_name}} ({{item.email}})</td>
						<td class="text-center"><nobr>{{item.framework_role_display}}<v-btn icon x-small class="ml-2" @click="edit_user(item.user_id)"><v-icon>fas fa-edit</v-icon></v-btn></nobr></td>
						<td class="text-center"><nobr>{{date_string(item.created_at)}}</nobr></td>
						<td class="text-center"><nobr>{{date_string(item.last_login)}}</nobr></td>
						<td class="text-center">{{item.login_count}}</td>
					</tr></template>
				</v-data-table>
			</div>
			<FrameworkAdminUserEdit v-if="user_being_edited"
				:user="user_being_edited" :framework_record="framework_record"
				@editor_cancel="user_being_edited=null"
				@user_edited="user_edited">
			</FrameworkAdminUserEdit>
			<AdminUserEdit v-if="new_user_being_edited" :user="new_user_being_edited" :warn_for_non_default_rights="true" @editor_cancel="new_user_being_edited=null" @user_edited="new_user_edited" @user_deleted="new_user_deleted"></AdminUserEdit>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkAdminUserEdit from './FrameworkAdminUserEdit'
import AdminUserEdit from './AdminUserEdit'

export default {
	components: { FrameworkAdminUserEdit, AdminUserEdit },
	props: {
		lsdoc_identifier: { type: String, required: true },
	},
	data() { return {
		dialog_open: true,
		all_users: null,
		headers: [
			{ text: 'User', align: 'left', sortable: true, value:'name_search' },
			{ text: 'Framework Rights', align: 'center', sortable: true, value:'framework_role_sort' },
			{ text: 'Date Created', align: 'center', sortable: true, value:'created_at' },
			{ text: 'Last Login', align: 'center', sortable: true, value:'last_login' },
			{ text: 'Login Ct.', align: 'center', sortable: true, value:'login_count' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
		new_user_being_edited: null,
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		framework_record() {
			return this.framework_records.find(x=>x.lsdoc_identifier == this.lsdoc_identifier)
		},
		framework_rights() {
			return this.framework_record.ss_framework_data.user_rights
		},
		// if user is not a system admin, restrict user list to those with permissions this framework
		framework_only_rights() {
			return (this.framework_rights[this.user_info.user_id] === 'admin' && this.user_info.system_role !== 'admin')
		},
		rows() {
			let arr = []
			for (let user of this.all_users) {
				// start with all the user fields
				let o = $.extend(true, {}, user)

				// and a searchable value for name/email
				o.name_search = sr('$1 $2 $3', o.last_name, o.first_name, o.email).toLowerCase()

				// get framework_role_display and framework_role_sort; for the latter, show users with system-level access first
				let user_fr_rights = this.framework_rights[user.user_id] || 'none'
				if (user.system_role == 'admin' || user_fr_rights == 'admin') {
					o.framework_role_sort = 2
					o.framework_role_display = 'Framework Admin'
					if (user.system_role == 'admin') {
						o.framework_role_display += '*'
						--o.framework_role_sort
					}

				} else if (user.system_role == 'editor' || user_fr_rights == 'editor') {
					o.framework_role_sort = 4
					o.framework_role_display = 'Framework Editor'
					if (user.system_role == 'editor') {
						o.framework_role_display += '*'
						--o.framework_role_sort
					}

				} else {
					// if we get to here and the framework is PRIVATE, show "Framework Reviewer (view/comment)" if the user has been granted rights, or "None" otherwise
					if (U.framework_is_private(this.framework_record)) {
						if (user.system_role == 'reviewer' || user_fr_rights == 'reviewer') {
							o.framework_role_sort = 6
							o.framework_role_display = 'Framework Reviewer (view/comment)'
							if (user.system_role == 'reviewer') {
								o.framework_role_display += '*'
								--o.framework_role_sort
							}

						} else {
							// don't show users without rights to the framework?? if we wanted to do this, return here
							o.framework_role_sort = 8
							o.framework_role_display = 'None (user cannot view framework)'
						}
					} else {
						o.framework_role_sort = 6
						// if we get to here the user does not have any special rights for the framework and it's public, so return this
						o.framework_role_display = 'Default (view/comment)'
					}
				}

				arr.push(o)
			}
			return arr
		}
	},
	created() {
		// DEBUG
		vapp.all_users_component = this
		this.load_admin_user_list()
	},
	mounted() {
	},
	methods: {
		show_help() { vapp.show_help('roles_framework') },

		load_admin_user_list() {
			let payload = {
				user_id: this.user_info.user_id,
				system_role: this.user_info.system_role,
				framework_only_rights: this.framework_rights[this.user_info.user_id]
			}

			U.loading_start()
			U.ajax('admin_get_all_users', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin_get_all_users ajax call'); vapp.ping(); return;
				}

				// remove unsignedin@cglt.com user
				let index = result.all_users.findIndex(x=>x.email == 'unsignedin@cglt.com')
				if (index > -1) result.all_users.splice(index, 1)

				this.all_users = result.all_users
			});
		},

		// if user has a framework role, it should be higher than system role
		date_string(gmt_date) {
			if (empty(gmt_date)) return '–'
			if (gmt_date == 'TODAY') return 'Just Now'
			return date.format(U.convert_to_local_date(gmt_date), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},

		// a non-system admin will not see a complete list of users, but they can add framework rights to an existing user
		add_framework_user() {
			this.$prompt({
				title: 'Add New User',
				text: 'Enter user email:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				initialValue: '',
				acceptText: 'Add User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.add_framework_user()})
					return
				}
				let new_framework_user = this.all_users.find(x=>x.email==email)

				if (empty(new_framework_user)) {
					if (this.framework_only_rights) {
						this.$alert('Sorry, but since you are not a system-level admin, you cannot add new users to the system. Please ask a system-level admin to add this user; then you will be able to grant the user rights to this framework.')
						return
					}

					this.$confirm({
					    title: '',
					    text: sr('<b>$1</b> is not currently in the system. Would you like to add this user to the system at this time?', email),
					    acceptText: 'Create New User',
					    cancelText: 'Cancel',
					}).then(y => {
						this.new_user_create(email)
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				if (['reviewer', 'editor', 'admin'].includes(new_framework_user.system_role)) {
					this.$alert(sr('<b>$1</b> already has framework user rights, which can be updated from the edit user button to the left of their name.', email))
					return
				}

				this.edit_user(new_framework_user.user_id)
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check email/name
			if (item.name_search.search(re) > -1) return true

			// check rights
			if (item.framework_role_display.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		edit_user(user_id) {
			this.user_being_edited = this.all_users.find(x=>x.user_id==user_id)
		},

		user_edited(payload) {
			payload.framework_identifier = this.lsdoc_identifier

			// TODO for MZ: rather than using admin_save_user, we want a new service admin_save_framework_user_rights
			// this sets/updates an object in the framework table's json for `user_rights`, where each key is a user_id and each key's value is the rights level ('reviewwer', 'editor', or 'admin')
			// note that rights are checked in App.vue->is_granted()
			U.loading_start()
			U.ajax('save_user_framework_rights', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in save_user_framework_rights ajax call'); vapp.ping(); return;
				}

				// close user editor
				this.user_being_edited = null

				// update this framework's user rights in store
				this.$store.commit('set', [this.framework_record, ['ss_framework_data', 'user_rights', payload.user_id_being_edited], payload.framework_role])

				// reload framework list
				this.load_admin_user_list()
			});
		},

		//////////////////////////
		// below fns are for if we want to add a brand-new user at this point

		new_user_edited(payload) {
			payload.user_id = this.user_info.user_id
			console.log(payload)

			U.loading_start()
			U.ajax('admin_save_user', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				// result.saved_user_id will contain the new user_id if this is a new user
				this.new_user_being_edited.user_id = result.saved_user_id
				this.new_user_being_edited.first_name = payload.first_name
				this.new_user_being_edited.last_name = payload.last_name
				this.new_user_being_edited.system_role = payload.system_role

				// close user editor
				this.new_user_being_edited = null

				// then open the framework editor for the new user
				this.edit_user(result.saved_user_id)
			});
		},

		new_user_deleted() {
			// from this interface, if user_deleted is called, it was always a new user being added
			if (this.new_user_being_edited.user_id == 0) {
				this.all_users.splice(this.all_users.length-1, 1)
				this.new_user_being_edited = null
				return
			}
		},

		new_user_create(email) {
			this.all_users.push({
				user_id:0,
				system_role: '',
				email: email,
				first_name: '',
				last_name: '',
				created_at: 'TODAY',
				last_login: '',
				login_count: 0,
			})
			this.new_user_being_edited = this.all_users[this.all_users.length-1]
		},
	}
}
</script>

<style lang="scss">
</style>
