<template><div class="k-comment-editor">
	<div class="k-comment-editor-top">
		<div class="k-comment-editor-commenter" :style="signed_in?'flex:1 1 50%':'flex: 0 1 auto;align-self:center'">
			<div class="float-left"><v-icon small color="#555" class="mr-2">fas {{edited_comment.resolved?'fa-check-double':'fa-comment'}}</v-icon></div>
			<span style="color:#555"><span v-html="header_string"></span> by 
				<span v-if="signed_in"><b>{{ edited_comment.first_name }} {{ edited_comment.last_name }}</b></span>
			</span>
		</div>
		<div v-if="!signed_in" style="flex:1 1 auto" class="mr-1"><v-text-field dense outlined background-color="#fff" hide-details label="Your name (optional)" v-model="public_review_name"></v-text-field></div>
		<v-spacer v-if="!public_review"/>
		<v-select v-if="!public_review&&!edited_comment.parent_comment_id" v-model="edited_comment.comment_group_id" background-color="#fff" class="ml-2" style="flex:0 0 25%;" :style="edited_comment.comment_group_id?'max-width:25%':'max-width:50%'" :items="comment_groups_select" label="Comment Group" dense outlined hide-details :menu-props="{ zIndex: 1001 }"></v-select>
		<v-select v-if="!public_review&&edited_comment.comment_group_id" v-model="edited_comment.attn_user_id" background-color="#fff" class="ml-2" style="flex:0 0 25%; max-width:25%" :items="comment_group_users_select" label="Attn:" dense outlined hide-details :menu-props="{ zIndex: 1001 }"></v-select>
		<v-btn x-small icon color="light-blue" @click="show_help" style="align-self:center" class="ml-1"><v-icon>fas fa-info-circle</v-icon></v-btn>
	</div>

	<div>
		<froala-wrapper :config="editor_config()" v-model="edited_comment.body" />
	</div>

	<div v-if="suggested_edits_formatted" class="mt-2">
		<div class="mb-1">
			<b style="color:#555; font-size:12px"><v-icon x-small class="mr-1" color="#555">fas fa-edit</v-icon>Suggested edits:</b>
			<span v-if="edited_comment.suggested_edits.applied" class="ml-3"><v-icon small class="mr-1" color="light-green darken-4">fas fa-check-circle</v-icon><b class="light-green--text text--darken-4">APPLIED</b></span>
		</div>
		<div class="k-comment-suggested-edits-wrapper" v-html="suggested_edits_formatted"></div>
	</div>

	<div class="d-flex mt-4">
		<!-- <v-checkbox v-if="edited_comment.parent_comment_id==0&&is_group_admin" class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom" v-model="pinned" hide-details><div slot="label">Pinned <v-icon small class="ml-1">fas fa-thumbtack</v-icon></div></v-checkbox> -->
		<v-btn v-if="show_suggest_edits_btn&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" class="k-tight-btn" small text color="primary" @click="suggest_item_edits"><v-icon small class="mr-1">fas fa-edit</v-icon>Suggest Edits</v-btn>
		<v-spacer></v-spacer>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" text v-if="edited_comment.comment_id==0" color="secondary" class="k-tight-btn" @click="archive_comment"><v-icon small class="mr-1">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" text v-if="edited_comment.comment_id!=0" color="red" dark class="k-tight-btn" @click="archive_comment"><v-icon small class="mr-1">fas fa-trash-alt</v-icon> Archive</v-btn>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" text v-if="show_resolved_btn" color="purple" dark class="k-tight-btn" @click="toggle_resolved"><v-icon small class="mr-1">fas {{edited_comment.resolved?'fa-circle-minus':'fa-check-double'}}</v-icon> Mark {{edited_comment.resolved?'Unresolved':'Resolved'}}</v-btn>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" v-if="(edited_comment.body!=''||edited_comment.suggested_edits)||values_changed" :text="!values_changed" color="primary" class="k-tight-btn ml-2 elevation-0" @click="save_edits"><v-icon small class="mr-1">fas fa-check</v-icon> {{save_btn_string}}</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		original_comment: { type: Object, required: true },
		item: { required: false, default() { return null } },
		viewer: { required: false, default() { return null } },
		render_latex: { required: false, default() { return true } },
	},
	data() { return {
		edited_comment: {},
		original_comment_string: '',
	}},
	computed: {
		...mapState(['comment_groups', 'comments']),
		...mapGetters(['signed_in', 'comments_hash']),
		public_review() { return this.original_comment.comment_group_id == -1 },
		public_review_name: {
			get() { return this.$store.state.lst.public_review_name },
			set(val) { 
				this.$store.commit('lst_set', ['public_review_name', val]) 
				// when public_review_name is changed, set edited_comment.first_name to the value
				this.edited_comment.first_name = val
			}
		},
		item_is_document() {
			if (empty(this.item)) return false
			return !empty(this.item.cfitem.title)
		},
		header_string() {
			let s = ''
			if (this.original_comment.body == '') s = 'new '
			if (this.public_review) {
				s += 'public review comment '
			} else if (this.original_comment.parent_comment_id != 0) {
				s += 'reply '
			} else {
				s += 'comment '
			}

			return U.capitalize_word(s)
		},
		values_changed() {
			return (JSON.stringify(this.edited_comment) != this.original_comment_string) && (this.edited_comment.body != '' || this.edited_comment.suggested_edits != null)
		},
		save_btn_string() {
			if (!this.values_changed) return 'Done'
			return 'Save'

			// if (this.edited_comment.parent_comment_id == 0) {
			// 	return 'Save Comment'
			// } else {
			// 	return 'Save Reply'
			// }
		},
		user_can_edit_framework() {
			if (this.viewer) return this.viewer.user_can_edit
			if (!this.signed_in) return false
			return vapp.is_granted('edit_framework', this.edited_comment.framework_identifier)
		},
		show_resolved_btn() {
			// not yet saved, so can't be resolved
			if (this.edited_comment.comment_id == 0) return false

			// can't mark replies as resolved
			if (this.edited_comment.parent_comment_id != 0) return false

			// only an editor of the framework can mark public_review comments as resolved
			if (this.public_review) {
				return this.user_can_edit_framework
			}
			// otherwise if you can edit it you can mark it as resolved
			return true
		},
		is_group_admin() {
			// you're always the admin of your personal group
			if (this.edited_comment.group == 0) return true
			// TODO: check your user_id against the group's admin_user_ids
		},
		comment_groups_select() {
			let arr = [{value: 0, text: 'Personal Comments'}]

			for (let cg of this.comment_groups) {
				if (cg.framework_identifier == this.original_comment.framework_identifier) {
					arr.push({value: cg.comment_group_id, text: cg.name})
				}
			}
			return arr
		},
		comment_group_users_select() {
			let arr = []
			// if a group has been designated
			if (this.edited_comment.comment_group_id != 0) {
				// get the group, then add all users to the arr
				let cg = this.comment_groups.find(x=>x.comment_group_id == this.edited_comment.comment_group_id)
				for (let i = 0; i < cg.user_ids.length; ++i) {
					arr.push({value: cg.user_ids[i]*1, text: cg.user_names[i]})
				}
				// sort names alphabetically
				arr.sort((a,b)=> {
					if (a.text < b.text) return -1
					if (b.text < a.text) return 1
					return 0
				})
			}
			// add a "none" value to the top
			arr.unshift({value: 0, text: '–'})
			return arr
		},
		show_suggest_edits_btn() {
			if (!this.viewer) return false
			if (!this.item || this.item_is_document) return false
			if (this.edited_comment.suggested_edits && this.edited_comment.suggested_edits.applied) return false
			return true
		},
		suggested_edits_formatted() { return this.edited_comment.suggested_edits_formatted(this.render_latex, vapp.case_tree_component.framework_record) }
	},
	watch: {
		'edited_comment.comment_group_id'() {
			// when group is set to 0 (Personal), reset attn_user_id
			if (this.edited_comment.comment_group_id == 0) {
				this.edited_comment.attn_user_id = 0
			}

			// set this to the default to be used next time -- but not if we aren't actually in any groups (if that is the case, comment_group_id is necessarily 0, and if you later get added to a group we'd like to default you to that group)
			if (this.comment_groups_select.length > 1) {
				this.$store.commit('lst_set_hash', ['default_comment_group_id', this.viewer.lsdoc_identifier, this.edited_comment.comment_group_id])
			}
		}
	},
	created() {
		// copy values from original_comment to edited_comment
		this.edited_comment = new Comment(this.original_comment)
		this.original_comment_string = JSON.stringify(this.edited_comment)

		// this.edited_comment = $.extend(true, {}, this.original_comment)

		// if we were passed a viewer component, save a reference to this component in the viewer component.
		// this lets the viewer component pass back edit suggestions
		if (this.viewer) {
			this.viewer.comment_editor = this
		}
	},
	mounted() {
	},
	methods: {
		show_help() { 
			if (this.public_review) vapp.show_help('public_review_comments')
			else vapp.show_help('add_comments') 
		},

		editor_config() {
			let config = U.get_froala_config({
				placeholderText: 'Comment text'
			})

			// hide most editor buttons, since users probably won't want to use most of them and they don't look good on a small screen
			config.toolbarButtons.moreRich.buttonsVisible = 4
			return config
		},

		suggest_item_edits() {
			// make sure viewer.comment_editor is set to *this* comment editor
			this.viewer.comment_editor = this

			// to handle suggested item edits, we call viewer.edit_item in suggestions_only mode. This causes the item editor to open in suggestions_only mode.
			// if the user clicks to save suggested edits, that will trigger item_edits_suggested (below) to be called with the updated CFItem json
			// note that this fn, and item_edits_suggested, will only be called (the btn will only be shown) if we have an item to suggest edits for and are in the viewer context
			this.viewer.edit_item(this.item, 'suggestions_only', this.edited_comment.suggested_edits)
		},

		item_edits_suggested(edited_json) {
			// get standardized version of item.cfitem to compare to edited_json
			let original_json = new CFItem(this.item.cfitem)

			// extract all suggested edits
			let edits = {}

			for (let key in edited_json) {
				// if the edited json[key] is empty
				if (empty(edited_json[key])) {
					// then if the original json[key] isn't empty, we need to clear it out
					if (!empty(original_json[key])) edits[key] = '*CLEAR*'

				// else if json[key] is an object,
				} else if (typeof(edited_json[key]) == 'object') {
					// then compare stringified jsons
					if (JSON.stringify(edited_json[key]) != JSON.stringify(original_json[key])) {
						edits[key] = edited_json[key]
					}

				// else just compare values
				} else if (edited_json[key] != original_json[key]) {
					edits[key] = edited_json[key]
				}
			}

			// if edits is empty (no keys), clear suggested_edits in comment
			if ($.isEmptyObject(edits)) {
				this.$set(this.edited_comment, 'suggested_edits', null)
				// this.edited_comment.suggested_edits = null
			} else {
				// else save edits in comment
				this.$set(this.edited_comment, 'suggested_edits', edits)
				// this.edited_comment.suggested_edits = edits
			}
		},

		save_edits() {
			if (this.values_changed) {
				// body or suggested_edits must be specified; but we shouldn't get here if it's not
				if (empty(this.edited_comment.body) && empty(this.edited_comment.suggested_edits)) return

				// if first_name is empty, set to 'Anonymous'
				if (empty(this.edited_comment.first_name)) this.edited_comment.first_name = 'Anonymous'

				this.$store.dispatch('save_comment', this.edited_comment).then((saved_comment)=>{
					// for public_review comments, save the comment in lst
					if (this.public_review) {
						let public_review_comments = object_copy(this.$store.state.lst.public_review_comments)
						let i = public_review_comments.findIndex(x=>x.comment_id == saved_comment.comment_id)
						if (i > -1) public_review_comments[i] = saved_comment
						else public_review_comments.push(saved_comment)
						this.$store.commit('lst_set', ['public_review_comments', public_review_comments])
					}
					
					this.$emit('editor_cancel')
				})
			} else {
				this.$emit('editor_cancel')
			}
		},

		archive_comment() {
			// if the comment wasn't created, no confirm
			if (this.edited_comment.comment_id == 0) {
				this.$emit('editor_cancel')
				return
			}

			let msg = 'Are you sure you want to archive this comment?'
			// warn user if child comments will also be archived
			let comments = this.comments_hash[this.edited_comment.framework_identifier]
			if (comments) comments = comments[this.edited_comment.item_identifier]
			if (comments && comments.find(x=>x.parent_comment_id == this.edited_comment.comment_id)) {
				msg += ' This will also archive all replies to the comment.'
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: msg,
			    acceptText: 'Archive Comment',
				acceptColor: 'red',
				// dialogMaxWidth: 600
			}).then(y => {
				this.$store.dispatch('archive_comment', this.edited_comment).then(()=>{
					// for public_review comments, remove the comment from lst
					if (this.public_review) {
						let public_review_comments = object_copy(this.$store.state.lst.public_review_comments)
						let i = public_review_comments.findIndex(x=>x.comment_id == this.edited_comment.comment_id)
						if (i > -1) public_review_comments.splice(i, 1)
						this.$store.commit('lst_set', ['public_review_comments', public_review_comments])
					}

					this.$emit('editor_cancel')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		toggle_resolved(val) {
			// note that we set the resolved status on original_comment as well as edited_comment
			if (typeof(val) != 'boolean') val = !this.original_comment.resolved
			this.edited_comment.resolved = val
			this.$store.commit('set', [this.original_comment, 'resolved', val])
			this.$store.dispatch('save_comment_resolved', this.original_comment)
			// reset original_comment_string to make values_changed work properly
			this.original_comment_string = JSON.stringify(new Comment(this.original_comment))
		},
	}
}
</script>

<style lang="scss">
.k-comment-editor {
	padding:6px;
	// background-color should be specified in parent div
	background-color:transparent;

	p {
		margin-bottom:6px;
	}

	textarea {
		font-size:14px;
		line-height:20px;
	}

	.fa-thumbtack {
		transform:rotate(30deg);
	}

	.v-label, .v-select__selection, input[type=text] {
		font-size:14px;
	}
}

.k-comment-editor-top {
	margin-bottom:16px;
	display:flex;
	.k-comment-editor-commenter {
		margin-right:4px;
	}
}
</style>
