<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="mt-3 pt-3" style="border-top:1px solid #ccc;">
	<div class="d-flex align-center mb-3">
		<b>Human Code Regex Help</b>
		<v-spacer/>
		<v-btn v-show="regexes_changed" x-small fab color="orange darken-3" dark class="mr-2" @click="check_items"><v-icon small>fas fa-recycle</v-icon></v-btn>
		<v-btn x-small color="secondary" @click="$emit('dialog_cancel')">Hide Helper<v-icon small class="ml-2">fas fa-caret-up</v-icon></v-btn>
	</div>
	<div><table class="k-crh-table">
		<tr>
			<th style="width:50%">Left Framework<span v-if="error_a" class="red--text text--darken-3 ml-2">REGEX ERROR</span></th>
			<th style="width:50%">Right Framework<span v-if="error_b" class="red--text text--darken-3 ml-2">REGEX ERROR</span></th>
		</tr>
		<tr>
			<td><b>{{items_with_codes_a.length}}</b> alignable items include codes
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.items=!data_showing.items">{{data_showing.items?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.items" class="k-crh-codes">
					<div v-for="(item) in items_with_codes_a" :key="item.identifier"><a @click="show_item(framework_record_a, item)">{{ item.original_hcs }}</a></div>
				</div>
			</td>
			<td><b>{{items_with_codes_b.length}}</b> alignable items include codes
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.items=!data_showing.items">{{data_showing.items?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.items" class="k-crh-codes">
					<div v-for="(item) in items_with_codes_b" :key="item.identifier"><a @click="show_item(framework_record_b, item)">{{ item.original_hcs }}</a></div>
				</div>
			</td>
		</tr>
		<tr>
			<td><b>{{altered_items_a.length}}</b> items altered by regexes
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.altered=!data_showing.altered">{{data_showing.altered?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.altered" class="k-crh-codes">
					<div v-for="(item) in altered_items_a" :key="item.identifier"><a @click="show_item(framework_record_a, item)">{{ item.original_hcs }}</a> <v-icon style="font-size:9px; padding:0 3px; margin-top:-2px;" color="#999">fas fa-long-arrow-right</v-icon> {{ item.altered_hcs }}</div>
				</div>
			</td>
			<td><b>{{altered_items_b.length}}</b> items altered by regexes
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.altered=!data_showing.altered">{{data_showing.altered?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.altered" class="k-crh-codes">
					<div v-for="(item) in altered_items_b" :key="item.identifier"><a @click="show_item(framework_record_b, item)">{{ item.original_hcs }}</a> <v-icon style="font-size:9px; padding:0 3px; margin-top:-2px;" color="#999">fas fa-long-arrow-right</v-icon> {{ item.altered_hcs }}</div>
				</div>
			</td>
		</tr>
		<tr>
			<td><b>{{not_altered_items_a.length}}</b> items not altered
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.not_altered=!data_showing.not_altered">{{data_showing.not_altered?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.not_altered" class="k-crh-codes">
					<div v-for="(item) in not_altered_items_a" :key="item.identifier"><a @click="show_item(framework_record_a, item)">{{ item.original_hcs }}</a></div>
				</div>
			</td>
			<td><b>{{not_altered_items_b.length}}</b> items not altered
				<v-btn class="ml-2" x-small text color="primary" @click="data_showing.not_altered=!data_showing.not_altered">{{data_showing.not_altered?'Hide':'Show'}}</v-btn>
				<div v-if="data_showing.not_altered" class="k-crh-codes">
					<div v-for="(item) in not_altered_items_b" :key="item.identifier"><a @click="show_item(framework_record_b, item)">{{ item.original_hcs }}</a></div>
				</div>
			</td>
		</tr>
		<tr><td colspan="2" style="background-color:#ccc; height:4px"></td></tr>
		<tr><td colspan="2">
			<div>
				<div v-show="item_matches.length>-1" class="d-flex align-center">
					<div><span class="green--text text--darken-3">single match: <b>{{n_single_match}}</b></span> / <span class="red--text text--darken-3">no matches: <b>{{n_no_match}}</b></span> / <span class="purple--text text--darken-3">multiple matches: <b>{{n_multiple_match}}</b></span></div>
					<v-btn class="ml-2" x-small text color="primary" @click="data_showing.matches=!data_showing.matches">{{data_showing.matches?'Hide':'Show'}}</v-btn>
				</div>
				<div v-if="data_showing.matches" class="k-crh-codes" style="max-height:400px">
					<div v-for="(o) in item_matches" :key="o.item_a.identifier" :style="`margin-left:${o.item_a.level*6}px`">
						<div v-if="o.matches.length==1" class="green--text text--darken-3">
							<b>{{ o.item_a.altered_hcs }}</b>:
							<a @click="show_item(framework_record_a, o.item_a)">{{ o.item_a.original_hcs }}</a>
							<v-icon style="font-size:9px; padding:0 3px; margin-top:-2px;" color="#999">fas fa-arrow-right-arrow-left</v-icon>
							<a @click="show_item(framework_record_b, o.matches[0])">{{ o.matches[0].original_hcs }}</a>
						</div>
						<div v-if="o.matches.length==0" class="red--text text--darken-3">
							<a @click="show_item(framework_record_a, o.item_a)"><b>{{ o.item_a.altered_hcs }}</b><span v-if="o.item_a.original_hcs!=o.item_a.altered_hcs"> / {{ o.item_a.original_hcs }}</span><span v-else>*</span></a>
						</div>
						<div v-if="o.matches.length>1" class="purple--text text--darken-3">
							<b>{{ o.item_a.altered_hcs }}</b>:
							<a @click="show_item(framework_record_a, o.item_a)">{{ o.item_a.original_hcs }}</a>
							<v-icon style="font-size:9px; padding:0 3px; margin-top:-2px;" color="#999">fas fa-arrow-right-arrow-left</v-icon>
							<a v-for="(m, i) in o.matches" @click="show_item(framework_record_b, m)">{{i>0?',  ':''}}{{ m.original_hcs }}</a>
						</div>
					</div>
				</div>
			</div>
		</td></tr>
	</table></div>
</div></template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		data_showing: {
			items: false,
			altered: false,
			not_altered: false,
			matches: false,
		},
		error_a: false,
		error_b: false,
		items_with_codes_a: [],
		items_with_codes_b: [],
		altered_items_a: [],
		altered_items_b: [],
		not_altered_items_a: [],
		not_altered_items_b: [],

		n_single_match: 0,
		n_no_match: 0,
		n_multiple_match: 0,

		last_processed_regexes: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		item_type_params() { return this.$parent.item_type_params },
		framework_record_a() { return this.$parent.framework_record_a },
		framework_record_b() { return this.$parent.framework_record_b },
		hcs_regex_params_a() { return this.$parent.hcs_regex_params_a },
		hcs_regex_params_b() { return this.$parent.hcs_regex_params_b },
		include_branches_left() { return this.$parent.include_branches_left },
		include_branches_right() { return this.$parent.include_branches_right },
		exclude_branches_left() { return this.$parent.exclude_branches_left },
		exclude_branches_right() { return this.$parent.exclude_branches_right },

		current_regexes() {
			return JSON.stringify([this.hcs_regex_params_a, this.hcs_regex_params_b])
		},

		regexes_changed() { return this.last_processed_regexes != this.current_regexes },

		item_matches() {
			this.n_single_match = 0
			this.n_no_match = 0
			this.n_multiple_match = 0

			if (this.items_with_codes_a.length == 0 || this.items_with_codes_b.length == 0) {
				return []
			}

			let arr = []
			for (let ai of this.items_with_codes_a) {
				let matches = []
				for (let bi of this.items_with_codes_b) if (ai.altered_hcs == bi.altered_hcs) matches.push(bi)
				arr.push({item_a: ai, matches: matches})

				if (matches.length == 0) ++this.n_no_match
				else if (matches.length == 1) ++this.n_single_match
				else ++this.n_multiple_match
			}

			return arr
		}
	},
	watch: {

	},
	created() {
	},
	mounted() {
		vapp.crosswalk_regex_helper = this
		this.check_items()
	},
	methods: {
		check_items() {
			let rv = this.process_items('a')
			this.items_with_codes_a = rv[0]
			this.altered_items_a = rv[1]
			this.not_altered_items_a = rv[2]
			this.error_a = rv[3]

			rv = this.process_items('b')
			this.items_with_codes_b = rv[0]
			this.altered_items_b = rv[1]
			this.not_altered_items_b = rv[2]
			this.error_b = rv[3]

			this.last_processed_regexes = this.current_regexes
		},

		process_items(side) {
			let arr = [], altered = [], not_altered = [], error = false

			let include_branches, exclude_branches, framework_record = this[`framework_record_${side}`]
			if (side == 'a') {
				include_branches = this.include_branches_left
				exclude_branches = this.exclude_branches_left
			} else {
				include_branches = this.include_branches_right
				exclude_branches = this.exclude_branches_right
			}

			try {
				// set up HCS regexes
				let hcs_regex_params = this[`hcs_regex_params_${side}`]
				let hcs_regexes = []
				for (let re of hcs_regex_params) {
					if (re.search == '') break
					hcs_regexes.push(new RegExp(re.search, 'gi'))
				}

				let get_items_with_codes = (node, level) => {
					// if we have exclude branches for this side, skip the item *and its children*
					if (exclude_branches.includes(node.cfitem.identifier)) {
						return
					}

					// if we're not aligning this item type for this side, skip this item, but process its children
					let is_alignable_type
					let item_type = U.item_type_string(node.cfitem) || '[no item type]'
					if (side == 'a') {
						let itp = this.item_type_params[item_type]
						is_alignable_type = (itp.alignable && itp.align_to.length > 0)
					} else {
						is_alignable_type = false
						for (let ait in this.item_type_params) {
							if (this.item_type_params[ait].align_to.includes(item_type)) {
								is_alignable_type = true
								break
							}
						}
					}

					if (is_alignable_type && !empty(node.cfitem.humanCodingScheme)) {
						let hcs = node.cfitem.humanCodingScheme
						let o = {identifier: node.cfitem.identifier, level:level, original_hcs: hcs}

						// apply regexps and save
						for (let i = 0; i < hcs_regexes.length; ++i) {
							hcs = hcs.replace(hcs_regexes[i], hcs_regex_params[i].replace)
						}
						o.altered_hcs = hcs

						arr.push(o)
						if (o.original_hcs == o.altered_hcs) not_altered.push(o)
						else altered.push(o)
					}
					for (let child of node.children) {
						get_items_with_codes(child, level+1)
					}
				}

				// only include included branches if any are marked as include only
				let items_successfully_limited = false
				for (let identifier of include_branches) {
					if (!framework_record.cfo.cfitems[identifier]) continue
					let node = framework_record.cfo.cfitems[identifier].tree_nodes[0]
					get_items_with_codes(node, 0)
					items_successfully_limited = true
				}
				// if none of the limiters worked for the left, process each top-level item in the tree
				if (!items_successfully_limited) {
					for (let child_node of framework_record.cfo.cftree.children) {
						get_items_with_codes(child_node, 0)
					}
				}
				// get_items_with_codes(framework_record.cfo.cftree, 0)

			} catch(e) {
				console.warn('error in process_items '+ side, e)
				arr = []
				altered = []
				not_altered = []
				error = true
			}

			return [arr, altered, not_altered, error]
		},

		show_item(framework_record, item) {
			let data = {
				framework_identifier: framework_record.lsdoc_identifier,
				selected_items: [item.identifier],
			}

			vapp.$refs.satchel.execute('show', {window_side: 'left'}).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			}).catch(()=>console.log('catch of vapp.$refs.satchel.execute(\'show\')'))	// this will execute when the standards are hidden
		},
	}
}
</script>

<style lang="scss">
.k-crh-table {
	width:100%;
	border-collapse: collapse;
	th, td {
		padding:2px 6px;
		border:1px solid #ccc;
		vertical-align: top;
		font-size:15px;
	}
}

.k-crh-codes {
	border:1px solid #999;
	padding:4px;
	margin:4px;
	max-height:200px;
	overflow:auto;
	font-size:12px;
	line-height:15px;

	a {
		color:inherit!important;
	}
}
</style>