// sample searches:
//    energy ask questions: string contains "energy" AND "ask" AND "questions"
//    energy "ask questions": string contains "energy" AND "ask questions"
//    energy OR "ask questions": string contains "energy" OR "ask questions"
//    energy OR ask questions: string contains "energy" OR ("ask" AND "questions")
//    energ*: string contains "energy" or "energize" or "energetic", etc.
U.create_search_re = function(search_terms) {
	search_term_res = []
	stop_words = []

	if (empty(search_terms)) search_terms = ''
	else search_terms = $.trim(search_terms+'')

	if (empty(search_terms)) {
		return [search_term_res, stop_words]
	}

	// remove all punctuation except a few things, to make sure regexps work
	search_terms = search_terms.replace(/[^0-9a-zA-Z+\-,;:'" .*]/g, '')

	// replace . with \.
	let st = search_terms.replace(/\./g, '\\.')

	// wildcards: replace * with .*? (eliminating any surrounding spaces)
	st = st.replace(/\s*\*\s*/g, '\.*?')

	// pull out stop words
	st = st.replace(/-\s*"([^"]+)"/g, ($0, $1) => {
		stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
		return ''
	})
	st = st.replace(/(^|\s)-\s*(\w+)(\s|$)/g, ($0, $1) => {
		stop_words.push(new RegExp('\\b' + $1 + '\\b ', 'gi'))
		return ''
	})
	st = $.trim(st)

	if (!empty(st)) {
		// first split by ' OR 's into or_re_strings: “energy OR ask questions” => ['energy', 'ask questions']
		let or_arr = st.split(/\s+OR\s+/)
		for (let or_re_string of or_arr) {
			// trim each or_re_string
			or_re_string = $.trim(or_re_string)
			if (empty(or_re_string)) continue

			// "hard-code" spaces within double-quotes: "foo bar" > foo\sbar
			or_re_string = or_re_string.replace(/"([^"]+)"/g, function($0, $1) {
				return "\\b" + $1.replace(/ +/g, "\\s") + "\\b";
			})
			or_re_string = $.trim(or_re_string)
			if (empty(or_re_string)) continue

			// then split the or_re_string into individual and_re_strings on spaces: “ask questions” => ['ask', 'questions']
			let or_res = []
			let and_re_strings = or_re_string.split(/\s+/)
			for (let and_re_string of and_re_strings) {
				and_re_string = $.trim(and_re_string)
				if (!empty(and_re_string)) {
					// create a regexp for this and_re_string
					or_res.push(new RegExp('(' + and_re_string + ')', 'gi'))
				}
			}

			// if we actually created an regexps, push onto search_term_re
			if (or_res.length > 0) {
				search_term_res.push(or_res)
			}
		}
	}

	return [search_term_res, stop_words]
}

U.strings_match_search_term_res = function(search_term_res, string_arr) {
	// at least one of the top-level search_term_res arrays must match at least one of the strings of string_arr
	// (note that usually, the user won't use the 'OR' keyword, so there will be only one sub-array of search_term_res)

	// go through each set of "and_res" in the search_term_res array
	for (let and_res of search_term_res) {
		// now go through each string
		for (let string of string_arr) {
			string = $.trim(string)
			if (empty(string)) continue

			// now go through each "re" in the "and_res" array; each re must be in the string for it to match
			let match = true
			for (let re of and_res) {
				// if this re isn't in the string, it's not a match!
				if (string.search(re) == -1) {
					match = false
					break
				}
			}

			// if match is true, this string includes all the re's from this set of and_res, so we can return true overall
			if (match == true) return true

			// otherwise we keep looking for matches in other strings and/or with other sets of and_res
		}
	}

	// if we haven't returned true somewhere above, return false -- no match
	return false
}

U.string_includes_stop_word = function(stop_words, s) {
	if (empty(s)) return false
	for (let sw of stop_words) {
		if (s.search(sw) > -1) return true
	}
}

/*
// sample usages...

let arr = U.create_search_re(this.search_terms)
this.search_term_res = arr[0]
this.stop_words = arr[1]

if (!U.string_includes_stop_word(this.stop_words, s) && U.strings_match_search_term_res(this.search_term_res, [s])) {
	// include s in search results
}

execute_search(node) {
	if (empty(node)) return false

	// by default return false (item doesn't meet criteria)
	let rv = false

	// if the node has children, search the children
	if (!empty(node.children) && node.children.length > 0) {
		for (let i = 0; i < node.children.length; ++i) {
			let child = node.children[i]
			if (this.execute_search(child)) {
				// if a child matches and this isn't the document node, open this parent item
				if (!empty(node.parent_node)) {
					this.$store.commit('set', [this.open_nodes, node.tree_key+'', true])
				}
				rv = true
			}
		}
	}

	// assuming the node has a cfitem, determine if it should be highlighted as a search result
	if (!empty(node.cfitem)) {
		// if the fullStatement includes a stop word, no
		if (!U.string_includes_stop_word(this.stop_words, node.cfitem.fullStatement)) {
			// check fullStatement and humanCodingScheme, and identifier if it looks like it might actually be an identifier (> 16 chars)
			let arr = [node.cfitem.fullStatement, node.cfitem.humanCodingScheme]
			if (this.search_terms.length > 16) arr.push(node.cfitem.identifier)

			if (U.strings_match_search_term_res(this.search_term_res, arr)) {
				this.search_results[node.tree_key] = true
				++this.search_result_count
				rv = true
			}
		}
	}

	return rv
}

*/
