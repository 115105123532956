<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="98vw" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<v-icon color="#000" class="mr-2">fas fa-forward</v-icon>
				<b>Progression</b>
				<v-spacer></v-spacer>
				<v-btn color="secondary" class="mr-3" @click="print"><v-icon small class="mr-2">fas fa-print</v-icon> Print</v-btn>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-xmark</v-icon> Done</v-btn>
			</v-card-title>
			<v-card-text class="pt-3" id="k-progression-table-printable_card" style="font-size:16px; background-color:#eee;">
				<div class="ml-2" v-if="parent_statement" v-html="parent_statement"></div>
				<div class="mb-2 pa-2" style="background-color:#fff; border-radius:8px">
					<div class="pa-1 grey lighten-4"><b>N</b>ot <b>Y</b>et <b>I</b>ntroduced (<b>NYI</b>): The competency has not yet been introduced; it is not a grade-level expectation.</div>
					<div class="pa-1 yellow lighten-4"><b>I</b>ntroduce: The competency is introduced at this grade level; students practice applying the competency, but end-of-grade mastery is not required.</div>
					<div class="pa-1 yellow lighten-4"><b>C</b>ontinue: The competency is introduced at an earlier grade level; students continue to practice and apply it, but end-of-grade mastery is not required. </div>
					<div class="pa-1 deep-orange lighten-4"><b>M</b>aster: The competency should be mastered by the end of this grade.</div>
					<div class="pa-1 blue lighten-4"><b>R</b>einforce: The competency is mastered in an earlier grade level; it should be reinforced in this grade level.</div>
				</div>
				<v-data-table light dense
					:headers="headers"
					disable-pagination
					hide-default-header
					hide-default-footer
					:items="table_rows"
					class="k-progression-table"
				>
					<template v-slot:header><thead>
						<tr class="k-progression-table-header">
							<th rowspan="2" colspan="2" style="border-bottom:1px solid #ddd"><div class="d-flex align-end">
								<v-text-field style="font-weight:normal" v-model="search" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search" outlined single-line hide-details dense background-color="#fff" class="k-progression-table-search-bar"></v-text-field>
							</div></th>
							<th :colspan="headers.length-2" class="text-center">Grades</th>
						</tr>
						<tr class="k-progression-table-header">
							<!-- th rowspan 2 above -->
							<th v-for="(header, index) in headers" v-if="index>=2" :key="index" class="text-center px-2" :style="(grade_hovered==header.text)?'font-weight:bold':'font-weight:normal'">{{header.text}}</th>
						</tr>
					</thead></template>
					<template v-slot:item="{ item }"><v-hover v-slot:default="{hover}"><tr>
						<td class="text-left pr-0" style="vertical-align:top; font-size:14px; white-space:nowrap;"><b style="color:#555" v-if="item.humanCodingScheme" class="pr-2">{{item.humanCodingScheme}}</b></td>
						<td class="text-left pl-0" style="vertical-align:top; font-size:14px;" v-html="statement_html(item.fullStatement)"></td>
						<v-tooltip v-for="(header, index) in headers" v-if="index>=2" :key="index" bottom><template v-slot:activator="{on}"><td v-on="on" class="text-center pa-0" style="font-size:14px; border-bottom:1px solid #ddd!important; border-left:1px solid #ddd!important" :class="grade_td_color(item.educationLevel, header.text, hover)" v-html="grade_td_html(item.educationLevel, header.text, hover)" @mouseover="grade_hovered=header.text" @mouseleave="grade_hovered=null"></td></template>Grade {{header.text}}: {{grade_td_word(item.educationLevel, header.text, hover)}}</v-tooltip>
					</tr></v-hover></template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
// components: { ProgressionTable },
	props: {
		framework_record: { type: Object, required: true },
		progression_table_data: { required: true },	// this must either be a cfo node, or an array of identifiers
	},
	data() { return {
		dialog_open: true,
		headers: [],
		search: '',
		grade_hovered: null,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		parent_statement() {
			if ($.isArray(this.progression_table_data)) return ''
			let s = ''
			if (this.progression_table_data.cfitem.humanCodingScheme) s += `<b>${this.progression_table_data.cfitem.humanCodingScheme}</b> `
			return U.marked_latex(s + this.progression_table_data.cfitem.fullStatement)
		},
		table_rows() {
			let rows = []
			let grades = []

			let search = $.trim(this.search).toLowerCase()
			let re = new RegExp(search, 'i')

			// get identifiers for rows, either directly from prop, or as children of the supplied parent_node
			let row_identifiers
			if ($.isArray(this.progression_table_data)) {
				row_identifiers = this.progression_table_data
			} else {
				row_identifiers = []
				for (let child of this.progression_table_data.children) {
					row_identifiers.push(child.cfitem.identifier)
				}
			}

			// for each row_identifier
			for (let identifier of row_identifiers) {
				let item = this.framework_record.cfo.cfitems[identifier]
				if (!item) {
					console.log('ProgressionTable: couldn’t locate item for ' + identifier)
					continue
				}

				if (search) {
					if (item.fullStatement.toLowerCase().search(re) == -1) {
						if (item.humanCodingScheme && item.humanCodingScheme.toLowerCase().search(re) == -1) {
							continue
						}
					}
				}

				rows.push(item)

				// determine grades to be included
				for (let grade of this.$store.state.grades) {
					if (item.educationLevel && item.educationLevel.includes(grade.value)) {
						grades[grade.index] = true
					}
				}
			}

			let headers = [
				{ text: 'Code' },
				{ text: 'Statement' },
			]
			let found_last = false
			for (let i = 0; i < this.$store.state.grades.length; ++i) {
				let gv = this.$store.state.grades[i].value
				// skip college
				if (gv == 'College') continue
				if (grades[i] || found_last) {
					headers.push({ text: gv })
				}
				found_last = grades[i]
			}

			this.headers = headers

			return rows
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		statement_html(statement) {
			return U.marked_latex(statement)
		},
		grade_td_html(educationLevel, gv, hover) {
			if (!educationLevel || educationLevel.length == 0) return ''

			let s = ''

			// first grade is when the item is INTRODUCED (I)
			if (educationLevel[0] == gv) s = 'I'

			// last grade is when the item should be MASTERED (M)
			if (educationLevel[educationLevel.length-1] == gv) {
				if (s == 'I') s = 'I, M'
				else s = 'M'
			}

			if (!s) {
				// for grades in between, the item should be CONTINUED (C)
				if (educationLevel.includes(gv)) {
					s = 'C'
				} else {
					let gvi = this.$store.state.grades.findIndex(x=>x.value == gv)

					// for previous grades, the item is NOT YET INTRODUCED (NYI)
					if (gvi < this.$store.state.grades.findIndex(x=>x.value == educationLevel[0])) s = 'NYI'

					// for later grades, the item is should be REINFORCED (R)
					else if (gvi > this.$store.state.grades.findIndex(x=>x.value == educationLevel[educationLevel.length-1])) s = 'R'
				}
			}

			if (hover && this.grade_hovered == gv) return `<b>${s}</b>`
			else return s
		},
		grade_td_word(education_level, gv) {
			let s = this.grade_td_html(education_level, gv, false)
			if (s == 'I') return 'Introduce'
			if (s == 'I, M') return 'Introduce, Master'
			if (s == 'C') return 'Continue'
			if (s == 'M') return 'Master'
			if (s == 'NYI') return 'Not Yet Introduced'
			if (s == 'R') return 'Reinforce'
			return ''
		},
		grade_td_color(educationLevel, gv, hover) {
			let s = this.grade_td_html(educationLevel, gv)
			if (s == 'NYI') s = 'grey lighten'
			else if (s == 'R') s = 'blue lighten'
			else if (s.includes('M')) s = 'deep-orange lighten'
			else if (s.includes('I') || s.includes('C')) s = 'yellow lighten'

			if (hover) return s + '-4'
			else return s + '-5'
		},

		print() {
			this.printing = true
			$('.v-dialog__content').css('position', 'static')
			$('.v-dialog').css('position', 'static')
			$('.v-application--wrap').hide()
			$('.v-card__title').hide()
			$('.v-card__text').css('overflow-y', 'visible')
			$('.k-progression-table-search-bar').hide()
			let window_title = document.title
			document.title = 'Progression'

			// $('.v-card').css('box-shadow', '')
			// $('.v-dialog').css('box-shadow', '')
			setTimeout(x=>{
				window.print()
				setTimeout(x=>{
					this.printing = false
					$('.v-dialog__content').css('position', 'fixed')
					$('.v-dialog').css('position', 'fixed')
					$('.v-application--wrap').show()
					$('.v-card__title').show()
					$('.v-card__text').css('overflow-y', 'auto')
					$('.k-progression-table-search-bar').show()
					document.title = window_title
				}, 10)
			}, 10)
		},
	}
}
</script>

<style lang="scss">
.k-progression-table {
	border:1px solid #ddd!important;
	p {
		margin:0;
	}

	td {
		height:auto!important;
		padding-top:4px!important;
		padding-bottom:4px!important;
	}
}
</style>