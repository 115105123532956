import '@/js/utilities.js'
// do this so that we can access "U." functions from @ events in vue components
Vue.prototype.U = window.U

import seedrandom from 'seedrandom'
Math.seedrandom = seedrandom

import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import DialogPromise from '@/components/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

import VueDraggableResizable from '@/components/vue-draggable-resizable-spedcol'
import '@/components/vue-draggable-resizable-spedcol/components/vue-draggable-resizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

// import froala component, custom froala fns, and FroalaWrapper component; to use: <froala-wrapper />
import froala from '@/components/FroalaWrapper/froala-plugin'
import FroalaWrapper from '@/components/FroalaWrapper/FroalaWrapper'
Vue.component('froala-wrapper', FroalaWrapper)

// https://github.com/arnog/mathlive/blob/master/src/vue-mathlive.js
import * as MathLive from 'mathlive/dist/mathlive.mjs';
window.MathLive = MathLive
Vue.use(MathLive);
// import MathfieldComponent from 'mathlive/dist/vue-mathlive.mjs';
// Vue.use(MathfieldComponent, MathLive);

import VueWorker from 'vue-worker'
Vue.use(VueWorker)

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import marked from 'marked'
window.marked = marked

// katex -- currently we use MathLive instead
// import 'katex/dist/katex.css'
// import katex from 'katex'
// window.katex = katex

import highcharts from '@/plugins/highcharts'

import '@/js/mathjax.js'
import '@/js/mathlive_core_css.js'
import '@/js/google_translate.js'
import '@/js/user_info.js'
import '@/js/case_data_structures.js'
import '@/js/case_utilities.js'
import '@/js/comments.js'
import '@/js/resources.js'
import '@/js/framework_icons.js'
import '@/scss/scss-base.scss'
import '@/scss/case-framework-colors.scss'

import '@/scss/responsive.scss'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
