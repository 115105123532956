// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		start_resource_import() {
			//////////////////////////////////
			// Prompt for import file

			this.$prompt({
				title: 'Import Resources',
				text: 'Choose a CSV- or TSV-formatted file specifying the resources you wish to align. For each resource, you must specify at least a resource ID. You may also specify a title, description, extra text, URL, and education level. When you click the “PROCESS FILE” button, the file will be evaluated, and you’ll be asked to confirm that you wish to import the resources found in the file.',
				promptType: 'file',
				acceptText: 'Process File',
				dialogMaxWidth: 550,
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()

				//////////////////////////////////
				// Read file and parse resource rows

				reader.onload = e => {
					let text = e.target.result
					// parse text; if first line includes a tab, assume it's a TSV; otherwise assume CSV
					let table
					if (text.split('\n')[0].indexOf('\t') > -1) {
						table = TSV.parse(text)
					} else {
						table = CSV.parse(text)
					}
					console.log(table)

					if (table.length == 1) {
						this.$alert('The uploaded file included only one line. A resource import must include at least one line of headers and at least one line specifying a resource.')
						return
					}

					// parse headers in line 0
					let fields = []
					for (let original_val of table[0]) {
						let val = original_val.toLowerCase()
						let field
						if (val.indexOf('id') > -1) field = 'external_resource_id'
						else if (val.indexOf('title') > -1) field = 'title'
						else if (val.indexOf('description') > -1) field = 'description'
						else if (val.indexOf('text') > -1 || val.indexOf('keywords') > -1) field = 'text'
						else if (val.indexOf('url') > -1) field = 'url'
						else if (val.indexOf('level') > -1) field = 'educationLevel'
						else if (val.indexOf('alignment') > -1) field = 'alignments'
						if (empty(field)) {
							this.$alert(sr('Unknown field specified in header row: “$1”', original_val))
							return
						}
						fields.push(field)
					}

					// now parse resource rows: for each non-header row...
					let resources = []
					for (let i = 1; i < table.length; ++i) {
						let r = {}
						// for each column in the row...
						for (let j = 0; j < table[i].length; ++j) {
							// if value isn't empty, set that column's field to the value
							if (!empty(table[i][j])) {
								r[fields[j]] = table[i][j]
							}
						}

						// at the least, an ID must be specified
						if (empty(r.external_resource_id)) {
							this.$alert(sr('The resource in row $1 does not have an ID specified. A resource ID must be specified for each resource.'))
							return
						}

						// make sure we don't get duplicate resource IDs
						if (resources.find(x=>x.external_resource_id == r.external_resource_id)) {
							this.$alert(sr('Duplicate resource IDs were found ($1)', r.external_resource_id))
							return
						}

						// educationLevel must be an array; allow for comma-separated values
						if (r.educationLevel) r.educationLevel = r.educationLevel.split(/\s*,\s*/)

						// check format for/parse alignments if we get them:
						// framework_identifier_1:item_identifier_1, framework_identifier_1:item_identifier_2, framework_identifier_2:item_identifier_3
						if (r.alignments) {
							let aarr = r.alignments.split(/\s*,\s*/)
							r.alignments = []
							for (let j = 0; j < aarr.length; ++j) {
								let arr = aarr[j].split(/\s*:\s*/)
								if (arr.length != 2) {
									this.$alert(sr('Improperly formatted alignments for resource ID $1: $2'))
									return
								}
								let framework_identifier = arr[0]
								let item_identifier = arr[1]

								// TODO: if item_identifier isn't a guid, assume it's a humanCodingScheme, and try to find it in the given framework
								r.alignments.push({fi: framework_identifier, ii: item_identifier})
							}
						}

						resources.push(r)
					}

					//////////////////////////////////
					// send resources to the server to check if any have been already imported

					U.loading_start()
					let payload = {
						service_url: 'save_resources',
						// we stringify the resources in case it's a lot of data
						resources: JSON.stringify(resources),
						user_id: this.user_info.user_id,
						framework_identifier: this.framework_identifier,
						check_only: 'yes',
					}
					this.$store.dispatch('service', payload).then((result)=>{
						U.loading_stop()

						//////////////////////////////////
						// prepare a brief report for the resources that would be uploaded
						let new_resource_ids = []
						let updated_resource_ids = []
						for (let external_resource_id in result.resource_status) {
							if (result.resource_status[external_resource_id] == 'new') {
								new_resource_ids.push(external_resource_id)
							} else {
								updated_resource_ids.push(external_resource_id)
							}
						}

						let html = ''
						if (new_resource_ids.length > 0) {
							html += sr('<div class="mb-3">$1 $2 will be <b>created</b>: $3</div>', new_resource_ids.length, U.ps('resource', new_resource_ids.length), new_resource_ids.join(', '))
						}
						if (updated_resource_ids.length > 0) {
							html += sr('<div class="mb-3">$1 $2 will be <b>updated</b>: $3</div>', updated_resource_ids.length, U.ps('resource', updated_resource_ids.length), updated_resource_ids.join(', '))
						}

						html += 'Would you like to proceed?'

						this.$confirm({
						    title: 'Import Resources',
						    text: html,
						    acceptText: 'Import Resources',
							dialogMaxWidth: 600
						}).then(y => {

							//////////////////////////////////
							// send resources back to the server to be imported

							U.loading_start()
							let payload = {
								service_url: 'save_resources',
								// we stringify the resources in case it's a lot of data
								resources: JSON.stringify(resources),
								user_id: this.user_info.user_id,
								framework_identifier: this.framework_identifier,
								check_only: 'no',
							}
							this.$store.dispatch('service', payload).then((result)=>{
								U.loading_stop()

								//////////////////////////////////
								// add/replace resources to the list of resources being considered for alignment here
								for (let resource of result.resources) {
									let i = this.resources.findIndex(x=>x.resource_id == resource.resource_id)
									if (i == -1) this.resources.push(resource)
									else this.resources.splice(i, 1, resource)
								}

							}).catch((result)=>{
								U.loading_stop()
								this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
							}).finally(()=>{})

						}).catch(n=>{console.log(n)}).finally(f=>{})

					}).catch((result)=>{
						U.loading_stop()
						this.$alert('<b class="red--text">An error occurred:</b> ' + result.error)
					}).finally(()=>{})
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
