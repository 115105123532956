<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-dialog v-model="dialog_open" max-width="600px" persistent scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Framework Category Admin Tool</b>
			<v-spacer></v-spacer>
			<v-btn small color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<draggable v-bind="drag_options" v-model="categories" @end="reorder_categories">
				<div v-for="(category, index) in categories" :key="category.uuid" class="d-flex" style="border:1px solid #000; border-radius:8px; padding:8px; margin:8px 0; background-color:#eee">
					<v-icon small class="k-category-move-handle mr-2" style="cursor:move">fas fa-arrows-alt</v-icon>
					<div v-html="category.title"></div>
					<v-spacer/>
					<div v-if="site_config.map_mode_enabled=='true'" class="ml-2 mr-1" style="margin-top:1px; border:1px solid #666; border-radius:5px; padding:4px; font-size:12px; line-height:12px; background-color:#fff; height:22px; cursor:pointer;" @click="edit_map_label(category)"><v-icon x-small class="mr-1">fas fa-map</v-icon>{{category.map_label}}</div>
					<v-icon class="mx-2" small @click.stop="edit_category(category)">fas fa-edit</v-icon>
					<v-icon small @click.stop="delete_category(category)">fas fa-circle-xmark</v-icon>
				</div>
			</draggable>
			<div class="mt-3 text-center" style="line-height:1.4em"><i>Note: to create a new framework category, edit any framework’s document element and click the “NEW CATEGORY” button.</i></div>
		</v-card-text>
	</v-card>
</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
	components: { draggable },
	props: {
	},
	data() { return {
		dialog_open: true,
		categories: [],
		drag_options: {
			// group: 'case_items',
			animation: 200,
			handle: ".k-category-move-handle",
		},
	}},
	computed: {
		...mapState(['framework_categories', 'framework_records', 'site_config']),
	},
	created() {
		this.create_category_array()
	},
	mounted() {
	},
	methods: {
		create_category_array() {
			let arr = []
			for (let category of this.framework_categories) {
				let t1 = category.replace(/^(\d+)\s*/, '')
				let sequence_number = (t1 == category) ? '' : RegExp.$1
				let title = t1.replace(/\s*\[\[(.+)\]\]$/, '')
				let map_label = (title == t1) ? '–' : RegExp.$1
				arr.push({
					index: arr.length,
					string: category,
					title: title,
					sequence_number: sequence_number,
					map_label: map_label,
					uuid: U.new_uuid(),
				})
			}
			arr.sort((a,b)=>U.natural_sort(a.string, b.string))
			this.categories = arr
		},

		get_category_string(title, sequence_number, map_label) {
			let s = title
			if (!empty(sequence_number)) s = sequence_number + ' ' + s
			if (!empty(map_label) && map_label != '–') s = s + ' [[' + map_label + ']]'
			return s
		},

		edit_category(category) {
			this.$prompt({
				text: 'Enter the updated title for this category',
				initialValue: category.title,
				acceptText: 'Update',
			}).then(new_title => {
				let framework_updates = []

				new_title = $.trim(new_title)
				// if empty or not changed, return
				if (empty(new_title) || new_title == category.title) return

				// add sequence_number and map_label if there
				let new_string = this.get_category_string(new_title, category.sequence_number, category.map_label)

				// if the new title matches another category title, merge the two categories
				let matched = false
				for (let oc of this.categories) {
					if (new_title == oc.title) {
						matched = true
						new_string = oc.string
						this.$store.commit('set', [this.framework_categories, 'SPLICE', category.index])
						break
					}
				}

				// update framework_categories if we didn't do so above
				if (!matched) {
					this.$store.commit('set', [this.framework_categories, 'SPLICE', category.index, new_string])
				}

				// update framework_records
				for (let fr of this.framework_records) {
					if (fr.ss_framework_data.category == category.string) {
						this.$store.commit('set', [fr.ss_framework_data, 'category', new_string])
						framework_updates.push({lsdoc_identifier: fr.lsdoc_identifier, category: new_string})
					}
				}

				// force framework_records to update; this forces the framework tile categories to update
				this.$store.commit('set', [this.framework_records, 'PUSH', {}])
				this.$store.commit('set', [this.framework_records, 'SPLICE', this.framework_records.length-1])

				// save updated framework_categories
				this.$store.dispatch('save_framework_categories', framework_updates)
				this.create_category_array()

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_category(category) {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'If you delete this category, all frameworks currently tagged as a member of the category will become “uncategorized”. Are you sure you want to proceed?',
			    acceptText: 'Delete Category',
				acceptColor: 'red',
				dialogMaxWidth: 500,
			}).then(y => {
				let framework_updates = []

				// remove framework_category and update framework_records
				this.$store.commit('set', [this.framework_categories, 'SPLICE', category.index])

				for (let fr of this.framework_records) {
					if (fr.ss_framework_data.category == category.string) {
						this.$store.commit('set', [fr.ss_framework_data, 'category', ''])
						framework_updates.push({lsdoc_identifier: fr.lsdoc_identifier, category: ''})
					}
				}

				// force framework_records to update; this forces the framework tile categories to update
				this.$store.commit('set', [this.framework_records, 'PUSH', {}])
				this.$store.commit('set', [this.framework_records, 'SPLICE', this.framework_records.length-1])

				// save updated framework_categories
				this.$store.dispatch('save_framework_categories', framework_updates)
				this.create_category_array()

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_map_label(category) {
			this.$prompt({
				text: sr('Enter the$1 map label for this category', (category.map_label == '–' ? '' : ' updated')),
				initialValue: category.map_label == '–' ? '' : category.map_label,
				acceptText: 'Update',
			}).then(new_label => {
				let framework_updates = []

				new_label = $.trim(new_label)
				if (!new_label) {
					// empty and it was empty before
					if (category.map_label == '–') return
				} else {
					// not empty and not changed from before
					if (category.map_label == new_label) return
				}

				// calculate the new value for the category string, also adding sequence_number if there
				let new_string = this.get_category_string(category.title, category.sequence_number, new_label)
				// update framework_categories
				this.$store.commit('set', [this.framework_categories, 'SPLICE', category.index, new_string])

				// update framework_records
				for (let fr of this.framework_records) {
					if (fr.ss_framework_data.category == category.string) {
						this.$store.commit('set', [fr.ss_framework_data, 'category', new_string])
						framework_updates.push({lsdoc_identifier: fr.lsdoc_identifier, category: new_string})
					}
				}

				// force framework_records to update; this forces the framework tile categories to update
				this.$store.commit('set', [this.framework_records, 'PUSH', {}])
				this.$store.commit('set', [this.framework_records, 'SPLICE', this.framework_records.length-1])

				// save updated framework_categories
				this.$store.dispatch('save_framework_categories', framework_updates)
				this.create_category_array()

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		reorder_categories() {
			let framework_updates = []
			let fcats = []
			// this.categories will now be in the new order the user put them in; go through each one...
			for (let i = 0; i < this.categories.length; ++i) {
				let category = this.categories[i]

				// calculate the possibly-new value for the category string, also adding map_label if there
				let new_string = this.get_category_string(category.title, i, category.map_label)
				fcats.push(new_string)

				// if the category's string value based on its new index has changed, update all frameworks tagged to the category
				if (new_string != category.string) {
					for (let fr of this.framework_records) {
						if (fr.ss_framework_data.category == category.string) {
							this.$store.commit('set', [fr.ss_framework_data, 'category', new_string])
							framework_updates.push({lsdoc_identifier: fr.lsdoc_identifier, category: new_string})
						}
					}
				}
			}

			// re-set framework_categories
			this.$store.commit('set', ['framework_categories', fcats])

			// force framework_records to update; this forces the framework tile categories to update
			this.$store.commit('set', [this.framework_records, 'PUSH', {}])
			this.$store.commit('set', [this.framework_records, 'SPLICE', this.framework_records.length-1])

			// save updated framework_categories
			this.$store.dispatch('save_framework_categories', framework_updates)
			this.create_category_array()
		},
	}
}
</script>

<style lang="scss">
</style>
