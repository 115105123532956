<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="400" content-class="k-switcher-dialog" persistent scrollable><v-card class="pa-0"><v-card-text class="ma-0 pa-2 text-center" style="font-size:16px; line-height:20px;">
		<FrameworkSwitcher btn_size="large" color="primary" @framework_selected="$emit('dialog_cancel')" />
	</v-card-text></v-card></v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkSwitcher from './FrameworkSwitcher'

export default {
	components: { FrameworkSwitcher },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-switcher-dialog {
	position:absolute;
	left:0;
	top:0;
}
</style>
