<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="95%" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>CASE Subject Coverage Across States</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<v-data-table dense class="k-framework-subject-table" hide-default-footer item-key="identifier"
					:headers="table_headers"
					:items="table_rows"
					:items-per-page="-1"
					xmust-sort="true"
					:custom-sort="custom_sort"
					Xoptions="{'sortBy':['category_title'],'sortDesc':[false]}"
				>
				<template v-slot:item="{ item }">
					<tr v-if="item.category_title=='COUNTS'">
						<td class="text-right">COUNTS <v-icon small color="black" style="margin-top:-3px">fas fa-arrow-right-long</v-icon></td>
						<td class="text-center">{{item.total_count}}</td>
						<td v-for="(subject) in subjects" :key="subject.label" class="text-center k-framework-subject-table-count-column" :class="subject.color_class"><nobr><b>{{Math.round(item[subject.label] / (table_rows.length-1) * 100)}}%</b> ({{item[subject.label]}})</nobr></td>
					</tr>
					<tr v-else>
						<td><b v-html="item.category_title"></b></td>
						<td class="text-center">{{item.total_count}}</td>
						<td v-for="(subject) in subjects" :key="subject.label" class="text-center k-framework-subject-table-count-column" :class="subject.color_class"><v-icon color="#666" v-if="item[subject.label]">fas fa-circle-check</v-icon></td>
					</tr>
				</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		category_records: { required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		subjects() {
			return U.framework_subject_key
		},
		table_headers() {
			let arr = [
				{ text: 'State', align: 'left', sortable: true, value:'category_title' },
				{ text: 'Total Frameworks', align: 'center', sortable: true, value:'total_count' },
			]
			for (let o of this.subjects) {
				arr.push({ text: o.label, align: 'center', sortable: true, value:o.label, class: o.color_class + ' pt-2' })
			}
			return arr
		},
		table_rows() {
			function get_subject_records(category) {
				let arr = []
				for (let fr of category.framework_records) {
					let subject = U.framework_subject_guess(fr.json.CFDocument)
					let sr = arr.find(x=>x.subject==subject)
					if (!sr) {
						 arr.push({
							subject: subject,
							doc_showing: true,
							framework_records: [fr],
						}) 
					} else {
						sr.framework_records.push(fr)
						sr.framework_records.sort((a,b)=>{
							if (a.json.CFDocument.title < b.json.CFDocument.title) return -1
							if (b.json.CFDocument.title < a.json.CFDocument.title) return -1
							return 0
						})
					}
				}
				arr.sort((a,b)=>a.subject.sort_index - b.subject.sort_index)
				return arr
			}

			let arr = []
			
			// row for counts
			let crow = {category_title: 'COUNTS', total_count: 0}
			for (let o of this.subjects) {
				crow[o.label] = 0
			}
			arr.push(crow)

			for (let cr of this.category_records) {
				let o = {total_count:0}

				// for now skip non-state categories, as well as the "no-category category"
				if (cr.category.search(/^\d+ (.*)\s*\[\[(\w\w)\]\].*/) == -1) continue
				o.category_title = RegExp.$1
				let state_abbrev = RegExp.$2
				if (!['AL','AK','AZ','AR','CA','CO','CT','DC','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'].includes(state_abbrev)) continue

				let subject_records = get_subject_records(cr)
				for (let sr of subject_records) {
					o.total_count += sr.framework_records.length
					o[sr.subject.label] = sr.framework_records.length
					if (o[sr.subject.label] > 0) ++crow[sr.subject.label]
				}
				arr.push(o)
				crow.total_count += o.total_count
			}
			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		custom_sort(items, index, isDesc) {
			let sby = index[0]
			console.log('custom_sort', sby)
			console.log(items)
			items.sort((a, b) => {
				// always put the counts row on top
				if (a.category_title == 'COUNTS') return -1
				if (b.category_title == 'COUNTS') return 1

				if (!sby || sby == 'category_title') {
					if (a.category_title < b.category_title) return -1
					if (a.category_title > b.category_title) return 1
					return 0
				}

				if (sby == 'total_count') return b[sby] - a[sby]

				if (!empty(a[sby]) && empty(b[sby])) return -1
				if (!empty(b[sby]) && empty(a[sby])) return 1
				// if (empty(a[sby]) && empty(b[sby])) return 0
				// if (a[sby] - b[sby] != 0) return a[sby] - b[sby]

				if (a.category_title < b.category_title) return -1
				if (a.category_title > b.category_title) return 1
				return 0
			})
			return items
		},
	}
}
</script>

<style lang="scss">
.k-framework-subject-table {
	th {
		vertical-align: bottom;
		font-size:12px!important;
		color:#000!important;
		min-width:90px;
		padding-bottom:4px!important;

		.v-data-table-header__icon {
			display:none!important;
		}
	}
	td {
		font-size:14px!important;
		line-height:18px;
	}
	a {
		color:#000!important;
	}
	a:hover {
		text-decoration:underline;
	}
}

.k-framework-subject-table-count-column {
	.fas { font-size:18px!important }
}
</style>