<template>
	<v-dialog v-model="dialog_open" max-width="600" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><v-icon color="primary" class="mr-2">fas fa-bolt</v-icon><b>Apply Sandbox Changes</b></v-card-title>
			<v-card-text class="mt-3 k-reduce-file-size-checkboxes" style="font-size:16px; line-height:21px">
				<div>This will apply all changes in the sandbox to the original framework. In other words, after this action the original framework will be identical to this sandbox. Archives for the original framework will remain available for comparison and restoration, if necessary.</div>
				<div class="mt-2">Note that any comments (and comment groups) from the sandbox will be <b>moved</b> to the original framework (i.e. the comments will no longer be viewable from the sandbox, but they <i>will</i> be viewable in the original framework.</div>
				<div v-if="extra_msg" class="mt-4 red--text text--darken-3" v-html="extra_msg"></div>
				<div class="mt-4 d-flex align-end">
					<v-checkbox class="mr-4 mt-0" :label="'Delete sandbox after applying changes'" v-model="delete_sandbox" hide-details></v-checkbox>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn small color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn small color="primary" @click="apply_sandbox"><v-icon small class="mr-2">fas fa-file-import</v-icon>Apply Sandbox Changes</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: { 
		framework_record: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		delete_sandbox: false,
		extra_msg: '',
	}},
	computed: {
		...mapState(['user_info', 'framework_records']),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// compare source framework lastChangedDateTime to sandbox sandboxSyncDateTime to see if any edits to framework will be overwritten. If there are, let user know prior to application.
		let sandbox_last_sync_date = this.framework_record.ss_framework_data.sandboxSyncDateTime
		let sandbox_lcdt = this.framework_record.json.CFDocument.lastChangeDateTime
		let source_framework = this.framework_records.find(x=>x.lsdoc_identifier==this.framework_record.ss_framework_data.sandboxOfIdentifier)
		if (!source_framework) {	// shouldn't happen
			this.$alert('The original framework appears to have been deleted.')
			setTimeout(()=>this.$emit('dialog_cancel'), 0)
			return
		}
		let source_lcdt = source_framework.json.CFDocument.lastChangeDateTime

		console.log('sandbox last synched BEFORE the most recent changes to the original: ' + (sandbox_last_sync_date < source_lcdt))
		console.log('sandbox last synched AFTER the sandbox was last changed: ' + (sandbox_last_sync_date > sandbox_lcdt))
		console.log('sandbox last synched EQUAL TO the most recent changes to the sandbox: ' + (sandbox_last_sync_date == sandbox_lcdt))

		// sandbox last synched BEFORE the most recent changes to the original
		if (sandbox_last_sync_date < source_lcdt) {
			if (sandbox_last_sync_date == sandbox_lcdt) {
				this.extra_msg = '<b>The sandbox is unchanged since creation or last application of changes to the original framework, whereas the original framework has more recent changes.</b> Applying sandbox changes will revert the original framework to the sandbox.'
			} else {
				// else both have changed
				this.extra_msg = '<b>The original framework has been edited since the time this sandbox was created or last synched to the original.</b> Those changes in the original framework would be wiped out by this action. However, those changes would be restorable from automatically-generated archives of the original framework.'
			}

		// 'normal' application: sandbox has changes, no changes in original framework since last synch
		} else if (sandbox_last_sync_date < sandbox_lcdt) {
			// no extra_msg to show

		// neither sandbox or original framework have changes
		} else {
			this.$alert('Neither the sandbox nor the original framework have updates, so there are no changes to apply.')
			setTimeout(()=>this.$emit('dialog_cancel'), 0)
		}
	},
	methods: {
		apply_sandbox() {
			let payload = {
				'user_id': this.user_info.user_id,
				'sandbox_identifier': this.framework_record.lsdoc_identifier,
			}

			U.loading_start()
			U.ajax('apply_sandbox', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log(sr('Error in apply_sandbox ajax call: $1', result.status));
					return
				}

				// set this.framework_record.ss_framework_data.sandboxSyncDateTime to what it will have been set to on the server
				this.$store.commit('set', [this.framework_record.ss_framework_data, 'sandboxSyncDateTime', this.framework_record.json.CFDocument.lastChangeDateTime])

				// force original json to reload when it is next viewed; also set its new lastChangeDateTime
				let source_framework = this.framework_records.find(x=>x.lsdoc_identifier==this.framework_record.ss_framework_data.sandboxOfIdentifier)
				this.$store.commit('set', [source_framework, 'framework_json_loaded', false])
				this.$store.commit('set', [source_framework.json.CFDocument, 'lastChangeDateTime', this.framework_record.json.CFDocument.lastChangeDateTime])

				if (this.delete_sandbox == true) {
					console.log('Deleting sandbox after applying changes to original framework.')
					this.delete_sandbox_framework(result.sandbox_identifier)
				} else {
					this.$alert('Sandbox changes applied to original framework.')
				}
				this.$emit('dialog_cancel')
			})
		},

		delete_sandbox_framework(lsdoc_identifier) {
			this.$store.dispatch('delete_lsdoc', lsdoc_identifier).then(()=>{
				this.$alert('Sandbox changes applied to original framework, and sandbox deleted.')
			})
			// hide tree here, since this framework will be deleted.
			this.$emit('hide_tree')
			this.$emit('dialog_cancel')
		},
	},
}
</script>
<style lang="scss">

</style>
