<template><div class="k-svg-lines">
	<SVGLine v-for="(line, index) in lines" :key="line.uuid" :update_counter="update_counter" @remove_line="remove_line"
		:uuid="line.uuid"
		:line_start="line.line_start"
		:line_end="line.line_end"
		:stroke_width="line.stroke_width"
		:stroke_color="line.stroke_color"
		:css_class="line.css_class"
		:z_index="line.z_index"
		:offset_start="line.offset_start"
		:offset_end="line.offset_end"
		:position_scheme="line.position_scheme"
	/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SVGLine from './SVGLine'

export default {
	components: { SVGLine },
	props: {
	},
	data() { return {
		update_counter: -1,
		lines: [],
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// setTimeout(x=>this.do_update(), 1)
	},
	methods: {
		do_update() {
			// this mechanism allows us to constantly update the endpoints of line(s), iff at least one line is actually present on the screen
			this.update_counter += 1
			// if (this.update_counter % 10 == 0) console.log('SVGLines update')

			if (this.lines.length > 0) setTimeout(x=>this.do_update(), 20)
		},
		add_line(line_props) {
			// line_props must at least have line_start and line_end specified.

			// fill in other default properties here if not specified
			if (!line_props.stroke_width) line_props.stroke_width = 1
			if (!line_props.stroke_color) line_props.stroke_color = '#000'
			if (!line_props.css_class) line_props.css_class = ''
			if (!line_props.z_index) line_props.z_index = 0
			if (!line_props.offset_start) line_props.offset_start = 'cc'
			if (!line_props.offset_end) line_props.offset_end = 'cc'
			if (!line_props.position_scheme) line_props.position_scheme = 'offset'
			// if click_fn isn't specified, default to bubbling click events to whatever element is under the line
			// specify 'none' to disable bubbling
			// no longer used (11/2022)
			// if (!line_props.click_fn) line_props.click_fn = 'bubble'

			// add a uuid
			line_props.uuid = U.new_uuid()

			// wait a tick before pushing the line, in case something in it still needs to be rendered
			this.$nextTick(x=>{
				this.lines.push(line_props)
				this.do_update()
			})

			return line_props.uuid
		},
		remove_line(uuid) {
			let i = this.lines.findIndex(x=>x.uuid == uuid)
			if (i > -1) {
				this.lines.splice(i, 1)
			}
		},
		remove_all_lines() {
			this.lines = []
		},
	}
}
</script>

<style lang="scss">
.k-svg-lines {
	position:absolute;
	left:0;
	top:0;
	pointer-events: none;
}
</style>
