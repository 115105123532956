// to run this script: tap cmd-shift-1 on keyboard; enter `ga_ela_progressions`
if (!window.custom_scripts) window.custom_scripts = {}
window.custom_scripts.ga_ela_progressions = {
	input_required: false,
	fn: function(input) {
		return new Promise((resolve, reject)=>{
			let add_to_log = (s) => { log += `${s}\n` }
			let parse_code = (s) => {
				if (s.search(/^(K|\d+)\.([A-Z]+)\.([A-Z]+)\.(\d+)(\.([a-z]|\d+))?/) == -1) {
					// add_to_log('INVALID CODE: ' + s)
					return null
				} else {
					return {
						hcs: s,
						grade: RegExp.$1,
						domain: RegExp.$2,
						big_idea: RegExp.$3,
						standard: RegExp.$4,
						expectation: RegExp.$6,
					}
				}
			}

			let log = ''

			// extract and parse codes from framework json
			let fr = vapp.case_tree_component.framework_record.json
			let codes = []
			for (let item of fr.CFItems) {
				// if (['Standard', 'Expectation', 'Expectation: Constructing Texts', 'Expectation: Interpreting Texts', 'Skill'].includes(item.CFItemType)) {
				if (['Skill'].includes(item.CFItemType)) {
					if (item.humanCodingScheme) {
						let co = parse_code(item.humanCodingScheme)
						if (!co) continue

						co.code_start = `${co.domain}.${co.big_idea}.${co.standard}`
						if (co.expectation) {
							co.code_start += '-'+co.expectation
						} else {
							co.code_start += '-!'
						}
						co.sort_code = co.code_start
						if (co.grade == 'K') co.sort_code += '-0'
						else co.sort_code += '-'+co.grade

						codes.push(co)
					}
				}
			}
			codes = codes.sort((a,b)=>U.natural_sort(a.sort_code, b.sort_code))

			// codes = window.custom_scripts.ga_ela_progressions.codes.trim().split('\n')

			// add_to_log(`${codes.length} lines`)

			let grades = {}
			let domains = {}
			let big_ideas = {}
			let standards = {}
			let last_code_start = ''
			let last_hcs = ''
			for (let o of codes) {
				// add_to_log(o.sort_code + '   ' + o.hcs)

				if (!grades[o.grade]) grades[o.grade] = []
				grades[o.grade].push(o)

				if (!domains[o.domain]) domains[o.domain] = []
				domains[o.domain].push(o)

				if (!big_ideas[o.big_idea]) big_ideas[o.big_idea] = []
				big_ideas[o.big_idea].push(o)

				if (!standards[o.standard]) standards[o.standard] = []
				standards[o.standard].push(o)

				if (o.code_start == last_code_start) {
					add_to_log(`precedes: ${last_hcs} ${o.hcs}`)
				}
				last_code_start = o.code_start
				last_hcs = o.hcs
			}

			// add_to_log('\n*** Grades:')
			// for (let grade in grades) add_to_log(`${grade}: ${grades[grade].length}`)

			// add_to_log('\n*** Domains:')
			// for (let domain in domains) add_to_log(`${domain}: ${domains[domain].length}`)

			// add_to_log('\n*** Big Ideas:')
			// for (let big_idea in big_ideas) add_to_log(`${big_idea}: ${big_ideas[big_idea].length}`)

			// add_to_log('\n*** Standards:')
			// for (let standard in standards) add_to_log(`${standard}: ${standards[standard].length}`)

			resolve(log)
		})
	},

	codes: `
K.P.EICC.1
K.P.EICC.1.a
K.P.EICC.1.b
K.P.EICC.1.c
K.P.EICC.1.d
K.P.EICC.1.e
K.P.EICC.1.f
K.P.EICC.2
K.P.EICC.2.a
K.P.EICC.2.b
K.P.EICC.2.c
K.P.EICC.2.d
K.P.EICC.2.e
K.P.EICC.3
K.P.EICC.3.a
K.P.EICC.3.b
K.P.EICC.3.c
K.P.EICC.3.d
K.P.EICC.3.e
K.P.EICC.3.f
K.P.EICC.3.g
K.P.EICC.4
K.P.EICC.4.a
K.P.EICC.4.b
K.P.EICC.4.c
K.P.EICC.4.d
K.P.EICC.4.e
K.P.EICC.4.f
K.P.EICC.4.g
K.P.EICC.4.h
K.P.ST.1
K.P.ST.1.a
K.P.ST.1.b
K.P.ST.1.c
K.P.ST.2
K.P.ST.2.a
K.P.ST.2.b
K.P.ST.2.c
K.P.AC.1
K.P.AC.1.a
K.P.AC.1.b
K.P.AC.1.c
K.P.AC.1.d
K.P.AC.2
K.P.AC.2.a
K.P.AC.2.b
K.P.AC.2.c
K.P.AC.2.d
K.P.AC.3
K.P.AC.3.a
K.P.AC.3.b
K.P.AC.3.c
K.P.AC.3.d
K.P.CP.1
K.P.CP.1.a
K.P.CP.1.b
K.P.CP.1.c
K.P.CP.1.d
K.P.CP.2
K.P.CP.2.a
K.P.CP.2.b
K.P.CP.2.c
K.P.CP.2.d
K.P.CP.2.e
K.F.PA.1
K.F.PA.1.a
K.F.PA.1.b
K.F.PA.2
K.F.PA.2.a
K.F.PA.2.b
K.F.PA.2.c
K.F.PA.3
K.F.PA.3.a
K.F.PA.3.b
K.F.PA.4
K.F.PA.4.a
K.F.PA.4.b
K.F.PA.4.c
K.F.PA.4.d
K.F.PA.5
K.F.PA.5.a
K.F.PA.5.b
K.F.PA.6
K.F.PA.6.a
K.F.PA.6.b
K.F.PA.6.c
K.F.PA.6.d
K.F.CP.1
K.F.CP.1.a
K.F.CP.1.b
K.F.CP.1.c
K.F.CP.2
K.F.CP.2.a
K.F.CP.2.b
K.F.CP.2.c
K.F.CP.3
K.F.CP.3.a
K.F.CP.3.b
K.F.CP.3.c
K.F.CP.4
K.F.CP.4.a
K.F.CP.4.b
K.F.P.1
K.F.P.1.a
K.F.P.1.b
K.F.P.2
K.F.P.2.a
K.F.P.2.b
K.F.P.2.e
K.F.P.3
K.F.P.3.a
K.F.P.3.b
K.F.F.1
K.F.F.1.a
K.F.F.1.b
K.F.F.1.c
K.F.H.1
K.F.H.1.a
K.F.H.1.b
K.F.H.1.c
K.F.H.2
K.F.H.2.a
K.F.H.2.b
K.L.GC.1
K.L.GC.2
K.L.GC.2.a
K.L.GC.2.b
K.L.GC.2.c
K.L.V.1
K.L.V.1.a
K.L.V.1.b
K.L.V.3
K.L.V.3.a
K.L.V.3.b
K.L.V.3.e
K.T.C.1
K.T.C.1.a
K.T.C.1.b
K.T.C.1.c
K.T.C.2
K.T.C.2.a
K.T.SS.1
K.T.SS.1.a
K.T.SS.1.b
K.T.SS.1.c
K.T.SS.2
K.T.SS.2.a
K.T.SS.2.b
K.T.T.1
K.T.T.1.a
K.T.T.1.c
K.T.T.1.d
K.T.T.1.e
K.T.T.2
K.T.T.2.a
K.T.T.2.b
K.T.T.2.c
K.T.T.3
K.T.T.3.a
K.T.T.3.c
K.T.T.4
K.T.T.4.a
K.T.T.4.b
K.T.RA.1
K.T.RA.1.a
K.T.RA.1.b
K.T.RA.1.c
K.RA.2
K.T.RA.2.a
K.T.RA.2.b
1.P.EIIC.1
1.P.EICC.1.a
1.P.EICC.1.b
1.P.EICC.1.c
1.P.EICC.1.d
1.P.EICC.1.e
1.P.EICC.1.f
1.P.EICC.2
1.P.EICC.2.a
1.P.EICC.2.b
1.P.EICC.2.c
1.P.EICC.2.d
1.P.EICC.2.e
1.P.EICC.3
1.P.EICC.3.a
1.P.EICC.3.b
1.P.EICC.3.c
1.P.EICC.3.d
1.P.EICC.3.e
1.P.EICC.3.f
1.P.EICC.3.g
1.P.EICC.4
1.P.EICC.4.a
1.P.EICC.4.b
1.P.EICC.4.c
1.P.EICC.4.d
1.P.EICC.4.e
1.P.EICC.4.f
1.P.EICC.4.g
1.P.EICC.4.h
1.P.ST.1
1.P.ST.1.a
1.P.ST.1.b
1.P.ST.1.c
1.P.ST.2
1.P.ST.2.a
1.P.ST.2.b
1.P.ST.2.c
1.P.AC.1
1.P.AC.1.a
1.P.AC.1.b
1.P.AC.1.c
1.P.AC.1.d
1.P.AC.2
1.P.AC.2.a
1.P.AC.2.b
1.P.AC.2.c
1.P.AC.2.d
1.P.AC.3
1.P.AC.3.a
1.P.AC.3.b
1.P.AC.3.c
1.P.AC.3.d
1.P.CP.1
1.P.CP.1.a
1.P.CP.1.b
1.P.CP.1.c
1.P.CP.1.d
1.P.CP.2
1.P.CP.2.a
1.P.CP.2.b
1.P.CP.2.c
1.P.CP.2.d
1.P.CP.2.e
1.F.PA.4
1.F.PA.4.a
1.F.PA.4.b
1.F.PA.4.c
1.F.PA.4.d
1.F.PA.5
1.F.PA.5.a
1.F.PA.5.b
1.F.PA.6
1.F.PA.6.a
1.F.PA.6.b
1.F.PA.6.c
1.F.PA.6.d
1.F.P.1
1.F.P.1.a
1.F.P.1.b
1.F.P.2
1.F.P.2.a
1.F.P.2.b
1.F.P.2.c
1.F.P.2.d
1.F.P.3
1.F.P.3.a
1.F.P.3.b
1.F.P.3.c
1.F.P.3.d
1.F.F.1
1.F.F.1.a
1.F.F.1.b
1.F.F.1.c
1.F.F.1.d
1.F.H.1
1.F.H.1.a
1.F.H.1.b
1.F.H.1.c
1.F.H.2
1.F.H.2.a
1.F.H.2.b
1.L.GC.1
1.L.GC.2
1.L.GC.2.a
1.L.GC.2.b
1.L.GC.2.c
1.L.GC.2.d
1.L.V.1
1.L.V.1.a
1.L.V.1.b
1.L.V.2
1.L.V.2.a
1.L.V.2.c
1.L.V.3
1.L.V.3.a
1.L.V.3.b
1.L.V.3.c
1.L.V.3.d
1.L.V.3.e
1.T.C.1
1.T.C.1.a
1.T.C.1.b
1.T.C.1.c
1.T.C.2
1.T.C.2.a
1.T.SS.1
1.T.SS.1.a
1.T.SS.1.b
1.T.SS.1.c
1.T.SS.2
1.T.SS.2.a
1.T.SS.2.b
1.T.T.1
1.T.T.1.a
1.T.T.1.b
1.T.T.1.c
1.T.T.1.d
1.T.T.1.e
1.T.T.2
1.T.T.2.a
1.T.T.2.b
1.T.T.2.c
1.T.T.3
1.T.T.3.a
1.T.T.3.c
1.T.T.4
1.T.T.4.a
1.T.T.4.b
1.T.RA.1
1.T.RA.1.a
1.T.RA.1.b
1.T.RA.1.c
1.RA.2
1.T.RA.2.a
1.T.RA.2.b
2.P.EIIC.1
2.P.EICC.1.a
2.P.EICC.1.b
2.P.EICC.1.c
2.P.EICC.1.d
2.P.EICC.1.e
2.P.EICC.1.f
2.P.EICC.2
2.P.EICC.2.a
2.P.EICC.2.b
2.P.EICC.2.c
2.P.EICC.2.d
2.P.EICC.2.e
2.P.EICC.3
2.P.EICC.3.a
2.P.EICC.3.b
2.P.EICC.3.c
2.P.EICC.3.d
2.P.EICC.3.e
2.P.EICC.3.f
2.P.EICC.3.g
2.P.EICC.4
2.P.EICC.4.a
2.P.EICC.4.b
2.P.EICC.4.c
2.P.EICC.4.d
2.P.EICC.4.e
2.P.EICC.4.f
2.P.EICC.4.g
2.P.EICC.4.h
2.P.ST.1
2.P.ST.1.a
2.P.ST.1.b
2.P.ST.1.c
2.P.ST.2
2.P.ST.2.a
2.P.ST.2.b
2.P.ST.2.c
2.P.AC.1
2.P.AC.1.a
2.P.AC.1.b
2.P.AC.1.c
2.P.AC.1.d
2.P.AC.2
2.P.AC.2.a
2.P.AC.2.b
2.P.AC.2.c
2.P.AC.2.d
2.P.AC.3
2.P.AC.3.a
2.P.AC.3.b
2.P.AC.3.c
2.P.AC.3.d
2.P.CP.1
2.P.CP.1.a
2.P.CP.1.b
2.P.CP.1.c
2.P.CP.1.d
2.P.CP.2
2.P.CP.2.a
2.P.CP.2.b
2.P.CP.2.c
2.P.CP.2.d
2.P.CP.2.e
2.F.P.1
2.F.P.1.a
2.F.P.1.b
2.F.P.2
2.F.P.2.a
2.F.P.2.b
2.F.P.2.c
2.F.P.2.d
2.F.P.3
2.F.P.3.a
2.F.P.3.b
2.F.P.3.c
2.F.P.3.d
2.F.F.1
2.F.F.1.a
2.F.F.1.b
2.F.F.1.c
2.F.F.1.d
2.F.H.1
2.F.H.1.a
2.F.H.1.b
2.F.H.1.c
2.F.H.2
2.F.H.2.a
2.F.H.2.b
2.L.GC.1
2.L.GC.2
2.L.GC.2.a
2.L.GC.2.b
2.L.GC.2.c
2.L.GC.2.d
2.L.V.1
2.L.V.1.a
2.L.V.1.b
2.L.V.2
2.L.V.2.a
2.L.V.2.c
2.L.V.3
2.L.V.3.a
2.L.V.3.b
2.L.V.3.c
2.L.V.3.d
2.L.V.3.e
2.T.C.1
2.T.C.1.a
2.T.C.1.b
2.T.C.1.c
2.T.C.2
2.T.C.2.a
2.T.SS.1
2.T.SS.1.a
2.T.SS.1.b
2.T.SS.1.c
2.T.SS.2
2.T.SS.2.a
2.T.SS.2.b
2.T.T.1
2.T.E.1.a
2.T.T.1.b
2.T.T.1.c
2.T.T.1.d
2.T.T.1.e
2.T.T.2
2.T.T.2.a
2.T.T.2.b
2.T.T.2.c
2.T.T.3
2.T.T.3.a
2.T.T.3.c
2.T.T.4
2.T.T.4.a
2.T.T.4.b
2.T.RA.1
2.T.RA.1.a
2.T.RA.1.b
2.T.RA.1.c
2.RA.2
2.T.RA.2.a
2.T.RA.2.b
3.P.EIIC.1
3.P.EICC.1.a
3.P.EICC.1.b
3.P.EICC.1.c
3.P.EICC.1.d
3.P.EICC.1.e
3.P.EICC.1.f
3.P.EICC.2
3.P.EICC.2.a
3.P.EICC.2.b
3.P.EICC.2.c
3.P.EICC.2.d
3.P.EICC.2.e
3.P.EICC.3
3.P.EICC.3.a
3.P.EICC.3.b
3.P.EICC.3.c
3.P.EICC.3.d
3.P.EICC.3.e
3.P.EICC.3.f
3.P.EICC.3.g
3.P.EICC.4
3.P.EICC.4.a
3.P.EICC.4.b
3.P.EICC.4.c
3.P.EICC.4.d
3.P.EICC.4.e
3.P.EICC.4.f
3.P.EICC.4.g
3.P.EICC.4.h
3.P.ST.1
3.P.ST.1.a
3.P.ST.1.b
3.P.ST.1.c
3.P.ST.2
3.P.ST.2.a
3.P.ST.2.b
3.P.ST.2.c
3.P.AC.1
3.P.AC.1.a
3.P.AC.1.b
3.P.AC.1.c
3.P.AC.1.d
3.P.AC.2
3.P.AC.2.a
3.P.AC.2.b
3.P.AC.2.c
3.P.AC.2.d
3.P.AC.3
3.P.AC.3.a
3.P.AC.3.b
3.P.AC.3.c
3.P.AC.3.d
3.P.CP.1
3.P.CP.1.a
3.P.CP.1.b
3.P.CP.1.c
3.P.CP.1.d
3.P.CP.2
3.P.CP.2.a
3.P.CP.2.b
3.P.CP.2.c
3.P.CP.2.d
3.P.CP.2.e
3.F.P.4
3.F.P.4.a
3.F.P.4.b
3.F.P.4.c
3.F.F.1
3.F.F.1.a
3.F.F.1.b
3.F.F.1.c
3.F.F.1.d
3.F.H.3
3.F.H.3.a
3.F.H.4
3.F.H.4.a
3.F.H.4.b
3.L.GC.1
3.L.GC.2
3.L.GC.2.a
3.L.GC.2.b
3.L.GC.2.c
3.L.GC.2.d
3.L.V.1
3.L.V.1.a
3.L.V.1.b
3.L.V.2
3.L.V.2.a
3.L.V.2.b
3.L.V.2.c
3.L.V.3
3.L.V.3.a
3.L.V.3.b
3.L.V.3.c
3.L.V.3.d
3.L.V.3.e
3.T.C.1
3.T.C.1.a
3.T.C.1.b
3.T.C.1.c
3.T.C.2
3.T.C.2.a
3.T.C.2.b
3.T.C.2.c
3.T.SS.1
3.T.SS.1.a
3.T.SS.1.b
3.T.SS.1.c
3.T.SS.1.d
3.T.SS.2
3.T.SS.2.a
3.T.SS.2.b
3.T.T.1
3.T.T.1.a
3.T.T.1.b
3.T.T.1.c
3.T.T.1.d
3.T.T.1.e
3.T.T.2
3.T.T.2.a
3.T.T.2.b
3.T.T.2.c
3.T.T.3
3.T.T.3.a
3.T.T.3.c
3.T.T.4
3.T.T.4.a
3.T.T.4.b
3.T.RA.1
3.T.RA.1.a
3.T.RA.1.b
3.T.RA.1.c
3.T.RA.2
3.T.RA.2.a
3.T.RA.2.b
4.P.EIIC.1
4.P.EICC.1.a
4.P.EICC.1.b
4.P.EICC.1.c
4.P.EICC.1.d
4.P.EICC.1.e
4.P.EICC.1.f
4.P.EICC.2
4.P.EICC.2.a
4.P.EICC.2.b
4.P.EICC.2.c
4.P.EICC.2.d
4.P.EICC.2.e
4.P.EICC.3
4.P.EICC.3.a
4.P.EICC.3.b
4.P.EICC.3.c
4.P.EICC.3.d
4.P.EICC.3.e
4.P.EICC.3.f
4.P.EICC.3.g
4.P.EICC.4
4.P.EICC.4.a
4.P.EICC.4.b
4.P.EICC.4.c
4.P.EICC.4.d
4.P.EICC.4.e
4.P.EICC.4.f
4.P.EICC.4.g
4.P.EICC.4.h
4.P.ST.1
4.P.ST.1.a
4.P.ST.1.b
4.P.ST.1.c
4.P.ST.2
4.P.ST.2.a
4.P.ST.2.b
4.P.ST.2.c
4.P.AC.1
4.P.AC.1.a
4.P.AC.1.b
4.P.AC.1.c
4.P.AC.1.d
4.P.AC.2
4.P.AC.2.a
4.P.AC.2.b
4.P.AC.2.c
4.P.AC.2.d
4.P.AC.3
4.P.AC.3.a
4.P.AC.3.b
4.P.AC.3.c
4.P.AC.3.d
4.P.CP.1
4.P.CP.1.a
4.P.CP.1.b
4.P.CP.1.c
4.P.CP.1.d
4.P.CP.2
4.P.CP.2.a
4.P.CP.2.b
4.P.CP.2.c
4.P.CP.2.d
4.P.CP.2.e
4.F.P.4
4.F.P.4.a
4.F.P.4.b
4.F.P.4.c
4.F.F.1
4.F.F.1.a
4.F.F.1.b
4.F.F.1.c
4.F.F.1.d
4.F.H.3
4.F.H.3.a
4.F.H.4
4.F.H.4.a
4.F.H.4.b
4.L.GC.1
4.L.GC.2
4.L.GC.2.a
4.L.GC.2.b
4.L.GC.2.c
4.L.GC.2.d
4.L.V.1
4.L.V.1.a
4.L.V.1.b
4.L.V.2
4.L.V.2.a
4.L.V.2.b
4.L.V.2.c
4.L.V.3
4.L.V.3.a
4.L.V.3.b
4.L.V.3.c
4.L.V.3.d
4.L.V.3.e
4.T.C.1
4.T.C.1.a
4.T.C.1.b
4.T.C.1.c
4.T.C.2
4.T.C.2.a
4.T.C.2.b
4.T.C.2.c
4.T.SS.1
4.T.SS.1.a
4.T.SS.1.b
4.T.SS.1.c
4.T.SS.1.d
4.T.SS.2
4.T.SS.2.a
4.T.SS.2.b
4.T.T.1
4.T.T.1.a
4.T.T.1.b
4.T.T.1.c
4.T.T.1.d
4.T.T.1.e
4.T.T.2
4.T.T.2.a
4.T.T.2.b
4.T.T.2.c
4.T.T.3
4.T.T.3.a
4.T.T.3.c
4.T.T.4
4.T.T.4.a
4.T.T.4.b
4.T.RA.1
4.T.RA.1.a
4.T.RA.1.b
4.T.RA.1.c
4.T.RA.2
4.T.RA.2.a
4.T.RA.2.b
5.P.EIIC.1
5.P.EICC.1.a
5.P.EICC.1.b
5.P.EICC.1.c
5.P.EICC.1.d
5.P.EICC.1.e
5.P.EICC.1.f
5.P.EICC.2
5.P.EICC.2.a
5.P.EICC.2.b
5.P.EICC.2.c
5.P.EICC.2.d
5.P.EICC.2.e
5.P.EICC.3
5.P.EICC.3.a
5.P.EICC.3.b
5.P.EICC.3.c
5.P.EICC.3.d
5.P.EICC.3.e
5.P.EICC.3.f
5.P.EICC.3.g
5.P.EICC.4
5.P.EICC.4.a
5.P.EICC.4.b
5.P.EICC.4.c
5.P.EICC.4.d
5.P.EICC.4.e
5.P.EICC.4.f
5.P.EICC.4.g
5.P.EICC.4.h
5.P.ST.1
5.P.ST.1.a
5.P.ST.1.b
5.P.ST.1.c
5.P.ST.2
5.P.ST.2.a
5.P.ST.2.b
5.P.ST.2.c
5.P.AC.1
5.P.AC.1.a
5.P.AC.1.b
5.P.AC.1.c
5.P.AC.1.d
5.P.AC.2
5.P.AC.2.a
5.P.AC.2.b
5.P.AC.2.c
5.P.AC.2.d
5.P.AC.3
5.P.AC.3.a
5.P.AC.3.b
5.P.AC.3.c
5.P.AC.3.d
5.P.CP.1
5.P.CP.1.a
5.P.CP.1.b
5.P.CP.1.c
5.P.CP.1.d
5.P.CP.2
5.P.CP.2.a
5.P.CP.2.b
5.P.CP.2.c
5.P.CP.2.d
5.P.CP.2.e
5.F.P.4
5.F.P.4.a
5.F.P.4.b
5.F.P.4.c
5.F.F.1
5.F.F.1.a
5.F.F.1.b
5.F.F.1.c
5.F.F.1.d
5.F.H.3
5.F.H.3.a
5.F.H.4
5.F.H.4.a
5.F.H.4.b
5.L.GC.1
5.L.GC.2
5.L.GC.2.a
5.L.GC.2.b
5.L.GC.2.c
5.L.GC.2.d
5.L.V.1
5.L.V.1.a
5.L.V.1.b
5.L.V.2
5.L.V.2.a
5.L.V.2.b
5.L.V.2.c
5.L.V.3
5.L.V.3.a
5.L.V.3.b
5.L.V.3.c
5.L.V.3.d
5.L.V.3.e
5.T.C.1
5.T.C.1.a
5.T.C.1.b
5.T.C.1.c
5.T.C.2
5.T.C.2.a
5.T.C.2.b
5.T.C.2.c
5.T.SS.1
5.T.SS.1.a
5.T.SS.1.b
5.T.SS.1.c
5.T.SS.1.d
5.T.SS.2
5.T.SS.2.a
5.T.SS.2.b
5.T.T.1
5.T.T.1.a
5.T.T.1.b
5.T.T.1.c
5.T.T.1.d
5.T.T.1.e
5.T.T.2
5.T.T.2.a
5.T.T.2.b
5.T.T.2.c
5.T.T.3
5.T.T.3.a
5.T.T.3.c
5.T.T.4
5.T.T.4.a
5.T.T.4.b
5.T.RA.1
5.T.RA.1.a
5.T.RA.1.b
5.T.RA.1.c
5.T.RA.2
5.T.RA.2.a
5.T.RA.2.b
5.T.RA.2.c
6.P.EIIC.1
6.P.EICC.1.a
6.P.EICC.1.b
6.P.EICC.1.c
6.P.EICC.1.d
6.P.EICC.1.e
6.P.EICC.1.f
6.P.EICC.2
6.P.EICC.2.a
6.P.EICC.2.b
6.P.EICC.2.c
6.P.EICC.2.d
6.P.EICC.2.e
6.P.EICC.3
6.P.EICC.3.a
6.P.EICC.3.b
6.P.EICC.3.c
6.P.EICC.3.d
6.P.EICC.3.e
6.P.EICC.3.f
6.P.EICC.3.g
6.P.EICC.4
6.P.EICC.4.a
6.P.EICC.4.b
6.P.EICC.4.c
6.P.EICC.4.d
6.P.EICC.4.e
6.P.EICC.4.f
6.P.EICC.4.g
6.P.EICC.4.h
6.P.ST.1
6.P.ST.1.a
6.P.ST.1.b
6.P.ST.1.c
6.P.ST.2
6.P.ST.2.a
6.P.ST.2.b
6.P.ST.2.c
6.P.AC.1
6.P.AC.1.a
6.P.AC.1.b
6.P.AC.1.c
6.P.AC.1.d
6.P.AC.2
6.P.AC.2.a
6.P.AC.2.b
6.P.AC.2.c
6.P.AC.2.d
6.P.AC.3
6.P.AC.3.a
6.P.AC.3.b
6.P.AC.3.c
6.P.AC.3.d
6.P.CP.1
6.P.CP.1.a
6.P.CP.1.b
6.P.CP.1.c
6.P.CP.1.d
6.P.CP.2
6.P.CP.2.a
6.P.CP.2.b
6.P.CP.2.c
6.P.CP.2.d
6.P.CP.2.e
6.L.GC.1
6.L.GC.2
6.L.GC.2.a
6.L.GC.2.b
6.L.GC.2.c
6.L.GC.2.d
6.L.V.1
6.L.V.1.a
6.L.V.1.b
6.L.V.2
6.L.V.2.a
6.L.V.2.b
6.L.V.2.c
6.L.V.2.d
6.L.V.3
6.L.V.3.b
6.L.V.3.c
6.L.V.3.d
6.L.V.3.e
6.T.C.1
6.T.C.1.a
6.T.C.1.b
6.T.C.1.c
6.T.C.2
6.T.C.2.a
6.T.C.2.b
6.T.C.2.c
6.T.C.2.d
6.T.SS.1
6.T.SS.1.a
6.T.SS.1.b
6.T.SS.1.c
6.T.SS.1.d
6.T.SS.2
6.T.SS.2.a
6.T.SS.2.b
6.T.SS.2.c
6.T.T.1
6.T.T.1.a
6.T.T.1.b
6.T.T.1.c
6.T.T.1.d
6.T.T.1.e
6.T.T.2
6.T.T.2.a
6.T.T.2.b
6.T.T.2.c
6.T.T.3
6.T.T.3.a
6.T.T.3.c
6.T.T.4
6.T.T.4.a
6.T.T.4.b
6.T.RA.1
6.T.RA1.a
6.T.RA.1.b
6.T.RA.1.c
6.RA.2
6.T.RA.2.a
6.T.RA.2.b
6.T.RA.2.c
6.T.PM.1
6.T.PM1.a
6.T.PM1.b
7.P.EIIC.1
7.P.EICC.1.a
7.P.EICC.1.b
7.P.EICC.1.c
7.P.EICC.1.d
7.P.EICC.1.e
7.P.EICC.1.f
7.P.EICC.2
7.P.EICC.2.a
7.P.EICC.2.b
7.P.EICC.2.c
7.P.EICC.2.d
7.P.EICC.2.e
7.P.EICC.3
7.P.EICC.3.a
7.P.EICC.3.b
7.P.EICC.3.c
7.P.EICC.3.d
7.P.EICC.3.e
7.P.EICC.3.f
7.P.EICC.3.g
7.P.EICC.4
7.P.EICC.4.a
7.P.EICC.4.b
7.P.EICC.4.c
7.P.EICC.4.d
7.P.EICC.4.e
7.P.EICC.4.f
7.P.EICC.4.g
7.P.EICC.4.h
7.P.ST.1
7.P.ST.1.a
7.P.ST.1.b
7.P.ST.1.c
7.P.ST.2
7.P.ST.2.a
7.P.ST.2.b
7.P.ST.2.c
7.P.AC.1
7.P.AC.1.a
7.P.AC.1.b
7.P.AC.1.c
7.P.AC.1.d
7.P.AC.2
7.P.AC.2.a
7.P.AC.2.b
7.P.AC.2.c
7.P.AC.2.d
7.P.AC.3
7.P.AC.3.a
7.P.AC.3.b
7.P.AC.3.c
7.P.AC.3.d
7.P.CP.1
7.P.CP.1.a
7.P.CP.1.b
7.P.CP.1.c
7.P.CP.1.d
7.P.CP.2
7.P.CP.2.a
7.P.CP.2.b
7.P.CP.2.c
7.P.CP.2.d
7.P.CP.2.e
7.L.GC.1
7.L.GC.2
7.L.GC.2.a
7.L.GC.2.b
7.L.GC.2.c
7.L.GC.2.d
7.L.V.1
7.L.V.1.a
7.L.V.1.b
7.L.V.2
7.L.V.2.a
7.L.V.2.b
7.L.V.2.c
7.L.V.2.d
7.L.V.3
7.L.V.3.b
7.L.V.3.c
7.L.V.3.d
7.L.V.3.e
7.T.C.1
7.T.C.1.a
7.T.C.1.b
7.T.C.1.c
7.T.C.2
7.T.C.2.a
7.T.C.2.b
7.T.C.2.c
7.T.C.2.d
7.T.SS.1
7.T.SS.1.a
7.T.SS.1.b
7.T.SS.1.c
7.T.SS.1.d
7.T.SS.2
7.T.SS.2.a
7.T.SS.2.b
7.T.SS.2.c
7.T.T.1
7.T.T.1.a
7.T.T.1.b
7.T.T.1.c
7.T.T.1.d
7.T.T.1.e
7.T.T.2
7.T.T.2.a
7.T.T.2.b
7.T.T.2.c
7.T.T.3
7.T.T.3.a
7.T.T.3.c
7.T.T.4
7.T.T.4.a
7.T.T.4.b
7.T.RA.1
7.T.RA.1.a
7.T.RA.1.b
7.T.RA.1.c
7.RA.2
7.T.RA.2.a
7.T.RA.2.b
7.T.RA.2.c
7.T.PM.1
7.T.PM1.a
7.T.PM1.b
8.P.EIIC.1
8.P.EICC.1.a
8.P.EICC.1.b
8.P.EICC.1.c
8.P.EICC.1.d
8.P.EICC.1.e
8.P.EICC.1.f
8.P.EICC.2
8.P.EICC.2.a
8.P.EICC.2.b
8.P.EICC.2.c
8.P.EICC.2.d
8.P.EICC.2.e
8.P.EICC.3
8.P.EICC.3.a
8.P.EICC.3.b
8.P.EICC.3.c
8.P.EICC.3.d
8.P.EICC.3.e
8.P.EICC.3.f
8.P.EICC.3.g
8.P.EICC.4
8.P.EICC.4.a
8.P.EICC.4.b
8.P.EICC.4.c
8.P.EICC.4.d
8.P.EICC.4.e
8.P.EICC.4.f
8.P.EICC.4.g
8.P.EICC.4.h
8.P.ST.1
8.P.ST.1.a
8.P.ST.1.b
8.P.ST.1.c
8.P.ST.2
8.P.ST.2.a
8.P.ST.2.b
8.P.ST.2.c
8.P.AC.1
8.P.AC.1.a
8.P.AC.1.b
8.P.AC.1.c
8.P.AC.1.d
8.P.AC.2
8.P.AC.2.a
8.P.AC.2.b
8.P.AC.2.c
8.P.AC.2.d
8.P.AC.3
8.P.AC.3.a
8.P.AC.3.b
8.P.AC.3.c
8.P.AC.3.d
8.P.CP.1
8.P.CP.1.a
8.P.CP.1.b
8.P.CP.1.c
8.P.CP.1.d
8.P.CP.2
8.P.CP.2.a
8.P.CP.2.b
8.P.CP.2.c
8.P.CP.2.d
8.P.CP.2.e
8.L.GC.1
8.L.GC.2
8.L.GC.2.a
8.L.GC.2.b
8.L.GC.2.c
8.L.GC.2.d
8.L.V.1
8.L.V.1.a
8.L.V.1.b
8.L.V.2
8.L.V.2.a
8.L.V.2.b
8.L.V.2.c
8.L.V.2.d
8.L.V.3
8.L.V.3.b
8.L.V.3.c
8.L.V.3.d
8.L.V.3.e
8.T.C.1
8.T.C.1.a
8.T.C.1.b
8.T.C.1.c
8.T.C.2
8.T.C.2.a
8.T.C.2.b
8.T.C.2.c
8.T.C.2.d
8.T.SS.1
8.T.SS.1.a
8.T.SS.1.b
8.T.SS.1.c
8.T.SS.1.d
8.T.SS.2
8.T.SS.2.a
8.T.SS.2.b
8.T.SS.2.c
8.T.T.1
8.T.T.1.a
8.T.T.1.b
8.T.T.1.c
8.T.T.1.d
8.T.T.1.e
8.T.T.2
8.T.T.2.a
8.T.T.2.b
8.T.T.2.c
8.T.T.3
8.T.T.3.a
8.T.T.3.c
8.T.T.4
8.T.T.4.a
8.T.T.4.b
8.T.RA.1
8.T.RA.1.a
8.T.RA.1.b
8.T.RA.1.c
8.RA.2
8.T.RA.2.a
8.T.RA.2.b
8.T.RA.2.c
8.T.PM.1
8.T.PM1.a
8.T.PM1.b
9.P.EIIC.1
9.P.EICC.1.a
9.P.EICC.1.b
9.P.EICC.1.c
9.P.EICC.1.d
9.P.EICC.1.e
9.P.EICC.1.f
9.P.EICC.2
9.P.EICC.2.a
9.P.EICC.2.b
9.P.EICC.2.c
9.P.EICC.2.d
9.P.EICC.2.e
9.P.EICC.3
9.P.EICC.3.a
9.P.EICC.3.b
9.P.EICC.3.c
9.P.EICC.3.d
9.P.EICC.3.e
9.P.EICC.3.f
9.P.EICC.3.g
9.P.EICC.4
9.P.EICC.4.a
9.P.EICC.4.b
9.P.EICC.4.c
9.P.EICC.4.d
9.P.EICC.4.e
9.P.EICC.4.f
9.P.EICC.4.g
9.P.EICC.4.h
9.P.ST.1
9.P.ST.1.a
9.P.ST.1.b
9.P.ST.1.c
9.P.ST.2
9.P.ST.2.a
9.P.ST.2.b
9.P.ST.2.c
9.P.AC.1
9.P.AC.1.a
9.P.AC.1.b
9.P.AC.1.c
9.P.AC.1.d
9.P.AC.2
9.P.AC.2.a
9.P.AC.2.b
9.P.AC.2.c
9.P.AC.2.d
9.P.AC.3
9.P.AC.3.a
9.P.AC.3.b
9.P.AC.3.c
9.P.AC.3.d
9.P.CP.1
9.P.CP.1.a
9.P.CP.1.b
9.P.CP.1.c
9.P.CP.1.d
9.P.CP.2
9.P.CP.2.a
9.P.CP.2.b
9.P.CP.2.c
9.P.CP.2.d
9.P.CP.2.e
9.L.GC.1
9.L.GC.2
9.L.GC.2.a
9.L.GC.2.b
9.L.GC.2.c
9.L.GC.2.d
9.L.GC.2.e
9.L.V.1
9.L.V.1.a
9.L.V.1.b
9.L.V.2
9.L.V.2.a
9.L.V.2.b
9.L.V.2.c
9.L.V.2.d
9.L.V.3
9.L.V.3.b
9.L.V.3.c
9.L.V.3.d
9.L.V.3.e
9.T.C.1
9.T.C.1.a
9.T.C.1.b
9.T.C.1.c
9.T.C.2
9.T.C.2.a
9.T.C.2.b
9.T.C.2.d
9.T.SS.1
9.T.SS.1.a
9.T.SS.1.b
9.T.SS.1.c
9.T.SS.1.d
9.T.SS.2
9.T.SS.2.a
9.T.SS.2.b
9.T.SS.2.c
9.T.T.1
9.T.T.1.a
9.T.T.1.b
9.T.T.1.c
9.T.T.1.d
9.T.T.1.e
9.T.T.2
9.T.T.2.a
9.T.T.2.b
9.T.T.2.c
9.T.T.3
9.T.T.3.a
9.T.T.3.b
9.T.T.3.c
9.T.T.3.d
9.T.T.4
9.T.T.4.a
9.T.T.4.b
9.T.RA.1
9.T.RA.1.a
9.T.RA.1.b
9.T.RA.1.c
9.T.RA.2
9.T.RA.2.a
9.T.RA.2.b
9.T.RA.2.c
9.T.PM.1
9.T.PM1.a
9.T.PM1.b
10.P.EIIC.1
10.P.EICC.1.a
10.P.EICC.1.b
10.P.EICC.1.c
10.P.EICC.1.d
10.P.EICC.1.e
10.P.EICC.1.f
10.P.EICC.2
10.P.EICC.2.a
10.P.EICC.2.b
10.P.EICC.2.c
10.P.EICC.2.d
10.P.EICC.2.e
10.P.EICC.3
10.P.EICC.3.a
10.P.EICC.3.b
10.P.EICC.3.c
10.P.EICC.3.d
10.P.EICC.3.e
10.P.EICC.3.f
10.P.EICC.3.g
10.P.EICC.4
10.P.EICC.4.a
10.P.EICC.4.b
10.P.EICC.4.c
10.P.EICC.4.d
10.P.EICC.4.e
10.P.EICC.4.f
10.P.EICC.4.g
10.P.EICC.4.h
10.P.ST.1
10.P.ST.1.a
10.P.ST.1.b
10.P.ST.1.c
10.P.ST.2
10.P.ST.2.a
10.P.ST.2.b
10.P.ST.2.c
10.P.AC.1
10.P.AC.1.a
10.P.AC.1.b
10.P.AC.1.c
10.P.AC.1.d
10.P.AC.2
10.P.AC.2.a
10.P.AC.2.b
10.P.AC.2.c
10.P.AC.2.d
10.P.AC.3
10.P.AC.3.a
10.P.AC.3.b
10.P.AC.3.c
10.P.AC.3.d
10.P.CP.1
10.P.CP.1.a
10.P.CP.1.b
10.P.CP.1.c
10.P.CP.1.d
10.P.CP.2
10.P.CP.2.a
10.P.CP.2.b
10.P.CP.2.c
10.P.CP.2.d
10.P.CP.2.e
10.L.GC.1
10.L.GC.2
10.L.GC.2.a
10.L.GC.2.b
10.L.GC.2.c
10.L.GC.2.d
10.L.GC.2.e
10.L.V.1
10.L.V.1.a
10.L.V.1.b
10.L.V.2
10.L.V.2.a
10.L.V.2.b
10.L.V.2.c
10.L.V.2.d
10.L.V.3
10.L.V.3.b
10.L.V.3.c
10.L.V.3.d
10.L.V.3.e
10.T.C.1
10.T.C.1.a
10.T.C.1.b
10.T.C.1.c
10.T.C.2
10.T.C.2.a
10.T.C.2.b
10.T.C.2.d
10.T.SS.1
10.T.SS.1.a
10.T.SS.1.b
10.T.SS.1.c
10.T.SS.1.d
10.T.SS.2
10.T.SS.2.a
10.T.SS.2.b
10.T.SS.2.c
10.T.T.1
10.T.T.1.a
10.T.T.1.b
10.T.T.1.c
10.T.T.1.d
10.T.T.1.e
10.T.T.2
10.T.T.2.a
10.T.T.2.b
10.T.T.2.c
10.T.T.3
10.T.T.3.a
10.T.T.3.b
10.T.T.3.c
10.T.T.3.d
10.T.T.4
10.T.T.4.a
10.T.T.4.b
10.T.RA.1
10.T.RA.1.a
10.T.RA.1.b
10.T.RA.1.c
10.T.RA.2
10.T.RA.2.a
10.T.RA.2.b
10.T.RA.2.c
10.T.PM.1
10.T.PM1.a
10.T.PM1.b
11.P.EIIC.1
11.P.EICC.1.a
11.P.EICC.1.b
11.P.EICC.1.c
11.P.EICC.1.d
11.P.EICC.1.e
11.P.EICC.1.f
11.P.EICC.2
11.P.EICC.2.a
11.P.EICC.2.b
11.P.EICC.2.c
11.P.EICC.2.d
11.P.EICC.2.e
11.P.EICC.3
11.P.EICC.3.a
11.P.EICC.3.b
11.P.EICC.3.c
11.P.EICC.3.d
11.P.EICC.3.e
11.P.EICC.3.f
11.P.EICC.3.g
11.P.EICC.4
11.P.EICC.4.a
11.P.EICC.4.b
11.P.EICC.4.c
11.P.EICC.4.d
11.P.EICC.4.e
11.P.EICC.4.f
11.P.EICC.4.g
11.P.EICC.4.h
11.P.ST.1
11.P.ST.1.a
11.P.ST.1.b
11.P.ST.1.c
11.P.ST.2
11.P.ST.2.a
11.P.ST.2.b
11.P.ST.2.c
11.P.AC.1
11.P.AC.1.a
11.P.AC.1.b
11.P.AC.1.c
11.P.AC.1.d
11.P.AC.2
11.P.AC.2.a
11.P.AC.2.b
11.P.AC.2.c
11.P.AC.2.d
11.P.AC.3
11.P.AC.3.a
11.P.AC.3.b
11.P.AC.3.c
11.P.AC.3.d
11.P.CP.1
11.P.CP.1.a
11.P.CP.1.b
11.P.CP.1.c
11.P.CP.1.d
11.P.CP.2
11.P.CP.2.a
11.P.CP.2.b
11.P.CP.2.c
11.P.CP.2.d
11.P.CP.2.e
11.L.GC.2
11.L.GC.2.a
11.L.GC.2.b
11.L.GC.2.c
11.L.GC.2.d
11.L.GC.2.e
11.L.V.1
11.L.V.1.a
11.L.V.1.b
11.L.V.2
11.L.V.2.a
11.L.V.2.b
11.L.V.2.c
11.L.V.2.d
11.L.V.3
11.L.V.3.b
11.L.V.3.c
11.L.V.3.d
11.L.V.3.e
11.T.C.1
11.T.C.1.a
11.T.C.1.b
11.T.C.1.c
11.T.C.2
11.T.C.2.a
11.T.C.2.b
11.T.C.2.d
11.T.SS.1
11.T.SS.1.a
11.T.SS.1.b
11.T.SS.1.c
11.T.SS.1.d
11.T.SS.2
11.T.SS.2.a
11.T.SS.2.b
11.T.SS.2.c
11.T.T.1
11.T.T.1.a
11.T.T.1.b
11.T.T.1.c
11.T.T.1.d
11.T.T.1.e
11.T.T.2
11.T.T.2.a
11.T.T.2.b
11.T.T.2.c
11.T.T.3
11.T.T.3.a
11.T.T.3.b
11.T.T.3.c
11.T.T.3.d
11.T.T.4
11.T.T.4.a
11.T.T.4.b
11.T.RA.1
11.T.RA.1.a
11.T.RA.1.b
11.T.RA.1.c
11.T.RA.2
11.T.RA.2.a
11.T.RA.2.b
11.T.RA.2.c
11.T.PM.1
11.T.PM1.a
11.T.PM1.b
12.P.EIIC.1
12.P.EICC.1.a
12.P.EICC.1.b
12.P.EICC.1.c
12.P.EICC.1.d
12.P.EICC.1.e
12.P.EICC.1.f
12.P.EICC.2
12.P.EICC.2.a
12.P.EICC.2.b
12.P.EICC.2.c
12.P.EICC.2.d
12.P.EICC.2.e
12.P.EICC.3
12.P.EICC.3.a
12.P.EICC.3.b
12.P.EICC.3.c
12.P.EICC.3.d
12.P.EICC.3.e
12.P.EICC.3.f
12.P.EICC.3.g
12.P.EICC.4
12.P.EICC.4.a
12.P.EICC.4.b
12.P.EICC.4.c
12.P.EICC.4.d
12.P.EICC.4.e
12.P.EICC.4.f
12.P.EICC.4.g
12.P.EICC.4.h
12.P.ST.1
12.P.ST.1.a
12.P.ST.1.b
12.P.ST.1.c
12.P.ST.2
12.P.ST.2.a
12.P.ST.2.b
12.P.ST.2.c
12.P.AC.1
12.P.AC.1.a
12.P.AC.1.b
12.P.AC.1.c
12.P.AC.1.d
12.P.AC.2
12.P.AC.2.a
12.P.AC.2.b
12.P.AC.2.c
12.P.AC.2.d
12.P.AC.3
12.P.AC.3.a
12.P.AC.3.b
12.P.AC.3.c
12.P.AC.3.d
12.P.CP.1
12.P.CP.1.a
12.P.CP.1.b
12.P.CP.1.c
12.P.CP.1.d
12.P.CP.2
12.P.CP.2.a
12.P.CP.2.b
12.P.CP.2.c
12.P.CP.2.d
12.P.CP.2.e
12.L.GC.2
12.L.GC.2.a
12.L.GC.2.b
12.L.GC.2.c
12.L.GC.2.d
12.L.GC.2.e
12.L.V.1
12.L.V.1.a
12.L.V.1.b
12.L.V.2
12.L.V.2.a
12.L.V.2.b
12.L.V.2.c
12.L.V.2.d
12.L.V.3
12.L.V.3.b
12.L.V.3.c
12.L.V.3.d
12.L.V.3.e
12.T.C.1
12.T.C.1.a
12.T.C.1.b
12.T.C.1.c
12.T.C.2
12.T.C.2.a
12.T.C.2.b
12.T.C.2.d
12.T.SS.1
12.T.SS.1.a
12.T.SS.1.b
12.T.SS.1.c
12.T.SS.1.d
12.T.SS.2
12.T.SS.2.a
12.T.SS.2.b
12.T.SS.2.c
12.T.T.1
12.T.T.1.a
12.T.T.1.b
12.T.T.1.c
12.T.T.1.d
12.T.T.1.e
12.T.T.2
12.T.T.2.a
12.T.T.2.b
12.T.T.2.c
12.T.T.3
12.T.T.3.a
12.T.T.3.b
12.T.T.3.c
12.T.T.3.d
12.T.T.4
12.T.T.4.a
12.T.T.4.b
12.T.RA.1
12.T.RA.1.a
12.T.RA.1.b
12.T.RA.1.c
12.T.RA.2
12.T.RA.2.a
12.T.RA.2.b
12.T.RA.2.c
12.T.PM.1
12.T.PM1.a
12.T.PM1.b
`
}