<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="k-cfv-toolbar">
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" v-show="show_edit_btn" x-small icon color="#666" :outlined="viewer.editing_enabled" @click="toggle_edit"><v-icon>fas fa-edit</v-icon></v-btn></template>{{viewer.editing_enabled?'Stop editing':'Edit framework'}}</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" :outlined="(signed_in||viewer.public_review_on)&&viewer.show_comments" @click="toggle_comments"><v-icon>fas fa-comment</v-icon></v-btn></template>{{viewer.show_comments?'Hide comments':'Show comments'}}</v-tooltip>
	<v-menu :close-on-content-click="false" bottom nudge-top="-24" nudge-left="80" max-height="calc(100vh - 156px)" :attach="'.k-cfv-toolbar'">
		<template v-slot:activator="{ on: menu, attrs }">
			<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on: tooltip}"><v-btn v-on="{...tooltip, ...menu}" v-bind="attrs" v-if="show_associations_btn" x-small icon color="#666" :outlined="viewer.show_associations" @click="viewer.show_associations_btn_clicked"><v-icon>fas fa-arrows-alt-h</v-icon></v-btn></template>Associations Options</v-tooltip>
		</template>
		<v-list>
			<v-list-item v-if="!viewer.has_associated_frameworks&&!viewer.has_copied_items" class="m-5"><i style="font-size: 15px; color: gray;">No associations exist for this framework</i></v-list-item>

			<v-list-item v-if="viewer.has_associated_frameworks" style="align-items: start;">
				<v-list-item-content>
					<v-list-item-title class="d-flex">
						<div>Show associations to/from:</div>
						<v-spacer/>
						<v-btn @click="e=>{viewer.toggle_all_association_frameworks()}" hide-details x-small text color="secondary">{{ !viewer.all_associations_showing ? "Select All" : "Select None" }}</v-btn>
					</v-list-item-title>

					<v-list-item-subtitle>
						<v-tooltip v-for="(o) in assoc_framework_list" v-if="o.fid!='copies'&&(o.afmi.count>0||o.afmi.crosswalk)" :key="o.fid" transition="fade-transition" left>
							<template v-slot:activator="{on, attrs}">
								<div v-on="on" v-bind="attrs">
									<v-checkbox v-model="o.afmi.showing" @click="" hide-details dense style="max-width:200px;"><template v-slot:label><b v-if="o.afmi.count" class="mr-1" style="margin-left:-4px;font-size:10px;background-color:#666; border-radius:8px; padding:2px 5px; line-height:12px; color:#fff;">{{o.afmi.count}}</b>
										<v-icon v-show="viewer.editing_enabled&&o.afmi.crosswalk" color="primary" small class="ml-1 mr-2" @click.stop="open_crosswalk(o.fid)">fas fa-edit</v-icon>
										{{o.title}}</template></v-checkbox>
								</div>
							</template>
							<div style="max-width: 150px; text-align: center;">{{ o.title }}</div>
						</v-tooltip>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item v-if="!viewer.no_associations_showing&&viewer.has_associated_frameworks" style="border-top:1px solid #ccc;">
				<v-list-item-content>
					<v-list-item-title class="d-flex">
						<div>Show association types:</div>
						<v-spacer/>
						<v-btn @click="e=>{viewer.toggle_all_association_types()}" hide-details x-small text color="secondary">{{ !viewer.all_association_types_showing ? "Select All" : "Select None" }}</v-btn>
					</v-list-item-title>
					<v-list-item-subtitle>
						<v-checkbox v-for="(option, name) in viewer.assoc_type_menu_options" :key="name" v-show="option.used" v-model="option.showing" @click="viewer.update_associations_to_show" hide-details dense><template v-slot:label><b v-if="option.count" class="mr-1" style="margin-left:-4px;font-size:10px;background-color:#666; border-radius:8px; padding:2px 5px; line-height:12px; color:#fff;">{{option.count}}</b>{{option.name}}</template></v-checkbox>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item v-if="viewer.has_copied_items"  :style="(viewer.has_associated_frameworks||viewer.editing_enabled)?'border-top:1px solid #ccc;':''">
				<v-list-item-content style="margin-top:-8px">
					<v-list-item-subtitle>
						<v-checkbox v-model="viewer.assoc_framework_menu_items.copies.showing" @click="viewer.update_associations_to_show" hide-details dense><template v-slot:label><b class="mr-1" style="margin-left:-4px;font-size:10px;background-color:#666; border-radius:8px; padding:2px 5px; line-height:12px; color:#fff;">{{viewer.assoc_framework_menu_items.copies.count}}</b>Show originals of copied items</template></v-checkbox>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" :outlined="viewer.wrap_item_text" @click="toggle_wrap"><v-icon style="transform:rotate(90deg); margin-left:-2px;margin-bottom:-2px;">fas fa-turn-down</v-icon></v-btn></template>Wrap item text: {{viewer.wrap_item_text?'ON':'OFF'}}</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" :class="(viewer.open_node_count==0)?'k-cfv-toolbar-disabled':''" @click="collapse_all"><v-icon>fas fa-angles-up</v-icon></v-btn></template>Collapse all items</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" :outlined="viewer.show_identifiers_in_tiles" @click="toggle_guids"><v-icon>fas fa-id-card</v-icon></v-btn></template>{{viewer.show_identifiers_in_tiles?'Hide item identifiers':'Show item identifiers'}}</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" @click="show_tiles"><v-icon>fas fa-table-cells-large</v-icon></v-btn></template>Switch to Tile View</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!$vuetify.breakpoint.xs" x-small icon color="#666" @click="show_table"><v-icon>fas fa-table-list</v-icon></v-btn></template>Switch to Table View</v-tooltip>
	<v-tooltip bottom :attach="'.k-cfv-toolbar'"><template v-slot:activator="{on}"><v-btn v-on="on" x-small icon color="#666" @click="show_help"><v-icon>fas fa-info-circle</v-icon></v-btn></template>What’s this?</v-tooltip>
</div></template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		viewer: { required: true },
	},
	data() { return {
		new_crosswalk_menu_showing: false,
	}},
	computed: {
		...mapState(['framework_records']),
		...mapGetters(['signed_in']),
		show_edit_btn() { return !this.viewer.embedded_mode && this.viewer.user_can_edit && !this.viewer.viewing_archive },
		show_associations_btn() { return !this.viewer.hide_associations },
		assoc_framework_list() {
			// make a special list of the associated frameworks here, with category abbreviations added and sorted by name
			let arr = []
			for (let fid in this.viewer.assoc_framework_menu_items) {
				if (fid == 'copies') continue
				
				let fr = this.framework_records.find(x=>x.lsdoc_identifier == fid)
				if (!fr) {	// shouldn't happen
					console.warn('CASEFVToolbar: bad framework record ' + fid)
					continue
				}
				arr.push({
					fid: fid, 
					afmi: this.viewer.assoc_framework_menu_items[fid], 
					title: U.framework_title_with_category(fr),
				})
			}
			arr.sort((a,b) => U.natural_sort(a.title, b.title))
			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_comments() {
			this.viewer.toggle_show_comments()
		},
		toggle_edit() {
			this.viewer.toggle_editing()
		},
		collapse_all() {
			this.viewer.collapse_all()
		},
		toggle_wrap() {
			// wrap is always on for a phone-sized screen
			// if (this.$vuetify.breakpoint.xs) return
			this.viewer.wrap_item_text = !this.viewer.wrap_item_text
		},
		toggle_guids() {
			this.viewer.show_identifiers_in_tiles = !this.viewer.show_identifiers_in_tiles
		},
		show_tiles() {
			this.viewer.enter_tile_mode()
		},
		show_table() {
			this.viewer.table_mode_start_node = null
			this.viewer.viewer_mode = 'table'
		},
		show_help() {
			vapp.show_help('tree_toolbar')
		},
		open_crosswalk(lsdoc_identifier) {
			vapp.framework_list_component.open_crosswalk([this.viewer.framework_record.lsdoc_identifier, lsdoc_identifier])
		},
		crosswalk_edit_btn_clicked() {
			 if (!this.$store.state.crosswalk_warning_issued) {
				this.$confirm({
					// title: 'Are you a witch?',
					text: `Note that the Crosswalk Editor is currently a <b>BETA</b> feature in ${this.$store.state.site_config.app_name}. Using this editor may cause unintended consequences.`,
					acceptText: 'Enable Beta Crosswalk Editor',
					// cancelText: 'I sink',
					dialogMaxWidth: 500,
					// focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.$store.commit('set', ['crosswalk_warning_issued', true])
					this.crosswalk_edit_btn_clicked()
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			 }
			 this.new_crosswalk_menu_showing = !this.new_crosswalk_menu_showing
		},
	}
}
</script>

<style lang="scss">
.k-cfv-toolbar {
	position:absolute;
	left:52px;
	top:12px;
	z-index:1;
	text-align:left;
	background-color: rgba(255,255,255,0.9);
	border-bottom:1px solid #ccc;
	height:28px;
	line-height:22px;
	text-align:center;
	padding:2px 4px;
	border-radius:10px 10px 0 0;
	pointer-events: auto;

	.v-btn--icon.v-size--x-small {
		width:22px;
		height:22px;
	}

	.v-btn {
		margin-left:2px;
		margin-right:2px;
		.fas, .far {
			font-size:14px!important;
		}
		.fa-id-card {
			font-size:13px!important;
		}

		.fa-info-circle {
			font-size:16px!important;
			color:$v-light-blue!important;
		}

	}

	.k-cfv-toolbar-disabled {
		.fas, .far {
			color:#666!important;
		}
	}

	.v-btn--outlined {
		border-color:#000!important;
		.fas, .far {
			color:#000!important;
		}
	}
}
</style>