import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		app_name() { 
			// look for site_config.app_product_name 
			let app_product_name = this.$store.state.site_config.app_product_name
			// if not found, use app_name (which defaults to 'Standards Satchel'
			if (!app_product_name) app_product_name = this.$store.state.site_config.app_name
			return sr('<span class="pink--text text--darken-4">$1</span>', app_product_name) 
		},
		framework_list_title() { return this.$store.state.site_config.framework_list_title },
		comp_noun() { return this.$store.state.site_config.comp_noun },
		framework_noun() { return this.$store.state.site_config.framework_noun },
		browsing_interface_title() { return this.$store.state.site_config.browsing_interface_title },
	},
	watch: {
	},
	methods: {
		link(doc, text) {
			return sr('<a class="k-help-internal-link" href="javascript:vapp.$refs.help.show(\'$1\',\'no_reposition\')">$2</a>', doc, text)
		}
	}
}
