<template>
	<v-dialog v-model="dialog_open" max-width="1200" persistent scrollable>
		<v-card>
			<div class="py-3 px-4" style="font-size:18px; border-bottom:1px solid #999">Framework Access: <b>{{framework_record.json.CFDocument.title}}</b></div>
			<v-card-text class="mt-3" style="font-size:16px">
				<FrameworkUsageChart :lsdoc_identifier="framework_record.lsdoc_identifier" :count_type="count_type" />

				<div><span v-html="access_summary_html"></span></div>

				<div class="py-4 d-flex">
					<div style="flex:0 0 20%" class="mr-1"><v-select v-model="count_type" :items="count_types" label="Access Type" outlined dense hide-details></v-select></div>
					<div style="flex:0 1 150px" class="mr-2"><v-select dense outlined hide-details v-model="framework_access_selected_interval" :items="interval_array" label="Day(s) to Look Back"></v-select></div>

					<div style="flex:1 1 60%"><v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff"
					></v-text-field></div>
				</div>

				<v-data-table light dense
					:headers="headers"
					:items="framework_access_list"
					:sort-by="['access_date']"
					:sort-desc="[true]"
					:must-sort="true"
					:custom-filter="table_search_filter"
					:search="search"
					:footer-props="footer_options"
					class="k-framework-access-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td class="text-center"><nobr>{{date_string(item.access_date)}}</nobr></td>
						<td class="text-center">{{item.access_type}}</td>
						<td class="text-center">{{email_string(item.email)}}</td>
						<td class="text-center">{{item.requester}}</td>
					</tr></template>
				</v-data-table>

			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkUsageChart from './FrameworkUsageChart'

export default {
	components: { FrameworkUsageChart },
	props: {
		 framework_record: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		access_count: 0,
		access_summary_html: '',
		headers: [
			{ text: 'Access Date', align: 'center', sortable: true, value:'access_date' },
			{ text: 'Access Type', align: 'center', sortable: true, value:'access_type' },
			{ text: 'Email', align: 'center', sortable: true, value:'email' },
			{ text: 'Requester', align: 'center', sortable: true, value:'requester' },
		],
		footer_options: {
			itemsPerPageOptions: [50,100,-1],
		},
		search: '',
		interval_array: [
			{value:1, text: '1'},
			{value:2, text: '2'},
			{value:3, text: '3'},
			{value:4, text: '4'},
			{value:5, text: '5'},
			{value:6, text: '6'},
			{value:7, text: '7'},
		],
		count_types: [
			{ value:"webapp", text:"Web App" },
			{ value:"api", text:"CASE API" },
		],
		framework_access_hash: {},
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		framework_identifier() { return this.framework_record.lsdoc_identifier },

		// get/store count_type (webapp or api) in localstorage
		count_type: {
			get() { return this.$store.state.lst.framework_access_report_count_type },
			set(val) { this.$store.commit('lst_set', ['framework_access_report_count_type', val]) }
		},
		framework_access_selected_interval: {
			get() { return this.$store.state.framework_access_selected_interval },
			set(val) { this.$store.commit('set', ['framework_access_selected_interval', val]) }
		},

		// date key format 'mm_dd' ascending
		// framework_access_hash date keys may have gaps, if there were is a day when no one uses system
		framework_access_date_keys() {
			let access_days = []
			let today = new Date()
			let day_key = ''

			for (let i = 0; i < this.framework_access_selected_interval; ++i) {
				let today = new Date()
				day_key = date.format(new Date(today.setDate(today.getDate() - i)), 'MM_DD')
				access_days.push(day_key)
			}

			return access_days
		},

		// display records for selected framework looking back day(s) in lookback interval
		framework_access_list() {
			let fa_arr = []
			this.access_count = 0
			if (this.framework_access_date_keys.length === 0) return fa_arr

			// add rows for access in lookback interval
			// get counts of rows per framework
			for (let i = 0; i < this.framework_access_selected_interval; ++i) {
				let day_key = this.framework_access_date_keys[i]
				let accesses_in_day = (empty(this.framework_access_hash[day_key])) ? [] : this.framework_access_hash[day_key]
				for (let k = 0; k < accesses_in_day.length; ++k) {
					let fa = accesses_in_day[k]

					// if type doesn't match count_type, don't show
					if (fa.access_type != this.count_type) continue

					if (fa.framework_identifier !== this.framework_identifier) continue

					fa_arr.push(fa)
					++this.access_count
				}
			}

			// total counts of framework access during lookback interval from framework.json
			this.access_summary_html = sr('Accessed <b>$1</b> in the last <b>$2</b>',
				U.psn('time', this.access_count),
				U.psn('day', this.framework_access_selected_interval).replace('1 day', 'day')
			)

			return fa_arr
		}, // { framework_identifier, access_date, access_type, email, requester }

	},
	watch: {
	},
	created() {
		this.get_framework_access_list()
	},
	mounted() {
	},
	methods: {
		// get framework_access records for this framework when table loads
		get_framework_access_list() {
			let payload = {
				user_id: this.$store.state.user_info.user_id,
				days_interval: 8,
				framework_identifier: this.framework_identifier,
			}
			U.loading_start()
			U.ajax('get_framework_access_list', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					console.log('Error retrieving framework access records')
					return
				}

				// bucket framework access records by day, accessed by key format `mm_dd`, filter by lookback interval
				let hash = {}
				for (let fa of result.framework_access_list) {
					// combine fields from framework_access_report_fields into `requester`
					fa.requester = ''
					for (let field of this.$store.state.framework_access_report_fields) {
						// only use 'http_user_agent' field for api-type accesses
						if (fa[field]) {
							// skip 'http_user_agent' field for non-api-type accesses
							if (fa.access_type == 'api' || field != 'http_user_agent') {
								if (fa.requester) fa.requester += ' / '
								fa.requester += fa[field]
							}
						}
					}

					let date_key = date.format(U.convert_to_local_date(fa.access_date), 'MM_DD')
					if (!hash[date_key]) hash[date_key] = []
					hash[date_key].push(fa)
				}
				this.framework_access_hash = hash
			});
		},

		date_string(gmt_date) {
			return date.format(U.convert_to_local_date(gmt_date), 'M/D/YYYY h:mm A')	// Jan 1, 2019 3:12 PM
		},

		// email may be empty if user not logged in for WEBAPP or API usage
		email_string(email) {
			return (empty(email)) ? '--' : email
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check searchable fields
			if (this.email_string(item.email).toLowerCase().search(re) > -1) return true
			//else if (item.framework_identifier.toLowerCase().search(re) > -1) return true
			//else if (item.title.toLowerCase().search(re) > -1) return true
			else if (this.date_string(item.access_date).toLowerCase().search(re) > -1) return true
			else if (item.access_type.toLowerCase().search(re) > -1) return true
			else if (item.requester.toLowerCase().search(re) > -1) return true

			// if we get to here return false
			return false
		},

	}
}
</script>

<style lang="scss">
.k-framework-access-table {
	th {
		font-size: 14px;
		text-align: left;
	}
	td.framework {
		font-size:12px!important;
		text-align: left;
	}
	td.count {
		font-size:12px!important;
		text-align: right;
		width: 25px;
	}
}
</style>
