// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Info {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'system_role', '', ['', 'admin', 'editor', 'reviewer'])

		// for recording which comments the user has read: translate from format "5,6,10,23" to {5:1, 6:1, 10:1, 23:1}
		this.comment_reads = {}
		if (!empty(data.comment_reads)) {
			let arr = data.comment_reads.split(',')
			for (let comment_id of arr) this.comment_reads[comment_id] = 1
		}

		// framework notification settings
		this.notification_settings = {}
		if (!data.notification_settings) data.notification_settings = {}
		sdp(this.notification_settings, data.notification_settings, 'on', 'no')
		sdp(this.notification_settings, data.notification_settings, 'email', this.email)
		sdp(this.notification_settings, data.notification_settings, 'list_type', 'all', ['all', 'agencies', 'frameworks'])
		sdp(this.notification_settings, data.notification_settings, 'agency_list', [])
		sdp(this.notification_settings, data.notification_settings, 'framework_list', [])
	}

	comment_reads_for_save() {
		// when we save comment_reads, convert back to string format
		let s = ''
		for (let comment_id in this.comment_reads) {
			if (s != '') s += ','
			s += comment_id
		}
		return s
	}
}
window.User_Info = User_Info
