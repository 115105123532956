<!-- Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams -->
<template><div>
	<div class="k-framework-updates-table" style="margin:0 -8px 8px -8px"><div class="orange lighten-4 d-flex align-center py-1 px-2" style="border-radius:12px;">
		<b class="mr-3">Framework Archives</b>
		<v-btn small color="orange darken-2" class="k-tight-btn" dark @click="view_framework_update_report"><v-icon small class="mr-2">fas fa-table</v-icon>Show Update Counts by Type/Date</v-btn>
		<v-spacer/>
		<v-btn v-if="editing_enabled&&!is_mirror" small class="ml-3 mr-1 my-1 k-tight-btn" color="primary" @click="save_new_archive"><v-icon small class="mr-2">fas fa-save</v-icon>Save a new archive of this framework</v-btn>
		<v-btn v-if="user_can_edit&&is_mirror" small class="ml-3 mr-1 my-1 k-tight-btn" color="primary" @click="save_new_archive"><v-icon small class="mr-2">fas fa-clone</v-icon>Check for updates to this mirrored framework</v-btn>
	</div></div>
	<div style="margin:0 -8px 0 -8px">
		<div v-if="framework_archives" style="clear:both">
			<div class="pt-1 pb-3 d-flex align-center">
				<div class="text-center" style="font-size:14px">Show Date:</div>
				<div>
					<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn ml-2" v-model="archive_date_to_show" mandatory>
						<v-btn x-small light class="k-nocaps-btn k-tight-btn k-toggle-btn-active-class-off" :value="'date_archived'" @click.stop="">Archive Was Saved</v-btn>
						<v-btn x-small light class="k-nocaps-btn k-tight-btn" :value="'archive_framework_date'" @click.stop="">Framework Last Changed</v-btn>
					</v-btn-toggle>
				</div>

				<div class="ml-6">
					<div class="ml-6"><v-checkbox class="mt-0 pt-0" v-model="col_option_details" hide-details><template v-slot:label><span style="font-size:14px">Show update details</span></template></v-checkbox></div>
					<v-btn-toggle v-show="col_option_details" dense active-class="k-toggle-btn-active-class" class="k-toggle-btn ml-2" v-model="update_summary_field" mandatory>
						<v-btn x-small light class="k-nocaps-btn k-tight-btn" :value="'comparison_summary'" @click.stop="">Archive-to-archive</v-btn>
						<v-btn x-small light class="k-nocaps-btn k-tight-btn k-toggle-btn-active-class-off" :value="'update_report_summary'" @click.stop="">   From current   </v-btn>
					</v-btn-toggle>
				</div>

				<div class="ml-6">
					<div class="ml-6"><v-checkbox class="mt-0 pt-0" v-model="col_option_auto_archives" hide-details><template v-slot:label><span style="font-size:14px">Show auto-archives ({{n_auto_archives}})</span></template></v-checkbox></div>
				</div>

				<v-spacer/>

				<div style="flex:1 1 auto" class="ml-4"><v-text-field
					v-model="search"
					prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
					label="Search" single-line hide-details outlined dense background-color="#fff"
				></v-text-field></div>
			</div>

			<v-data-table light dense
				:headers="headers"
				hide-default-header
				:items="framework_archives"
				:sort-by="['timestamp']"
				:sort-desc="[true]"
				:must-sort="true"
				:custom-filter="table_search_filter"
				:search="search"
				:footer-props="footer_options"
				:items-per-page="50"
				class="k-framework-updates-table k-framework-updates-all-archives-table"
			>
				<template v-slot:header><thead>
					<tr v-if="!col_option_details" class="k-framework-updates-table-header">
						<th style="cursor:default" class="text-left k-framework-updates-table-th-left" v-html="(archive_date_to_show == 'archive_framework_date') ? 'Framework Last Change Date' : 'Date Archive Was Saved'"></th>
						<th style="cursor:default" class="text-left pl-12">Stamped / Description</th>
						<th style="cursor:default" class="text-center">Archived By</th>
						<th style="cursor:default; border-left:1px solid #ccc;" class="text-center">Item Count</th>
						<th style="cursor:default; border-left:1px solid #ccc;" class="text-center">Assoc Count</th>
						<th style="cursor:defaul; border-left:1px solid #ccc;t" class="k-framework-updates-table-th-right"></th>
					</tr>
					<tr v-if="col_option_details" class="k-framework-updates-table-header">
						<th style="cursor:default" class="text-left k-framework-updates-table-th-left" v-html="(archive_date_to_show == 'archive_framework_date') ? 'Framework<br>Last Change Date' : 'Date Archive Was Saved'"></th>
						<th style="cursor:default; border-left:1px solid #ccc;" class="text-center">Item<br>Count</th>
						<th style="cursor:default" class="text-center">Item<br>Code/Stmt<br>Updates</th>
						<th style="cursor:default" class="text-center">Item<br>Updates<br>Other</th>
						<th style="cursor:default" class="text-center">Item<br>Moves</th>
						<th style="cursor:default" class="text-center">Items<br>Created</th>
						<th style="cursor:default" class="text-center">Items<br>Deleted</th>
						<th style="cursor:default; border-left:1px solid #ccc;" class="text-center">Assoc<br>Count</th>
						<th style="cursor:default" class="text-center">Assocs<br>Updated</th>
						<th style="cursor:default" class="text-center">Assocs<br>Created</th>
						<th style="cursor:default" class="text-center">Assocs<br>Deleted</th>
						<th style="cursor:default; border-left:1px solid #ccc;" class="k-framework-updates-table-th-right"></th>
					</tr>
				</thead></template>
				<template v-slot:item="{ item }"><tr v-show="row_visible(item)" :class="row_class(item)">
					<td class="text-left pl-2" :class="!col_option_details?'pr-0':'pr-2'"><div class="d-flex align-center">
						<v-checkbox v-if="editing_enabled" v-visible="item.archive_fn" class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="selected_archives[item.archive_fn]"></v-checkbox>
						<nobr v-if="archive_date_to_show=='date_archived'" v-html="date_string(item.timestamp, item.open_edit_archive, item.archive_type)"></nobr>
						<nobr v-if="archive_date_to_show=='archive_framework_date'" v-html="date_string(item.archive_lastChangeDateTime, item.open_edit_archive, item.archive_type)"></nobr>
					</div></td>
					<td v-if="!col_option_details"><div class="d-flex align-start"><v-icon v-visible="has_note(item)" color="amber darken-4" small class="mr-2">fas fa-stamp</v-icon><div v-html="archive_note(item)"></div></div></td>
					<td v-if="!col_option_details" class="text-center"><nobr>{{item.email}}</nobr></td>
					<!-- <td class="text-center">{{archive_type(item.archive_type)}}</td> -->
					
					<td class="text-center orange--text text--darken-4" style="border-left:1px solid #ccc; font-weight:bold;">{{item.item_count}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].item_updates_code_or_statement>0)?item[update_summary_field].item_updates_code_or_statement:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].item_updates_not_code_or_statement>0)?item[update_summary_field].item_updates_not_code_or_statement:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].item_updates_moved>0)?item[update_summary_field].item_updates_moved:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].items_created>0)?item[update_summary_field].items_created:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].items_deleted>0)?item[update_summary_field].items_deleted:''}}</td>

					<td class="text-center orange--text text--darken-4" style="border-left:1px solid #ccc; font-weight:bold;">{{item.assoc_count_not_child}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].assocs_updated>0)?item[update_summary_field].assocs_updated:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].assocs_created>0)?item[update_summary_field].assocs_created:''}}</td>
					<td v-if="col_option_details" class="text-center" style="font-weight:bold;">{{(item[update_summary_field]&&item[update_summary_field].assocs_deleted>0)?item[update_summary_field].assocs_deleted:''}}</td>

					<td class="text-center pl-1" style="border-left:1px solid #ccc"><nobr v-if="item.archive_type!='current'">
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-visible="!row_showing(item)" small icon color="orange darken-2" class="mr-1" @click="compare_archive(item)"><v-icon small>fas fa-table-list</v-icon></v-btn></template>Compare to current version in table</v-tooltip>
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-visible="!row_showing(item)" small icon color="orange darken-2" @click="track_changes(item)"><v-icon small>fas fa-exchange-alt</v-icon></v-btn></template>Track changes to this version</v-tooltip>
						<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="!editing_enabled" v-on="on" v-visible="!row_showing(item)" small icon color="orange darken-2" @click="view_archive(item, 'view')"><v-icon small>fas fa-glasses</v-icon></v-btn></template>View this archived version</v-tooltip>
						<v-menu :transition="false" nudge-top="8">
							<template v-slot:activator="{on}"><v-btn v-show="editing_enabled" v-on="on" small icon color="orange darken-2" class="ml-1"><v-icon small>fas fa-ellipsis-v</v-icon></v-btn></template>
							<v-list dense>
								<v-list-item v-if="!row_showing(item)" @click="view_archive(item, 'view')"><v-list-item-icon><v-icon small>fas fa-glasses</v-icon></v-list-item-icon><v-list-item-title>View this archived version…</v-list-item-title></v-list-item>
								<v-list-item @click="edit_archive_notes(item)"><v-list-item-icon><v-icon small>fas fa-stamp</v-icon></v-list-item-icon><v-list-item-title>{{has_note(item) ? 'Edit archive description…' : 'Stamp archive and enter description…'}}</v-list-item-title></v-list-item>
								<v-list-item v-if="item.open_edit_archive=='no'" @click="delete_archive(item, item.timestamp)"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this archived version…</v-list-item-title></v-list-item>
								<v-list-item v-if="!is_mirror" @click="restore_archive(item)"><v-list-item-icon><v-icon small>fas fa-history</v-icon></v-list-item-icon><v-list-item-title>Restore to this archived version…</v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</nobr></td>

				</tr></template>
			</v-data-table>
			<div v-if="editing_enabled" class="mt-3">
				<v-btn small color="secondary" class="k-tight-btn mr-2" @click="select_non_annotated">Select all non-stamped archives</v-btn>
				<v-btn small color="red darken-2" dark @click="delete_selected"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Selected</v-btn>
			</div>
			<div v-if="user_can_edit&&!editing_enabled" class="mt-2 mx-auto py-2 text-center" style="font-size:16px">To restore to an archive, you must first <v-btn small color="primary" class="ml-2" @click="check_out_for_editing">enable editing</v-btn></div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: { },
	props: {
		framework_records: { required: true },
		framework_identifier: { type: String, required: true },
		update_records: { type: Object, required: true },
		parent_component: { required: true },

		// lsdoc_identifier: { type: String, required: true },
		// view_archive_fn: { type: String, required: true },
	},
	data() { return {
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		show_info_dialog:false,
		selected_archives: {},

		view_archive_fn: '',	// from ManageArchives...
	}},
	computed: {
		...mapState(['user_info', 'last_managed_archive']),
		...mapGetters([]),
		framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.framework_identifier) },
		// update_record() { return this.update_records[this.framework_identifier] },
		framework_archives() { 
			// framework_archives are held in the store when we get them; that way we don't keep pulling them over and over if the user goes back and forth between different archive views
			// here we get the archives for our current framework
			return this.$store.state.framework_archives[this.framework_identifier] 
		},
		editing_enabled: {
			get() { return this.$store.state.editing_enabled},
			set(val) { this.$store.commit('set', ['editing_enabled', val])}
		},
		user_can_edit() {
			console.log('user_can_edit', this.framework_identifier)
			// no editing or admining or aligning when on a phone!
			if (!this.$store.getters.signed_in || this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return false
			return vapp.is_granted('edit_framework', this.framework_identifier)
		},
		archive_date_to_show: {
			get() { return this.$store.state.lst.archive_date_to_show },
			set(val) { this.$store.commit('lst_set', ['archive_date_to_show', val]) }
		},
		col_option_details: {
			get() { return this.$store.state.lst.archive_col_option_details },
			set(val) { this.$store.commit('lst_set', ['archive_col_option_details', val]) }
		},
		col_option_auto_archives: {
			get() { return this.$store.state.lst.archive_col_option_auto_archives },
			set(val) { this.$store.commit('lst_set', ['archive_col_option_auto_archives', val]) }
		},
		update_summary_field: {	// comparison_summary or update_report_summary
			get() { return this.$store.state.lst.archive_update_summary_field },
			set(val) { this.$store.commit('lst_set', ['archive_update_summary_field', val]) }
		},
		n_auto_archives() {
			let n = 0
			for (let archive of this.framework_archives) if (archive.archive_note && !this.has_note(archive)) ++n
			return n
		},
		headers() {
			let arr = []

			// if (this.editing_enabled) arr.push({ text: '', align: 'center', sortable: false })

			if (this.archive_date_to_show == 'archive_framework_date') {
				arr.push({ text: 'Framework Last Change Date', align: 'center', sortable: false, value:'archive_lastChangeDateTime' })
			} else {
				arr.push({ text: 'Date Archive Was Saved', align: 'center', sortable: false, value:'timestamp' })
			}

			if (!this.col_option_details) {
				arr.push({ text: 'Stamped / Description', align: 'left', sortable: false, value:'archive_note' })
				arr.push({ text: 'Archived By', align: 'center', sortable: false, value:'email' })
				arr.push({ text: 'Item Count', align: 'center', sortable: false, value:'item_count' })
				arr.push({ text: 'Assn Count', align: 'center', sortable: false, value:'assoc_count_not_child' })

			} else {
				arr.push({ text: 'Item Count', align: 'center', sortable: false, value:'item_count' })
				arr.push({ text: 'Item Code/Stmt Updates', align: 'center', sortable: false, value: this.update_report_summary + '.item_updates_code_or_statement' })
				arr.push({ text: 'Item Updates Other', align: 'center', sortable: false, value: this.update_report_summary + '.item_updates_not_code_or_statement' })
				arr.push({ text: 'Item Moves', align: 'center', sortable: false, value: this.update_report_summary + '.item_updates_moved' })
				arr.push({ text: 'Items Created', align: 'center', sortable: false, value: this.update_report_summary + '.items_created' })
				arr.push({ text: 'Items Deleted', align: 'center', sortable: false, value: this.update_report_summary + '.items_deleted' })

				arr.push({ text: 'Assoc Count', align: 'center', sortable: false, value:'assoc_count_not_child' })
				arr.push({ text: 'Assocs Updated', align: 'center', sortable: false, value: this.update_report_summary + '.assocs_updated' })
				arr.push({ text: 'Assocs Created', align: 'center', sortable: false, value: this.update_report_summary + '.assocs_created' })
				arr.push({ text: 'Assocs Deleted', align: 'center', sortable: false, value: this.update_report_summary + '.assocs_deleted' })

			}

			arr.push({ text: '', align: 'center', sortable: false, })
			return arr
		},
		is_mirror() { return this.framework_record.ss_framework_data.is_mirror === 'yes' },
		archive_button_text() {
			return (!this.is_mirror) ? '' : ''
		},
		at_least_one_archive_selected() {
			for (let archive_fn in this.selected_archives) {
				if (this.selected_archives[archive_fn] == true) return true
			}
			return false
		},
	},
	watch: {
		framework_archives() {
			if (this.framework_archives == null) {
				this.load_framework_archive_list()
			} else {
				this.create_selected_archives()
			}
		}
	},
	created() {
		// if the framework_record doesn't exist, go back to the update_type report
		if (!this.framework_record) {
			this.parent_component.report_type = 'update_type'
			return
		} else {
			this.$store.commit('set', ['last_framework_update_report', this.framework_identifier])
		}

		vapp.framework_update_report_framework_archives = this

		// get framework_archives if we don't already have them
		if (!this.framework_archives) this.load_framework_archive_list()
	},
	mounted() {
	},
	methods: {
		show_help() { vapp.show_help('archives') },

		load_framework_archive_list() {
			this.$store.dispatch('load_framework_archive_list', this.framework_identifier).then(()=>{
				this.create_selected_archives()
			})
		},

		create_selected_archives() {
			let sa = {}	// object for selected_archives
			for (let a of this.framework_archives) {
				if (a.open_edit_archive != 'yes') {
					sa[a.archive_fn] = false
				}
			}
			this.selected_archives = sa
		},

		row_visible(item) {
			// don't show unstamped rows when col_option_auto_archives is false -- but do show the current framework one
			return (this.col_option_auto_archives || !item.archive_note || this.has_note(item))
		},

		row_class(item) {
			if (item.archive_fn == this.last_managed_archive) return 'k-framework-updates-all-archives-table-last-managed-row'
			if (this.has_note(item)) return 'k-framework-updates-all-archives-table-stamped-row'
			return ''
		},

		row_showing(item) {
			return (this.view_archive_fn == item.archive_fn)
		},

		date_string(gmt_date, open_flag, type) {
			if (type == 'current' && this.archive_date_to_show == 'date_archived') return ''
			if (empty(gmt_date)) return '–'
			if (open_flag == 'yes') return 'Open edit archive'
			return date.format(U.convert_to_local_date(gmt_date, 0), 'M/D/YYYY h:mm A')
		},

		archive_type(type) {
			if (type == 'current') return ''
			return (type == 'by_user') ? 'manual' : 'auto'
		},

		has_note(item) {
			return !empty(item.archive_note) && item.archive_note != '--' && item.archive_note != 'mirror auto-update'
		},

		archive_note(item) {
			// keep this in synch with code in CASEEntityHistory
			let show_note = item.archive_note
			if (show_note == 'mirror auto-update') show_note = 'Mirror auto-update'
			else if (empty(show_note)) show_note = '<i>Current framework</i>'
			else if (empty(show_note) || show_note == '--') {
				if (item.open_edit_archive == 'yes') show_note = 'Current auto-archive'
				else if (item.archive_type == 'checkout') show_note = '<i>Auto-archived prior to edits</i>'
				else if (item.archive_type == 'prior_to_restore') show_note = sr("Archived prior to restoring to archive from $1", this.date_string(item.restored_from_timestamp))
			}

			return show_note
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check email
			if (item.email && item.email.toLowerCase().search(re) > -1) return true
			else if (this.archive_type(item.archive_type).toLowerCase().search(re) > -1) return true
			else if (this.date_string(item.timestamp, 'no').toLowerCase().search(re) > -1) return true
			else if (item.archive_note && item.archive_note.toLowerCase().search(re) > -1) return true

			// if we get to here return false
			return false
		},

		// NOTE for fns below: if we're viewing this report, we must have already loaded this framework in the viewer
		save_new_archive() {
			vapp.case_tree_component.archive_framework()
		},

		track_changes(archive) {
			// mark this as last_managed_archive, so that it gets highlighted in the table
			this.$store.commit('set', ['last_managed_archive', archive.archive_fn])

			// reset entity_archives so they'll be reloaded when we re-open change history for entities [not sure if this is used...]
			this.$store.commit('set', ['entity_archives', {}])

			vapp.case_tree_component.track_changes(archive.archive_fn, archive.timestamp, this.archive_note(archive))
			this.parent_component.hide_report()
		},

		compare_archive(archive) {
			// mark this as last_managed_archive, so that it gets highlighted in the table
			this.$store.commit('set', ['last_managed_archive', archive.archive_fn])

			// reset entity_archives so they'll be reloaded when we re-open change history for entities [not sure if this is used...]
			this.$store.commit('set', ['entity_archives', {}])

			this.parent_component.return_to_report_value = 'framework_all_archives'
			this.parent_component.compare_to_archive(this.framework_identifier, ['current', archive])
		},

		view_archive(archive) {
			// mark this as last_managed_archive, so that it gets highlighted in the table
			this.$store.commit('set', ['last_managed_archive', archive.archive_fn])

			// reset entity_archives so they'll be reloaded when we re-open change history for entities [not sure if this is used...]
			this.$store.commit('set', ['entity_archives', {}])

			vapp.case_tree_component.view_archive(archive.archive_fn, this.date_string(archive.timestamp), this.archive_note(archive), null)
			this.parent_component.hide_report()
		},

		// restore_archive in parent, CASEFrameworkViewer, will call manage_archives svc.
		restore_archive(archive) {
			this.$confirm({
				title: 'Restore from Archive',
				text: 'This action will first save an archive of the open framework as it currently exists, then restore the framework to the selected archive. Please confirm that you wish to proceed.',
				acceptText: 'Restore',
				acceptColor: 'red',
			}).then(y => {
				vapp.case_tree_component.restore_archive(archive.archive_fn)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_archive_notes(archive) {
			// mark this as last_managed_archive, so that it gets highlighted in the table
			this.$store.commit('set', ['last_managed_archive', archive.archive_fn])

			let has_note = this.has_note(archive)

			this.$prompt({
				title: has_note ? 'Edit Archive Description' : 'Stamp Archive and Add Description',
				text: has_note ? '<p>Edit the description for this stamped archive:</p>' : '<p>Enter a description for this archive to “stamp” it:</p>',
				initialValue: (archive.archive_note == '--') ? '' : archive.archive_note,
				promptType: 'textarea',
				acceptText: has_note ? 'Save Edited Description' : 'Stamp Archive with this Description',
				dialogMaxWidth: 620,
			}).then(new_note => {
				// if unchanged or empty, don't save
				if (new_note == archive.archive_note || empty(new_note)) {
					return
				}

				U.loading_start()
				U.ajax('manage_archives', {action: 'edit_notes', archive_filename: archive.archive_fn, archive_note: new_note}, result=>{
					U.loading_stop()

					// reset entity_archives so they'll be reloaded when we re-open change history for entities
					this.$store.commit('set', ['entity_archives', {}])

					if (result.status != 'ok') { this.$alert('An error occured (archive-3)'); return; }
					this.load_framework_archive_list()
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_archive(archive) {
			// mark this as last_managed_archive, so that it gets highlighted in the table
			this.$store.commit('set', ['last_managed_archive', archive.archive_fn])

			this.$confirm({
				title: 'Delete Archive',
				text: 'Are you sure you want to delete this archive? This cannot be undone.',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				U.ajax('manage_archives', {action: 'delete', archive_filename: archive.archive_fn}, result=>{
					U.loading_stop()

					// reset entity_archives so they'll be reloaded when we re-open change history for entities
					this.$store.commit('set', ['entity_archives', {}])

					if (result.status != 'ok') { this.$alert('An error occured (archive-4)'); return; }
					this.load_framework_archive_list()
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_selected() {
			if (!this.at_least_one_archive_selected) {
				this.$alert('Select one or more archives to select before clicking the “DELETE SELECTED” button.')
				return
			}

			// turn this.selected_archives into an array
			let deleted_archive_fns = []
			for (let fn in this.selected_archives) {
				if (this.selected_archives[fn] == true) deleted_archive_fns.push(fn)
			}

			this.$confirm({
				title: 'Delete Archives',
				text: sr('Are you sure you want to delete $1? This cannot be undone.', ((deleted_archive_fns.length == 1) ? 'this archive' : 'these ' + deleted_archive_fns.length + ' archives')),
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				U.ajax('manage_archives', {action: 'delete_multiple', archive_filename: deleted_archive_fns}, result=>{
					U.loading_stop()

					// reset entity_archives so they'll be reloaded when we re-open change history for entities
					this.$store.commit('set', ['entity_archives', {}])

					if (result.status != 'ok') { this.$alert('An error occured (archive-5)'); return; }
					this.load_framework_archive_list()

					// scroll to top of list
					$('.k-manage-archives-dialog-text').scrollTop(0)
				});

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		select_non_annotated() {
			for (let archive of this.framework_archives) {
				if (archive.archive_type != 'checkout') continue
				if (archive.archive_note && archive.archive_note != '--') continue

				this.selected_archives[archive.archive_fn] = true
			}
		},

		check_out_for_editing() { 
			vapp.case_tree_component.check_out_for_editing() 
			this.headers.unshift({ text: '', align: 'center', sortable: false })
		},

		view_framework_update_report() {
			// this.parent_component.return_to_report_value = 'framework'
			this.parent_component.show_framework_report(this.framework_identifier, 'framework')
		},
	}
}
</script>

<style lang="scss">
.k-framework-updates-all-archives-table {
	td {
		vertical-align:middle!important;
	}
}

.k-framework-updates-all-archives-table-last-managed-row {
	background-color:$v-yellow-lighten-4!important;
}

.k-framework-updates-all-archives-table-stamped-row {
	background-color:$v-pink-lighten-5!important;
}
</style>
