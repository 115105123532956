<template><div class="k-sbs-comments">
	<!-- <div class="d-flex pr-1" v-if="!new_comment">
		<v-spacer/>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" text color="#555" class="k-tight-btn pl-1 pr-1 mr-2" @click="create_new_comment"><v-icon x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" class="mr-2">fas fa-plus</v-icon>Comment</v-btn>
		<v-btn x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" text color="#555" class="k-tight-btn pl-1 pr-1" @click="show_all_comments"><v-icon x-small :small="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm" class="mr-1">fas fa-comment</v-icon>Show All</v-btn>
	</div> -->
	<div v-if="new_comment" class="k-sbs-comments-new-tile-editor-wrapper"><CommentEditor :original_comment="new_comment" @editor_cancel="cancel_new_comment" :viewer="viewer" :item="item" :render_latex="true"/></div>
	<div v-if="item_top_level_comments.length>0">
		<TileComment v-for="(comment) in item_top_level_comments" :key="comment.comment_id" :comment="comment" :viewer="viewer" :item="item" :render_latex="true" v-show="$store.state.comments_show_resolved||!comment.resolved" />
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CommentEditor from './CommentEditor'
import TileComment from './TileComment'

export default {
	components: { TileComment, CommentEditor },
	props: {
		framework_record: { type: Object, required: true },
		item_identifier: { type: String, required: true },
		// caller has to include item if the "suggest edits" functionality is to be used
		item: { required: false, default() { return null } },
		viewer: { required: true },
	},
	data() { return {
		new_comment: null,
	}},
	computed: {
		...mapState(['user_info', 'comments']),
		...mapGetters(['comments_hash']),
		framework_identifier() { return this.framework_record.lsdoc_identifier },
		item_top_level_comments() {
			if (!this.comments_hash[this.framework_identifier] || !this.comments_hash[this.framework_identifier][this.item_identifier]) return []
			let arr = []
			for (let c of this.comments_hash[this.framework_identifier][this.item_identifier]) {
				// framework_identifier should always match, but check just in case
				if (c.parent_comment_id == 0) {
					arr.push(c)
				}
			}

			arr.sort((a,b) => {
				// sort replies so that newer ones come first
				return b.created_at - a.created_at
			})

			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.tile_comments_component = this
	},
	mounted() {
	},
	methods: {
		create_new_comment() {
			// look for previously-selected comment group
			let comment_group_id = this.$store.state.lst.default_comment_group_id[this.framework_identifier]
			if (!empty(comment_group_id) && comment_group_id != '0') {
				// make sure we still have this comment group
				let cg = this.$store.state.comment_groups.find(x=>x.comment_group_id == comment_group_id)
				if (!cg) comment_group_id = null
			}
			if (empty(comment_group_id)) {
				// if none found and we have comment groups, find the latest-created one (which will be the first one that matches the framework identifier)
				let cg = this.$store.state.comment_groups.find(x=>x.framework_identifier == this.framework_identifier)
				if (cg) comment_group_id = cg.comment_group_id
				// if all else fails, set to personal comment
				else comment_group_id = 0
			}
			
			this.new_comment = new Comment({
				item_identifier: this.item_identifier,
				framework_identifier: this.framework_identifier,
				author_user_id: this.user_info.user_id,
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
				comment_group_id: comment_group_id,
			})
			console.log(this.new_comment)
		},

		cancel_new_comment() {
			this.new_comment = null
		},

		show_all_comments() {
			// set comments_table_starting_comment_id to make sure the first comment from this item shows up in the page that's showing in the datatable
			if (this.comments_hash[this.framework_identifier] && this.comments_hash[this.framework_identifier][this.item_identifier] && this.comments_hash[this.framework_identifier][this.item_identifier][0]) {
				this.viewer.comments_table_starting_comment_id = this.comments_hash[this.framework_identifier][this.item_identifier][0].comment_id
			} else {
				// else start on the first page
				this.viewer.comments_table_starting_comment_id = -1
			}
			console.log('show_all_comments: ' + this.viewer.comments_table_starting_comment_id)

			this.viewer.show_comments_table = true
		},
	}
}
</script>

<style lang="scss">
.k-sbs-comments {
	padding:0;
	font-size:14px;
	line-height:16px;
	white-space:nowrap;
	p {
		display:inline;
		margin:0 6px 0 0!important;
	}

	.k-comment-wrapper {
		border:0;
		padding-left:4px;
	}
	.k-comment-wrapper {
		// background-color:transparent;
	}

	.k-comment {
		padding:0;
	}

	.k-comment-author-initials {
		display:block;
		float:left;
		margin-right:4px;
		font-weight:bold;
	}

	.k-comment-author-line {
		display:none;
	}
}

// style for .k-sbs-comments-new-tile-editor-wrapper is set in TileComment
</style>
